/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';
import staticImageUrl from 'helpers/staticImageUrl';
import Link from 'components/Link';

const AppStoreButton = props => (
  <Link target="_blank" {...props}>
    <img src={staticImageUrl('/img/buttons/appstore.svg')} alt="App Store" />
  </Link>
);

AppStoreButton.propTypes = {
  href: string,
};

AppStoreButton.defaultProps = {
  href: 'https://itunes.apple.com/nl/app/blendle/id947936149?mt=8',
};

export default AppStoreButton;
