/* eslint-disable react/no-danger */
import React from 'react';
import { pure } from '@blendle/recompose';
import { v4 as uuidv4 } from 'uuid';
import Environment from 'environment';

const DaisyconPixel = () => {
  const programmId = Environment.daisyconPixelId;
  const transactionId = uuidv4();

  return (
    <React.Fragment>
      <img
        src={`https://www.lt45.net/t/?si=${programmId}&ti=${transactionId}`}
        style={{ border: '0px', height: '1px', width: '1px' }}
        alt="Daisycon Affiliate Marketing"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(d){var a,b,c;if(/comp|inter|loaded/.test(d.readyState)){return _a();}d.addEventListener('DOMContentLoaded', _a);function _a(){setTimeout(function(){a=d.getElementsByTagName('img');for(b=0;b<a.length;b++){try{if(/[s|c]i=/i.exec(a[b].src)&&(!a[b].offsetHeight||a[b].offsetHeight<1)){c=d.createElement('img');c.height=c.width=1;c.id='news';c.className='net';c.src='//'+Math.round(+new Date()/83000)+'.'+c.id+'tat.'+c.className+'/ab/'+a[b].src.substring(a[b].src.indexOf('?'),a[b].src.length)+'&v3';d.body.appendChild(c);}}catch(e){}}},100)}})(document);
          `,
        }}
      />
    </React.Fragment>
  );
};

export default pure(DaisyconPixel);
