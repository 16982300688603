import * as Sentry from '@sentry/browser';

/* eslint-disable */
window.__consoleErrors = [];

function pushErrorMessage(message) {
  window.__consoleErrors.push(message);
}

function compressData(data) {
  if (typeof data === 'string' && data.length > 200) {
    return data.substr(0, 200) + '...';
  }

  if (typeof data === 'object') {
    var res = {};
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        res[key] = compressData(data[key]);
      }
    }
    return res;
  }

  return data;
}

const setExtraScope = (scope, options) => {
  for (let prop in options) {
    if (Object.prototype.hasOwnProperty.call(options, prop)) {
      scope.setExtra(prop, options[prop]);
    }
  }
};

var sentryOptions = {
  dsn: window._sentryApiKey,
  release: window._commit,
  environment: window._environment,
  ignoreErrors: [
    'Network Error',
    'Internal Server Error',
    'TiApp is not defined',
    'Image at url',
    'Unable to load library',
    'pktAnnotationHighlighter',
  ],
};

// Setup wrapping ErrorLogger
var ErrorLogger = function() {
  var env = window._environment;
  var urlOverwrite = location.href.match(/\?enableSentry/);

  this.active =
    env === 'production' ||
    env === 'staging' ||
    env === 'approval' ||
    urlOverwrite;

  if (this.active) {
    Sentry.init(sentryOptions);

    // find googlebot errors, to fix seo related error
    if (navigator.userAgent.match(/googlebot/i)) {
      Sentry.configureScope(scope =>
        scope.setTag('googlebot', navigator.userAgent),
      );
    }
  }
};

ErrorLogger.prototype = {
  captureException: function(exception, options) {
    if (!this.active) {
      return;
    }

    // enhance the error report with additional data
    options = options || {};
    options.extra = options.extra || {};
    for (var key in exception) {
      if (exception.hasOwnProperty(key)) {
        options.extra[key] = compressData(exception[key]);
      }
    }

    Sentry.withScope(scope => {
      setExtraScope(scope, options);
      Sentry.captureException(exception);
    });
  },

  captureMessage: function(message, options) {
    if (!this.active) {
      return;
    }

    Sentry.withScope(scope => {
      setExtraScope(scope, options || {});
      Sentry.captureMessage(message);
    });
  },

  setUser: function(userId) {
    Sentry.setUser({ user_id: userId });
  },
};

window.ErrorLogger = new ErrorLogger();

window.onerror = function(message, url, line, column, exception) {
  if (window._environment === 'test' || window._environment === 'local') {
    pushErrorMessage(message);
  }

  window.ErrorLogger.captureException(exception);
};

window.onunhandledrejection = function(obj) {
  if (
    window.console &&
    window.console.error &&
    !navigator.userAgent.match(/Chrome/)
  ) {
    window.console.error('Unhandled promise rejection ' + obj.reason);
  }

  window.ErrorLogger.captureException(obj.reason);
};

if (window._environment === 'test' || window._environment === 'local') {
  var errorLog = window.console.error;
  window.console.error = function() {
    pushErrorMessage(arguments[0]);

    errorLog.apply(window.console, arguments);
  };
}
