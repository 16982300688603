// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3lyeO{margin-top:85px;margin-bottom:32px}@media (min-width: 600px){.container-3lyeO{margin-bottom:78px}}.covers-3lUN3{width:100%;height:330px;background-position-x:center;background-repeat:repeat no-repeat;background-size:cover}@media (min-width: 600px){.covers-3lUN3{height:500px}}@media (min-width: 1150px){.smallImage-1HvpZ{background-size:contain}}@media (min-width: 1850px){.wideImage-3K8VM{background-size:contain}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3lyeO",
	"covers": "covers-3lUN3",
	"smallImage": "smallImage-1HvpZ",
	"wideImage": "wideImage-3K8VM"
};
module.exports = exports;
