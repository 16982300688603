import alt from 'instances/altInstance';
import CookieConsentActions from 'actions/CookieConsentActions';

class CookieConsentStore {
  constructor() {
    this.bindActions(CookieConsentActions);

    this.state = {
      hasGivenConsent: null,
      showCookieBar: null,
    };
  }

  onSetConsentAndHideBar({ hasGivenConsent }) {
    this.setState({
      hasGivenConsent,
      showCookieBar: false,
    });
  }

  onShowCookieBar(showCookieBar) {
    this.setState({
      showCookieBar,
    });
  }
}

export default alt.createStore(CookieConsentStore, 'CookieConsentStore');
