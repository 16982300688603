import React, { memo } from 'react';
import { shape, string, arrayOf } from 'prop-types';
import styledModule from 'helpers/styledModule';
import { propEq, find, pipe, isNil, not } from 'ramda';
import { withRouter } from 'react-router';
import CSS from './style.scss';
import MobileSearchBar from './MobileSearchBar';
import DesktopSearchBar from './DesktopSearchBar';

const DesktopContainer = styledModule.div(CSS.desktopContainer);
const MobileContainer = styledModule.div(CSS.mobileContainer);

const isOnSearchModule = pipe(find(propEq('module', 'search')), isNil, not);

export const SearchBarComponent = memo(({ routes, params }) => {
  const isOnSearchRoute = isOnSearchModule(routes);

  return (
    <>
      <MobileContainer>
        <MobileSearchBar
          isOnSearchRoute={isOnSearchRoute}
          searchQueryFromUrl={params.splat}
          data-testid="desktop-search-bar"
        />
      </MobileContainer>
      <DesktopContainer>
        <DesktopSearchBar
          isOnSearchRoute={isOnSearchRoute}
          searchQueryFromUrl={params.splat}
          data-testid="mobile-search-bar"
        />
      </DesktopContainer>
    </>
  );
});

SearchBarComponent.displayName = 'SearchBar';

SearchBarComponent.propTypes = {
  params: shape({
    splat: string,
  }).isRequired,
  routes: arrayOf(
    shape({
      module: string,
    }),
  ).isRequired,
};

export default withRouter(SearchBarComponent);
