// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hub-2DsDG{position:fixed;top:0;right:0;z-index:10001}@media only screen and (max-width: 600px){.hub-2DsDG{top:initial;bottom:0;left:0;width:100%}}\n", ""]);
// Exports
exports.locals = {
	"hub": "hub-2DsDG"
};
module.exports = exports;
