import prefixProp from 'prefix-style';

export default style => {
  const prefixedStyle = {};

  Object.keys(style).forEach(prop => {
    const prefixedProp = prefixProp(prop);

    if (prefixedProp) {
      prefixedStyle[prefixedProp] = style[prop];
    }
  });

  return { ...prefixedStyle, ...style };
};
