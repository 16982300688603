import stripTags from 'underscore.string/stripTags';
import { unescape } from 'lodash';
import R from 'ramda';

const mainTitleType = 'hl1';
const subTitleType = 'hl2';
const titleElementTypes = [mainTitleType, subTitleType];
const paragraphHeaderType = 'ph';
const paragraphType = 'p';
const paragraphFullNameType = 'paragraph';
const introType = 'intro';
const leadType = 'lead';
const introElementTypes = [introType, leadType, paragraphFullNameType, paragraphType];
const averageWordsPerMinute = 275;

export function getTitle(manifestBody) {
  const title = manifestBody.find(bodySection => titleElementTypes.includes(bodySection.type));
  return title ? title.content : '';
}

export function getMainTitle(manifestBody) {
  const title = manifestBody.find(bodySection => bodySection.type === mainTitleType);
  return title ? title.content : undefined;
}

export function getSubTitle(manifestBody) {
  const subtitle = manifestBody.find(bodySection => bodySection.type === subTitleType);
  return subtitle ? subtitle.content : undefined;
}

export function getByLine(manifestBody) {
  const byLine = manifestBody.find(bodySection => bodySection.type === 'byline');
  return byLine ? byLine.content : '';
}

export function getWordCount(manifestBody) {
  return R.path(['length', 'words'], manifestBody);
}

export function getReadingTime(manifestLength) {
  return Math.ceil(R.divide(manifestLength.words, averageWordsPerMinute));
}

export function getContentAsText(contentSection) {
  return unescape(stripTags(contentSection)).trim();
}

export function getAnIntro(manifestBody) {
  const intro = manifestBody.find(bodySection => introElementTypes.includes(bodySection.type));
  return intro ? intro.content : '';
}

export function getIntro(manifestBody) {
  const intro = manifestBody.find(bodySection => bodySection.type === introType);
  return intro ? intro.content : undefined;
}

export function getLead(manifestBody) {
  const lead = manifestBody.find(bodySection => bodySection.type === leadType);
  return lead ? lead.content : undefined;
}

export function getParagraphHeader(manifestBody) {
  const paragraphHeader = manifestBody.find(
    bodySection => bodySection.type === paragraphHeaderType,
  );
  return paragraphHeader ? paragraphHeader.content : undefined;
}

export function getParagraphs(manifestBody) {
  return manifestBody
    .map(bodySection =>
      bodySection.type === paragraphType || bodySection.type === paragraphFullNameType
        ? bodySection.content
        : undefined,
    )
    .filter(Boolean);
}

export function getManifestBody(manifest) {
  // For non backbone-model manifest types
  if (manifest.body) {
    return manifest.body;
  }

  if (manifest.get('format_version') >= 4) {
    return manifest.get('body');
  }

  return [
    { type: 'kicker', content: this.get('kicker') },
    { type: 'head', content: this.get('head') },
    { type: 'byline', content: this.get('byline') },
    { type: 'dateline', content: this.get('dateline') },
    { type: 'intro', content: this.get('intro') },
    { type: 'lead', content: this.get('lead') },
  ];
}
