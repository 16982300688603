export default {
  ...require('./development'),
  name: 'local',

  analytics: 'https://events-staging.blendle.io/v1/events',
  ssl: false,
  landing_project_protocol: 'http',
  landing_project_host: 'localhost:9000',
  landing_experiments_endpoint: 'http://localhost:3000/api/active-experiments',
  paymentUri: 'https://localhost:3100',

  // ...require('./test'),
  // ...require('./approval'),
  // ...require('./production'),
  // ...require('./staging')
};
