import alt from 'instances/altInstance';
import IssueManager from 'managers/issue';

class PremiumIssueActions {
  fetchIssueSuccess = x => x;

  fetchIssueError = x => x;

  fetchIssue({ userId, bnlId }) {
    IssueManager.fetchIssue(userId, bnlId)
      .then(issue => {
        this.fetchIssueSuccess({ bnlId, issue });
      })
      .catch(error => {
        this.fetchIssueError({ bnlId, error });

        window.ErrorLogger.captureException(
          new Error('Failed fetching discover issue', error.message),
        );
      });

    return { bnlId };
  }

  fetchTiles({ userId, itemIds, bnlId }) {
    IssueManager.fetchTiles(userId, itemIds)
      .then(tiles => {
        this.fetchTilesSuccess({ tiles });
        this.fetchTilesStatusSuccess({ bnlId });
      })
      .catch(() => this.fetchTilesStatusError({ bnlId }));

    this.fetchTilesStatusStart({ bnlId });

    return null;
  }

  fetchTilesStatusStart({ bnlId }) {
    return { bnlId };
  }

  fetchTilesStatusSuccess({ bnlId }) {
    return { bnlId };
  }

  fetchTilesStatusError({ bnlId }) {
    return { bnlId };
  }

  fetchTilesSuccess({ tiles }) {
    return { tiles };
  }
}

export default alt.createActions(PremiumIssueActions);
