import { Analytics } from '@blendle/analytics';
import features from 'config/features';
import environment from 'environment';
import { adjust, fromPairs, map, pipe, replace, toPairs, omit } from 'ramda';
import getExperimentsStatsPayload from 'helpers/getExperimentsStatsPayload';

const renameCampaignKey = replace('campaign', 'name');

const transformUtmAsSegmentAnalyticsPayload = pipe(
  toPairs,
  map(adjust(0, renameCampaignKey)),
  fromPairs,
  omit(['adid']),
);

const SegmentAnalytics = {
  enabled: false,
  analytics: undefined,
  snippet: undefined,
  sessionId: undefined,
  user: undefined,
  adId: undefined,
  utmPayload: undefined,

  init() {
    this.enabled = features.enableSegment();

    if (this.enabled) {
      this.analytics = Analytics({ environment: environment.segmentEnvironmentName });
      this.snippet = this.analytics.init();
    }
  },

  setSessionId(sessionId) {
    this.sessionId = sessionId;
  },

  setUser(user) {
    this.user = user;
  },

  setUTMParameters(utmParams = {}) {
    this.adId = utmParams.adid;

    this.utmPayload = transformUtmAsSegmentAnalyticsPayload(utmParams);
  },

  track(eventName, properties) {
    if (!this.enabled || !this.allowTracking) {
      return;
    }

    this.analytics.track({
      eventName,
      properties: {
        ...properties,
        ...this.defaultProperties,
      },
      options: this.options,
    });
  },

  identify() {
    if (!this.enabled || !this.allowTracking) {
      return;
    }

    if (this.user) {
      this.analytics.identify({
        userId: this.user.get('tracking_uid'),
        options: this.options,
      });
    }
  },

  page({ pageName, properties } = {}) {
    if (!this.enabled || !this.allowTracking) {
      return;
    }

    this.analytics.page({
      pageName,
      properties: {
        ...properties,
        ...this.defaultProperties,
      },
      options: this.options,
    });
  },

  reset() {
    if (!this.enabled || !this.allowTracking) {
      return;
    }

    this.analytics.reset();
  },

  get defaultProperties() {
    return {
      ...getExperimentsStatsPayload(),
      ad_id: this.adId,
      blendle_session_id: this.sessionId,
      userId: this.user ? this.user.get('tracking_uid') : undefined,
      sent_to_segment: true,
    };
  },

  get options() {
    return {
      context: {
        app: {
          build: window._version,
        },
        campaign: {
          ...this.utmPayload,
        },
      },
    };
  },

  get allowTracking() {
    return !this.user || (this.user && !this.user.get('mixpanel_opt_out'));
  },
};

export default SegmentAnalytics;
