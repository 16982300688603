import React from 'react';
import styledModule from 'helpers/styledModule';
import { translate } from 'instances/i18n';
import { H4, H5 } from '../Typography';
import CSS from './styles.scss';

const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L18.2929 19.7071ZM14.5071 13.0929C14.1166 12.7024 13.4834 12.7024 13.0929 13.0929C12.7024 13.4834 12.7024 14.1166 13.0929 14.5071L14.5071 13.0929ZM13.0962 3.9038C15.6346 6.44221 15.6346 10.5578 13.0962 13.0962L14.5104 14.5104C17.8299 11.1909 17.8299 5.80904 14.5104 2.48959L13.0962 3.9038ZM13.0962 13.0962C10.5578 15.6346 6.44221 15.6346 3.9038 13.0962L2.48959 14.5104C5.80904 17.8299 11.1909 17.8299 14.5104 14.5104L13.0962 13.0962ZM3.9038 13.0962C1.3654 10.5578 1.3654 6.44221 3.9038 3.9038L2.48959 2.48959C-0.829864 5.80904 -0.829864 11.1909 2.48959 14.5104L3.9038 13.0962ZM3.9038 3.9038C6.44221 1.3654 10.5578 1.3654 13.0962 3.9038L14.5104 2.48959C11.1909 -0.829864 5.80904 -0.829864 2.48959 2.48959L3.9038 3.9038ZM19.7071 18.2929L14.5071 13.0929L13.0929 14.5071L18.2929 19.7071L19.7071 18.2929Z"
      fill="#FCA401"
    />
  </svg>
);

const BookmarkIcon = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 19L8 14L1 19V3V3C1 1.89543 1.89543 1 3 1H13V1C14.1046 1 15 1.89543 15 3V19Z"
      stroke="#06C587"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const GlobeIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.071 3.92893C21.9763 7.83417 21.9763 14.1658 18.071 18.071C14.1658 21.9763 7.83413 21.9763 3.92893 18.071C0.02369 14.1658 0.02369 7.83413 3.92893 3.92893C7.83417 0.02369 14.1658 0.02369 18.071 3.92893"
      stroke="#373AED"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 11H21"
      stroke="#373AED"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 1V1C13.5013 3.73835 14.9227 7.29203 15 11V11C14.9227 14.7079 13.5013 18.2616 11 21H11C8.49872 18.2616 7.07725 14.7079 7 11L7 11C7.07724 7.29203 8.49871 3.73835 11 1L11 1Z"
      stroke="#373AED"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MoonIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9995 10.79V10.79C18.5421 15.7395 14.159 19.381 9.20952 18.9236C4.26005 18.4662 0.618498 14.0831 1.07589 9.13363C1.47475 4.81754 4.89343 1.39886 9.20952 1L9.20952 1C6.91155 4.10889 7.56892 8.49203 10.6778 10.79C13.1506 12.6178 16.5267 12.6178 18.9995 10.79L18.9995 10.79Z"
      stroke="#1F2E6E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Container = styledModule.div(CSS.container);
const FeatureWrapper = styledModule.div(CSS.featureWrapper);
const Feature = styledModule.div(CSS.feature);
const Copy = styledModule.p(CSS.copy);

const More = () => (
  <Container>
    <H4>{translate('discover_premium.more.title')}</H4>
    <FeatureWrapper>
      <Feature>
        <SearchIcon />
        <H5>{translate('discover_premium.more.search.title')}</H5>
        <Copy>{translate('discover_premium.more.search.text')}</Copy>
      </Feature>
      <Feature>
        <BookmarkIcon />
        <H5>{translate('discover_premium.more.reading_list.title')}</H5>
        <Copy>{translate('discover_premium.more.reading_list.text')}</Copy>
      </Feature>
      <Feature>
        <GlobeIcon />
        <H5>{translate('discover_premium.more.international.title')}</H5>
        <Copy>{translate('discover_premium.more.international.text')}</Copy>
      </Feature>
      <Feature>
        <MoonIcon />
        <H5>{translate('discover_premium.more.bedtime_story.title')}</H5>
        <Copy>{translate('discover_premium.more.bedtime_story.text')}</Copy>
      </Feature>
    </FeatureWrapper>
  </Container>
);

export default More;
