import React from 'react';
import styledModule from 'helpers/styledModule';
import staticImageUrl from 'helpers/staticImageUrl';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
const Covers = styledModule.div(CSS.covers);
const SmallCovers = styledModule(Covers)(CSS.smallImage);
const WideCovers = styledModule(Covers)(CSS.wideImage);

const isRetina = window.devicePixelRatio > 1;
const loadWideImage = window.innerWidth > 562;

const getCoverUrl = (version) =>
  staticImageUrl(
    `/img/backgrounds/curved-magazine-covers-${version}${
      isRetina ? '@2x' : ''
    }.png?auto=format,compress`,
  );

const SmallCoversComponent = () => (
  <SmallCovers style={{ backgroundImage: `url(${getCoverUrl('small')})` }} />
);

const WideCoversComponent = () => (
  <WideCovers style={{ backgroundImage: `url(${getCoverUrl('wide')})` }} />
);

const MagazineCoversCurved = () => (
  <Container>{loadWideImage ? <WideCoversComponent /> : <SmallCoversComponent />}</Container>
);

export default MagazineCoversCurved;
