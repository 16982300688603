import React from 'react';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import altConnect from 'higher-order-components/altConnect';
import { hasCapability } from 'selectors/capabilities';
import { bool } from 'prop-types';
import {
  FEATURE_CAPABILITY_MICROPAYMENT_MIGRATION_FEATURES,
  SUCCESS_DIALOG_VERSION,
} from 'app-constants';
import PremiumOverlayContainer from './PremiumOverlayContainer';

const TimelineDialogContainer = ({
  hasMicropaymentMigrationFeatures,
  isAppMigrationFlow,
  isMpPlusPremiumMigrationFlow,
}) => {
  if (hasMicropaymentMigrationFeatures) {
    if (isAppMigrationFlow) {
      return (
        <PremiumOverlayContainer version={SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW} />
      );
    }

    if (isMpPlusPremiumMigrationFlow) {
      return <PremiumOverlayContainer version={SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW} />;
    }

    return <PremiumOverlayContainer version={SUCCESS_DIALOG_VERSION.WALLET_CONVERSION} />;
  }

  return <PremiumOverlayContainer version={SUCCESS_DIALOG_VERSION.DEFAULT} />;
};

TimelineDialogContainer.propTypes = {
  hasMicropaymentMigrationFeatures: bool.isRequired,
  isAppMigrationFlow: bool.isRequired,
  isMpPlusPremiumMigrationFlow: bool.isRequired,
};

function mapStateToProps({ featureCapabilitiesState }, props) {
  return {
    hasMicropaymentMigrationFeatures: hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_MICROPAYMENT_MIGRATION_FEATURES,
    ),
    ...props,
  };
}

mapStateToProps.stores = {
  FeatureCapabilitiesStore,
};

export default altConnect(mapStateToProps)(TimelineDialogContainer);
