import altConnect from 'higher-order-components/altConnect';
import CookieConsentStore from 'stores/CookieConsentStore';
import { compose, withState } from '@blendle/recompose';
import environment from 'environment';
import { equals } from 'ramda';

function addScript(scriptText) {
  const script = document.createElement('script');
  script.text = scriptText;

  document.head.appendChild(script);
}

function loadScript(url) {
  const script = document.createElement('script');
  script.async = true;
  script.src = url;

  document.head.appendChild(script);
}

function addFacebookPixel(isTestEnvironment) {
  const facebookPixel = isTestEnvironment
    ? 'function fbq(){}'
    : `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${environment.facebookPixelId}');`;

  addScript(facebookPixel);
}

function addTwitterPixel(isTestEnvironment) {
  const twitterPixel = isTestEnvironment
    ? 'function twq(){}'
    : `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
  },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
  a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
  twq('init','${environment.twitterPixelId}');`;

  addScript(twitterPixel);
}

function addDoubleClick(isTestEnvironment) {
  if (isTestEnvironment) {
    return;
  }

  loadScript(`https://www.googletagmanager.com/gtag/js?id=${environment.doubleClickId}`);

  const doubleClickScript = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${environment.doubleClickId}');`;

  addScript(doubleClickScript);
}

export function mapStateToProps({ cookieConsentState }, props) {
  const { areTrackingPixelsLoaded, setAreTrackingPixelsLoaded } = props;
  const { hasGivenConsent } = cookieConsentState;

  if (hasGivenConsent && !areTrackingPixelsLoaded) {
    const isTestEnvironment = equals('test', environment.name);

    addFacebookPixel(isTestEnvironment);
    addTwitterPixel(isTestEnvironment);
    addDoubleClick(isTestEnvironment);
    setAreTrackingPixelsLoaded(true);
  }

  return props;
}

mapStateToProps.stores = { CookieConsentStore };

const enhance = compose(
  withState('areTrackingPixelsLoaded', 'setAreTrackingPixelsLoaded', false),
  altConnect(mapStateToProps),
);

export default enhance;
