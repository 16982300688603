// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-DG5qk{position:relative;margin-bottom:24px;margin-left:-16px;margin-right:-16px}@media (min-width: 485px){.container-DG5qk{margin-left:0;margin-right:0;padding-top:62px;margin-top:-25px}}.box-10HFt{background:linear-gradient(344.85deg, #ff6255 0%, #ff0e61 100%);width:100%;padding:32px 24px}@media (min-width: 485px){.box-10HFt{border-radius:8px;box-shadow:0 6px 40px rgba(0,0,0,0.25);padding:48px 56px}}@media (min-width: 730px){.box-10HFt{min-height:260px;padding:54px 56px 64px}}@media (min-width: 730px){.content-2zGEq{padding-right:275px}}.title-ecC0p{font-family:\"GT-Walsheim\",sans-serif;font-weight:600;font-size:36px;line-height:40px;color:#fff;letter-spacing:-0.05em}.copy-1VLzh{font-family:\"GT-Walsheim\",sans-serif;font-size:24px;line-height:28px;color:#fff;letter-spacing:-0.02em;opacity:0.9;padding-top:8px}.appButtons-28gDk{margin:17px 0 0;padding:0;width:274px}.device-1FYSJ{display:none}@media (min-width: 730px){.device-1FYSJ{display:block;position:absolute;bottom:0;right:0}}.mobile-2K3zP{display:block}@media (min-width: 730px){.mobile-2K3zP{display:none}}.desktop-2yOUY{display:none}@media (min-width: 730px){.desktop-2yOUY{display:block}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-DG5qk",
	"box": "box-10HFt",
	"content": "content-2zGEq",
	"title": "title-ecC0p",
	"copy": "copy-1VLzh",
	"appButtons": "appButtons-28gDk",
	"device": "device-1FYSJ",
	"mobile": "mobile-2K3zP",
	"desktop": "desktop-2yOUY"
};
module.exports = exports;
