// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3UXc8{margin-bottom:28px}@media (min-width: 600px){.container-3UXc8{margin-bottom:76px}}.covers-2AGtG{width:100%;background-position-x:center;background-repeat:repeat no-repeat;background-size:cover}.smallImage-3-xm8{height:223px}@media (min-width: 562px){.smallImage-3-xm8{background-size:contain}}.wideImage-3K9Ul{height:431px}@media (min-width: 1404px){.wideImage-3K9Ul{background-size:contain}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3UXc8",
	"covers": "covers-2AGtG",
	"smallImage": "smallImage-3-xm8",
	"wideImage": "wideImage-3K9Ul"
};
module.exports = exports;
