import React from 'react';
import { NavigationItemBadge } from '@blendle/lego';
import useDidDismissNewCreditKioskDialog from 'hooks/useDidDismissNewCreditKioskDialog';
import styledModule from 'helpers/styledModule';
import { translate } from 'instances/i18n';
import { bool } from 'prop-types';
import CSS from './styles.scss';

const SmallBadge = styledModule(NavigationItemBadge)(CSS.smallBadge);

const CreditsKioskBadge = ({ smallBadge }) => {
  const { showDialog } = useDidDismissNewCreditKioskDialog();

  const badge = smallBadge ? (
    <SmallBadge />
  ) : (
    <NavigationItemBadge>{translate('navigation.new')}</NavigationItemBadge>
  );

  return showDialog ? badge : null;
};

CreditsKioskBadge.propTypes = {
  smallBadge: bool,
};

CreditsKioskBadge.default = {
  smallBadge: false,
};

export default CreditsKioskBadge;
