import axios from 'axios';
import Settings from 'controllers/settings';

const WalletMigrationManager = {
  getWalletMigrationOffer() {
    const link = Settings.getLink('premium_migration_coupon_offer');

    return axios.get(link);
  },
};

export default WalletMigrationManager;
