import { useState } from 'react';
import useUser from 'hooks/useUser';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import useAlt from 'hooks/useAlt';
import { hasCapability } from 'selectors/capabilities';
import { FEATURE_CAPABILITY_MICROPAYMENT_MIGRATION_FEATURES } from 'app-constants';

const DID_SEE_DISCOVER_PREMIUM_PAGE = 'did_see_discover_premium_page';

const useDidSeeDiscoverPremiumPage = () => {
  const user = useUser();
  const capabilityState = useAlt([FeatureCapabilitiesStore], ([state]) => state);

  const isMigratedMpUser = hasCapability(
    capabilityState,
    FEATURE_CAPABILITY_MICROPAYMENT_MIGRATION_FEATURES,
  );

  const [didSeeDiscoverPremiumPage, setDidSeeDiscoverPremiumPage] = useState(
    !!user.getPreference(DID_SEE_DISCOVER_PREMIUM_PAGE),
  );

  const userSawDiscoverPremiumPage = () => {
    if (!didSeeDiscoverPremiumPage) {
      setDidSeeDiscoverPremiumPage(true);

      user.savePreferences({
        [DID_SEE_DISCOVER_PREMIUM_PAGE]: true,
      });
    }
  };

  return {
    useProminentColor: isMigratedMpUser && !didSeeDiscoverPremiumPage,
    didSeeDiscoverPremiumPage,
    userSawDiscoverPremiumPage,
  };
};

export default useDidSeeDiscoverPremiumPage;
