// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2TKni{display:flex;width:298px;margin:0 auto;padding:0 16px;justify-content:space-between}.container-2TKni img{width:100%;height:100%}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2TKni"
};
module.exports = exports;
