import introspectionQueryResultData from 'config/graphql/fragmentTypes';
import { IntrospectionFragmentMatcher, InMemoryCache, defaultDataIdFromObject } from 'apollo-boost';
import { GRAPHQL_TYPE_NAME } from 'app-constants';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const cache = new InMemoryCache({
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      node: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Issue', id: args.id }),
    },
  },
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case GRAPHQL_TYPE_NAME.ARTICLE:
        /*
         * To allow an article in the GraphQL cache to be updated after an action in the tileStore,
         * we need to be able to interact with the item in the cache without knowing the graphQL ID
         * of the article, since that is not known in the "tileStore context".
         * This allows us to access it by 'Article:bnlId' instead of `Article:graphQLId`
         * Throwing an error in case we ever forget to query an article without a bnlId
         */
        if (!object.bnlId) {
          throw new Error(
            'Articles must always be queried with "bnlId" in order to generate a cache key',
          );
        }
        return `${GRAPHQL_TYPE_NAME.ARTICLE}:${object.bnlId}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

export const clearGraphQLCache = () => {
  cache.reset();
};
