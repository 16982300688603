import i18n from 'instances/i18n';
import Country from 'instances/country';
import ApplicationState from 'instances/application_state';
import Analytics from 'instances/analytics';
import trackPageView from 'helpers/trackingPixels/trackPageView';
import trackExistingUsers from 'helpers/trackingPixels/trackExistingUsers';
import BrowserEnvironment from 'instances/browser_environment';
import Settings from 'controllers/settings';
import Auth from 'controllers/auth';
import { startSyncingCapabilities } from 'services/updateFeatureCapabilities';
import { isLandingProjectDomainOrSubDomain } from 'helpers/landingProject';
import redirectToUpsellPage from 'higher-order-components/redirectToUpsellPage';
import withTrackingPixels from 'higher-order-components/withTrackingPixels';
import ApplicationStore from 'stores/ApplicationStore';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';

import ModuleNavigationActions from 'actions/ModuleNavigationActions';
import AuthActions from 'actions/AuthActions';
import ChannelActions from 'actions/ChannelActions';

import zendesk from 'instances/zendesk';
import getUuid from 'helpers/uuid';

import React, { Component } from 'react';
import Application from 'components/Application/Application';
import { browserHistory } from 'react-router';
import { getItem, setItem } from 'helpers/localStorage';
import { shouldOpenStandaloneUrl } from 'helpers/standaloneUrl';
import { compose } from '@blendle/recompose';
import withCookieBar from 'higher-order-components/withCookieBar';
import withGraphQL from 'higher-order-components/withGraphQL';
import { unregisterServiceWorker } from 'helpers/serviceWorker';
import withSyncActions from 'higher-order-components/withSyncActions';
import SegmentAnalytics from 'instances/segmentAnalytics';

// LinkClickHandler will listen to all clicks on links and handle/route them (pushState)
require('helpers/linkclickhandler');

unregisterServiceWorker();

startSyncingCapabilities();

class ApplicationContainer extends Component {
  constructor() {
    super();

    this.state = {
      status: ApplicationStore.getState().status,
      languageCode: i18n.getIso6391(),
      isLoggedIn: Boolean(Auth.getUser()),
      userAgent: window.BrowserDetect,
    };
  }

  UNSAFE_componentWillMount() {
    const uuid = getUuid();
    Analytics.setSession(uuid);
    SegmentAnalytics.setSessionId(uuid);

    const didComeFromOtherBlendleDomain =
      document.referrer && isLandingProjectDomainOrSubDomain(document.referrer);

    if (!didComeFromOtherBlendleDomain) {
      Analytics.track('Session', {
        browser: window.BrowserDetect.browser,
        device: window.BrowserDetect.device,
        os: window.BrowserDetect.operatingSystem,
        version: window.BrowserDetect.version,
        orientation: BrowserEnvironment.getOrientation(),
        app: window.BrowserDetect.app,
        appVersion: window.BrowserDetect.appVersion,
        standalone: navigator.standalone !== undefined ? navigator.standalone : 'unknown',
      });
    }
  }

  componentDidMount() {
    this._initZendesk();

    browserHistory.listen(this._onRoute.bind(this));
    this._onRoute(browserHistory.getCurrentLocation());

    // Standlone (like on iOS added to desktop) remembers last URl visited
    // so on re-open the app starts at it last url
    if (navigator.standalone) {
      browserHistory.listen(this._rememberLastVisitedURL.bind(this));
      this._rememberLastVisitedURL(browserHistory.getCurrentLocation());
    }

    if (Settings.embedded) {
      window.parent.postMessage(
        {
          event: 'blendle.frame.hideclosebutton',
        },
        Settings.embeddedOrigin,
      );
    }

    i18n.on('initialized switch', (ev) => {
      this._setAnalyticsOrigin(ev);
      const languageCode = i18n.getIso6391();
      this.setState({ languageCode });

      zendesk.execute('setLocale', languageCode);
    });

    ApplicationStore.listen(() => {
      this.setState({
        status: ApplicationStore.getState().status,
      });
    });

    // Load current application state from cookie
    ApplicationState.loadFromCookie();

    this._run();
  }

  _authHandler() {
    const user = Auth.getUser();

    AuthActions.authenticateUser.defer(user);

    window.ErrorLogger.setUser(user.id);
    Analytics.setUser(user);
    SegmentAnalytics.setUser(user);
    SegmentAnalytics.identify();
    Country.setCountryCode(user.get('country'));
    i18n.load(user.get('primary_language'));
    i18n.setCurrency(user.get('currency'));
    trackExistingUsers(user);

    ChannelActions.fetchChannels.defer();

    zendesk.execute('identify', {
      name: user.get('full_name'),
      email: user.get('email'),
      organization: '',
    });

    // Next tick to make sure experiments are synced
    setTimeout(() => this.setState({ isLoggedIn: Boolean(user) }));
  }

  _run() {
    if (Auth.getUser()) {
      this._authHandler();
    } else {
      Auth.on('login', () => {
        this._authHandler();
      });
    }

    // Open the last visited url from the previous session
    const standaloneUrl = getItem('standaloneUrl');
    if (navigator.standalone && shouldOpenStandaloneUrl(standaloneUrl)) {
      browserHistory.replace(standaloneUrl);
    }

    // Send the orientation to MixPanel when it changes.
    window.addEventListener('orientationchange', this._trackOrientation.bind(this));

    Auth.on('logout', i18n.resetCookie);
  }

  _initZendesk() {
    zendesk.execute('setLocale', this.state.languageCode);
    zendesk.execute('setHelpCenterSuggestions', {
      url: true,
      labels: location.pathname.split('/'),
    });
  }

  _rememberLastVisitedURL() {
    if (!window.BrowserDetect.localStorageEnabled()) {
      return;
    }

    let { activeUrl } = ModuleNavigationStore.getState();
    if (activeUrl === 'logout') {
      activeUrl = null;
    }

    if (activeUrl) {
      setItem('standaloneUrl', activeUrl);
    }
  }

  _trackOrientation() {
    Analytics.track('Change orientation', {
      orientation: BrowserEnvironment.getOrientation(),
      location: ModuleNavigationStore.getState().activeUrl,
    });
  }

  _onRoute(location) {
    Analytics.urlChange();
    const { isLoggedIn } = this.state;
    trackPageView(location, isLoggedIn);
    ModuleNavigationActions.setActiveUrl.defer(location.pathname);
    zendesk.execute('setHelpCenterSuggestions', {
      url: true,
      labels: location.pathname.split('/'),
    });
  }

  _setAnalyticsOrigin() {
    Analytics.setOrigin(Country.getCountryCode(), i18n.getLocale());
  }

  render() {
    return <Application {...this.state} {...this.props} />;
  }
}

const enhance = compose(
  redirectToUpsellPage,
  withCookieBar,
  withTrackingPixels,
  withGraphQL,
  withSyncActions,
);

export default enhance(ApplicationContainer);
