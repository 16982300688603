import Auth from 'controllers/auth';

export function getSignupEntryPoint() {
  let signUpType;
  const user = Auth.getUser();

  if (user) {
    signUpType = user.getPreference('signup_entry_point');
  }

  return signUpType || 'unknown';
}
