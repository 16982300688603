import URI from 'urijs';
import { removeOrigin } from './url';

const REDIRECT_PARAM_SECTION = 'redirect=';

export default function getRedirectFromToken({ item_id: itemId, redirect }) {
  if (itemId) {
    return { pathname: `/item/${itemId}` };
  }

  if (redirect) {
    const indexOfRedirectParamSection = redirect.indexOf(REDIRECT_PARAM_SECTION);

    if (indexOfRedirectParamSection > -1) {
      const redirectFromQueryParam = redirect.substring(
        indexOfRedirectParamSection + REDIRECT_PARAM_SECTION.length,
      );

      const redirectUri = new URI(decodeURIComponent(redirectFromQueryParam));

      return {
        pathname: redirectUri.pathname(),
        query: redirectUri.query(true),
        origin: redirectUri.origin(),
      };
    }

    return { pathname: removeOrigin(redirect) };
  }

  return { pathname: '/' };
}
