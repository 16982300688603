import ByeBye from 'byebye';
import Q from 'q';
import Settings from 'controllers/settings';
import axios from 'axios';

const SharingManager = {
  /**
   * Request a unique link for the given item and service. This link
   * can be shared and accessed exactly one time by the external service provided.
   *
   * @param  {String} itemId id of the item to request a unique link for
   * @param  {String} service the service to allow access (for now getpocket.com and instapaper.com)
   * @return {Promise} a promise which, if successful, returns the unique url
   */
  requestUniqueAccessURL(itemId, service) {
    const defer = Q.defer();

    const ajaxOptions = {
      url: Settings.getLink('onetimelink', { item_id: itemId, service }),
      type: 'POST',
    };

    // This is a solution for the problem with the standalone browser. It relies on a small
    // change in sparta, because the open a new url event needs to happen on the same
    // thread as the initial click. Therefor no promises can be used in between.
    if (navigator.standalone) {
      ajaxOptions.async = false;
      ajaxOptions.success = (response) => {
        const a = document.createElement('a');
        a.setAttribute('href', this.createPocketUrl(response.data.url));
        a.setAttribute('target', '_blank');

        const dispatch = document.createEvent('HTMLEvents');
        dispatch.initEvent('click', true, true);
        a.dispatchEvent(dispatch);

        defer.resolve(response.data.url);
      };
    }

    ByeBye.ajax(ajaxOptions).then(
      (response) => {
        if (response.status === 201) {
          defer.resolve(response.data.url);
        } else {
          defer.reject(response);
        }
      },
      (response) => {
        defer.reject(response);
      },
    );

    return defer.promise;
  },

  /**
   * Share itemId to pocket
   *
   * @param  {String} itemId the item to share to pocket
   * @return {Promise} promise
   */
  shareToPocket(itemId) {
    const self = this;

    let pocketWindow;
    if (!navigator.standalone) {
      pocketWindow = window.open('about:blank', 'pocket', 'width=550,height=300');
    }

    return SharingManager.requestUniqueAccessURL(itemId, 'getpocket.com').then((url) => {
      if (!navigator.standalone) {
        pocketWindow.location.href = self.createPocketUrl(url);
      }
    });
  },

  /**
   * Share itemId to e-mail
   *
   * @param  {Number} userId
   * @param  {String} itemId
   * @param  {Array} emails
   * @param  {String} message
   * @return {Promise} promise
   */
  shareToEmail(userId, itemId, emails, message) {
    return ByeBye.ajax({
      url: Settings.getLink('email_share', { user_id: userId }),
      type: 'POST',
      data: JSON.stringify({
        item_id: itemId,
        emails,
        message,
      }),
    });
  },

  shareToFollowing(userId, itemId, text) {
    const link = Settings.getLink('posts', { user_id: userId });

    return axios.post(link, {
      id: itemId,
      text,
    });
  },

  removeShareToFollowing(userId, itemId) {
    const link = Settings.getLink('user_post', { user_id: userId, item_id: itemId });

    return axios.delete(link, { id: itemId });
  },

  shareToChannel({ channel, itemId, message, publicationTime }) {
    const url = Settings.getLink('posts', { user_id: channel.id });
    return axios
      .post(url, {
        id: itemId,
        text: message,
        published_at: publicationTime,
      })
      .then((resp) => resp.data);
  },

  generateShareToken(itemId) {
    const link = Settings.getLink('share_token', {
      item_uid: itemId,
    });

    return axios.get(link);
  },

  getSharedItem(token) {
    const link = Settings.getLink('shared_article', {
      token,
    });

    return axios.get(link);
  },

  createPocketUrl(url) {
    return `https://getpocket.com/edit?url=${encodeURIComponent(url)}`;
  },
};

export default SharingManager;
