import { STATUS_INITIAL, STATUS_PENDING, STATUS_ERROR, STATUS_OK } from 'app-constants';
import alt from 'instances/altInstance';
import PaymentActions from '../actions/PaymentActions';

class PaymentStore {
  constructor() {
    this.bindActions(PaymentActions);

    this.paymentMethods = [];
    this.status = STATUS_INITIAL;
    this.selectedPaymentMethod = '0';
    this.selectedBanks = {}; // key => value: paymentMethod.code => bank.code
    this.paymentURL = ''; // url to adyen
    this.paymentURLStatus = STATUS_INITIAL;
    this.recurring = false;
    this.returnUrl = '';
    this.useHardRedirect = false;
    this.recurring_contracts = [];
  }

  onSetReturnUrl({ returnUrl, useHardRedirect = false }) {
    this.setState({ returnUrl, useHardRedirect });
  }

  onFetchPaymentMethods() {
    this.setState({ status: STATUS_PENDING });
  }

  onFetchPaymentMethodsSuccess({ paymentMethods, recurring, activeStep, recurring_contracts }) {
    this.setState({
      status: STATUS_OK,
      paymentMethods,
      recurring,
      activeStep,
      recurring_contracts,
    });
  }

  onFetchPaymentMethodsError() {
    this.setState({ status: STATUS_ERROR });
  }

  onSetPaymentMethod({ selectedPaymentMethod, selectedBank }) {
    this.setState({
      selectedPaymentMethod,
      selectedBanks: {
        ...this.selectedBanks,
        [selectedPaymentMethod]: selectedBank,
      },
    });
  }

  onSetRecurringContract({ recurring }) {
    this.setState({ recurring });
  }

  onFetchPaymentURL() {
    this.setState({ paymentURLStatus: STATUS_PENDING });
  }

  onFetchPaymentUrlError() {
    this.setState({
      paymentURLStatus: STATUS_ERROR,
    });
  }

  onFetchPaymentURLSuccess({ paymentURL, activeStep }) {
    this.setState({
      paymentURL,
      activeStep,
      paymentURLStatus: STATUS_OK,
    });
  }
}

export default alt.createStore(PaymentStore, 'PaymentStore');
