// Unregister our old service worker in case its still active
// and refresh the page afterwards to make sure user has the latest version of our site.
// Our old serviceworker used to fetch the bundle js files, so there is a change there
// is still and old bundle file present in the browser
export function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    // eslint-disable-next-line compat/compat
    navigator.serviceWorker.getRegistrations().then(registrations =>
      registrations.map(registration =>
        registration
          .unregister()
          .then(unregistered => {
            if (window.ErrorLogger) {
              window.ErrorLogger.captureMessage(`Serviceworker unregister result: ${unregistered}`);
              window.location.reload(true);
            }
          })
          .catch(error => {
            if (window.ErrorLogger) {
              window.ErrorLogger.captureMessage(`Serviceworker unregister failed`, {
                error: JSON.stringify(error),
              });
            }
          }),
      ),
    );
  }
}
