// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".smallBadge-3Lqtm{width:13px}\n", ""]);
// Exports
exports.locals = {
	"smallBadge": "smallBadge-3Lqtm"
};
module.exports = exports;
