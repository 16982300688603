import _ from 'lodash';
import ByeBye from 'byebye';
import BlendAlytics from 'instances/blendalytics';
import Environment from 'environment';
import { providerById, prefillSelector } from 'selectors/providers';
import { getManifestBody, getTitle } from 'helpers/manifest';
import getExperimentsStatsPayload from 'helpers/getExperimentsStatsPayload';
import moment from 'moment';
import stripTags from 'underscore.string/stripTags';
import { getFeaturedImage } from 'selectors/manifest';
import { getTrackingURL } from 'helpers/url';
import R from 'ramda';
import features from 'config/features';
import SegmentAnalytics from './segmentAnalytics';

const Analytics = {
  _utm: {},
  user: null,
  isSegmentEnabled: features.enableSegment(),

  outputToConsole(eventName, properties) {
    if (Environment.name === 'local' || Environment.name === 'test') {
      console.log(
        '%cAnalytics', // jshint ignore:line
        'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
        eventName,
        JSON.stringify(properties),
        properties,
      );
    }
  },

  /**
   * users can disable tracking
   * @returns {boolean} allowed
   */
  allowTracking() {
    return !this.user || (this.user && !this.user.get('mixpanel_opt_out'));
  },

  /**
   * Override track event to add additional properties
   * @param {String} eventName
   * @param {Object} trackProps properties to track
   */
  track(eventName, trackProps = {}) {
    if (!this.allowTracking()) {
      return;
    }

    const props = Analytics.setDefaultProperties(trackProps);
    Analytics.outputToConsole(eventName, props);
    BlendAlytics.track(eventName, props);

    if (this.isSegmentEnabled) {
      SegmentAnalytics.track(eventName, trackProps);
    }
  },

  attempToSendUntrackedEventsOnce() {
    return BlendAlytics.attempToSendUntrackedEventsOnce();
  },

  setDefaultProperties(properties) {
    const session = {
      session: this._session,
    };

    const client_timestamp = {
      client_timestamp: moment().format(),
    };

    if (this.user) {
      session.tracking_uid = this.user.get('tracking_uid');

      // Some logged in users don't have a tracking_uid and we're trying to fix the issue
      if (!session.tracking_uid) {
        window.ErrorLogger.captureMessage('Logged in user without tracking_uid', {
          tracking_uid: _.get(this.user, 'attributes.tracking_uid'),
        });
      }
    }

    return {
      ...properties,
      ...Analytics._utm,
      ...Analytics._origin,
      ...Analytics.getHistory(),
      ...session,
      ...getExperimentsStatsPayload(),
      ...client_timestamp,
      sent_to_segment: this.isSegmentEnabled,
    };
  },

  getHistory() {
    const history = {
      current_uri: getTrackingURL(window.location),
    };

    if (document.referrer) {
      history.referrer = document.referrer;
    }

    if (ByeBye.history.getPrevious()) {
      history.previous_uri = getTrackingURL(ByeBye.history.getPreviousAsObject());
    }

    return history;
  },

  urlChange() {
    if (!this.allowTracking()) {
      return;
    }

    const eventName = 'URL Change';

    const properties = Analytics.setDefaultProperties();
    this.outputToConsole(eventName, properties);
    BlendAlytics.track(eventName, properties);

    if (this.isSegmentEnabled) {
      SegmentAnalytics.track(eventName);
    }
  },

  setSession(session) {
    this._session = session;
    return BlendAlytics.setSession(session);
  },

  signup(user, payload = {}) {
    const platform = user.get('facebook_id') ? 'facebook' : 'blendle';
    this.setUser(user);
    this.track('Signup/Successful', {
      platform,
      ...payload,
    });
  },

  setUser(user) {
    this.user = user;
  },

  setOrigin(country, locale) {
    Analytics._origin = { country, locale };
  },

  setUTMParameters(utmParams) {
    Analytics._utm = utmParams;
  },

  /**
   * Track an open item to analytics
   *
   * @param {Object} item the item to track
   * @param {Object} options   event options
   * @param {String} eventName the event name to log (default: Open Item)
   */
  trackItemEvent(item, options, eventName) {
    const payload = {};

    if (!eventName || typeof eventName === 'object') {
      eventName = 'Open Item';
    }

    const manifest = item._embedded['b:manifest'] || item._embedded.manifest;
    const manifestBody = getManifestBody(manifest);
    const byline = _.find(manifest.body, { type: 'byline' });

    payload.provider = prefillSelector(providerById)(manifest.provider.id).name;
    payload.item_id = manifest.id;
    payload.item_title = getTitle(manifestBody);
    payload.issue_id = R.path(['issue', 'id'], manifest);
    payload.acquired = item.item_purchased;
    payload.revenue = item.price; // price in cents
    payload.full_issue = item.issue_purchased;
    payload.wordcount = manifest.length.words;
    payload.byline = byline ? stripTags(byline.content) : null;
    payload.has_featured_image = !!getFeaturedImage(manifest);

    if (this.user) {
      this.user
        .get('active_subscriptions')
        .some((providerUid) => providerUid === manifest.provider.id);
    }

    const properties = _.extend(options || {}, payload);

    this.track(eventName, properties);

    if (eventName === 'Open Item' && this.isSegmentEnabled) {
      SegmentAnalytics.page({
        pageName: 'Article',
        properties,
      });
    }
  },
};

export default Analytics;
