const CAPABILITY_LOCKED_PATHNAME = '/404';
const CAPABILITY_KEY = 'lockedCapability';

/**
 * Get a route to which we should send the user because they try to access a feature that is locked for them
 * @param {String} capability Name of capability that is locked for this user
 */
export function getCapabilityLockedRoute(capability) {
  return {
    pathname: CAPABILITY_LOCKED_PATHNAME,
    state: {
      [CAPABILITY_KEY]: capability,
    },
  };
}

/**
 * Get the name of the capability that is set in the router.
 * @param {Object} location Router location object
 */
export function getCapabilityName(location) {
  const { state } = location;
  return state && state[CAPABILITY_KEY];
}

/**
 * Test if the current route is a route for a feature that was locked for the user.
 * @param {Object} location Router location object
 */
export function isCapabilityLockedRoute(location) {
  const { pathname, state } = location;

  return pathname === CAPABILITY_LOCKED_PATHNAME && state && state[CAPABILITY_KEY];
}
