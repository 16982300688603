// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdownLink-2LhBm{display:block;color:#948784;text-decoration:none;transition:color 0.1s ease-out}.dropdownLink-2LhBm:hover,.dropdownLink-2LhBm strong{color:#333}.balance-3AKWy.balance-3AKWy{font-size:13px;margin-left:5px;height:25px}.badge-2egAk{display:inline-block;width:8px;height:8px;background-color:#ff1060;border-radius:4px;margin-left:8px}\n", ""]);
// Exports
exports.locals = {
	"dropdownLink": "dropdownLink-2LhBm",
	"balance": "balance-3AKWy",
	"badge": "badge-2egAk"
};
module.exports = exports;
