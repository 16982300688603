export const TILE_SCROLL_WIDTH = 290; // TODO: Do not hardcode this
export const STAFF_PICKS_SECTION_ID = 'channel_staffpicks';
export const MUST_READS_SECTION_ID = 'editorial_must_reads';
export const PINS_SECTION_ID = 'user_pin_tiles';

export const NON_LOCKED_SECTION_IDS = new Set([STAFF_PICKS_SECTION_ID, MUST_READS_SECTION_ID]);

export const MAX_SECTION_WIDTH_PX = 1080;

export const TITLE_BREAK_SMALL_PX = 300;
export const TITLE_BREAK_MEDIUM_PX = 355;
export const TITLE_BREAK_LARGE_PX = 400;
export const TITLE_BREAK_EXTRA_LARGE_PX = 650;
export const TITLE_BREAK_FEATURED_PX = MAX_SECTION_WIDTH_PX + 1;

export const MAX_CHARACTERS_FOR_INTRO = 280;
export const MIN_CHARACTER_FOR_INTRO = 30;

export const CONTEXT_MENU_ACTION_UNFOLLOW_CHANNEL = 'CONTEXT_MENU_ACTION_UNFOLLOW_CHANNEL';
export const CONTEXT_MENU_ACTION_FOLLOW_CHANNEL = 'CONTEXT_MENU_ACTION_FOLLOW_CHANNEL';
export const CONTEXT_MENU_ACTION_UNFOLLOW_PROVIDER = 'CONTEXT_MENU_ACTION_UNFOLLOW_PROVIDER';
export const CONTEXT_MENU_ACTION_FOLLOW_PROVIDER = 'CONTEXT_MENU_ACTION_FOLLOW_PROVIDER';
export const CONTEXT_MENU_ACTION_UNFOLLOW_ENTITY = 'CONTEXT_MENU_ACTION_UNFOLLOW_ENTITY';
export const CONTEXT_MENU_ACTION_FOLLOW_ENTITY = 'CONTEXT_MENU_ACTION_FOLLOW_ENTITY';

export const PARALLAX_DISTANCE_PX = 75;

export const NEXT_SECTIONS_TO_LOAD_COUNT = 2;

export const MOBILE_LAYOUT_SCROLLING = 'mobile_scrolling';
export const MOBILE_LAYOUT_WRAPPING = 'mobile_wrapping';
export const MOBILE_LAYOUTS = [MOBILE_LAYOUT_SCROLLING, MOBILE_LAYOUT_WRAPPING];

export const SECTION_TYPE_CHANNEL = 'channel';
export const SECTION_TYPE_PROVIDER = 'provider';
export const SECTION_TYPE_ENTITY = 'entity';
export const SECTION_TYPE_EDITORIAL = 'editorial';
export const SECTION_TYPE_GENERIC = 'generic';
export const SECTION_TYPE_HEADER = 'header';

export const SUPPORTED_SECTION_TYPES = [
  SECTION_TYPE_CHANNEL,
  SECTION_TYPE_PROVIDER,
  SECTION_TYPE_ENTITY,
  SECTION_TYPE_EDITORIAL,
  SECTION_TYPE_GENERIC,
  SECTION_TYPE_HEADER,
];

// WHen updating this variable also update '$c-light-blue' in '_vars.scss'
export const PROVIDER_CARD_SECTION_BACKGROUND_COLOR = '#f2f2f6';

export const SECTION_ID_WITH_PROVIDER_CARD_BACKGROUND_COLOR = 'header_personalized';

export const EDITORIAL_CARD_GRID_TILES_LIMIT = 5;
export const PROVIDER_CARD_GRID_LIMIT = 4;

export const EDITORIAL_CARD_DEFAULT_WIDTH_PX = 280;
export const EDITORIAL_CARD_DEFAULT_HEIGHT_PX = 350;
export const PROVIDER_CARD_DEFAULT_WIDTH_PX = 343;
export const PROVIDER_CARD_DEFAULT_HEIGHT_PX = 400;
export const PROVIDER_CARD_MIN_WIDTH_PX = 300;
export const PROVIDER_CARD_MAX_HEIGHT_PX = 460;

export const EDITORIAL_CARD_VERTICAL_TILE_ASPECT_RATIO = '1:1';
export const PROVIDER_CARD_VERTICAL_TILE_ASPECT_RATIO = `${PROVIDER_CARD_DEFAULT_WIDTH_PX}:${PROVIDER_CARD_DEFAULT_HEIGHT_PX}`;
