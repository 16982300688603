import R from 'ramda';

export function getProviderColors(styles, providerId) {
  const hasFontColor = R.pipe(
    R.prop('selector'),
    R.equals(`.provider-${providerId}#mobile .item-paragraph`),
  );

  const getFontColor = R.pipe(R.filter(hasFontColor), R.head, R.path(['attributes', 'color']));

  const hasBackgroundColor = R.pipe(
    R.prop('selector'),
    R.equals(`div.item.provider-${providerId}#mobile`),
  );

  const getBgColor = R.pipe(
    R.filter(hasBackgroundColor),
    R.head,
    R.path(['attributes', 'background-color']),
  );

  return {
    bgColor: getBgColor(styles),
    fontColor: getFontColor(styles),
  };
}
