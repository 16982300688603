import React, { memo } from 'react';
import { func, string, node, object, bool, arrayOf } from 'prop-types';
import classNames from 'classnames';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';
import ModuleNavigationActions from 'actions/ModuleNavigationActions';
import { browserHistory } from 'react-router';
import Analytics from 'instances/analytics';
import URI from 'urijs';
import { propEq } from 'ramda';
import useAlt from 'hooks/useAlt';

const isModifiedEvent = event =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const isLeftClickEvent = propEq('button', 0);

const Link = memo(
  ({
    onClick,
    href,
    target,
    aliases,
    className,
    children,
    analytics,
    state,
    isLoading,
    markActiveForChildRoutes,
    ...rest
  }) => {
    const { activeUrl } = useAlt([ModuleNavigationStore], ([storeState]) => storeState);

    const isLinkActive = () => {
      if (activeUrl === href) {
        return true;
      }

      if (aliases && aliases.indexOf(activeUrl) > -1) {
        return true;
      }

      if (markActiveForChildRoutes && activeUrl && activeUrl.startsWith(href)) {
        return true;
      }

      return false;
    };

    const clickHandler = e => {
      let onClickPromise = Promise.resolve();

      if (onClick) {
        onClickPromise = Promise.resolve(onClick(e));
      }

      if (isModifiedEvent(e) || !isLeftClickEvent(e) || href === null || e.defaultPrevented) {
        return;
      }

      e.preventDefault();

      onClickPromise.then(() => {
        if (href.substr(0, 4) === 'http') {
          Analytics.track('External href', {
            href,
            ...analytics,
          });

          Analytics.attempToSendUntrackedEventsOnce().then(() => {
            if (target) {
              window.open(href, target);
            } else {
              window.location = href;
            }
          });
        } else {
          if (target) {
            window.open(href, target);
            return;
          }

          const uri = new URI(href);

          setTimeout(() => {
            ModuleNavigationActions.setAnalytics(uri.path(), analytics);
            ModuleNavigationActions.setAnalytics(href, analytics);
            browserHistory.push({
              pathname: uri.path(),
              query: uri.query(true),
              hash: uri.hash(),
              state,
            });
          });
        }
      });
    };

    const anchorClassName = classNames(className, {
      active: isLinkActive(),
    });

    return (
      <a
        {...rest}
        target={target}
        href={href}
        data-ignoreclickhandler
        className={anchorClassName}
        onClick={clickHandler}
        rel="noopener"
      >
        {children}
      </a>
    );
  },
);

Link.propTypes = {
  onClick: func,
  href: string,
  target: string,
  aliases: arrayOf(string),
  className: string,
  children: node,
  /* eslint-disable-next-line react/forbid-prop-types */
  analytics: object,
  /* eslint-disable-next-line react/forbid-prop-types */
  state: object,
  isLoading: bool,
  markActiveForChildRoutes: bool,
};

Link.defaultProps = {
  onClick: undefined,
  href: null,
  target: undefined,
  aliases: undefined,
  className: undefined,
  children: undefined,
  analytics: undefined,
  state: undefined,
  isLoading: undefined,
  markActiveForChildRoutes: false,
};

Link.displayName = 'Link';

export default Link;
