import { compose, when, equals } from 'ramda';
import { getManifestBody, getContentAsText, getTitle } from 'helpers/manifest';
import { prefillSelector, providerById } from 'selectors/providers';
import slugify from 'helpers/slugify';

const titleAsText = compose(
  when(equals(''), () => 'article'),
  slugify,
  getContentAsText,
  getTitle,
);

export function createItemUriFromManifest(manifest) {
  const manifestBody = getManifestBody(manifest);
  // Support backbone model and pojo
  const providerId = manifest.get ? manifest.get('provider').id : manifest.provider.id;
  const provider = prefillSelector(providerById)(providerId);

  return ['i', slugify(provider.name), titleAsText(manifestBody), manifest.id].join('/');
}

export function createItemUri({ contentPreviewElements, providerName, bnlId }) {
  return ['i', slugify(providerName), titleAsText(contentPreviewElements), bnlId].join('/');
}
