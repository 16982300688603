import Country from 'instances/country';

export function userEligibleForPremium(user) {
  return Country.isPremiumCountry(user.get('country'));
}

export function countryEligibleForPremium() {
  return Country.isPremiumCountry();
}

export function hasAccessToPremiumFeatures(user) {
  return (user && user.hasActivePremiumSubscription()) || countryEligibleForPremium();
}
