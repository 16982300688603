/* eslint-disable global-require */
import { asyncRoute } from 'helpers/routerHelpers';
import { getCountryCode } from 'instances/i18n';
import Features from 'config/features';
import { getExceptionForCountry } from 'helpers/countryExceptions';

const routeBase = {
  module: 'about',
  requireAuth: false,
};

export default [
  {
    ...routeBase,
    path: '/about',
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            dialogue: require('./containers/AboutPageContainer'),
          });
        },
        'about',
      );
    }),
    indexRoute: {
      ...routeBase,
      getComponent: asyncRoute((nextState, cb) => {
        require.ensure(
          [],
          () => {
            cb(null, require('./components/About'));
          },
          'about',
        );
      }),
    },
    childRoutes: [
      {
        ...routeBase,
        path: 'blendle',
        onEnter: (nextState, replace) => replace('/about'),
      },
      {
        ...routeBase,
        path: 'termsandconditions',
        getComponent: asyncRoute((nextState, cb) => {
          require.ensure(
            [],
            () => {
              cb(null, require('./components/TermsAndConditions'));
            },
            'about',
          );
        }),
      },
      {
        ...routeBase,
        path: 'this-is-blendle',
        onEnter: (_, replace) => {
          if (!getExceptionForCountry(getCountryCode(), 'hasTermsAndConditionsAddendum', false)) {
            replace('/404');
          }
        },
        getComponent: asyncRoute((nextState, cb) => {
          require.ensure(
            [],
            () => {
              cb(null, require('./components/TermsAndConditionsAddendum'));
            },
            'about',
          );
        }),
      },
      {
        ...routeBase,
        path: 'announcing-updated-termsandconditions',
        onEnter: (_, replace) => {
          if (
            !getExceptionForCountry(
              getCountryCode(),
              'showNewTermsAndConditionsAnnouncement',
              false,
            )
          ) {
            replace('/404');
          }
        },
        getComponent: asyncRoute((nextState, cb) => {
          require.ensure(
            [],
            () => {
              cb(null, require('./components/AnnouncingUpdatedTermsAndConditions'));
            },
            'about',
          );
        }),
      },
      {
        ...routeBase,
        path: 'privacy',
        getComponent: asyncRoute((nextState, cb) => {
          require.ensure(
            [],
            () => {
              cb(null, require('./containers/PrivacyContainer'));
            },
            'about',
          );
        }),
      },
    ],
  },
  {
    ...routeBase,
    path: '/contact',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/about'),
    },
  },
];
