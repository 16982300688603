import alt from 'instances/altInstance';
import { get } from 'lodash';
import FeatureCapabilitiesActions from 'actions/FeatureCapabilitiesActions';
import * as localStorage from 'helpers/localStorage';

const LOCAL_STORAGE_ITEM_NAME = 'forceCapabilities';

function forceAddedCapabilities() {
  const value = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
  if (!value) {
    return undefined;
  }

  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_NAME));
}

function getForceAddedCapabilities() {
  return get(forceAddedCapabilities(), 'forceAddedCapabilities', []);
}

function getForceRemovedCapabilities() {
  return get(forceAddedCapabilities(), 'forceRemovedCapabilities', []);
}

class FeatureCapabilitiesStore {
  capabilities = null;

  forceAddedCapabilities = new Set();

  forceRemovedCapabilities = new Set();

  constructor() {
    this.bindActions(FeatureCapabilitiesActions);

    // Setting the values later is needed to let the first snapshot pass in older browsers (casper, ie11, etc)
    setTimeout(() => {
      this.setState({
        forceAddedCapabilities: new Set(getForceAddedCapabilities()),
        forceRemovedCapabilities: new Set(getForceRemovedCapabilities()),
      });
    }, 0);
  }

  onSetCapabilities(capabilities) {
    this.setState({
      capabilities: new Set(capabilities),
    });
  }

  setForceCapability({ capability, value }) {
    this.forceAddedCapabilities.delete(capability);
    this.forceRemovedCapabilities.delete(capability);

    switch (value) {
      case 'add':
        this.forceAddedCapabilities.add(capability);
        break;
      case 'remove':
        this.forceRemovedCapabilities.add(capability);
        break;
      case 'unset':
        break;
      default:
        throw new Error(
          `setForceCapability called with invalid value \`${value}\`. Valid values are \`unset|add|remove\``,
        );
    }

    this.setState({
      forceAddedCapabilities: new Set(this.forceAddedCapabilities),
      forceRemovedCapabilities: new Set(this.forceRemovedCapabilities),
    });

    localStorage.setItem(
      LOCAL_STORAGE_ITEM_NAME,
      JSON.stringify({
        forceAddedCapabilities: Array.from(this.forceAddedCapabilities),
        forceRemovedCapabilities: Array.from(this.forceRemovedCapabilities),
      }),
    );
  }
}

export default alt.createStore(FeatureCapabilitiesStore, 'FeatureCapabilitiesStore');
