import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';

import withVisibilitySensor from 'higher-order-components/withVisibilitySensor';
import CSS from './style.scss';

export const Video = withVisibilitySensor(({ isInViewPort, ...props }) => {
  const videoRef = React.useRef(null);
  const filteredProps = R.omit(['hasBeenInViewPort'], props);

  React.useEffect(() => {
    const { current: videoEl } = videoRef;

    if (videoEl) {
      if (isInViewPort) {
        videoEl.play();
      } else {
        videoRef.current.currentTime = 0;
        videoEl.pause();
      }
    }
  }, [isInViewPort]);

  return (
    <video
      className={CSS.video}
      ref={videoRef}
      autoPlay
      muted
      loop
      playsInline
      preload="auto"
      {...filteredProps}
    />
  );
});

Video.propTypes = {
  src: PropTypes.string.isRequired,
};
