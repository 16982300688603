import Environment from 'environment';

// Used in filtering out the subscriptions on external providers
export const PREMIUM_PROVIDER_ID = 'blendlepremium';

// Used for navigation to the correct payment page in web-payments
export const PREMIUM_MONTHLY_PRODUCT = 'blendlepremium_monthly';
export const PREMIUM_YEARLY_PRODUCT = 'blendlepremium_yearly';
export const PREMIUM_MIGRATION_YEARLY_PAID_PER_MONTH =
  'blendlepremium_migration_yearly_paid_per_month';
export const PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL =
  'blendlepremium_thirty_days_reactivation_auto_renewal';

// Used to make sure a user cannot delete their account while a
// 6/12/14 months paid-per-month subscription is active
export const PREMIUM_NON_CLOSABLE_PAID_PER_MONTH_SUBSCRIPTION = [
  'blendlepremium_migration_half_year_paid_per_month_for_825_offer',
  'blendlepremium_migration_half_year_paid_per_month_for_700_offer',
  'blendlepremium_migration_half_year_paid_per_month_for_650_offer',
  'blendlepremium_migration_half_year_paid_per_month_for_550_offer',
  'blendlepremium_migration_half_year_paid_per_month_for_500_offer',
  'blendlepremium_migration_half_year_paid_per_month_for_400_offer',
  'blendlepremium_6_months_vouchers',
  'blendlepremium_migration_yearly_paid_per_month_175_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_275_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_350_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_425_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_500_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_600_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_675_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_726_discount_offer',
  'blendlepremium_migration_yearly_paid_per_month_825_discount_offer',
  'blendlepremium_yearly_paid_per_month_for_700_offer',
  'blendlepremium_yearly_paid_per_month_for_825',
  PREMIUM_MIGRATION_YEARLY_PAID_PER_MONTH,
  'blendlepremium_migration_2_years_paid_per_month_for_825_offer',
  'blendlepremium_migration_2_years_paid_per_month_for_750_offer',
  'blendlepremium_migration_2_years_paid_per_month_for_450_offer',
  'blendlepremium_migration_2_years_paid_per_month_for_400_offer',
  'blendlepremium_yearly_paid_per_month_for_750_offer',
];

export const STAFFPICKS = {
  NL: 'staffpicks',
  BE: 'staffpicks',
  DE: 'staffpicksde',
  US: 'staffpicksus',
};

export const STAFFPICKS_CHANNELS = Object.keys(STAFFPICKS).map((country) => STAFFPICKS[country]);

export const CUSTOM_SUBSCRIPTION_URLS = {
  zeit:
    'https://premium.zeit.de/bestellung/1551085?wt_zmc=fix.ext.zonpme.blendle.subscription.n4.text.link.link&utm_medium=fix&utm_source=blendle_zonpme_ext&utm_campaign=subscription&utm_content=n4_text_link_link#start',
};

export const CHANNEL_COLORS = {
  staffpicks: {
    text: '#000',
    background: '#FEB800',
  },
  achterklap: {
    background: '#8700B9',
    text: '#FFF',
  },
  economie: {
    background: '#FFF5B1',
    text: '#000',
  },
  muziek: {
    background: '#008DE8',
    text: '#FFF',
  },
  tech: {
    background: '#00DD5B',
    text: '#FFF',
  },
  politiek: {
    background: '#D9FD3D',
    text: '#000',
  },
  interviews: {
    background: '#001EB9',
    text: '#FFF',
  },
  columns: {
    background: '#5F4439',
    text: '#FFF',
  },
  crime: {
    background: '#78036E',
    text: '#FFF',
  },
  gezondheid: {
    background: '#BAFFFA',
    text: '#000',
  },
  buitenland: {
    background: '#00DFB7',
    text: '#000',
  },
  wetenschap: {
    background: '#000E51',
    text: '#FFF',
  },
  voetbalblendle: {
    background: '#FC1F58',
    text: '#FFF',
  },
  blendlemedia: {
    background: '#FF3796',
    text: '#FFF',
  },
  blendlesport: {
    background: '#008DA2',
    text: '#FFF',
  },
  food: {
    background: '#C5FF8A',
    text: '#000',
  },
  onderwijs: {
    background: '#84FD78',
    text: '#000',
  },
  cultuur: {
    background: '#147800',
    text: '#FFF',
  },
  geest: {
    background: '#A574FF',
    text: '#FFF',
  },
  reizen: {
    background: '#FFF031',
    text: '#000',
  },
  klimaat: {
    background: '#32E7FD',
    text: '#000',
  },
  geschiedenis: {
    background: '#002674',
    text: '#FFF',
  },
  usverkiezingen: {
    background: '#9357A9',
    text: '#FFF',
  },
  // USA CHANNELS
  techscience: {
    background: '#00DD5B',
    text: '#FFF',
  },
  politics: {
    background: '#D9FD3D',
    text: '#FFF',
  },
  election2016: {
    background: '#EF303B',
    text: '#FFF',
  },
  opinion: {
    background: '#5F4439',
    text: '#FFF',
  },
  businesseconomy: {
    background: '#FFF5B1',
    text: '#000',
  },
  wellnesshealth: {
    background: '#BAFFFA',
    text: '#000',
  },
  culturelifestyle: {
    background: '#147800',
    text: '#FFF',
  },
  sports: {
    background: '#008DA2',
    text: '#FFF',
  },
  interview: {
    background: '#001EB9',
    text: '#FFF',
  },
  usmedia: {
    background: '#FF3796',
    text: '#000',
  },
  staffpicksus: {
    background: '#FEB800',
    text: '#000',
  },
  mustread: {
    background: '#4A5170',
    text: '#FFF',
  },
};

export const LAB_EXPERIMENTS = {
  EDITOR_BUTTON: 'editor_button',
  TEXT_TO_SPEECH: 'TEXT_TO_SPEECH',
  ENTITIES: 'ENTITIES',
  SEARCH: 'SEARCH',
};

const LINDA_SHARER = 'blendanieuws';
export const PARTNER_SHARERS = [LINDA_SHARER];

export const FEATURE_CAPABILITY_SEARCH = 'has_search';
export const FEATURE_CAPABILITY_ALERTS = 'has_alerts';
export const FEATURE_CAPABILITY_NEWSSTAND = 'has_newsstand';
export const FEATURE_CAPABILITY_LEGACY_TIMELINES = 'has_legacy_timelines';
export const FEATURE_CAPABILITY_NON_STAFFPICKS_SECTIONS = 'has_non_staff_picks_sections';
export const FEATURE_CAPABILITY_SUBSCRIPTION_CONFIGURATION =
  'has_publisher_subscription_configuration';
export const FEATURE_CAPABILITY_CONTENT_PREFERENCE_SELECTOR = 'has_content_preferences_selector';
export const FEATURE_CAPABILITY_MICROPAYMENTS = 'has_micropayments';
export const FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP = 'has_micropayments_top_up';
export const FEATURE_CAPABILITY_MICROPAYMENTS_AUTO_TOPUP = 'has_micropayment_auto_top_up';
export const FEATURE_CAPABILITY_ACCESS_TO_BLENDLE = 'has_access_to_blendle';
export const FEATURE_CAPABILITY_MANAGE_PREMIUM = 'can_manage_premium';
export const FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA = 'has_premium_upsell_call_to_action';
export const FEATURE_CAPABILITY_BLENDLE_PREMIUM = 'has_blendle_premium';
export const FEATURE_CAPABILITY_MICROPAYMENT_MIGRATION_FEATURES =
  'has_micropayment_migration_features';
export const FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST =
  'has_audio_recommendation_playlist';
export const FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS = 'has_brands_and_topics';
export const FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER = 'has_upsell_wallet_offer';
export const FEATURE_CAPABILITY_HAS_ALGORITHMIC_SELECTION = 'has_algorithmic_selection';
export const FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS = 'has_premium_credits';
export const ALL_FEATURE_CAPABILITIES = [
  FEATURE_CAPABILITY_SEARCH,
  FEATURE_CAPABILITY_NEWSSTAND,
  FEATURE_CAPABILITY_ALERTS,
  FEATURE_CAPABILITY_LEGACY_TIMELINES,
  FEATURE_CAPABILITY_NON_STAFFPICKS_SECTIONS,
  FEATURE_CAPABILITY_SUBSCRIPTION_CONFIGURATION,
  FEATURE_CAPABILITY_CONTENT_PREFERENCE_SELECTOR,
  FEATURE_CAPABILITY_MICROPAYMENTS,
  FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP,
  FEATURE_CAPABILITY_MICROPAYMENTS_AUTO_TOPUP,
  FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  FEATURE_CAPABILITY_MICROPAYMENT_MIGRATION_FEATURES,
  FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST,
  FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS,
  FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER,
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
];

// Email toggles
export const MASTER_OPT_OUT = 'master_opt_out';
export const ALERTS_OPT_OUT = 'alerts_opt_out';
export const NEW_EDITION_OPT_OUT = 'new_edition_opt_out';
export const READ_LATER_OPT_OUT = 'read_later_opt_out';
export const DIGEST_OPT_OUT = 'digest_opt_out';
export const WEEKLY_DIGEST_OPT_OUT = 'weekly_digest_opt_out';
export const MAGAZINE_DIGEST_OPT_OUT = 'magazine_digest_opt_out';
export const MARKETING_OPT_OUT = 'marketing_opt_out';
export const TIPS_OPT_OUT = 'tips_opt_out';
export const ANNOUNCEMENTS_OPT_OUT = 'announcements_opt_out';
export const SURVEY_OPT_OUT = 'survey_opt_out';
export const EMAIL_TOGGLES = [
  MASTER_OPT_OUT,
  ALERTS_OPT_OUT,
  NEW_EDITION_OPT_OUT,
  READ_LATER_OPT_OUT,
  DIGEST_OPT_OUT,
  WEEKLY_DIGEST_OPT_OUT,
  MAGAZINE_DIGEST_OPT_OUT,
  MARKETING_OPT_OUT,
  TIPS_OPT_OUT,
  ANNOUNCEMENTS_OPT_OUT,
  SURVEY_OPT_OUT,
];

export const keyCode = {
  BACKSPACE: 8,
  TAB: 9,
  CONTROL: 17,
  ESC: 27,
  RETURN: 13,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  COMMAND: 91,
};

export const XHR = 'XHR';
export const XHR_STATUS = 'XHR_STATUS';
export const XHR_ERROR = 'XHR_ERROR';
export const XHR_TIMEOUT = 'XHR_TIMEOUT';
export const XHR_ABORT = 'XHR_ABORT';
export const STATUS_ERROR = -1;
export const STATUS_INITIAL = 0;
export const STATUS_PENDING = 1;
export const STATUS_OK = 2;

export const NO_TOKEN = 'NO_TOKEN';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const AUTH_REQUIRED = 'AuthRequired';

export const NOT_FOUND = 'NOT_FOUND';
export const MAINTENANCE = 'Maintenance';

export const SIGNUP_TYPE_AFFILIATE = 'SIGNUP_TYPE_AFFILIATE';
export const SIGNUP_TYPE_PAID_ADVERTISEMENT = 'SIGNUP_TYPE_PAID_ADVERTISEMENT';
export const SIGNUP_TYPE_DEEPLINK = 'DEEPLINK';
export const SIGNUP_TYPE_SUBSCRIPTION = 'SUBSCRIPTION';
export const SIGNUP_TYPE_PREMIUM_ONBOARDING = 'SIGNUP_TYPE_PREMIUM_ONBOARDING';
export const SIGNUP_TYPE_ONBOARDING = 'SIGNUP_TYPE_ONBOARDING';
export const SIGNUP_TYPE_EMAIL_DEEPLINK = 'SIGNUP_TYPE_EMAIL_DEEPLINK';

export const SIGNUP_PLATFORM_EMAIL = 'SIGNUP_EMAIL';
export const SIGNUP_PLATFORM_FACEBOOK = 'SIGNUP_FACEBOOK';

export const EMAIL_BLACKLISTED = 'BlacklistedEmail';
export const EMAIL_INVALID = 'InvalidEmail';
export const EMAIL_NOT_CONFIRMED = 'EmailNotConfirmed';
export const EMAIL_CONTAINS_PLUS_SIGN = 'EmailContainsPlusSign';
export const EMAIL_EXISTS = 'ExistingEmail';
export const USER_ID_TAKEN = 'UserIdTaken';
export const FIRST_NAME_INVALID = 'FirstNameInvalid';
export const PASSWORD_INVALID = 'PasswordInvalid';

export const USER_REJECTED_EMAIL = 'USER_REJECTED_EMAIL';

export const FACEBOOK_FAILURE = 'FacebookFailure';

export const PREMIUM_REASON_MUST_READ = 'PREMIUM_REASON_MUST_READ';
export const PREMIUM_REASON_CHANNEL = 'PREMIUM_REASON_CHANNEL';
export const PREMIUM_REASON_STAFF_PICK = 'PREMIUM_REASON_STAFF_PICK';
export const PREMIUM_REASON_FOR_YOU = 'PREMIUM_REASON_FOR_YOU';

export const ORDER_REFUSED = 'ORDER_REFUSED';

export const MANUAL_LOGOUT = 'Manual_logout';
export const MISSING_PASSWORD = 'MissingPassword';

export const LIBRARY_UNAVAILABLE = 'LIBRARY_UNAVAILABLE';

export const REDIRECT_TO_URL = 'RedirectToUrl';

export const RECURRING_MINIMAL_AMOUNT = 1; // Minimal amount (euro's) before a recurring payment is triggere

export const BLENDLE_COUPON = 'Blendle';

export const DEFAULT_AVATAR =
  Environment.name === 'localhost:3000/static/images/avatar.jpg'
    ? ''
    : 'https://static.blendle.com/images/default/avatar/default.jpg';

export const MAX_TILE_HEIGHT = 760;

export const MAX_PREMIUM_TILE_HEIGHT = 740;

export const MAX_DISCOVER_TILE_HEIGHT = 700;

export const DESKTOP_NAVIGATION_HEIGHT_PX = 62;

export const DEFAULT_ISSUES_PER_PAGE = 20;

export const AUTO_REFUND_TIMEOUT = 10000; // 10s in milliseconds
export const TOP_BAR_SLIDE_IN_PERCENTAGE = 97;

export const JOBS_URL_BASE =
  'https://blendle.homerun.co/utm_medium=web&utm_campaign=werken-bij-blendle&utm_source=blendle&utm_content=usernavigation&';

export const HOME_ROUTE = '/home';

export const PREFER = {
  LESS: 'less',
  NEGATIVE: 'negative',
  RESET: 'reset',
};

export const APP_STORE_URL = 'https://itunes.apple.com/nl/app/blendle/id947936149';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.blendle.app';

export const LINKEDIN_LINK =
  Environment.name === 'test'
    ? ''
    : 'http://www.linkedin.com/shareArticle?mini=true&url=%s&title=%s&summary=%s&source=%s';

export const HELP_DESK_URL = 'https://www.blendle.support/';

export const ENTITY_STATE = {
  FOLLOW: 'follow',
};

export const ADJUST_CAMPAIGN = {
  PERSONAL_PAGE: 'Personal Page',
  DISCOVER_PREMIUM: 'Discover Premium',
  DISCOVER_PREMIUM_HARDPHASE: 'Discover Premium (Hard Phase)',
  PREMIUM_CONFIRMATION_DIALOG: 'Premium Confirmation Dialog',
  TRY_AUDIO_PAGE: 'Download App Link',
};

export const ADJUST_ADGROUP = {
  BANNER: 'Banner',
  FAQ: 'FAQ',
  FOOTER: 'Footer',
  HEADER: 'Header',
  NEW_USER: 'New User',
  MICROPAYMENT_CONVERSION: 'Micropayment Conversion',
  WEB_AUDIO: 'Web Audio',
};

export const ADJUST_CREATIVE = {
  GENERIC: 'generic-banner',
  AUDIO: 'audio-banner',
  ITEM: 'item-banner',
  BUTTON: 'button',
};

export const INTERNAL_LOCATION = {
  OPEN_APP_READER: 'open-app-reader',
  OPEN_APP_TIMELINE: 'open-app-timeline',
  READ_FOR_FREE: 'read-for-free',
  READ_FOR_FREE_LIMIT_REACHED: 'read-for-free-limit-reached',
  TIMELINE_PREMIUM: 'timeline/premium',
  TIMELINE_EARLIER: 'timeline/earlier',
  TIMELINE_PINS: 'timeline/pins',
  TIMELINE_USER: 'timeline/user',
  TIMELINE_ARCHIVE: 'timeline/archive',
  TIMELINE_TRENDING: 'timeline/trending',
  TIMELINE_CHANNEL: 'timeline/channel',
  ITEM: 'item',
  NAVIGATION_BAR: 'navigation-bar',
  USER_DROPDOWN: 'user_dropdown',
  EXPLAIN_PREMIUM_UPSELL_PAGE: 'explain_premium_upsell_page',
  EXPLAIN_PREMIUM_PAGE: 'explain_premium_page',
  AUDIO_PAGE: 'audio-page',
  MIGRATION_ANNOUNCEMENT_DIALOG: 'migration_announcement_dialog',
  PREMIUM_ONBOARDUNG: 'premiumonboarding',
  DISCOVER_PAGE: 'discover-page',
  DISCOVER_ISSUE_ARCHIVE: 'discover-issue-archive',
  PAGED_ISSUE_VIEW: 'paged-issue-view',
  DEEPLINK: 'deeplink',
  CREDIT_KIOSK: 'credit-kiosk',
  SETTINGS: 'settings',
  SEARCH: 'search',
  DISCOVER_SEARCH: 'discover-search',
};

export const LOCATION_IN_LAYOUT = {
  UPSELL_POPUP: 'upsell_popup',
  UPSELL_BANNER: 'upsell_banner',
  MICROPAYMENT_OVERLAY: 'micropayment_overlay',
  EXPLAIN_PREMIUM_AUDIO: 'explain_premium_audio',
  EXPLAIN_PREMIUM_COVERS: 'explain_premium_covers',
  EXPLAIN_PREMIUM_ENTITIES: 'explain_premium_entities',
  EXPLAIN_PREMIUM_TRIAL: 'explain_premium_trial',
  TOPUP_DIALOG: 'topup_dialog',
  NAVIGATION_TOP_BAR: 'navigation-top-bar',
  HEADER: 'header',
  FAQ: 'faq',
  MANIFEST_DROPDOWN: 'manifest-dropdown',
};

export const SUCCESS_DIALOG_VERSION = {
  DEFAULT: 'default',
  WALLET_CONVERSION: 'wallet_conversion',
  WALLET_CONVERSION_APP_FLOW: 'wallet_conversion_app_flow',
  MP_PLUS_PREMIUM_FLOW: 'mp_plus_premium_flow',
  ITEM_DEEPLINK: 'item_deeplink',
};

export const APP_DEEPLINK = {
  WALLET_CONVERSION_SUCCCESS: 'blendle://wallet-conversion/success',
  WALLET_CONVERSION_FAILED: 'blendle://wallet-conversion/failed',
  LOGIN: 'blendle://login/',
};

export const DISCOVER_PREMIUM_PAGE_VERSION = {
  PREMIUM_ONLY: 'premium_only',
  UPSELL_ONLY: 'upsell_only',
};

export const GRAPHQL_QUERY_NAME = {
  RECENT_ISSUES: 'recentIssues',
  KIOSK_ISSUES: 'kioskIssues',
  NODES: 'nodes',
};

export const GRAPHQL_TYPE_NAME = {
  ARTICLE: 'Article',
  ARTICLE_VIEWER_CONTEXT: 'ArticleViewerContext',
  SEARCH_ARTICLE_CARD: 'SearchArticleCard',
};

export const CREDIT_CURRENCY_NAME = 'CREDIT';

export const DEFAULT_CREDITS_BUNDLE = {
  amount: 50,
  formattedPrice: '€\u00a02,50',
};

export const DISCOVER_PAGE_TYPE = {
  RECENT_ISSUES: 'recent-issues',
  PROVIDER_ISSUES: 'provider-issues',
  ISSUE: 'issue',
};

export const NAVIGATION_BAR_TYPE = {
  PREMIUM_ONLY: 'premium_only',
  PREMIUM_AND_MICROPAYMENTS: 'premium_and_micropayments',
  MICROPAYMENTS: 'micropayments',
};

export const SHARING_PLATFORM = {
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  WHATSAPP: 'whatsapp',
  LINKEDIN: 'linkedin',
  EMAIL: 'email',
};

export const SYNC_ACTION_TYPE = {
  PIN_STATE_CHANGED_VIA_MUTATION: 'pinStateChangedViaMutation',
  PIN_STATE_CHANGED_VIA_TILES: 'pinStateChangedViaTiles',
  ITEM_REFUNDED: 'itemRefunded',
  ITEM_OPENED: 'itemOpened',
  ITEM_LIKED: 'itemLiked',
  ITEM_UN_LIKED: 'itemUnLiked',
};
