// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".topSection-3-i-B{margin-top:99px;margin-bottom:31px}.topTitle-rIAUp{color:#333;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;padding-bottom:15px;font-size:24px;line-height:28px;letter-spacing:-0.05em}@media (min-width: 600px){.topTitle-rIAUp{font-size:34px;line-height:38px;text-align:center}}.topBody-18DAJ{font-family:\"GT-Walsheim\",sans-serif;color:#3d3d3d;font-size:22px;line-height:28px;padding-bottom:16px;max-width:630px;margin:0 auto}@media (min-width: 600px){.topBody-18DAJ{font-size:26px;line-height:41px;text-align:center}}.boxWrapper-1kjhc{display:flex;flex-direction:column;margin-left:-8px;margin-right:-8px;margin-bottom:100px;justify-content:'space-between'}@media (min-width: 700px){.boxWrapper-1kjhc{flex-direction:row;margin-left:0;margin-right:0}}.basebox-III-_{width:100%;background:#f5f7f8;padding:24px}@media (min-width: 700px){.basebox-III-_{padding:42px}}@media (min-width: 700px){.boxLeft-3lJuT{max-width:468px;margin-right:12px}}.boxRight-aprnd{margin-top:8px}@media (min-width: 700px){.boxRight-aprnd{max-width:468px;margin-left:12px;margin-top:0}}.boxTitle-1Nq6a{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-size:28px;line-height:31px;letter-spacing:-0.05em;font-weight:600;margin-bottom:12px}.boxCopy-1dJpn{font-family:\"GT-Walsheim\",sans-serif;color:#707070;font-size:18px;line-height:25px;letter-spacing:-0.02em}\n", ""]);
// Exports
exports.locals = {
	"topSection": "topSection-3-i-B",
	"topTitle": "topTitle-rIAUp",
	"topBody": "topBody-18DAJ",
	"boxWrapper": "boxWrapper-1kjhc",
	"basebox": "basebox-III-_",
	"boxLeft": "boxLeft-3lJuT",
	"boxRight": "boxRight-aprnd",
	"boxTitle": "boxTitle-1Nq6a",
	"boxCopy": "boxCopy-1dJpn"
};
module.exports = exports;
