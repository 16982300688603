import React from 'react';
import { bool, oneOf } from 'prop-types';
import { getCountryCode, translate } from 'instances/i18n';
import { getExceptionForCountry } from 'helpers/countryExceptions';
import {
  BookmarkIcon,
  BrowseIcon,
  NavigationBar,
  NavigationLeft,
  NavigationRight,
  NavigationItem,
  KioskIcon,
} from '@blendle/lego';
import AudioIcon from 'components/icons/Audio';
import Link from 'components/Link';
import UserDropdownContainer from 'containers/navigation/UserDropdownContainer';
import DiscoverPremiumLink from 'components/navigation/DiscoverPremiumLink';
import PinsCountNavigationItemBadge from 'components/navigation/PinsCountNavigationItemBadge';
import { NAVIGATION_BAR_TYPE } from 'app-constants';
import styledModule from 'helpers/styledModule';
import MicropaymentSignupNavigationBar from '../MicropaymentSignupNavigationBar';
import SearchBar from '../SearchBar';
import LogoLink from '../LogoLink';
import CSS from './style.scss';
import CreditsKioskBadge from '../CreditsKioskBadge';

// When changing these breakpoints, then you must also update the breakpoints in _vars.scss file in the navigation folder
const navBarBreakpoints = {
  [NAVIGATION_BAR_TYPE.PREMIUM_ONLY]: {
    smallBreakpoint: '400px',
    mediumBreakpoint: '820px',
    largeBreakpoint: '920px',
  },
  [NAVIGATION_BAR_TYPE.PREMIUM_AND_MICROPAYMENTS]: {
    smallBreakpoint: '350px',
    mediumBreakpoint: '750px',
    largeBreakpoint: '920px',
  },
  [NAVIGATION_BAR_TYPE.MICROPAYMENTS]: {
    smallBreakpoint: '350px',
    mediumBreakpoint: '620px',
    largeBreakpoint: '920px',
  },
};

const StyledLink = styledModule(Link)(CSS.link);
const IconWrapperLink = styledModule(Link)(CSS.iconWrapper);
const StyledBrowseIcon = styledModule(BrowseIcon)(CSS.iconBrowse);
const StyledAudioIcon = styledModule(AudioIcon)(CSS.iconAudio);
const StyledBookmarkIcon = styledModule(BookmarkIcon)(CSS.iconBookmark);

function getBrowseLink({ showKiosk, showLegacyTimelines }) {
  if (showKiosk) {
    return '/kiosk';
  }

  if (showLegacyTimelines) {
    return '/trending/now';
  }

  return undefined;
}

const HOME_ALIASES = ['/home', '/premium2'];

const NavigationItemDesktop = (props) => (
  <NavigationItem hideBelowNavBarBreakpoint="medium" {...props} />
);

const NavigationItemMobile = (props) => (
  <NavigationItem hideAboveNavBarBreakpoint="medium" {...props} />
);

function DefaultNavigationBar({
  blendleIsLocked,
  showSearch,
  showAudio,
  showAlerts,
  showEarlierPage,
  showLegacyTimelines,
  showKiosk,
  showCreditsKiosk,
  showDiscoverIssue,
  isLoggedIn,
  showDiscoverPremium,
  navigationBarType,
}) {
  // We only have to check if the user is not logged in. The premium signup module defines the
  // navigation bar in the router, so this will never render on the premium signup.
  if (!isLoggedIn) {
    return <MicropaymentSignupNavigationBar data-testid="mp-signup-navigation-bar" />;
  }

  const blendleLogo = (
    <LogoLink
      className={CSS.logo}
      beta={getExceptionForCountry(getCountryCode(), 'showBetaLogo', false)}
      width={97}
      height={26}
    />
  );

  if (blendleIsLocked) {
    return (
      <NavigationBar data-testid="navigation-bar-main">
        <NavigationLeft>{blendleLogo}</NavigationLeft>
        <NavigationRight>
          <UserDropdownContainer />
        </NavigationRight>
      </NavigationBar>
    );
  }

  const browseLink = getBrowseLink({ showLegacyTimelines, showKiosk });

  return (
    <NavigationBar data-testid="navigation-bar-main" {...navBarBreakpoints[navigationBarType]}>
      <NavigationLeft>
        {blendleLogo}
        <NavigationItemDesktop>
          <StyledLink href="/" aliases={HOME_ALIASES}>
            {translate('navigation.links.for_you.label')}
          </StyledLink>
        </NavigationItemDesktop>
        {showEarlierPage && (
          <NavigationItemDesktop>
            <StyledLink href="/earlier">{translate('navigation.links.earlier.label')}</StyledLink>
          </NavigationItemDesktop>
        )}
        {browseLink && (
          <NavigationItemDesktop>
            <StyledLink href={browseLink} data-testid="browse-link">
              {translate('navigation.links.kiosk.label')}
            </StyledLink>
          </NavigationItemDesktop>
        )}
        {showDiscoverIssue && (
          <NavigationItemDesktop>
            <StyledLink
              href="/discover/issue"
              data-testid="discover-issues-link"
              markActiveForChildRoutes
            >
              {translate('navigation.links.magazines.label')}
            </StyledLink>
          </NavigationItemDesktop>
        )}
        {showCreditsKiosk && (
          <NavigationItemDesktop>
            <StyledLink
              href="/kiosk/issue"
              data-testid="credits-kiosk-link"
              markActiveForChildRoutes
            >
              {translate('navigation.links.creditsKiosk.label')}
            </StyledLink>
            <CreditsKioskBadge />
          </NavigationItemDesktop>
        )}
        <NavigationItemDesktop>
          <StyledLink href="/pins">{translate('navigation.links.readlater.label')}</StyledLink>
          <PinsCountNavigationItemBadge data-testid="navigation-readlater-count" />
        </NavigationItemDesktop>
        {showAlerts && (
          <NavigationItemDesktop>
            <StyledLink href="/alerts">{translate('navigation.links.alerts.label')}</StyledLink>
          </NavigationItemDesktop>
        )}
        {showAudio && (
          <NavigationItemDesktop>
            <StyledLink href="/probeer-audio">
              {translate('navigation.links.audio.label')}
            </StyledLink>
          </NavigationItemDesktop>
        )}
      </NavigationLeft>
      <NavigationRight>
        {browseLink && (
          <NavigationItemMobile>
            <IconWrapperLink href={browseLink}>
              <StyledBrowseIcon />
            </IconWrapperLink>
          </NavigationItemMobile>
        )}
        {showDiscoverIssue && (
          <NavigationItemMobile>
            <IconWrapperLink
              markActiveForChildRoutes
              href="/discover/issue"
              data-testid="discover-issues-link-mobile"
            >
              <StyledBrowseIcon />
            </IconWrapperLink>
          </NavigationItemMobile>
        )}
        {showCreditsKiosk && (
          <NavigationItemMobile>
            <IconWrapperLink
              markActiveForChildRoutes
              href="/kiosk/issue"
              data-testid="credits-kiosk-link-mobile"
            >
              <KioskIcon />
              <CreditsKioskBadge smallBadge />
            </IconWrapperLink>
          </NavigationItemMobile>
        )}
        {showAudio && (
          <NavigationItemMobile hideBelowNavBarBreakpoint="small">
            <IconWrapperLink href="/probeer-audio">
              <StyledAudioIcon />
            </IconWrapperLink>
          </NavigationItemMobile>
        )}
        <NavigationItemMobile>
          <IconWrapperLink href="/pins">
            <StyledBookmarkIcon />
          </IconWrapperLink>
          <PinsCountNavigationItemBadge />
        </NavigationItemMobile>
        {showDiscoverPremium && <DiscoverPremiumLink data-testid="discover-premium-link" />}
        {showSearch && <SearchBar />}
        <UserDropdownContainer />
      </NavigationRight>
    </NavigationBar>
  );
}

DefaultNavigationBar.propTypes = {
  blendleIsLocked: bool,
  showSearch: bool.isRequired,
  showAudio: bool.isRequired,
  showAlerts: bool.isRequired,
  showEarlierPage: bool.isRequired,
  showLegacyTimelines: bool.isRequired,
  showKiosk: bool.isRequired,
  showCreditsKiosk: bool.isRequired,
  showDiscoverIssue: bool.isRequired,
  isLoggedIn: bool.isRequired,
  showDiscoverPremium: bool.isRequired,
  navigationBarType: oneOf([
    NAVIGATION_BAR_TYPE.MICROPAYMENTS,
    NAVIGATION_BAR_TYPE.PREMIUM_AND_MICROPAYMENTS,
    NAVIGATION_BAR_TYPE.PREMIUM_ONLY,
  ]).isRequired,
};

DefaultNavigationBar.defaultProps = {
  blendleIsLocked: true,
};

export default DefaultNavigationBar;
