/* eslint-disable global-require */
import { asyncRoute } from 'helpers/routerHelpers';
import { FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS } from 'app-constants';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { capabilityIsLockedAsync } from 'selectors/capabilities';

const onEnter = (_, replace, callback) => {
  capabilityIsLockedAsync(FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS).then((creditsKioskIsLocked) => {
    if (creditsKioskIsLocked) {
      replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS));
    }
    callback();
  });
};

function routeRecentIssues(path) {
  return {
    module: 'creditsKiosk',
    path,
    onEnter,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/RecentIssuesRootContainer'),
          });
        },
        'creditsKiosk',
      );
    }),
  };
}

function routeProviderIssues(path) {
  return {
    module: 'creditsKiosk',
    path,
    onEnter,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/ProviderIssuesRootContainer'),
          });
        },
        'creditsKiosk',
      );
    }),
  };
}

function onEnterIssue(_, replace, callback) {
  const creditsKioskNavigationActions = require('actions/CreditsKioskNavigationActions');
  creditsKioskNavigationActions.setIssuePageActive();

  onEnter(_, replace, callback);
}

function onLeaveIssue() {
  const creditsKioskNavigationActions = require('actions/CreditsKioskNavigationActions');
  creditsKioskNavigationActions.setIssuePageInactive();
}

function routeIssue(path) {
  return {
    module: 'creditsKiosk',
    path,
    onEnter: onEnterIssue,
    onLeave: onLeaveIssue,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/IssueRootContainer'),
          });
        },
        'creditsKiosk',
      );
    }),
  };
}

export default [
  routeRecentIssues('kiosk/issue'),
  routeProviderIssues('kiosk/issue/:graphQLProviderId'),
  routeIssue('kiosk/issue/:graphQLProviderId/:graphQLIssueId'),
];
