import axios from 'axios';

export default function sendPolledRequest(requestOptions, getTimeoutTime, maxRetries) {
  let retryCount = 0;

  return new Promise((resolve, reject) => {
    const wrappedRequest = () => {
      axios
        .request(requestOptions)
        .then(resolve)
        .catch(err => {
          retryCount += 1;

          // Check if max retry count is reached
          if (retryCount >= maxRetries) {
            return reject(err);
          }

          // Get the retry timeout..
          const nextTimeoutTime = getTimeoutTime(err, retryCount);

          // ..and try again
          setTimeout(wrappedRequest, nextTimeoutTime);

          return null;
        });
    };

    wrappedRequest();
  });
}
