import { getReturnUrl, asyncRoute } from 'helpers/routerHelpers';
import {
  FEATURE_CAPABILITY_MICROPAYMENTS,
  FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP,
} from 'app-constants';
import { capabilityIsLockedAsync } from 'selectors/capabilities';

function onEnter() {
  document.body.classList.add('m-payment');
}

function onLeave() {
  document.body.classList.remove('m-payment');
}

function route(path, dialogueHandler, { isLegoDialog }) {
  return {
    module: 'payment',
    path,
    onEnter,
    onLeave,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          const paymentModule = require('./module');
          const returnUrl = getReturnUrl(path, nextState.location.state);
          cb(
            null,
            isLegoDialog
              ? {
                  legoDialog: dialogueHandler.bind(
                    null,
                    paymentModule,
                    returnUrl,
                    nextState.params,
                    nextState.location.state,
                  ),
                }
              : {
                  dialogue: dialogueHandler.bind(
                    null,
                    paymentModule,
                    returnUrl,
                    nextState.params,
                    nextState.location.state,
                  ),
                },
          );
        },
        'payment',
      );
    }),
  };
}

function lockableWalletRoute(path, dialogHandler, options = { isLegoDialog: false }) {
  const originalWalletRoute = route(path, dialogHandler, options);

  return {
    ...originalWalletRoute,
    onEnter: (nextState, replace, callback) => {
      capabilityIsLockedAsync(FEATURE_CAPABILITY_MICROPAYMENTS).then(walletIsLocked => {
        if (walletIsLocked) {
          replace('/404');
          callback();
          return;
        }

        originalWalletRoute.onEnter(nextState, replace, callback);

        callback();
      });
    },
  };
}

function lockableTopupRoute(path, dialogHandler, options = { isLegoDialog: false }) {
  const originalWalletRoute = route(path, dialogHandler, options);

  return {
    ...originalWalletRoute,
    onEnter: (nextState, replace, callback) => {
      capabilityIsLockedAsync(FEATURE_CAPABILITY_MICROPAYMENTS_TOPUP).then(walletIsLocked => {
        if (walletIsLocked) {
          replace('/404');
          callback();
          return;
        }

        originalWalletRoute.onEnter(nextState, replace, callback);

        callback();
      });
    },
  };
}

export default [
  lockableTopupRoute('payment', (mod, returnUrl) => mod.openPayment(returnUrl)),
  lockableTopupRoute('payment/success', mod => mod.openPaymentResult('success')),
  lockableTopupRoute('payment/pending', mod => mod.openPaymentResult('pending')),
  lockableTopupRoute('payment/cancelled', mod => mod.openPaymentResult('cancelled')),
  lockableWalletRoute(
    'payment/outofbalance',
    (mod, returnUrl, params, nextLocationState) =>
      mod.openOutOfBalance(returnUrl, nextLocationState),
    { isLegoDialog: true },
  ),
  lockableTopupRoute('payment/recurring', (mod, returnUrl) => mod.openPaymentRecurring(returnUrl)),
  lockableTopupRoute('payment/recurring/activate', (mod, returnUrl) =>
    mod.openPaymentRecurring(returnUrl, true),
  ),
];
