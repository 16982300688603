// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-1xV0H{padding:0 0 83px}.container-J9meP{background-color:#f5f7f8;border-radius:4px;min-height:80px;display:flex;flex-direction:column;padding:18px;justify-content:space-between;text-align:center}@media (min-width: 600px){.container-J9meP{flex-direction:row;text-align:left}}.body-1k6VP{align-self:center;font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:25px;letter-spacing:-0.02em;color:#333;opacity:0.9;padding:0 14px 16px}@media (min-width: 600px){.body-1k6VP{padding:0 14px}}.error-18wz3{color:#e4300b}.button-2sQ__.button-2sQ__{padding:10px;height:44px;width:100%;max-width:200px;font-weight:500;font-size:16px;letter-spacing:-0.02em;color:#333;background-color:#fff;align-self:center;box-shadow:0 1px 2px rgba(0,0,0,0.05);border:1px solid rgba(0,0,0,0.1)}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-1xV0H",
	"container": "container-J9meP",
	"body": "body-1k6VP",
	"error": "error-18wz3",
	"button": "button-2sQ__"
};
module.exports = exports;
