import React, { Component } from 'react';
import { oneOfType, string, shape, func, object } from 'prop-types';
import { wrapDisplayName } from '@blendle/recompose';
import { withRouter } from 'react-router';

const redirect = ({ to, replace = false, renderNothing = false } = {}) => (ComposedComponent) =>
  withRouter(
    class Redirect extends Component {
      static displayName = wrapDisplayName(ComposedComponent, 'redirect');

      static propTypes = {
        redirectTo: oneOfType([
          string,
          shape({
            pathname: string,
            query: object,
            state: object,
          }),
        ]),
        router: shape({
          replace: func.isRequired,
          push: func.isRequired,
        }).isRequired,
      };

      redirectOnce() {
        if (this.redirected) {
          return;
        }

        const { router, redirectTo } = this.props;
        const destination = redirectTo || to;
        const redirectFn = replace ? router.replace : router.push;

        this.redirected = setTimeout(() => redirectFn(destination), 0);
      }

      render() {
        this.redirectOnce();

        if (renderNothing) {
          return null;
        }

        return <ComposedComponent {...this.props} />;
      }
    },
  );

export default redirect;
