// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3s-Na{display:flex;margin-bottom:12px}.checkmark-2L9Df{align-self:center;margin-right:12px}.copy-1d2n7{font-family:\"GT-Walsheim\",sans-serif;color:#5b5b5b;font-size:18px;letter-spacing:-0.02em}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3s-Na",
	"checkmark": "checkmark-2L9Df",
	"copy": "copy-1d2n7"
};
module.exports = exports;
