// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1svCb{width:335px;height:500px;padding:50px;text-align:center}.container-1svCb p{margin-bottom:20px}.container-1svCb button{width:100%}.avatar-1MCrN{max-width:85%}.l-mobile .avatar-1MCrN{width:125px}.title-vnXm1{font-size:1.5em}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1svCb",
	"avatar": "avatar-1MCrN",
	"title": "title-vnXm1"
};
module.exports = exports;
