import React from 'react';
import { locale as i18n } from 'instances/i18n';
import AvatarImage from 'components/AvatarImage';
import styledModule from 'helpers/styledModule';
import BalanceLabel from 'components/BalanceLabel';
import useUser from 'hooks/useUser';
import CSS from './styles.scss';

const StyledBalanceLabel = styledModule(BalanceLabel)(CSS.balance);

export default function PaymentHeader() {
  const user = useUser();

  const avatar = user.getAvatarHref();

  return (
    <div className="v-avatar-balance-header">
      <div className="title">
        <div className="avatar">
          <AvatarImage src={avatar} />
        </div>
        <div className="current-balance">
          {i18n.payment.text.current_balance}
          <StyledBalanceLabel />
        </div>
      </div>
    </div>
  );
}
