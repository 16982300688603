import React from 'react';
import { bool, string, oneOf } from 'prop-types';
import AppStoreButton from 'components/buttons/AppStoreButton';
import PlayStoreButton from 'components/buttons/PlayStoreButton';
import styledModule from 'helpers/styledModule';
import Analytics from 'instances/analytics';
import { getAdjustUrl, getAdjustLoginDeeplink } from 'helpers/adjust';
import {
  ADJUST_CAMPAIGN,
  ADJUST_CREATIVE,
  ADJUST_ADGROUP,
  APP_STORE_URL,
  PLAY_STORE_URL,
  INTERNAL_LOCATION,
  SUCCESS_DIALOG_VERSION,
} from 'app-constants';
import AuthStore from 'stores/AuthStore';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);

const AppButtons = ({ className, showAppStore, showPlayStore, version }) => {
  const onClickStoreButton = platform => {
    Analytics.track('Download Native App Button Clicked', {
      internal_location: INTERNAL_LOCATION.TIMELINE_PREMIUM,
      platform,
    });
  };

  const { user } = AuthStore.getState();
  const deeplink = getAdjustLoginDeeplink(user);

  const isWalletConversionVersion = version === SUCCESS_DIALOG_VERSION.WALLET_CONVERSION;

  const adgroup = isWalletConversionVersion
    ? ADJUST_ADGROUP.MICROPAYMENT_CONVERSION
    : ADJUST_ADGROUP.NEW_USER;

  const appStoreAdjustUrl = getAdjustUrl({
    campaign: ADJUST_CAMPAIGN.PREMIUM_CONFIRMATION_DIALOG,
    adgroup,
    creative: ADJUST_CREATIVE.BUTTON,
    fallback: APP_STORE_URL,
    deeplink,
  });

  const playStoreAdjustUrl = getAdjustUrl({
    campaign: ADJUST_CAMPAIGN.PREMIUM_CONFIRMATION_DIALOG,
    adgroup,
    creative: ADJUST_CREATIVE.BUTTON,
    fallback: PLAY_STORE_URL,
    deeplink,
  });

  return (
    <Container className={className}>
      {showAppStore && (
        <AppStoreButton onClick={() => onClickStoreButton('ios')} href={appStoreAdjustUrl} />
      )}
      {showPlayStore && (
        <PlayStoreButton onClick={() => onClickStoreButton('android')} href={playStoreAdjustUrl} />
      )}
    </Container>
  );
};

AppButtons.propTypes = {
  className: string,
  showAppStore: bool,
  showPlayStore: bool,
  version: oneOf([SUCCESS_DIALOG_VERSION.DEFAULT, SUCCESS_DIALOG_VERSION.WALLET_CONVERSION])
    .isRequired,
};

AppButtons.defaultProps = {
  className: '',
  showAppStore: true,
  showPlayStore: true,
};

export default AppButtons;
