import _ from 'lodash';
import i18n, { defaultCurrency } from 'instances/i18n';
import numberFormat from 'underscore.string/numberFormat';
import { CREDIT_CURRENCY_NAME } from 'app-constants';

const micropaymentWalletDefaultOptions = {
  US: {
    decimalSeperator: '.',
    orderSeperator: ',',
    replaceNullDecimal: '00',
    noSpace: true,
  },
  GB: {
    decimalSeperator: '.',
    orderSeperator: ',',
  },
  NL: {
    decimalSeperator: ',',
    orderSeperator: '.',
    replaceNullDecimal: '-',
  },
  DE: {
    decimalSeperator: ',',
    orderSeperator: '.',
    replaceNullDecimal: '-',
    currencySymbolAfter: true,
  },
};

const symbol = {
  EUR: '€',
  USD: '$',
};

const creditsWordSingular = 'credit';
const creditsWordPlural = 'credits';

const CENTS_CONVERSION_FACTOR = 100;

export const convertFromCents = amount => amount / CENTS_CONVERSION_FACTOR;

function formatCredits(amount, { amountIsInCents }) {
  const parsedAmount = parseInt(amount, 10);

  const numberOfCredits = amountIsInCents ? convertFromCents(parsedAmount) : parsedAmount;

  const creditsWord = numberOfCredits === 1 ? creditsWordSingular : creditsWordPlural;

  return `${numberOfCredits}\u00A0${creditsWord}`;
}

function formatLegacyMicropaymentWallet(amount, incomingOptions) {
  const { currentCurrency } = i18n;

  let locale = i18n.currentLocale.substr(3, 2);

  if (!micropaymentWalletDefaultOptions[locale]) {
    locale = 'NL';
  }

  const currency = symbol[currentCurrency] ? currentCurrency : defaultCurrency;

  const options = _.defaults(incomingOptions, micropaymentWalletDefaultOptions[locale], {
    includeSymbol: true,
    symbol: symbol[currency],
    multiplier: 1,
    decimals: 2,
  });

  const parsedAmount = parseFloat(amount) * options.multiplier;

  const amountCorrectedForCents = incomingOptions.amountIsInCents
    ? convertFromCents(parsedAmount)
    : parsedAmount;

  let str = numberFormat(
    amountCorrectedForCents,
    options.decimals,
    options.decimalSeperator,
    options.orderSeperator,
  );

  if (options.replaceNullDecimal) {
    // decimalSeperator could be a dot, needs escaping.
    const regExp = new RegExp(`${_.escapeRegExp(options.decimalSeperator)}00$`);
    if (regExp.test(str)) {
      str = str.replace(/00$/, options.replaceNullDecimal);
    }
  }

  if (options.includeSymbol) {
    const space = options.noSpace ? '' : '\u00A0';
    if (options.currencySymbolAfter) {
      str = str + space + options.symbol;
    } else {
      str = options.symbol + space + str;
    }
  }

  return str;
}

export default function(amount = 0, options = {}) {
  return i18n.currentCurrency === CREDIT_CURRENCY_NAME
    ? formatCredits(amount, options)
    : formatLegacyMicropaymentWallet(amount, options);
}
