/* eslint-disable global-require */
import { asyncRoute } from 'helpers/routerHelpers';

function route(path) {
  return {
    module: 'universal-deeplinks',
    path,
    requireAuth: false,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./components/UniversalDeeplink').default,
          });
        },
        'universal-deeplinks',
      );
    }),
  };
}

export default [
  route('user/interests'),
  route('topic/:topicId'),
  route('edition/:editionId'),
  // The track route (audio/track/:trackId) is supported and implemented in web-landings
  route('audio/playlist/:playlistId'),
];
