import axios from 'axios';

import alt from 'instances/altInstance';
import Settings from 'controllers/settings';
import { getProviderColors } from 'helpers/providerStyles';

class ProviderStyleActions {
  fetchProviderStyles(providerId) {
    axios
      .get(
        Settings.getLink('style', {
          provider_id: providerId,
        }),
      )
      .then((res) => {
        const { styles } = res.data;

        return {
          ...getProviderColors(styles, providerId),
          providerId,
        };
      })
      .then(this.fetchProviderStylesSuccess)
      .catch((err) => this.fetchProviderStylesError(err, providerId));

    return null;
  }

  fetchProviderStylesSuccess = (x) => x;

  fetchProviderStylesError = (error, providerId) => {
    window.ErrorLogger.captureMessage('Error while fetching provider styles', {
      error: JSON.stringify(error),
      providerId,
    });

    return null;
  };
}

export default alt.createActions(ProviderStyleActions);
