export const EXPERIMENT_AA = 'AA_EXPERIMENT';
export const AA_VARIANT_1 = 'AA_VARIANT_1';
export const AA_VARIANT_2 = 'AA_VARIANT_2';

// All users get classified in a group for these experiments
export const runningExperiments = [
  {
    name: EXPERIMENT_AA,
    variants: [AA_VARIANT_1, AA_VARIANT_2],
  },
];

export const forcedVariants = {};
