import alt from 'instances/altInstance';
import UsersManager from 'managers/users';
import FacebookManager from 'managers/facebook';
import Analytics from 'instances/analytics';
import Auth from 'controllers/auth';
import ReactDOM from 'react-dom';
import Facebook from 'instances/facebook';

class FacebookActions {
  constructor() {
    this.generateActions('loginAndConnectFacebookError', 'loginAndConnectFacebookSuccess');
  }

  initializeFacebook() {
    // Init Facebook. Don't do anything if it failed here. If it failed we will
    // know in other places we use the lib. Handle fails there.
    Facebook.execute('init', {
      appId: Facebook.appId,
      version: Facebook.version,
      status: true,
    });

    return null;
  }

  connectFacebook(user) {
    return dispatch => {
      dispatch();

      FacebookManager.authorize(user)
        .then(() => this.facebookConnectSuccess())
        .catch(resp => this.facebookError(resp));
    };
  }

  toggleFacebook(user) {
    return dispatch => {
      dispatch();

      if (user.get('facebook_id')) {
        UsersManager.disconnectFromFacebook(user)
          .then(() => this.facebookDisconnectSuccess())
          .catch(resp => this.facebookError(resp));
      } else {
        this.connectFacebook(user);
      }
    };
  }

  facebookConnectSuccess() {
    Analytics.track('Social Connect', { platform: 'facebook' });

    return null;
  }

  facebookDisconnectSuccess() {
    Analytics.track('Social Disconnect', { platform: 'facebook' });

    return null;
  }

  facebookError(resp) {
    const analyticsError = resp.message;
    Analytics.track('Social Connect Error', {
      platform: 'facebook',
      error: analyticsError,
    });

    return null;
  }

  loginAndConnectFacebook(email, password) {
    return dispatch => {
      dispatch();

      Auth.loginWithCredentials({
        login: email.trim(),
        password,
      })
        .then(() => {
          Analytics.track('Login Successful', {
            platform: 'facebook',
            login_type: 'manual',
          });
        })
        .then(() => {
          this.connectFacebook(Auth.getUser());
          Auth.navigateToReturnURL();
          ReactDOM.unmountComponentAtNode(document.querySelector('.a-dialogue'));
        })
        .catch(error => this.loginAndConnectFacebookError(error));
    };
  }
}

export default alt.createActions(FacebookActions);
