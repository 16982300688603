/* eslint-disable camelcase */
import alt from 'instances/altInstance';
import WalletMigrationManager from 'managers/walletMigration';
import R from 'ramda';

const balance = R.path(['data', 'balance']);
const couponCode = R.path(['data', 'coupon_code']);
const equivalentMoneyOfferedMonths = R.path(['data', 'equivalent_money_offered_months']);
const offeredNumberOfMonths = R.path(['data', 'offered_number_of_months']);

class WalletMigrationActions {
  getMigrationOfferSuccess = res => res;

  getMigrationOfferError = error => error;

  getMigrationOffer() {
    WalletMigrationManager.getWalletMigrationOffer()
      .then(res =>
        this.getMigrationOfferSuccess({
          balance: balance(res),
          couponCode: couponCode(res),
          equivalentMoneyOfferedMonths: equivalentMoneyOfferedMonths(res),
          offeredNumberOfMonths: offeredNumberOfMonths(res),
        }),
      )
      .catch(error => this.getMigrationOfferError(error));

    return null;
  }
}

export default alt.createActions(WalletMigrationActions);
