// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-38vw3{from{opacity:0}to{opacity:1}}.title-VnGHK{color:#fff;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;padding-bottom:9px;line-height:36px;font-size:32px;letter-spacing:-0.05em;margin:24px auto 0;max-width:320px;opacity:0;animation:fadeIn-38vw3 0.3s 0.3s ease-out forwards}@media (min-width: 600px){.title-VnGHK{max-width:620px;font-size:44px;line-height:48px;margin:31px auto 0;text-align:center}}.introCopy-3fgRN{font-family:\"GT-Walsheim\",sans-serif;color:#f7f0ff;letter-spacing:-0.02em;font-size:17px;line-height:24px;padding-bottom:16px;max-width:600px;margin:0 auto 7px;opacity:0;animation:fadeIn-38vw3 0.3s 0.6s ease-out forwards}@media (min-width: 600px){.introCopy-3fgRN{font-size:22px;line-height:31px;margin:0 auto 41px;text-align:center}}@keyframes fadeIn-38vw3{from{opacity:0}to{opacity:1}}.container-2XCwN{margin:12px auto 0;text-align:center;padding-bottom:2px}.offerContainer-1aYRE{margin:5px auto 27px;max-width:640px}@media (min-width: 600px){.offerContainer-1aYRE{margin:13px auto 27px}}.cta-3IYtI{color:#4900fe !important;background:#fff !important;box-shadow:0 2px 2px rgba(0,0,0,0.15),0 0 2px rgba(0,0,0,0.05) !important;border-radius:4px !important;text-align:center;cursor:pointer;width:220px;line-height:44px;font-size:16px;font-weight:500;letter-spacing:-0.02em;padding:0 !important;opacity:0;animation:fadeIn-38vw3 0.3s 0.6s ease-out forwards}.cta-3IYtI:hover{color:#8f16c9 !important;box-shadow:0 2px 2px rgba(0,0,0,0.25),0 0 2px rgba(0,0,0,0.15) !important}.cta-3IYtI:active{color:#740da5 !important;box-shadow:0 2px 2px rgba(0,0,0,0.25),0 0 2px rgba(0,0,0,0.15) !important}.walletOffer-1NQlY{max-width:320px;margin:0 auto 18px;opacity:0;animation:fadeIn-38vw3 0.3s 0.6s ease-out forwards}.upsell-1YSGj{font-family:\"GT-Walsheim\",sans-serif;color:#fff;font-size:18px;line-height:23px;text-align:center}.upsellLabel-3uxbk{color:#fff;border-color:#fff}\n", ""]);
// Exports
exports.locals = {
	"title": "title-VnGHK",
	"fadeIn": "fadeIn-38vw3",
	"introCopy": "introCopy-3fgRN",
	"container": "container-2XCwN",
	"offerContainer": "offerContainer-1aYRE",
	"cta": "cta-3IYtI",
	"walletOffer": "walletOffer-1NQlY",
	"upsell": "upsell-1YSGj",
	"upsellLabel": "upsellLabel-3uxbk"
};
module.exports = exports;
