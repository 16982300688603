import Settings from 'controllers/settings';
import axios from 'axios';

const PinsManager = {
  updatePin(user, itemId, active) {
    return axios.put(Settings.getLink('user_pin', { user_id: user.id, item_id: itemId }), {
      pinned: active,
    });
  },
};

export default PinsManager;
