import Environment from 'environment';
import Auth from 'controllers/auth';
import { v4 as uuidv4 } from 'uuid';
import { getSignupEntryPoint } from 'helpers/signupEntryPoint';
import CookieConsentStore from 'stores/CookieConsentStore';
import { isNil } from 'ramda';

const GoogleAnalytics = {
  googleAnalyticsRequested: false,
  shouldCheckIfAllowAdFeaturesNeedsToBeSet: true,

  init(trackerId) {
    // init google analytics
    // copied from google's documentation
    window.ga =
      window.ga ||
      ((...args) => {
        window.ga.q = window.ga.q || [];
        window.ga.q.push(args);
      });
    window.ga.l = +new Date();

    window.ga(
      'create',
      trackerId,
      'auto',
      /* Cross domain setup */
      { allowLinker: true },
    );
    window.ga('set', 'anonymizeIp', true);
    window.ga('require', 'ecommerce');

    /* Cross domain setup */
    window.ga('require', 'linker');
    window.ga('linker:autoLink', ['merol.blendle.com', 'payment.blendle.com']);

    return this;
  },

  setUTMParameters(utmTags) {
    // Our own events use different names than Google does, so we need to map them
    const utmCouples = [
      {
        blendleName: 'campaign',
        googleName: 'campaignName',
      },
      {
        blendleName: 'source',
        googleName: 'campaignSource',
      },
      {
        blendleName: 'medium',
        googleName: 'campaignMedium',
      },
      {
        blendleName: 'content',
        googleName: 'campaignContent',
      },
    ];

    utmCouples.forEach((utmCouple) => {
      // Only set the tags that are actually provided
      if (utmTags[utmCouple.blendleName]) {
        window.ga('set', utmCouple.googleName, utmTags[utmCouple.blendleName]);
      }
    });
  },

  set(fieldName, fieldValue) {
    window.ga('set', fieldName, fieldValue);
  },

  send(event, ...values) {
    this.loadGoogleAnalyticsIfNeeded();
    this.enableAllowAdFeaturesIfNeeded();
    window.ga('send', event, ...values);
  },

  enableAllowAdFeaturesIfNeeded() {
    if (!this.shouldCheckIfAllowAdFeaturesNeedsToBeSet) {
      return;
    }

    const { hasGivenConsent } = CookieConsentStore.getState();

    if (!isNil(hasGivenConsent)) {
      window.ga('set', 'allowAdFeatures', hasGivenConsent);
      this.shouldCheckIfAllowAdFeaturesNeedsToBeSet = false;
    }
  },

  loadGoogleAnalyticsIfNeeded() {
    if (!this.googleAnalyticsRequested) {
      if (Environment.name === 'local' || Environment.name === 'test') {
        // eslint-disable-next-line no-console
        console.info(
          '%cGoogleAnalytics Loaded',
          'color: #02a; padding: 3px; display: block; background: #9af; font-size: 90%;',
          'Started to load `analytics.js`',
        );
      }
      this.loadGoogleAnalytics();
    }
  },

  loadGoogleAnalytics() {
    const scriptElement = document.createElement('script');
    scriptElement.async = 1;
    scriptElement.src = 'https://www.google-analytics.com/analytics.js';
    const otherScripts = document.getElementsByTagName('script')[0];
    otherScripts.parentNode.insertBefore(scriptElement, otherScripts);

    this.googleAnalyticsRequested = true;
  },

  logEvent(type, ...values) {
    if (Environment.name === 'local' || Environment.name === 'test') {
      // eslint-disable-next-line no-console
      console.log(
        `%cGoogleAnalytics ${type}`,
        'color: #02a; padding: 3px; display: block; background: #9af; font-size: 90%;',
        ...values,
      );
    }
  },

  isOptOut() {
    const user = Auth.getUser();
    if (user) {
      return user.get('mixpanel_opt_out');
    }
    return false;
  },

  trackPageView(url) {
    this.logEvent('PageView', 'tracked page view to', url);
    if (this.isOptOut()) {
      this.logEvent('User is opt out', 'Not going to track.');
      return;
    }

    this.set('page', url);
    this.send('pageview');
  },

  trackEvent(category, action, label, value) {
    this.logEvent('Event', category, action, label, value);
    if (this.isOptOut()) {
      this.logEvent('User is opt out', 'Not going to track.');
      return;
    }
    this.send('event', category, action, label, value);
  },

  /**
   * Track an Onboarding event like `registered`, `channels selected`, etc
   * @param {string} action the action user has taken.
   * Can be
   * @param {string?} signUpType the signup type a user has taken.
   * If not set, read from user prefs or set to 'unknown'
   */
  trackOnboardingEvent(action, signUpType) {
    if (!signUpType) {
      signUpType = getSignupEntryPoint();
    }

    this.trackEvent('onboarding', action, signUpType);
  },

  sendPremiumSignupTransaction(transactionId = uuidv4()) {
    window.ga('ecommerce:addTransaction', {
      id: transactionId,
      revenue: '0',
    });

    window.ga('ecommerce:addItem', {
      id: transactionId,
      name: 'Blendle Premium trial',
      price: '0',
      quantity: '1',
    });

    window.ga('ecommerce:send');
  },
};

export default GoogleAnalytics.init(Environment.gaTrackingId);
