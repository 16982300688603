// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".aligment-2sh54{max-width:992px;margin:0 auto;padding:0 16px;position:relative}\n", ""]);
// Exports
exports.locals = {
	"aligment": "aligment-2sh54"
};
module.exports = exports;
