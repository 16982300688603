import React from 'react';
import Question from 'components/WalletConversion/Faq/Question';
import Faq from 'components/WalletConversion/Faq';
import { number } from 'prop-types';

const QStopsAutomatically = () => (
  <Question title="Stopt het abonnement vanzelf?">
    <p>
      Nee, het abonnement loopt na de genoemde periode automatisch over in een maandelijks opzegbaar
      abonnement. Omdat we niet houden van verkooptrucjes, geven we je een paar dagen van te voren
      een seintje zodat je weet dat dat de eerste incasso eraan komt. Je kunt ook eerder al aangeven
      dat je het abonnement direct wilt stopzetten na de gratis maanden via het menu op de website.
    </p>
  </Question>
);

const QWhatCanIDoWithMyMoney = () => (
  <Question title="Wat zijn de opties voor overgebleven leestegoed?">
    <p>
      Overgebleven leestegoed kun je sinds 1 januari 2020 niet meer uitgeven aan losse artikelen of
      terugvragen, maar is op deze datum omgezet in een kortingsbon voor Blendle Premium. Je kunt
      deze kortingsbon vandaag, morgen en nog de rest van het jaar inzetten als aanbetaling op
      Blendle Premium.
    </p>
  </Question>
);

const QCanITopUp = () => (
  <Question title="Kan ik mijn leestegoed nog opwaarderen?">
    <p>
      Lezen, luisteren, zoeken en bladeren op Blendle doe je sinds 1 januari 2020 voor een vast
      bedrag per maand. Je hoeft daardoor niet meer per artikel af te rekenen en dus ook niet meer
      je leestegoed op te waarderen. Bovenaan deze pagina kun je het vernieuwde Blendle de komende
      drie maanden gratis uitproberen.
    </p>
  </Question>
);

const QWhereCanIUseBlendle = () => (
  <Question title="Waar kan ik Blendle gebruiken?">
    <p>
      Overal! Op je computer, laptop, smartphone en tablet werkt Blendle zonder problemen. Je hoeft
      alleen even in te loggen en je kunt direct beginnen met lezen. Sommige functies, zoals het
      luisteren naar artikelen werkt alleen op je smartphone of tablet via onze gratis app.
    </p>
  </Question>
);

export default function FaqSection({ balance }) {
  return (
    <Faq>
      {balance > 0 ? <QWhatCanIDoWithMyMoney /> : <QCanITopUp />}
      <QStopsAutomatically />
      <QWhereCanIUseBlendle />
    </Faq>
  );
}

FaqSection.propTypes = {
  balance: number.isRequired,
};
