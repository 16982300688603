import React from 'react';
import styledModule from 'helpers/styledModule';
import { translate, translateElement } from 'instances/i18n';
import CSS from './Intro.scss';
import SharedCSS from '../shared.scss';

const Container = styledModule.div(CSS.container);
const Title = styledModule.h1(SharedCSS.title, CSS.title);
const IntroCopy = styledModule.p(SharedCSS.introCopy, CSS.introCopy);

const Intro = () => (
  <Container>
    <Title>{translate('discover_premium.intro.title')}</Title>
    <IntroCopy>{translateElement('discover_premium.intro.text')}</IntroCopy>
  </Container>
);

export default Intro;
