import { useEffect } from 'react';
import SegmentAnalytics from 'instances/segmentAnalytics';

const usePageEventOnMount = ({ pageName, properties }) => {
  useEffect(() => {
    SegmentAnalytics.page({ pageName, properties });
  }, []);
};

export default usePageEventOnMount;
