import { useState } from 'react';
import useUser from 'hooks/useUser';

const DID_DISMISS_NEW_CREDIT_KIOSK_DIALOG = 'did_dismiss_new_credit_kiosk_dialog';

const useDidDismissNewCreditKioskDialog = () => {
  const user = useUser();
  const [showDialog, setShowDialog] = useState(
    !user.getPreference(DID_DISMISS_NEW_CREDIT_KIOSK_DIALOG),
  );

  const dismissDialog = () => {
    setShowDialog(false);

    user.savePreferences({
      [DID_DISMISS_NEW_CREDIT_KIOSK_DIALOG]: true,
    });
  };

  return {
    showDialog,
    dismissDialog,
  };
};

export default useDidDismissNewCreditKioskDialog;
