import { countryEligibleForPremium } from 'helpers/premiumEligibility';
import Auth from 'controllers/auth';

const redirect = (from, to = '/') => ({
  path: from,
  requireAuth: false,
  onEnter: (_, replace) => {
    replace(to);
  },
});

const redirectWrongUpsellEmailLink = (from) => ({
  path: from,
  requireAuth: false,
  onEnter: () => {
    window.location =
      'https://blendle.com/g/welcome-back?redirect=https%3A%2F%2Fpayment.blendle.com%2Fswitch-to-premium%3FsuccessPath%3D%252Fhome%252Fsuccess%26cancelPath%3D%252Fhome%26couponCode%3DOOZSB2CN0V7NVFKRXFCM%26autoApply%3Dtrue';
  },
});

const redirectNewsletterSignup = (from) => {
  return {
    path: from,
    requireAuth: false,
    onEnter: (_, replace) => {
      if (Auth.getUser()) {
        replace('/settings/emails');
      }

      replace('/');
    },
  };
};

const redirectLegacyCouponRoute = (from) => ({
  path: from,
  requireAuth: false,
  onEnter: ({ params }, replace) => {
    const { code } = params;
    const codeQueryString = code ? `?couponCode=${encodeURIComponent(code)}` : '';

    return countryEligibleForPremium() ? replace(`/actie${codeQueryString}`) : replace('/');
  },
});

export const redirectedRoutes = [
  redirect('/getpremium/try'),
  redirect('/getpremium/go'),
  redirect('/voorjou'),
  redirect('/premium'),
  redirect('/getpremium/magazine'),
  redirect('/hello', '/getpremium/actie/coupon'),
  redirect('/ade', '/getpremium/actie/ADE'),
  redirect('/ADE', '/getpremium/actie/ADE'),
  redirect('/fnv', '/getpremium/actie/coupon?code=FNVCADEAU'),
  redirect('/fnv-subscription', '/subscription/blendlepremium_two_months_partner_fnv_bondgenoten'),
  redirect('/getpremium/welcome/:personaId', '/getpremium'),
  redirect('/getpremium/v/:variant', '/getpremium'),
  redirect('/%20postcodeloterij', '/postcodeloterij'),
  redirect('/voordeel', '/actie/voordeelgids?couponCode=3MNDNPL2'),
  redirect('/open-app'),
  redirect('/open-app/:itemId'),
  redirect('/aholdzomer'),
  redirect('/greetz'),
  redirect('/vnc'),
  redirect('/htm'),
  redirect('/vodafone/V45TRs3P11'),
  redirect('/vodafone/V45TRs3P33'),
  redirect('/vodafone/V45TRs3P55'),
  redirect('/vodafone/V45TRs3P77'),
  redirect('/paypal'),
  redirect('/tmobile'),
  redirect('/deichmann'),
  redirect('/voetbalshop'),
  redirect('/kicker'),
  redirect('/e-fellows(/:code)'),
  redirect('/hsgalumni'),
  redirect('/vrr'),
  redirect('/reportagenfm'),
  redirect('/reportagennewsletter'),
  redirect('/cjp'),
  redirect('/theinnercirclev'),
  redirect('/theinnercirclem'),
  redirect('/wired'),
  redirect('/gq'),
  redirect('/lieferando(/:code)'),
  redirect('/skyscanner'),
  redirect('/cosmosdirekt(/:code)'),
  redirect('/businesspunk'),
  redirect('/timemagazine'),
  redirect('/bier-deluxe'),
  redirect('/brille24'),
  redirect('/markt.de'),
  redirect('/festival.travel'),
  redirect('/hrs(/:code)'),
  redirect('/thanks'),
  redirect('/dankjewel'),
  redirect('/danke'),
  redirect('/ns', '/actie/ns'),
  redirect('/settings/email', '/settings/emails'),
  redirectLegacyCouponRoute('/coupon(/:code)'),
  redirect(
    '/utm_source=blendle&utm_medium=email&utm_campaign=blendlepremium_3_month_loyalty_offer_reminder&utm_content=header',
    '/g/welcome-back?utm_medium=email&utm_source=blendle&utm_campaign=blendlepremium_3_month_loyalty_offer_reminder&redirect=https%3A%2F%2Fpayment.blendle.com%2Fswitch-to-premium%3FcouponCode%3DA72484ECF96C99F797A7%26autoApply%3Dtrue',
  ),
  redirect(
    '/utm_source=blendle&utm_medium=email&utm_campaign=blendlepremium_3_month_loyalty_offer&utm_content=header',
    '/g/welcome-back?utm_medium=email&utm_source=blendle&utm_campaign=blendlepremium_3_month_loyalty_offer&redirect=https%3A%2F%2Fpayment.blendle.com%2Fswitch-to-premium%3FcouponCode%3DA72484ECF96C99F797A7%26autoApply%3Dtrue',
  ),
  redirect('/payment/success/blendlepremium/external', '/home/success'),
  redirect('/home/extend'),
  redirect('subscription/:productId'),
  redirect('subscription/:fromId/:toId'),
  redirect('payment/subscription/:productId'),
  redirect('payment/success/subscription/:subscriptionProductId'),
  redirect('premium-intro'),
  redirect('features/expired', 'getpremium/why-premium'),
  redirect('features(/:upsellType)', 'getpremium/why-premium'),
  redirectWrongUpsellEmailLink(
    'https%3A%2F%2Fpayment.blendle.com%2Fswitch-to-premium%3FsuccessPath%3D%252Fhome%252Fsuccess%26cancelPath%3D%252Fhome%26couponCode%3DOOZSB2CN0V7NVFKRXFCM%26autoApply%3Dtrue&utm_campaign=final_micropayment_reminder_reminder_2&utm_medium=email&utm_source=blendle&utm_content=upsell_only',
  ),
  redirectNewsletterSignup('email'),
  redirect('/discover/issues', '/discover/issue'),
  redirect('/following'),
];
