/* eslint-disable global-require */
import React from 'react';
import { asyncRoute } from 'helpers/routerHelpers';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { FEATURE_CAPABILITY_SEARCH, FEATURE_CAPABILITY_BLENDLE_PREMIUM } from 'app-constants';
import { hasCapability, getCapabilityStateAsync } from 'selectors/capabilities';
import SearchActions from 'actions/SearchActions';

function onEnter(_, replace, callback) {
  getCapabilityStateAsync()
    .then((capabilitiesState) => {
      // In case the user is not yet logged in
      if (!capabilitiesState) {
        return;
      }

      if (
        hasCapability(capabilitiesState, FEATURE_CAPABILITY_SEARCH) ||
        hasCapability(capabilitiesState, FEATURE_CAPABILITY_BLENDLE_PREMIUM)
      ) {
        document.body.classList.add('m-search');

        SearchActions.setActive();

        return;
      }

      replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_SEARCH));
    })
    .then(() => {
      callback();
    });
}

function onLeave() {
  document.body.classList.remove('m-search');

  // eslint-disable-next-line compat/compat
  SearchActions.setInactive(window.scrollY);
}

const modulePortalNavigationRoot = () => <div className="moduleNavigationPortalRoot" />;

function routeSearch(path) {
  return {
    module: 'search',
    path,
    onEnter,
    onLeave,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            navigation: modulePortalNavigationRoot,
            content: require('./SearchContainer'),
          });
        },
        'search',
      );
    }),
  };
}

export default [routeSearch('search'), routeSearch('search/*')];
