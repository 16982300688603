import React from 'react';
import { asyncRoute } from 'helpers/routerHelpers';
import LoginActions from 'actions/LoginActions';
import { path, pipe, propOr, isNil } from 'ramda';
import TokenErrorPage from './TokenErrorPage';

function onEnter() {
  document.body.classList.add('m-login-email');
}

function onLeave() {
  document.body.classList.remove('m-login-email');
}

const redirectQueryParam = path(['query', 'redirect']);
const isTokenMissing = pipe(propOr(undefined, 'token'), isNil);

export default [
  {
    module: 'emailLogin',
    path: 'login-email/:itemId/:token(/:force)',
    onEnter,
    onLeave,
    requireAuth: false,
    getComponent: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          const mod = require('./module');
          const { params } = nextState;
          cb(null, {
            item: () => mod.openItemLogin(params.itemId, params.token),
          });
        },
        'emailLogin',
      );
    }),
  },
  {
    module: 'emailLogin',
    path: 'login-email(/:token)',
    onEnter: ({ params, location }) => {
      onEnter();

      const { token } = params;

      if (token) {
        LoginActions.loginWithEmailTokenAndRedirect(token);
      } else {
        window.ErrorLogger.captureMessage(`On login-email without a token`, {
          redirect: redirectQueryParam(location),
        });
      }
    },
    onLeave,
    requireAuth: false,
    getComponent: asyncRoute(({ params }, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            /*
             * If a user clicks the CTA in a magic login email while the token is expired,
             * (they can be used once, and are only valid for 15 min after sending),
             * then Core-API redirects the user to 'https://blendle.com/login-email/',
             * therefor passing 'isTokenMissing' to show the user an error message
             *
             */
            item: () => <TokenErrorPage isTokenMissing={isTokenMissing(params)} />,
          });
        },
        'emailLogin',
      );
    }),
  },
];
