// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-XYLHB{text-align:center;margin:22px auto 0}@media (min-width: 600px){.container-XYLHB{margin:44px auto 0}}.title-3ANDB.title-3ANDB{color:#000}.introCopy-3MULj.introCopy-3MULj{color:#474747;margin-bottom:0;padding-bottom:0}@media (min-width: 600px){.introCopy-3MULj.introCopy-3MULj{margin-bottom:25px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-XYLHB",
	"title": "title-3ANDB",
	"introCopy": "introCopy-3MULj"
};
module.exports = exports;
