// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-YkWe-{position:relative}.gradient-1sVf3{background:linear-gradient(to bottom right, #ab32e5 0%, #4900fe 100%);position:absolute;width:100%;top:0;bottom:0}\n", ""]);
// Exports
exports.locals = {
	"container": "container-YkWe-",
	"gradient": "gradient-1sVf3"
};
module.exports = exports;
