import React from 'react';
import { createAsyncComponent } from 'helpers/routerHelpers';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { FEATURE_CAPABILITY_LEGACY_TIMELINES } from 'app-constants';
import { capabilityIsLockedAsync } from 'selectors/capabilities';
import TimelineNavigation from './TimelineNavigationContainer';

const EmptyNavigation = () => <ul className="v-navigation collapsed" />;

const AsyncTimelineComponent = createAsyncComponent((resolve) => {
  require.ensure(
    [],
    () => {
      resolve(require('./TimelineRouterContainer'));
    },
    'timeline',
  );
});

const AsyncModuleCallComponent = createAsyncComponent((resolve, props) => {
  require.ensure(
    [],
    () => {
      resolve(props.handler(require('./module'), props.nextState.params));
    },
    'timeline',
  );
});

function onEnter() {
  document.body.classList.add('m-timeline');
}

function onLeave() {
  document.body.classList.remove('m-timeline');
}

const routeBase = {
  module: 'timeline',
  onEnter,
  onChange: onEnter,
  onLeave,
};

function timelineRoute(path, navigation, dialogHandler, restProps) {
  return {
    ...routeBase,
    path,
    getComponents(nextState, cb) {
      if (dialogHandler) {
        require.ensure(
          [],
          () => {
            dialogHandler(require('./module'), nextState.params);
          },
          'timeline',
        );
      }

      cb(null, {
        content: AsyncTimelineComponent,
        navigation,
        ...restProps,
      });
    },
  };
}

function lockableTimelineRoute(path, navigation) {
  const originalTimelineRoute = timelineRoute(path, navigation);

  return {
    ...originalTimelineRoute,
    onEnter: (nextState, replace, callback) => {
      capabilityIsLockedAsync(FEATURE_CAPABILITY_LEGACY_TIMELINES).then(
        (legacyTimelinesAreLocked) => {
          if (legacyTimelinesAreLocked) {
            replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_LEGACY_TIMELINES));
            callback();
            return;
          }

          originalTimelineRoute.onEnter(nextState, replace, callback);

          callback();
        },
      );
    },
  };
}

function collapsedNavRoute(path, contentHandler) {
  return {
    ...routeBase,
    path,
    getComponents(nextState, cb) {
      cb(null, {
        content: AsyncModuleCallComponent.bind(null, {
          handler: contentHandler,
          nextState,
        }),
        navigation: EmptyNavigation,
      });
    },
  };
}

const openChannelsDropdownRoute = (route) => {
  let timeout;
  route.onEnter = () => {
    timeout = setTimeout(() => document.querySelector('.v-add-channel-button').click(), 500);
    onEnter();
  };

  route.onLeave = () => {
    clearTimeout(timeout);
    onLeave();
  };
  return route;
};

export default [
  lockableTimelineRoute('trending(/:details)', TimelineNavigation),
  lockableTimelineRoute('channel/:details', TimelineNavigation),
  timelineRoute('pins', EmptyNavigation),
  timelineRoute('user/:userId', EmptyNavigation),
  timelineRoute('user/:userId/items', EmptyNavigation),
  timelineRoute('verified', TimelineNavigation, (mod) => mod.openVerifiedDialogue()),

  // redirects /me to /user/:userId
  collapsedNavRoute('me', (mod) => mod.rerouteAuthUser()),
  // redirects /archive to /user/:userId/items
  collapsedNavRoute('archive', (mod) => mod.rerouteAuthUser('items')),

  openChannelsDropdownRoute(timelineRoute('channels', TimelineNavigation)),
];
