export const hasBundle = user => user && user.hasActivePremiumSubscription();

export const activeSubscriptions = user => user.get('active_subscriptions');

export const hasActiveSubscription = (user, providerId) =>
  activeSubscriptions(user).includes(providerId);

export const getUserProperty = (user, property) =>
  `${property}` in user ? user[property] : user.attributes[property];

export const email = user => getUserProperty(user, 'email');
