import { asyncRoute } from 'helpers/routerHelpers';
import React from 'react';
import AuthStore from 'stores/AuthStore';
import redirectIfOnboardingNotComplete from 'helpers/redirectIfOnboardingNotComplete';
import googleAnalytics from 'instances/google_analytics';
import { getTrackingURL } from 'helpers/url';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import TimelineDialogContainer from 'containers/overlays/TimelineDialogContainer';
import R from 'ramda';
import PersonalPageActions from './actions/PersonalPageActions';
import EarlierPageActions from './actions/EarlierPageActions';

const baseRoute = { module: 'sectionsPage' };

function onEnter(_, replace, callback) {
  const { user } = AuthStore.getState();

  if (user) {
    redirectIfOnboardingNotComplete(user, replace, FeatureCapabilitiesStore.getState());
  }

  document.body.classList.add('m-sections-page');
  callback();
}

function onLeave() {
  PersonalPageActions.setHidden(window.scrollY);
  document.body.classList.remove('m-sections-page');
}

function onLeavePersonalPage() {
  PersonalPageActions.setHidden(window.scrollY);
  onLeave();
}

function onLeaveEarlierPage() {
  EarlierPageActions.setHidden(window.scrollY);
  onLeave();
}

function route(path, getComponentsHandler, other) {
  return {
    ...baseRoute,
    path,
    onLeave,
    getComponents: asyncRoute(getComponentsHandler),
    ...other,
  };
}

const isTrue = R.anyPass([R.equals(true), R.equals('true')]);

const personalPageRoute = path => ({
  ...baseRoute,
  onEnter,
  onChange: (_, ...args) => onEnter(...args),
  onLeave: onLeavePersonalPage,
  path,
  getComponents: asyncRoute((nextState, cb) => {
    require.ensure(
      [],
      () => {
        const Container = require('./containers/PersonalPageContainer');
        cb(null, {
          content: Container,
        });
      },
      'sectionsPage',
    );
  }),
  childRoutes: [
    route('success', (nextState, cb) => {
      googleAnalytics.trackPageView(getTrackingURL(window.location));
      const { query = {}, state = {} } = nextState.location;

      const showLinkToApp = query.showLinkToApp || state.showLinkToApp;
      const hadPremium = query.hadPremium || state.hadPremium;

      require.ensure(
        [],
        () => {
          cb(null, {
            overlay: () => (
              <TimelineDialogContainer
                isAppMigrationFlow={isTrue(showLinkToApp)}
                isMpPlusPremiumMigrationFlow={isTrue(hadPremium)}
              />
            ),
          });
        },
        'bundle',
      );
    }),
  ],
});

function earlierForYouRoute(path) {
  function getComponentsHandler(nextState, cb) {
    require.ensure(
      [],
      () => {
        const Container = require('./containers/EarlierPageContainer');
        cb(null, {
          content: Container,
        });
      },
      'sectionsPage-earlier',
    );
  }

  const otherOptions = {
    onEnter: (nextState, replace, callback) => {
      const { user } = AuthStore.getState();

      // This page is only accessible for users with a valid premium subscription
      if (!user || !user.hasActivePremiumSubscription()) {
        replace('/');
        callback();

        return;
      }

      // Call the main onEnter function
      onEnter(nextState, replace, callback);
    },
    onLeave: onLeaveEarlierPage,
  };

  return route(path, getComponentsHandler, otherOptions);
}

export default [personalPageRoute('/home'), earlierForYouRoute('/earlier')];
