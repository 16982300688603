import React, { memo } from 'react';
import { node } from 'prop-types';

import DefaultNavigationBar from 'components/navigation/DefaultNavigationBar';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import {
  FEATURE_CAPABILITY_SEARCH,
  FEATURE_CAPABILITY_ALERTS,
  FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
  FEATURE_CAPABILITY_NEWSSTAND,
  FEATURE_CAPABILITY_LEGACY_TIMELINES,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST,
  FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS,
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  FEATURE_CAPABILITY_MICROPAYMENTS,
  NAVIGATION_BAR_TYPE,
} from 'app-constants';
import { capabilityIsLocked, hasCapability } from 'selectors/capabilities';
import features from 'config/features';
import HideOnScroll from 'components/navigation/HideOnScroll';
import useUser from 'hooks/useUser';
import useAlt from 'hooks/useAlt';

function determineNavigationBarType({ hasBlendlePremium, hasMicropayments }) {
  if (hasBlendlePremium && hasMicropayments) {
    return NAVIGATION_BAR_TYPE.PREMIUM_AND_MICROPAYMENTS;
  }

  if (hasBlendlePremium) {
    return NAVIGATION_BAR_TYPE.PREMIUM_ONLY;
  }

  return NAVIGATION_BAR_TYPE.MICROPAYMENTS;
}

const NavigationBarContainer = memo(({ subNavigation, ...rest }) => {
  const user = useUser();
  const featureCapabilitiesState = useAlt([FeatureCapabilitiesStore], ([state]) => state);

  const isLoggedIn = Boolean(user);

  const hasBlendlePremium = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  );
  const hasMicropayments = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_MICROPAYMENTS,
  );

  const navigationBarType = determineNavigationBarType({ hasBlendlePremium, hasMicropayments });

  const isPremiumOnly = hasBlendlePremium && !hasMicropayments;

  const showSearch =
    hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_SEARCH) || isPremiumOnly;

  return (
    <HideOnScroll navigationBarType={navigationBarType}>
      <DefaultNavigationBar
        data-testid="nav-bar"
        navigationBarType={navigationBarType}
        isLoggedIn={isLoggedIn}
        showSearch={showSearch}
        showAlerts={hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_ALERTS)}
        showAudio={hasCapability(
          featureCapabilitiesState,
          FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST,
        )}
        showEarlierPage={hasBlendlePremium}
        showLegacyTimelines={hasCapability(
          featureCapabilitiesState,
          FEATURE_CAPABILITY_LEGACY_TIMELINES,
        )}
        showKiosk={hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_NEWSSTAND)}
        showCreditsKiosk={hasCapability(
          featureCapabilitiesState,
          FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
        )}
        showDiscoverIssue={hasCapability(
          featureCapabilitiesState,
          FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS,
        )}
        blendleIsLocked={capabilityIsLocked(
          featureCapabilitiesState,
          FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
        )}
        showDiscoverPremium={isPremiumOnly}
        {...rest}
      />
      {subNavigation}
    </HideOnScroll>
  );
});

NavigationBarContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  subNavigation: node,
};

NavigationBarContainer.defaultProps = {
  subNavigation: undefined,
};

export default NavigationBarContainer;
