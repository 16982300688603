// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2Jlfa{display:flex}.checkmark-1Bi0D{margin-top:7px;margin-right:10px}.copy-1whmI{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:30px;letter-spacing:-0.02em;color:#474747;margin:0}@media (max-width: 850px){.copy-1whmI{font-size:16px;line-height:26px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2Jlfa",
	"checkmark": "checkmark-1Bi0D",
	"copy": "copy-1whmI"
};
module.exports = exports;
