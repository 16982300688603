// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-o1N5x{position:relative}.navigationBar-1evAw.navigationBar-1evAw{background:transparent}.logo-Ams-X{height:54px;margin-left:15px;margin-right:15px}@media (min-width: 600px){.logo-Ams-X .logo-Ams-X{height:66px;margin-left:20px;margin-right:30px}}.login-3aoXp{font-family:\"GT-Walsheim\",sans-serif;color:white;font-size:14px;line-height:54px;letter-spacing:-0.02em;text-decoration:none;margin-left:15px;margin-right:15px}@media (min-width: 600px){.login-3aoXp{line-height:54px;margin-left:20px;margin-right:30px}}.publishersOverviewLink-3rJnQ{margin-right:8px;text-decoration:none;padding-top:3px}@media (min-width: 600px){.publishersOverviewLink-3rJnQ{margin-right:16px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-o1N5x",
	"navigationBar": "navigationBar-1evAw",
	"logo": "logo-Ams-X",
	"login": "login-3aoXp",
	"publishersOverviewLink": "publishersOverviewLink-3rJnQ"
};
module.exports = exports;
