import Cookies from 'cookies-js';
import Analytics from 'instances/analytics';
import { isDev } from '../environment';
import { getUTMParameters, makeUrlParamsFromObject } from '../url';

export const buildQueryString = fragments =>
  `${fragments.length > 0 ? '?' : ''}${fragments.join('&')}`;

export const getUtmString = () => {
  const utmData = Analytics._utm || getUTMParameters();
  return makeUrlParamsFromObject(utmData, 'utm_');
};

export const getRefreshTokenIfDevelopmentEnv = () => {
  return (
    isDev() &&
    (Cookies.get('refreshToken-devel') ||
      Cookies.get('refreshToken-local') ||
      Cookies.get('refreshToken-development'))
  );
};
