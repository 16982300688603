// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label-OTNfe.label-OTNfe{text-transform:uppercase;height:27px;font-weight:500;border-radius:4px}\n", ""]);
// Exports
exports.locals = {
	"label": "label-OTNfe"
};
module.exports = exports;
