// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes confetti-slow-1uzC-{0%{transform:translate3d(0, 0, 0) rotateX(0) rotateY(0)}100%{transform:translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg)}}@keyframes confetti-medium-3xBf0{0%{transform:translate3d(0, 0, 0) rotateX(0) rotateY(0)}100%{transform:translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg)}}@keyframes confetti-fast-hca4G{0%{transform:translate3d(0, 0, 0) rotateX(0) rotateY(0)}100%{transform:translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg)}}.confetti-1DTXY{position:relative}.confettiContainer-2YyXr{perspective:700px;position:absolute;overflow:hidden;top:0;right:0;bottom:0;left:0;pointer-events:none}.confettiParticle-3k8TH{position:absolute;z-index:1001;top:-10px;border-radius:0}.slow-11KfV{animation:confetti-slow-1uzC- 2.25s linear 1 forwards}.medium-2M5e7{animation:confetti-medium-3xBf0 1.75s linear 1 forwards}.fast-9BZfY{animation:confetti-fast-hca4G 1.25s linear 1 forwards}\n", ""]);
// Exports
exports.locals = {
	"confetti": "confetti-1DTXY",
	"confettiContainer": "confettiContainer-2YyXr",
	"confettiParticle": "confettiParticle-3k8TH",
	"slow": "slow-11KfV",
	"confetti-slow": "confetti-slow-1uzC-",
	"medium": "medium-2M5e7",
	"confetti-medium": "confetti-medium-3xBf0",
	"fast": "fast-9BZfY",
	"confetti-fast": "confetti-fast-hca4G"
};
module.exports = exports;
