import environment from 'environment';
import R from 'ramda';
import Analytics from 'instances/analytics';
import { APP_STORE_URL, PLAY_STORE_URL, APP_DEEPLINK } from 'app-constants';
import { getUTMParameters, makeUrlParamsFromObject } from './url';

const labelAsParamString = R.ifElse(R.isEmpty, () => '', label => `${label.key}=${label.value}`);
const isNotEmpty = R.compose(
  R.not,
  R.isEmpty,
);
const filterEmpty = R.filter(Boolean);
const joinParams = R.compose(
  R.join('&'),
  filterEmpty,
);

export function getAdjustLoginDeeplink(user) {
  if (!user) {
    return undefined;
  }

  // Prefill the email adresss in the app login screen if user can login with email+password
  return user.get('has_password')
    ? `${APP_DEEPLINK.LOGIN}?email=${user.get('email')}`
    : APP_DEEPLINK.LOGIN;
}

export function getFallBackUrl(device) {
  const APP_INSTALL_LINKS = new Map([
    ['iphone', APP_STORE_URL],
    ['ipod', APP_STORE_URL],
    ['ipad', APP_STORE_URL],
    ['android', PLAY_STORE_URL],
  ]);

  return APP_INSTALL_LINKS.get(R.toLower(device));
}

export function getlabels(label) {
  const utmData = Analytics._utm || getUTMParameters();
  const utmString = makeUrlParamsFromObject(utmData, 'utm_');
  const labels = [labelAsParamString(label), utmString];

  return filterEmpty(labels);
}

const labelSection = labels => `label=${encodeURIComponent(joinParams(labels))}`;

export function getAdjustUrl({ campaign, adgroup, creative, fallback, deeplink, label = {} }) {
  const labels = getlabels(label);

  return `${environment.adjust_url}/${environment.adjust_tracker}?${joinParams([
    `campaign=${encodeURIComponent(campaign)}`,
    `adgroup=${encodeURIComponent(adgroup)}`,
    `creative=${encodeURIComponent(creative)}`,
    `fallback=${encodeURIComponent(fallback)}`,
    deeplink ? `deep_link=${encodeURIComponent(deeplink)}` : '',
    isNotEmpty(labels) ? labelSection(labels) : '',
  ])}`;
}
