// DEPRECATED
// i18n.translateElement() is the successor of this component and should be used.

import React from 'react';
import DOM from 'react-dom-factories';
import PropTypes from 'prop-types';
import { TranslateElement } from 'instances/i18n';

export default function Translate(props) {
  const { nodeName, sanitize, args, find } = props;
  return React.createElement(TranslateElement, {
    node: DOM[nodeName](props),
    sanitize,
    args,
    path: find,
  });
}

Translate.propTypes = {
  find: PropTypes.string.isRequired,
  args: PropTypes.array,
  sanitize: PropTypes.bool,
  nodeName: PropTypes.string,
};

Translate.defaultProps = {
  sanitize: true,
  nodeName: 'span',
  args: [],
};
