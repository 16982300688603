// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".deviceContainer-3KAAs{height:540px;width:340px;position:relative;display:block}@media (max-width: 850px){.deviceContainer-3KAAs{display:none}}\n", ""]);
// Exports
exports.locals = {
	"deviceContainer": "deviceContainer-3KAAs"
};
module.exports = exports;
