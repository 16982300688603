import { XHR, INVALID_TOKEN, NO_TOKEN, MAINTENANCE, STATUS_OK } from 'app-constants';
import Environment from 'environment';
import axios from 'axios';
import i18n from 'instances/i18n';
import Country from 'instances/country';
import Analytics from 'instances/analytics';
import GoogleAnalytics from 'instances/google_analytics';
import { getUTMParameters } from 'helpers/url';
import BrowserEnvironment from 'instances/browser_environment';
import Settings from 'controllers/settings';
import Auth from 'controllers/auth';
import { load as loadFonts } from 'helpers/fonts';
import TypedError from 'helpers/typederror';
import loadScriptsWhenIdle from 'helpers/loadScriptsWhenIdle';
import InternationalLaunchHelper from 'helpers/internationalLaunch';
import ProviderActions from 'actions/ProviderActions';
import ProviderStore from 'stores/ProviderStore';
import LocalizationManager from 'managers/localization';
import React, { useEffect, useState } from 'react';
import RouterContainer from 'containers/RouterContainer';
import Prerender from 'components/Application/Prerender';
import * as Features from 'config/features';
import ExperimentsActions from 'actions/ExperimentsActions';
import { ThemeProvider } from '@blendle/lego';
import SegmentSnippet from 'components/SegmentSnippet';
import SegmentAnalytics from 'instances/segmentAnalytics';

function loadSettings() {
  return Settings.fetch({ accept: 'application/hal+json' }).catch(
    (resp) => new TypedError(XHR, 'Unable to load api.json', resp),
  );
}

function loadUser() {
  return Auth.autoLogin()
    .then((token) => {
      // By default the country it the current geografical country you are in.
      // After login this is overwritten by the country from the current user.
      // This is done in the _authHandler in the Application container.
      // In some edge cases this is too late since we are already using the country in some logic,
      // and assume there it is the country of the current user and not the geografical country.
      // So therefore we are setting the country here as well since this is the earliest possible place to do it
      const user = token.get('user');
      Country.setCountryCode(user.get('country'));
    })
    .catch((err) => {
      // token expired
      if (err.type === INVALID_TOKEN) {
        Analytics.track('JWT Expired', { type: 'autologin' });
        return Promise.resolve(null);
      }
      if (err.type === NO_TOKEN) {
        return Promise.resolve(null);
      }
      if (err.status) {
        throw new TypedError(MAINTENANCE, err.message, err);
      }
      throw err;
    });
}

function loadLocale() {
  const locale = Country.getLocale();
  return i18n
    .load(locale)
    .then(() => {
      // Set currency based on locale. This will be overwritten after a user authenticates
      if (!i18n.currentCurrency) {
        i18n.setCurrencyBasedOnLocale(locale);
      }
    })
    .catch((err) => {
      if (!(err instanceof Error)) {
        return new TypedError(XHR, `Unable to load locale (${locale})`, err);
      }
      return err;
    });
}

function loadSupportedLocales() {
  return LocalizationManager.getSupported().then((res) => {
    i18n.setSupportedOptionsFromResponse(res);
    if (!Country.getCountryCode()) {
      Country.setCountryCode(res.countryHeader);
    }
    return res;
  });
}

function loadProviderStyles() {
  if (Environment.name !== 'test') {
    // next tick so it runs when the whole document has been loaded
    // this ensures a non-blocking load in IE11.
    setTimeout(() => {
      const link = document.createElement('link');
      link.href = 'https://provider-assets.blendlecdn.com/styles/providers.css';
      link.rel = 'stylesheet';
      document.querySelector('script').parentNode.appendChild(link);
    });
  }

  return Promise.resolve();
}

function loadUserAndValidateCountry() {
  return Promise.all([loadUser(), loadSupportedLocales()])
    .then(() => {
      const user = Auth.getUser();

      // if the country isn't supported yet, we redirect to the fancy launch page
      if (!user && InternationalLaunchHelper.shouldRedirect()) {
        // don't resolve the promise to make it blocking.
        // this will prevent the app from continuing the load flow
        return new Promise(() => {
          InternationalLaunchHelper.redirectToLaunchSite();
        });
      }

      if (user) {
        Analytics.setUser(user);
        SegmentAnalytics.setUser(user);
      }

      return true;
    })
    .then(() => {
      // set a unsupported country to US
      if (!Country.isSupportedCountry()) {
        Country.setCountryCode(Country.getDefaultCountryCode());
      }
      return loadLocale();
    });
}

function loadBaseFonts() {
  if (BrowserEnvironment.isMobile()) {
    return Promise.resolve();
  }

  return loadFonts(
    [
      {
        family: 'GT-Walsheim',
      },
      {
        family: 'GT-Walsheim',
        style: 'medium',
      },
      {
        family: 'GT-Walsheim',
        style: 'bold',
      },
    ],
    1000,
  ).catch(() => Promise.resolve());
}

function loadLandingExperiments() {
  const experimentsEndpoint = Environment.landing_experiments_endpoint;

  if (!Features.landingPageMicroFrontend || !experimentsEndpoint) {
    return Promise.resolve();
  }

  // Get the active experiments from the landing project by the endpoint it exposes
  return axios
    .get(experimentsEndpoint)
    .then((res) => {
      ExperimentsActions.setLandingExperiments(res.data.experiment_names || []);
    })
    .catch(() => Promise.resolve()); // Silent fail
}

function requestMaintenanceState() {
  if (!Environment.maintenanceFile) {
    return Promise.reject();
  }

  return axios.get(`${Environment.maintenanceFile}?${Date.now()}`).then((resp) => {
    let message = '';
    if (resp && resp.data && resp.data.message) {
      message = resp.data.message;
    }
    return new TypedError(MAINTENANCE, message, resp);
  });
}

const RootContainer = () => {
  const [applicationLoading, setApplicationLoading] = useState(true);
  const [providersLoading, setProvidersLoading] = useState(true);
  const [error, setError] = useState(null);

  const onReady = () => {
    setApplicationLoading(false);

    // download other chunks in the background after a small delay
    // it uses requestIdleCallback which isn't widely supported yet and we don't want to
    // interfere the application too much.
    setTimeout(() => {
      loadScriptsWhenIdle(window.__chunkFiles);
    }, 1000);
  };

  const onProvidersStoreChanged = (storeState) => {
    if (storeState.status === STATUS_OK) {
      setTimeout(() => setProvidersLoading(false));
    }
  };

  const onError = (incomingError) => {
    if (error && error.type === MAINTENANCE) {
      return Promise.resolve(); // Don't show the incomingError when already in maintenance mode
    }

    // Do not resolve to error if in maintenance mode
    if (incomingError.type === MAINTENANCE) {
      setApplicationLoading(false);
      setError(incomingError);

      return Promise.reject(incomingError);
    }

    // Sentry
    window.ErrorLogger.captureException(
      new Error(`Application load error: ${incomingError.message}`),
    );

    // @TODO googlebot crashes for unknown reasons. Hide the fail message for it, for now...
    if (!window.navigator.userAgent.match(/googlebot/i)) {
      setApplicationLoading(false);
      setError(incomingError);
    }

    // If the current environment is 'production', console.error the error message and
    // resolve promise to ensure errors don't break the application
    if (Environment.name === 'production') {
      // eslint-disable-next-line no-console
      console.error(incomingError.message);
      return Promise.resolve();
    }

    return Promise.reject(incomingError);
  };

  useEffect(() => {
    ProviderActions.fetchProviderConfigurations.defer();

    const utmParams = getUTMParameters();
    Analytics.setUTMParameters(utmParams);
    SegmentAnalytics.setUTMParameters(utmParams);
    GoogleAnalytics.setUTMParameters(utmParams);

    Promise.all([
      loadUserAndValidateCountry(),
      loadSettings(),
      loadBaseFonts(),
      loadProviderStyles(),
      loadLandingExperiments(),
    ]).then(onReady, onError);

    ProviderStore.listen(onProvidersStoreChanged);

    setTimeout(() => {
      requestMaintenanceState().then(onError, () => null);
    }, 1000);

    return () => {
      ProviderStore.unlisten(onProvidersStoreChanged);
    };
  }, []);

  return (
    <ThemeProvider>
      <SegmentSnippet />
      {applicationLoading || providersLoading ? <Prerender /> : <RouterContainer error={error} />}
    </ThemeProvider>
  );
};

export default RootContainer;
