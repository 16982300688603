import { signUpPayload } from 'selectors/signUp';
import {
  SIGNUP_TYPE_DEEPLINK,
  SIGNUP_TYPE_AFFILIATE,
  SIGNUP_TYPE_PAID_ADVERTISEMENT,
  SIGNUP_TYPE_PREMIUM_ONBOARDING,
} from 'app-constants';

const PREFERENCES = 'preferences';

export function getSignUpType(pathname) {
  const isDeeplink = /\/getpremium\/item\//.test(pathname);
  const isAffiliateSignUp = /\/getpremium\/actie\//.test(pathname);
  const isPersonaSignup = /\/getpremium\/welcome\//.test(pathname);
  const isPrefencesRoute = /\/preferences\//.test(pathname);

  if (isDeeplink) {
    return SIGNUP_TYPE_DEEPLINK;
  }

  if (isAffiliateSignUp) {
    return SIGNUP_TYPE_AFFILIATE;
  }

  if (isPersonaSignup) {
    return SIGNUP_TYPE_PAID_ADVERTISEMENT;
  }

  if (isPrefencesRoute) {
    return PREFERENCES;
  }

  return SIGNUP_TYPE_PREMIUM_ONBOARDING;
}

export const internalLocation = pathname => {
  const signUpType = getSignUpType(pathname);

  switch (signUpType) {
    case PREFERENCES:
      return 'preferences';
    case SIGNUP_TYPE_DEEPLINK:
    case SIGNUP_TYPE_AFFILIATE:
    case SIGNUP_TYPE_PAID_ADVERTISEMENT:
    default:
      return signUpPayload(signUpType).internal_location;
  }
};

export const track = (instance, eventName, eventPayload) =>
  instance.track(eventName, {
    ...eventPayload,
    internal_location: internalLocation(window.location.pathname),
  });

/**
 * Check if we should send click events to Google Analytics. We don't want to send them on the deeplink at the moment
 * @param  {String} pathname Current pathname
 * @return {Bool}
 */
export const shouldTrackGAClickEvent = pathname => {
  const isItemUrl = /(\/getpremium)?\/i(tem)?\//.test(pathname);

  return !isItemUrl;
};
