import alt from 'instances/altInstance';
import { STATUS_INITIAL, STATUS_PENDING, STATUS_OK, STATUS_ERROR } from 'app-constants';
import ProviderStyleActions from 'actions/ProviderStyleActions';

class ProviderStyleStore {
  constructor() {
    this.bindActions(ProviderStyleActions);

    this.state = {
      status: STATUS_INITIAL,
      providers: {},
    };
  }

  onFetchProviderStyles() {
    this.setState({
      status: STATUS_PENDING,
    });
  }

  onFetchProviderStylesSuccess(data) {
    const { providerId, ...rest } = data;
    const { providers } = this.state;
    this.setState({
      status: STATUS_OK,
      providers: {
        ...providers,
        [providerId]: rest,
      },
    });
  }

  onFetchProviderStylesError() {
    this.setState({
      status: STATUS_ERROR,
    });
  }
}

export default alt.createStore(ProviderStyleStore, 'ProviderStyleStore');
