/* eslint-disable no-param-reassign */
import React from 'react';
import { history } from 'byebye';
import AuthStore from 'stores/AuthStore';
import ItemActions from 'actions/ItemActions';
import { asyncRoute } from 'helpers/routerHelpers';
import { replaceLastPath, isFamilyPath } from 'helpers/url';
import { countryEligibleForPremium } from 'helpers/premiumEligibility';
import ApplicationState from 'instances/application_state';
import PremiumOverlayContainer from 'containers/overlays/PremiumOverlayContainer';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import { setDeeplink, removeDeeplink, hasDirectVisitClass } from './helpers/isDeeplink';

function onEnter(nextState, replace, callback) {
  const { params } = nextState;
  const { query } = nextState.location;

  if (hasDirectVisitClass()) {
    // set the item id in the session storage to get redirect after login working
    setDeeplink(params.itemId);
  }

  if (query.p) {
    replace(`/login-email/${params.itemId}/${query.p}`);
    callback();

    return;
  }

  if (!history.getPrevious()) {
    setDeeplink(params.itemId);
  } else {
    removeDeeplink(params.itemId);
  }

  const { user } = AuthStore.getState();

  if (!user && countryEligibleForPremium()) {
    // Classes are added in inline javascript in index.html
    document.body.classList.remove('direct-visit', 'm-item');
    replace(`/getpremium/item/${params.itemId}${nextState.location.search}`);
    callback();

    return;
  }

  nextState.returnUrl =
    (nextState.location.state && nextState.location.state.returnUrl) ||
    nextState.returnUrl ||
    history.getPrevious() ||
    '/';

  if (/^(\/i\/|\/item\/)/.test(nextState.returnUrl)) {
    nextState.returnUrl = '/';
  }

  if (user) {
    ItemActions.openItem(nextState.returnUrl);
  } else {
    ApplicationState.set({ requireAuthUrl: nextState.location.pathname });
  }

  callback();
}

const noEnterPath = ['/buy-warning', '/acquire'];
const isNoEnterPath = pathname => noEnterPath.some(path => pathname.endsWith(path));
function onEnterNoEntryPoint(nextState, replace, callback) {
  const { pathname } = nextState.location;
  const { params } = nextState;

  if (!history.getPrevious() && isNoEnterPath(pathname)) {
    setDeeplink(params.itemId);
    const newPath = replaceLastPath(pathname, '');
    replace(newPath);
    callback();

    return;
  }

  onEnter(nextState, replace, callback);
}

function onLeave(nextState) {
  if (!isFamilyPath(nextState.location.pathname, window.location.pathname)) {
    document.body.classList.remove('direct-visit', 'm-item');
  }
}

function getOverlay(nextState) {
  const { location, params } = nextState;
  const { query } = location;
  const { itemId } = params;

  if ('verified' in query) {
    return (
      <PremiumOverlayContainer
        version={SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK}
        itemId={itemId}
        testId="premium-deeplink-signup-confetti"
      />
    );
  }

  return null;
}

function route(path, getComponentsHandler, other) {
  return {
    module: 'item',
    path,
    requireAuth: false,
    getComponents: asyncRoute(getComponentsHandler),
    ...other,
  };
}

function routeBuyItemWarning(path) {
  return {
    module: 'item',
    path,
    onEnter: onEnterNoEntryPoint,
    onLeave,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            dialog: require('./containers/BuyItemWarningContainer'),
          });
        },
        'reader',
      );
    }),
  };
}

function routeAcquireItem(path) {
  return {
    module: 'item',
    path,
    onEnter: onEnterNoEntryPoint,
    onLeave,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            dialog: require('./containers/AcquireItemContainer'),
          });
        },
        'reader',
      );
    }),
  };
}

const readerChildRoutes = [
  route('refund', (nextState, cb) => {
    require.ensure(
      [],
      () => {
        cb(null, { dialog: require('./containers/RefundContainer') });
      },
      'item',
    );
  }),
  routeBuyItemWarning('buy-warning'),
  routeAcquireItem('acquire'),
];

function routeItem(path) {
  return [
    {
      module: 'item',
      path,
      onEnter,
      onLeave,
      requireAuth: false,
      getComponents: asyncRoute((nextState, cb) => {
        require.ensure(
          [],
          () => {
            const { user } = AuthStore.getState();

            cb(null, {
              item: user
                ? require('./containers/ReaderContainer')
                : require('modules/deeplink/DeeplinkContainer'),
              legoDialog: () => getOverlay(nextState),
            });
          },
          'reader',
        );
      }),
      childRoutes: readerChildRoutes,
    },
  ];
}

function routeEmailVerification(path) {
  return {
    module: 'item',
    path,
    onEnter,
    onLeave,
    requireAuth: false,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            item: require('./containers/EmailVerificationContainer'),
          });
        },
        'reader',
      );
    }),
  };
}

function redirectReferrer(from) {
  return {
    path: from,
    requireAuth: false,
    onEnter: (nextState, replace) => {
      replace(`/item/${nextState.params.itemId}`);
    },
  };
}

export default [
  ...routeItem('item/:itemId'),
  ...routeItem('i/:provider/:itemName/:itemId'),
  ...routeItem('item/:itemId/welcome'),
  ...routeItem('item/:itemId/signup'),
  ...routeItem('item/:itemId/login'),
  ...routeItem('i/:provider/:itemName/:itemId/welcome'),
  ...routeItem('i/:provider/:itemName/:itemId/signup'),
  ...routeItem('i/:provider/:itemName/:itemId/login'),
  routeEmailVerification('item/:itemId/verify-email/:emailToken'),
  routeEmailVerification('i/:provider/:itemName/:itemId/verify-email/:emailToken'),
  redirectReferrer('item/:itemId/referrer/:ref'),
  redirectReferrer('item/:itemId/ref/:ref'),
  redirectReferrer('item/:itemId/r/:ref'),
  redirectReferrer('i/:provider/:itemName/:itemId/referrer/:ref'),
  redirectReferrer('i/:provider/:itemName/:itemId/ref/:ref'),
  redirectReferrer('i/:provider/:itemName/:itemId/r/:ref'),
];
