import React from 'react';
import { string, func, bool, number } from 'prop-types';
import { translate } from 'instances/i18n';
import { NavigationUserDropdown, DropdownOptionGroup, DropdownOption } from '@blendle/lego';
import Link from 'components/Link';
import PrivacyLink from 'containers/PrivacyLink';
import { convertToLandingProjectURL } from 'libs/middleware/routerRedirectToLanding';
import BalanceLabel from 'components/BalanceLabel';
import styledModule from 'helpers/styledModule';
import useDidSeeDiscoverPremiumPage from 'hooks/useDidSeeDiscoverPremiumPage';
import withViewportSize from 'higher-order-components/withViewportSize';
import CSS from './style.scss';

// When changing this breakpoint, then you must also update the breakpoint in _vars.scss file in the navigation folder
const BREAKPOINT_DISCOVER_PREMIUM_LINK = 1130;

const StyledBalanceLabel = styledModule(BalanceLabel)(CSS.balance);
const DropdownLink = styledModule(Link)(CSS.dropdownLink);
const StyledPrivacyLink = styledModule(PrivacyLink)(CSS.dropdownLink);
const Badge = styledModule.span(CSS.badge);

function DefaultNavigationUserDropdown({
  avatarUrl,
  useNeutralLowBalanceColor,
  helpPageHref,
  subscriptionPageHref,
  onToggleDropdown,
  showAlerts,
  showAudio,
  showMicropaymentsWallet,
  showCreditsWallet,
  showContentPreferences,
  blendleIsLocked,
  showSubscription,
  showPublishersOverview,
  showDiscoverPremium,
  innerWidth,
}) {
  const { didSeeDiscoverPremiumPage } = useDidSeeDiscoverPremiumPage();

  const balanceLabel = (
    <StyledBalanceLabel inline useNeutralLowBalanceColor={useNeutralLowBalanceColor} />
  );

  const isBelowDiscoverPremiumLinkBreakpoint = innerWidth < BREAKPOINT_DISCOVER_PREMIUM_LINK;
  const showBadge =
    showDiscoverPremium && !didSeeDiscoverPremiumPage && isBelowDiscoverPremiumLinkBreakpoint;

  return (
    <NavigationUserDropdown
      onToggleDropdown={onToggleDropdown}
      avatarUrl={avatarUrl}
      data-testid="navigation-user-dropdown"
      arrowColor="#fff"
      showBadge={showBadge}
    >
      {!blendleIsLocked && (
        <DropdownOptionGroup>
          <DropdownOption>
            <DropdownLink href="/me">
              <strong>{translate('navigation.links.profile.label')}</strong>
            </DropdownLink>
          </DropdownOption>
          {showContentPreferences && (
            <DropdownOption>
              <DropdownLink href="/preferences/channels">
                <strong>{translate('navigation.links.readingprefs.label')}</strong>
              </DropdownLink>
            </DropdownOption>
          )}
          {showDiscoverPremium && isBelowDiscoverPremiumLinkBreakpoint && (
            <DropdownOption data-testid="discover-premium">
              <DropdownLink href="/discover-premium">
                <strong>{translate('navigation.links.discoverPremium.label')}</strong>
                {showBadge && <Badge data-testid="discover-premium-badge" />}
              </DropdownLink>
            </DropdownOption>
          )}
          {showPublishersOverview && (
            <DropdownOption>
              <DropdownLink href={convertToLandingProjectURL('/aanbod')}>
                <strong>{translate('navigation.links.publishersOverview.label')}</strong>
              </DropdownLink>
            </DropdownOption>
          )}
          {showMicropaymentsWallet && (
            <DropdownOption data-testid="legacy-wallet">
              <DropdownLink href="/settings/wallet">
                <strong>{translate('navigation.links.wallet.label')}</strong>
                {balanceLabel}
              </DropdownLink>
            </DropdownOption>
          )}
          {showCreditsWallet && (
            <DropdownOption data-testid="credits-wallet">
              <DropdownLink href="/settings/credits">
                <strong>{translate('navigation.links.credits.label')}</strong>
                {balanceLabel}
              </DropdownLink>
            </DropdownOption>
          )}
          {showAlerts && (
            <DropdownOption hideAboveNavBarBreakpoint="medium" data-testid="alerts">
              <DropdownLink href="/alerts">
                <strong>{translate('navigation.links.alerts.label')}</strong>
              </DropdownLink>
            </DropdownOption>
          )}
          {showAudio && (
            <DropdownOption hideAboveNavBarBreakpoint="small" data-testid="audio">
              <DropdownLink href="/probeer-audio">
                <strong>{translate('navigation.links.audio.label')}</strong>
              </DropdownLink>
            </DropdownOption>
          )}
        </DropdownOptionGroup>
      )}
      <div>
        <DropdownOption>
          <DropdownLink href="/settings">
            {translate('navigation.links.settings.label')}
          </DropdownLink>
        </DropdownOption>
        {showSubscription && (
          <DropdownOption>
            <DropdownLink href={subscriptionPageHref}>
              {translate('navigation.links.subscription.label')}
            </DropdownLink>
          </DropdownOption>
        )}
        <DropdownOption>
          <DropdownLink href="/about">{translate('navigation.links.about.label')}</DropdownLink>
        </DropdownOption>
        <DropdownOption>
          <StyledPrivacyLink>{translate('about.privacy_statement.navigation')}</StyledPrivacyLink>
        </DropdownOption>
        <DropdownOption>
          <DropdownLink href={helpPageHref}>{translate('app.help')}</DropdownLink>
        </DropdownOption>
        <DropdownOption>
          <DropdownLink href="/logout">{translate('navigation.links.logout.label')}</DropdownLink>
        </DropdownOption>
      </div>
    </NavigationUserDropdown>
  );
}

DefaultNavigationUserDropdown.propTypes = {
  showAlerts: bool.isRequired,
  showAudio: bool.isRequired,
  showMicropaymentsWallet: bool.isRequired,
  showCreditsWallet: bool.isRequired,
  showContentPreferences: bool.isRequired,
  blendleIsLocked: bool.isRequired,
  showSubscription: bool.isRequired,
  showPublishersOverview: bool.isRequired,
  showDiscoverPremium: bool.isRequired,
  avatarUrl: string.isRequired,
  useNeutralLowBalanceColor: bool.isRequired,
  helpPageHref: string.isRequired,
  subscriptionPageHref: string.isRequired,
  onToggleDropdown: func.isRequired,
  innerWidth: number.isRequired,
};

export default withViewportSize({ debounce: 100 })(DefaultNavigationUserDropdown);
