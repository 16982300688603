import Link from 'components/Link';
import { objOf } from 'ramda';
import { withProps } from '@blendle/recompose';
import { getPrivacyLink } from 'helpers/privacyLink';

const asHref = objOf('href');

const privacyProps = () => asHref(getPrivacyLink());

const PrivacyLink = withProps(privacyProps)(Link);

PrivacyLink.displayName = 'PrivacyLink';

export default PrivacyLink;
