import { useState, useRef, useEffect } from 'react';
import history from 'libs/byebye/history';
import { HOME_ROUTE, FEATURE_CAPABILITY_SEARCH } from 'app-constants';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import useAlt from 'hooks/useAlt';
import { hasCapability } from 'selectors/capabilities';

const useSearch = ({ searchQueryFromUrl, isOnSearchRoute }) => {
  const [query, setQuery] = useState(searchQueryFromUrl);
  const inputRef = useRef(null);
  const featureCapabilitiesState = useAlt([FeatureCapabilitiesStore], ([state]) => state);

  const hasMicropaymentSearchCapability = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_SEARCH,
  );

  const isPremiumOnlySearch = !hasMicropaymentSearchCapability;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (inputRef.current && isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    // In the PremiumOnly search the form in the navbar is the only input, so it needs to be opened by default
    if (isOnSearchRoute && isPremiumOnlySearch) {
      setIsOpen(true);
    }

    // The search bar needs to be closed and query removed when user navigates away from the search page
    if (!isOnSearchRoute) {
      setIsOpen(false);
      setQuery('');
    }
  }, [isOnSearchRoute]);

  const openSearchBar = () => setIsOpen(true);

  const closeSearchBar = () => {
    setIsOpen(false);

    // In the premium only search we want to navigate away from the search page when search bar is closed
    if (isPremiumOnlySearch && isOnSearchRoute) {
      history.navigate(HOME_ROUTE, { trigger: true });
    }
  };

  const onChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (inputRef.current) {
      inputRef.current.blur();
    }

    // In the MP search we want to close the searchBar o submit, since this is not the primary search bar for the MP search
    if (!isPremiumOnlySearch) {
      setIsOpen(false);
    }

    history.navigate(`/search/${encodeURIComponent(query)}`, {
      trigger: true,
    });
  };

  return {
    inputRef,
    onChangeQuery,
    onSubmit,
    query,
    openSearchBar,
    closeSearchBar,
    isOpen,
  };
};

export default useSearch;
