import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Logo extends PureComponent {
  static propTypes = {
    beta: PropTypes.bool,
  };

  static defaultProps = {
    height: 23,
    width: 90,
    beta: false,
  };

  _renderLogo() {
    const { beta, ...others } = this.props;

    if (beta) {
      return (
        <svg viewBox="0 0 130 36" version="1.1" {...others}>
          <g className="blendleText" transform="translate(41.131667, 6.056782)" fill="#000000">
            <path d="M0.0705833333,19.4321767 L0.0705833333,0 L6.19116667,0 C8.21791667,0 9.84133333,0.444164038 11.011,1.3022082 C12.2008333,2.16025237 12.8058333,3.43217666 12.8058333,5.15835962 C12.8058333,6.87444795 12.0899167,8.26750789 10.6379167,9.31735016 C12.3924167,10.3974763 13.2696667,11.9520505 13.2696667,13.9205047 C13.2696667,17.3324921 10.6883333,19.4422713 6.66508333,19.4422713 L0.0705833333,19.4422713 L0.0705833333,19.4321767 Z M3.33758333,2.98801262 L3.33758333,8.00504732 L5.53575,8.00504732 C8.1675,8.00504732 9.47833333,7.14700315 9.47833333,5.4511041 C9.47833333,3.56340694 7.96583333,2.98801262 5.36433333,2.98801262 L3.33758333,2.98801262 L3.33758333,2.98801262 Z M3.33758333,16.444164 L5.72733333,16.444164 C8.55066667,16.444164 9.97241667,15.5053628 9.97241667,13.6176656 C9.97241667,11.9015773 8.60108333,11.0435331 5.82816667,11.0435331 L3.3275,11.0435331 L3.3275,16.444164 L3.33758333,16.444164 Z" />
            <path d="M15.15525,19.4927445 L15.15525,0.0504731861 L18.2810833,0.0504731861 L18.2810833,19.4826498 L15.15525,19.4826498 L15.15525,19.4927445 Z" />
            <path d="M32.5288333,13.6277603 L23.3429167,13.6277603 C23.4840833,15.6567823 24.8755833,16.9287066 26.8821667,16.9287066 C28.3341667,16.9287066 29.4836667,16.2927445 30.34075,15.0107256 L32.4481667,17.0094637 C31.0264167,18.8769716 29.161,19.785489 26.8821667,19.785489 C24.8856667,19.785489 23.232,19.1495268 22.0018333,17.8473186 C20.7716667,16.5451104 20.1364167,14.8492114 20.1364167,12.7697161 C20.1364167,10.740694 20.7111667,9.02460568 21.8405,7.64164038 C22.99,6.25867508 24.5226667,5.56214511 26.4183333,5.56214511 C30.7743333,5.56214511 32.5590833,9.0851735 32.5590833,12.5173502 L32.5288333,13.6277603 L32.5288333,13.6277603 Z M23.5143333,11.1041009 L29.2416667,11.1041009 C29.2114167,9.69085174 28.3341667,8.43911672 26.4485833,8.43911672 C24.7949167,8.44921136 23.8168333,9.64037855 23.5143333,11.1041009 L23.5143333,11.1041009 Z" />
            <path d="M40.3030833,5.55205047 C40.0409167,5.54195584 34.3135833,5.41072555 34.3135833,10.9829653 L34.3135833,19.5028391 L37.631,19.5028391 L37.631,11.1343849 C37.631,9.64037855 38.8309167,8.43911672 40.3030833,8.43911672 C41.77525,8.43911672 42.9751667,9.65047319 42.9751667,11.1343849 L42.9751667,19.5028391 L46.2825,19.5028391 L46.2825,10.9829653 C46.2825,5.40063091 40.5551667,5.54195584 40.3030833,5.55205047 L40.3030833,5.55205047 Z" />
            <path d="M58.3724167,0.0403785489 L58.3724167,6.88454259 C57.354,5.99621451 56.0935833,5.55205047 54.6214167,5.55205047 C52.7055833,5.55205047 51.1426667,6.21829653 49.9024167,7.58107256 C48.67225,8.94384858 48.06725,10.629653 48.06725,12.6788644 C48.06725,14.7280757 48.67225,16.4239748 49.9024167,17.7766562 C51.1325833,19.1091483 52.6955,19.7753943 54.6214167,19.7753943 C56.1843333,19.7753943 57.475,19.2807571 58.4329167,18.2511041 L58.4329167,19.4826498 L61.49825,19.4826498 L61.49825,0.0403785489 L58.3724167,0.0403785489 L58.3724167,0.0403785489 Z M54.84325,17.0094637 C52.5644167,17.0094637 51.2535833,15.1217666 51.2535833,12.6788644 C51.2535833,10.2359621 52.5644167,8.35835962 54.84325,8.35835962 C57.14225,8.35835962 58.4329167,10.2460568 58.4329167,12.6788644 C58.4329167,15.1217666 57.14225,17.0094637 54.84325,17.0094637 L54.84325,17.0094637 Z" />
            <path d="M63.73675,19.4927445 L63.73675,0.0504731861 L66.8625833,0.0504731861 L66.8625833,19.4826498 L63.73675,19.4826498 L63.73675,19.4927445 Z" />
            <path d="M80.8380833,13.6277603 L71.6521667,13.6277603 C71.7933333,15.6567823 73.1848333,16.9287066 75.1914167,16.9287066 C76.6434167,16.9287066 77.7929167,16.2927445 78.65,15.0107256 L80.7574167,17.0094637 C79.3356667,18.866877 77.47025,19.785489 75.1914167,19.785489 C73.1949167,19.785489 71.54125,19.1495268 70.3110833,17.8473186 C69.0809167,16.5451104 68.4456667,14.8492114 68.4456667,12.7697161 C68.4456667,10.740694 69.0204167,9.02460568 70.14975,7.64164038 C71.29925,6.25867508 72.8319167,5.56214511 74.7275833,5.56214511 C79.0835833,5.56214511 80.8683333,9.0851735 80.8683333,12.5173502 L80.8380833,13.6277603 L80.8380833,13.6277603 Z M71.8235833,11.1041009 L77.5509167,11.1041009 C77.5206667,9.69085174 76.6434167,8.43911672 74.7578333,8.43911672 C73.11425,8.44921136 72.1260833,9.64037855 71.8235833,11.1041009 L71.8235833,11.1041009 Z" />
          </g>
          <g className="blendleIcon">
            <path
              d="M31.581,15.9697161 C31.5305833,21.0271293 30.3911667,25.3072555 27.67875,27.992429 C25.01675,30.7381703 20.7918333,31.888959 15.7905,31.9394322 C10.7891667,31.888959 6.56425,30.7381703 3.90225,27.992429 C1.18983333,25.3072555 0.0504166667,21.0271293 0,15.9697161 C0.0504166667,10.9123028 1.18983333,6.63217666 3.90225,3.94700315 C6.56425,1.20126183 10.7891667,0.0504731861 15.7905,0 C20.7918333,0.0504731861 25.01675,1.20126183 27.67875,3.94700315 C30.3810833,6.63217666 31.5305833,10.9123028 31.581,15.9697161 L31.581,15.9697161 Z"
              id="supercircle"
              fill="#FF6255"
            />
            <g className="blendleArrows" transform="translate(7.764167, 7.974763)" fill="#FFFFFF">
              <path d="M9.3775,7.99495268 L9.3775,7.99495268 C6.87683333,5.28958991 4.43666667,2.66498423 1.98641667,0.0302839117 C1.089,0.736908517 0.87725,0.90851735 0.03025,1.56466877 C0.03025,1.56466877 2.19816667,5.19873817 3.29725,6.93501577 C3.55941667,7.35899054 3.72075,7.68201893 3.751,7.99495268 C3.73083333,8.30788644 3.5695,8.63091483 3.29725,9.05488959 C2.20825,10.7911672 0.03025,14.4252366 0.03025,14.4252366 C0.867166667,15.081388 1.07891667,15.2529968 1.98641667,15.9596215 C4.43666667,13.3249211 6.87683333,10.7003155 9.3775,7.99495268 L9.3775,7.99495268" />
              <path d="M18.05925,7.99495268 L18.05925,7.99495268 C15.3770833,5.0977918 13.3301667,2.89716088 10.6681667,0.0302839117 C9.77075,0.736908517 9.559,0.90851735 8.712,1.56466877 C8.712,1.56466877 10.8799167,5.19873817 11.979,6.93501577 C12.2411667,7.35899054 12.4025,7.68201893 12.43275,7.99495268 C12.4125833,8.30788644 12.25125,8.63091483 11.979,9.05488959 C10.89,10.7911672 8.712,14.4252366 8.712,14.4252366 C9.54891667,15.081388 9.76066667,15.2529968 10.6681667,15.9596215 C13.1083333,13.3249211 15.5485,10.7003155 18.05925,7.99495268 L18.05925,7.99495268" />
            </g>
          </g>
        </svg>
      );
    }

    return (
      <svg viewBox="0 0 100 27" xmlns="http://www.w3.org/2000/svg" {...others}>
        <defs>
          <linearGradient id="razzmatazz-svg-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#ff1060', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#ff6255', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <g fill="none">
          <g fill="#000" className="blendleText">
            <path d="M33.197 21.024V4.996h5.054c1.674 0 3.015.366 3.98 1.074.983.708 1.483 1.757 1.483 3.18 0 1.416-.592 2.565-1.79 3.431 1.448.891 2.173 2.173 2.173 3.797 0 2.814-2.132 4.554-5.454 4.554h-5.446v-.008zM35.895 7.46V11.6h1.815c2.173 0 3.256-.708 3.256-2.107 0-1.557-1.25-2.032-3.397-2.032h-1.674zm0 11.1h1.973c2.332 0 3.506-.775 3.506-2.332 0-1.415-1.133-2.123-3.422-2.123h-2.065v4.455h.008zM45.654 21.074V5.037h2.58v16.029h-2.58zM60 16.236h-7.585c.116 1.674 1.265 2.723 2.922 2.723 1.2 0 2.148-.524 2.856-1.582l1.74 1.649c-1.174 1.54-2.714 2.29-4.596 2.29-1.648 0-3.014-.525-4.03-1.6-1.016-1.073-1.54-2.472-1.54-4.187 0-1.674.474-3.09 1.407-4.23.95-1.14 2.215-1.715 3.78-1.715 3.597 0 5.071 2.906 5.071 5.737l-.025.915zm-7.444-2.081h4.73c-.025-1.166-.75-2.198-2.307-2.198-1.365.008-2.173.99-2.423 2.198zM66.42 9.575c-.217-.008-4.946-.116-4.946 4.48v7.027h2.74V14.18c0-1.232.99-2.223 2.206-2.223 1.215 0 2.206.999 2.206 2.223v6.902h2.731v-7.027c0-4.605-4.73-4.488-4.937-4.48zM81.34 5.03v5.644c-.84-.732-1.881-1.099-3.097-1.099-1.582 0-2.872.55-3.897 1.674-1.015 1.124-1.515 2.515-1.515 4.205s.5 3.089 1.515 4.205c1.016 1.099 2.307 1.648 3.897 1.648 1.29 0 2.357-.408 3.148-1.257v1.016h2.53V5.029h-2.58zm-2.914 13.996c-1.881 0-2.964-1.557-2.964-3.572s1.083-3.564 2.964-3.564c1.899 0 2.965 1.557 2.965 3.564 0 2.015-1.066 3.572-2.965 3.572zM85.77 21.074V5.037h2.581v16.029h-2.58zM99.892 16.236h-7.586c.117 1.674 1.266 2.723 2.923 2.723 1.199 0 2.148-.524 2.856-1.582l1.74 1.649c-1.174 1.532-2.714 2.29-4.596 2.29-1.649 0-3.014-.525-4.03-1.6-1.016-1.073-1.54-2.472-1.54-4.187 0-1.674.474-3.09 1.407-4.23.949-1.14 2.215-1.715 3.78-1.715 3.597 0 5.07 2.906 5.07 5.737l-.024.915zm-7.444-2.081h4.73c-.026-1.166-.75-2.198-2.307-2.198-1.357.008-2.173.99-2.423 2.198z" />
          </g>
          <path
            className="blendleIcon"
            d="M26.078 13.172c-.041 4.172-.982 7.702-3.222 9.917-2.198 2.265-5.687 3.214-9.817 3.256-4.13-.042-7.619-.991-9.817-3.256C.982 20.874.042 17.344 0 13.172.042 9.001.983 5.47 3.222 3.256 5.42.99 8.91.042 13.04 0c4.13.042 7.619.99 9.817 3.256 2.231 2.214 3.18 5.745 3.222 9.916z"
            fill="url(#razzmatazz-svg-gradient)"
          />
          <g fill="#FFF" className="blendleArrows">
            <path d="M14.155 13.172c-2.065-2.231-4.08-4.396-6.103-6.57-.741.584-.916.725-1.616 1.266 0 0 1.79 2.998 2.698 4.43.217.35.35.616.375.874-.017.258-.15.525-.375.875-.9 1.432-2.698 4.43-2.698 4.43.691.54.866.682 1.616 1.265 2.023-2.173 4.038-4.338 6.103-6.57" />
            <path d="M21.324 13.172c-2.215-2.39-3.905-4.204-6.103-6.57-.741.584-.916.725-1.616 1.266 0 0 1.79 2.998 2.698 4.43.217.35.35.616.375.874-.017.258-.15.525-.375.875-.9 1.432-2.698 4.43-2.698 4.43.691.54.866.682 1.616 1.265 2.015-2.173 4.03-4.338 6.103-6.57" />
          </g>
        </g>
      </svg>
    );
  }

  render() {
    return this._renderLogo();
  }
}

export default Logo;
