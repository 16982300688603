// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".navigationItem-1hw4y.navigationItem-1hw4y{display:none}@media (min-width: 1130px){.navigationItem-1hw4y.navigationItem-1hw4y{display:block}}.regularLink-1n8Ln{transition:color 0.1s ease-out;letter-spacing:-0.02em}.regularLink-1n8Ln.regularLink-1n8Ln{color:#999;font-weight:400}.regularLink-1n8Ln:hover.regularLink-1n8Ln:hover{color:#ff1060}.prominentLink-27bfq{transition:color 0.1s ease-out;letter-spacing:-0.02em}.prominentLink-27bfq.prominentLink-27bfq{color:#ff1060;font-weight:500}.prominentLink-27bfq:hover.prominentLink-27bfq:hover{color:#bd0041}\n", ""]);
// Exports
exports.locals = {
	"navigationItem": "navigationItem-1hw4y",
	"regularLink": "regularLink-1n8Ln",
	"prominentLink": "prominentLink-27bfq"
};
module.exports = exports;
