/* eslint-disable global-require */
import { asyncRoute } from 'helpers/routerHelpers';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { FEATURE_CAPABILITY_NEWSSTAND } from 'app-constants';
import { capabilityIsLockedAsync } from 'selectors/capabilities';

function onEnter(nextState, replace, callback) {
  capabilityIsLockedAsync(FEATURE_CAPABILITY_NEWSSTAND).then((kioskIsLocked) => {
    if (kioskIsLocked) {
      replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_NEWSSTAND));

      callback();
      return;
    }

    document.body.classList.add('m-kiosk');

    callback();
  });
}

function onLeave() {
  document.body.classList.remove('m-kiosk');
}

function routeKiosk(path) {
  return {
    module: 'kiosk',
    path,
    onEnter,
    onLeave,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('modules/kiosk/KioskContainer'),
            navigation: require('modules/timeline/TimelineNavigationContainer'),
          });
        },
        'kiosk',
      );
    }),
  };
}

export default [routeKiosk('kiosk'), routeKiosk('kiosk/:category')];
