import { path, prop, includes, pipe } from 'ramda';
import { HOME_ROUTE } from 'app-constants';
import AffiliatesActions from 'actions/AffiliatesActions';
import googleAnalytics from 'instances/google_analytics';
import AffiliatesStore from 'stores/AffiliatesStore';
import { countryEligibleForPremium } from 'helpers/premiumEligibility';
import settings from 'config/settings';
import Country from 'instances/country';
import AuthStore from 'stores/AuthStore';
import { debounce } from 'lodash';
import ApplicationState from 'instances/application_state';

// debounce since the event comes twice in one tick
export const debouncedTrack = debounce((...args) => googleAnalytics.trackPageView(...args), {
  wait: 0,
  leading: false,
});

// See comment for debouncedTrack()
export const debouncedTrackEvent = debounce((...args) => googleAnalytics.trackEvent(...args), {
  wait: 0,
  leading: false,
});

const activatePage = '/getpremium/activate';

const shouldRedirectToPlatform = (user, affiliateState, affiliateRoute) =>
  user &&
  user.get('email_confirmed') &&
  user.hasActivePremiumSubscription() &&
  user.didOnboarding() &&
  user.didPremiumOnboarding() &&
  !affiliateState.affiliate &&
  !affiliateRoute;

const isUserEligibleForTrialMonth = pipe(
  prop('returnurl'),
  includes('nl-blendle-standalone-trial'),
);

const storeAffilateData = nextState => {
  const { query } = nextState.location;
  const { params } = nextState;
  const affiliateId = params.affiliateId || query.affiliate;

  if (affiliateId) {
    AffiliatesActions.selectAffiliate(affiliateId);
  }

  if (affiliateId === 'vodafone' && window.location.search !== '') {
    ApplicationState.set('requireAuthUrl', activatePage);
    // always give the same path, even on different entry points
    const { origin, search } = window.location;

    AffiliatesActions.saveVodafoneMetaData({
      vodafoneFullUrl: `${origin}/getpremium/actie/vodafone/signup${search}`,
      isUserEligibleForTrialMonth: isUserEligibleForTrialMonth(query),
    });
  }
};

export function onEnterNoAuth(nextState, replace) {
  document.body.classList.add('m-premiumsignup');
  const { user } = AuthStore.getState();
  const {
    params: { affiliateId },
    location: { pathname },
  } = nextState;

  storeAffilateData(nextState);

  if (user && affiliateId === 'vodafone') {
    replace('/getpremium/vodafone-connect');
    return;
  }

  if (shouldRedirectToPlatform(user, AffiliatesStore.getState(), affiliateId)) {
    debouncedTrackEvent(pathname, 'redirect', 'user logged in');

    replace(HOME_ROUTE);
    return;
  }

  // Only redirect if
  // - the current country is not a premium country
  // - and in case there is already a user, that user did not signup via a premium signup flow
  if (
    !countryEligibleForPremium() &&
    !(user && settings.premiumCountries.includes(user.get('country')))
  ) {
    debouncedTrackEvent(pathname, 'redirect', 'country redirect', Country.getCountryCode());

    replace('/');
  }
}

const codeFromNextState = path(['location', 'query', 'code']);
const affiliateIdFromNextState = path(['params', 'affiliateId']);
const affiliatesForWebClient = ['vodafone'];
const affiliatesThatShouldBeRedirected = new Map([
  ['ahtogo', 'ahtogo'],
  ['ns', 'ns'],
  ['ns_cadeautje', 'ns'],
]);

export const onEnterAffiliate = (nextState, replace) => {
  const affiliate = affiliateIdFromNextState(nextState);
  const code = codeFromNextState(nextState);
  const codeQueryString = code ? `?couponCode=${encodeURIComponent(code)}` : '';

  if (affiliatesForWebClient.includes(affiliate)) {
    onEnterNoAuth(nextState, replace);
    return;
  }

  if (affiliatesThatShouldBeRedirected.has(affiliate)) {
    replace(`/actie/${affiliatesThatShouldBeRedirected.get(affiliate)}${codeQueryString}`);
    return;
  }

  replace(`/actie${codeQueryString}`);
};
