// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-aohDw{position:relative;text-align:center}@media (min-width: 600px){.container-aohDw{padding-bottom:0;margin-bottom:25px}}.backgroundGradient-3TTcX{position:absolute;width:100%;height:269px;bottom:0;background:linear-gradient(180deg, rgba(245,247,248,0) 0%, #f5f7f8 100%)}@media (min-width: 600px){.backgroundGradient-3TTcX{bottom:18px}}.label-31tqj.label-31tqj{text-transform:uppercase;font-size:10px;font-weight:700}.title-1xMp8{color:#000;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;letter-spacing:-0.05em;font-size:28px;line-height:31px;margin-top:13px}@media (min-width: 600px){.title-1xMp8{font-size:40px;line-height:42px;margin-top:10px}}.paragraph-11IcL{font-family:\"GT-Walsheim\",sans-serif;color:#474747;letter-spacing:-0.02em;max-width:632px;margin:4px auto 14px;font-size:17px;line-height:24px}@media (min-width: 600px){.paragraph-11IcL{font-size:18px;line-height:25px;margin:12px auto 9px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-aohDw",
	"backgroundGradient": "backgroundGradient-3TTcX",
	"label": "label-31tqj",
	"title": "title-1xMp8",
	"paragraph": "paragraph-11IcL"
};
module.exports = exports;
