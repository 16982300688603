export default {
  name: 'production',
  api: 'https://static.blendle.com/api.json',
  tokens: 'https://ws.blendle.com/tokens',
  i18n: 'https://ws.blendle.com/i18n',
  providerConfigurations: 'https://static.blendle.nl/provider_configurations.json',
  analytics: 'https://events.blendle.com/v1/events',
  gaTrackingId: 'UA-90032766-6', // google analytics tracking id
  maintenanceFile: 'https://static.blendle.com/maintenance.json',
  ssl: true,
  paymentUri: 'https://payment.blendle.com',
  doubleclick_existing_user_id: 'DC-8327133/user/blend000+standard',
  doubleclick_pageview_id: 'DC-8327133/noaccoun/blend0+standard',
  doubleclick_success_id: 'DC-8327133/user/blend0+standard',
  landing_project_protocol: 'https',
  landing_project_host: 'blendle.com',
  landing_experiments_endpoint: 'https://landing.blendle.io/api/active-experiments',
  facebookAppId: '157559707786240',
  facebookVersion: 'v3.1',
  adjust_url: 'https://app.adjust.com',
  adjust_tracker: 'or13m85',
  sentryApiKey: 'https://71e65c4c369442038ba57880fd8591bb@app.getsentry.com/19604',
  daisyconPixelId: '13476',
  facebookPixelId: '742491399133724',
  twitterPixelId: 'nw3xj',
  doubleClickId: 'DC-8327133',
  graphQLHost: 'https://graph.blendle.com/graphql',
  segmentEnvironmentName: 'production',
};
