import { asyncRoute } from 'helpers/routerHelpers';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { FEATURE_CAPABILITY_ALERTS } from 'app-constants';
import { capabilityIsLockedAsync } from 'selectors/capabilities';

function onEnter(_, replace, callback) {
  capabilityIsLockedAsync(FEATURE_CAPABILITY_ALERTS).then(alertsIsLocked => {
    if (alertsIsLocked) {
      replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_ALERTS));

      callback();

      return;
    }

    document.body.classList.add('m-alerts');
    require.ensure(
      [],
      () => {
        const AlertsActions = require('actions/AlertsActions');
        AlertsActions.setActive();
      },
      'alerts',
    );

    callback();
  });
}

function onLeave() {
  document.body.classList.remove('m-alerts');
  require.ensure(
    [],
    () => {
      const AlertsActions = require('actions/AlertsActions');
      AlertsActions.setInactive();
    },
    'alerts',
  );
}

function routeAlerts(path) {
  return {
    module: 'alerts',
    path,
    onEnter,
    onLeave,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            navigation: require('modules/alerts/AlertsNavigationContainer'),
            content: require('modules/alerts/AlertsContainer'),
          });
        },
        'alerts',
      );
    }),
  };
}

export default [
  routeAlerts('alerts'),
  routeAlerts('alerts/manage/:new'),
  routeAlerts('alerts/:query'),
];
