import AuthStore from 'stores/AuthStore';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import FeatureCapabilitiesActions from 'actions/FeatureCapabilitiesActions';

function onUserModelChange(userModel) {
  const modelCapabilities = userModel.get('capabilities');
  const { capabilities } = FeatureCapabilitiesStore.getState();

  if (
    !capabilities ||
    (modelCapabilities &&
      (modelCapabilities.length !== capabilities.length ||
        !modelCapabilities.every(capibility => capabilities.has(capibility))))
  ) {
    FeatureCapabilitiesActions.setCapabilities.defer(modelCapabilities);
  }
}

/**
 * Starts tracking changes to the user model or the auth store to ensure
 * the CapabilitiesStore is always update whenever something changes
 */
export function startSyncingCapabilities() {
  let prevUser;
  function onAuthStoreChange({ user }) {
    if (user) {
      onUserModelChange(user);

      if (user !== prevUser) {
        user.on('change', onUserModelChange);
      }
    }

    prevUser = user;
  }

  AuthStore.listen(onAuthStoreChange);
  const { user } = AuthStore.getState();
  prevUser = user;

  if (user) {
    FeatureCapabilitiesActions.setCapabilities.defer(user.get('capabilities'));
    user.on('change', onUserModelChange);
  }
}
