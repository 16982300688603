import React from 'react';
import styledModule from 'helpers/styledModule';
import staticImageUrl from 'helpers/staticImageUrl';
import { string } from 'prop-types';
import { translate } from 'instances/i18n';
import { useDiscoverPremiumPageContext } from 'modules/discoverPremium/context/DiscoverPremiumContext';
import SharedCSS from '../shared.scss';
import CSS from './styles.scss';
import { H3, Body } from '../../Typography';
import USP from '../USP';

const Container = styledModule.div(SharedCSS.container);
const StyledContainer = styledModule(Container)(CSS.container);
const Device = styledModule.img(CSS.device);
const Content = styledModule.div(SharedCSS.content);
const StyledContent = styledModule(Content)(CSS.content);
const LabelWrapper = styledModule.div(SharedCSS.labelWrapper);
const Kicker = styledModule.p(SharedCSS.kicker);
const UspWrapper = styledModule.div(SharedCSS.uspWrapper);

const imagePath = staticImageUrl('/img/backgrounds/pixel_3_audio');

const Audio = ({ kicker }) => {
  const { primaryColor } = useDiscoverPremiumPageContext();

  return (
    <StyledContainer>
      <StyledContent>
        <LabelWrapper>
          <Kicker style={{ color: primaryColor }}>
            {kicker || translate('discover_premium.features.app_only')}
          </Kicker>
        </LabelWrapper>
        <H3>{translate('discover_premium.features.audio.title')}</H3>
        <Body>{translate('discover_premium.features.audio.text')}</Body>
        <UspWrapper>
          <USP>{translate('discover_premium.features.audio.usp_one')}</USP>
          <USP>{translate('discover_premium.features.audio.usp_two')}</USP>
          <USP>{translate('discover_premium.features.audio.usp_three')}</USP>
        </UspWrapper>
      </StyledContent>
      <Device
        src={`${imagePath}@2x.png?auto=format,compress`}
        srcSet={`${imagePath}.png?auto=format,compress 1x, ${imagePath}@2x.png?auto=format,compress 2x`}
        alt="Pixel 3 Audio"
      />
    </StyledContainer>
  );
};

Audio.propTypes = {
  kicker: string,
};

Audio.defaultProps = {
  kicker: undefined,
};

export default Audio;
