import React from 'react';
import { string } from 'prop-types';

export default function AudioIcon({ stroke = 'currentColor', fill = 'none', ...props }) {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 5.60418 6.68961L 4.87668 1.51321C 4.80856 1.02845 4.36035 0.690701 3.87559 0.75883C 1.92004 1.03366 0.511387 2.78634 0.784226 4.72769C 1.05707 6.66904 2.89425 7.96554 4.8498 7.69071C 5.33456 7.62258 5.67231 7.17437 5.60418 6.68961ZM 15.8232 1.51321L 15.0957 6.68961C 15.0276 7.17437 15.3653 7.62258 15.8501 7.69071C 17.8056 7.96554 19.6428 6.66904 19.9157 4.72769C 20.1885 2.78634 18.7798 1.03367 16.8243 0.75883C 16.3395 0.690701 15.8913 1.02845 15.8232 1.51321Z"
        strokeWidth="1.5"
        strokeMiterlimit="8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={stroke}
        transform="translate(.55 9.678)"
      />
      <path
        d="M 0 9.60001L 0 8C -6.67138e-07 3.58172 3.58172 0 8 0C 12.4183 -6.67141e-07 16 3.58172 16 8L 16 9.60001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={stroke}
        transform="translate(2.9 1)"
      />
    </svg>
  );
}

AudioIcon.propTypes = {
  stroke: string,
  fill: string,
};

AudioIcon.defaultProps = {
  stroke: 'currentColor',
  fill: 'none',
};
