// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-2nWmS{display:flex;align-items:center;background:white;height:100%;padding:15px;position:absolute;width:100%}.input-xJhRU{width:100%;height:21px;line-height:21px;margin-left:10px;margin-right:10px;font-family:\"GT-Walsheim\",sans-serif;font-size:16px}.input-xJhRU:focus{box-shadow:none}.input-xJhRU::placeholder{color:#b2b2b2}.searchIcon-2r6Me{position:relative;width:19px;height:19px;color:#999}.active-3ntcq{color:#ff1060}.cursorPointer-ZPOkD{cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"form": "form-2nWmS",
	"input": "input-xJhRU",
	"searchIcon": "searchIcon-2r6Me",
	"active": "active-3ntcq",
	"cursorPointer": "cursorPointer-ZPOkD"
};
module.exports = exports;
