import React from 'react';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import { oneOf } from 'prop-types';

const QRCode = ({ version }) =>
  version === SUCCESS_DIALOG_VERSION.WALLET_CONVERSION ||
  version === SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW ? (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.0857 0H16.9646V2.12106H19.0857V0Z" fill="black" />
      <path d="M27.5781 0H25.457V2.12106H27.5781V0Z" fill="black" />
      <path d="M29.6914 0H27.5703V2.12106H29.6914V0Z" fill="black" />
      <path d="M40.2968 0H38.1758V2.12106H40.2968V0Z" fill="black" />
      <path d="M44.5429 0H42.4219V2.12106H44.5429V0Z" fill="black" />
      <path d="M46.666 0H44.5449V2.12106H46.666V0Z" fill="black" />
      <path d="M48.789 0H46.668V2.12106H48.789V0Z" fill="black" />
      <path d="M50.9021 0H48.781V2.12106H50.9021V0Z" fill="black" />
      <path d="M53.0254 0H50.9043V2.12106H53.0254V0Z" fill="black" />
      <path d="M23.332 2.12305H21.2109V4.24411H23.332V2.12305Z" fill="black" />
      <path d="M25.455 2.12305H23.334V4.24411H25.455V2.12305Z" fill="black" />
      <path d="M27.5781 2.12305H25.457V4.24411H27.5781V2.12305Z" fill="black" />
      <path d="M31.8144 2.12305H29.6934V4.24411H31.8144V2.12305Z" fill="black" />
      <path d="M33.9375 2.12305H31.8164V4.24411H33.9375V2.12305Z" fill="black" />
      <path d="M42.4199 2.12305H40.2988V4.24411H42.4199V2.12305Z" fill="black" />
      <path d="M50.9021 2.12305H48.781V4.24411H50.9021V2.12305Z" fill="black" />
      <path d="M29.6914 4.24609H27.5703V6.36716H29.6914V4.24609Z" fill="black" />
      <path d="M36.0605 4.24609H33.9395V6.36716H36.0605V4.24609Z" fill="black" />
      <path d="M40.2968 4.24609H38.1758V6.36716H40.2968V4.24609Z" fill="black" />
      <path d="M48.789 4.24609H46.668V6.36716H48.789V4.24609Z" fill="black" />
      <path d="M50.9021 4.24609H48.781V6.36716H50.9021V4.24609Z" fill="black" />
      <path d="M53.0254 4.24609H50.9043V6.36716H53.0254V4.24609Z" fill="black" />
      <path d="M19.0857 6.35913H16.9646V8.48019H19.0857V6.35913Z" fill="black" />
      <path d="M21.209 6.35913H19.0879V8.48019H21.209V6.35913Z" fill="black" />
      <path d="M25.455 6.35913H23.334V8.48019H25.455V6.35913Z" fill="black" />
      <path d="M29.6914 6.35913H27.5703V8.48019H29.6914V6.35913Z" fill="black" />
      <path d="M31.8144 6.35913H29.6934V8.48019H31.8144V6.35913Z" fill="black" />
      <path d="M33.9375 6.35913H31.8164V8.48019H33.9375V6.35913Z" fill="black" />
      <path d="M42.4199 6.35913H40.2988V8.48019H42.4199V6.35913Z" fill="black" />
      <path d="M44.5429 6.35913H42.4219V8.48019H44.5429V6.35913Z" fill="black" />
      <path d="M48.789 6.35913H46.668V8.48019H48.789V6.35913Z" fill="black" />
      <path d="M53.0254 6.35913H50.9043V8.48019H53.0254V6.35913Z" fill="black" />
      <path d="M25.455 8.48242H23.334V10.6035H25.455V8.48242Z" fill="black" />
      <path d="M27.5781 8.48242H25.457V10.6035H27.5781V8.48242Z" fill="black" />
      <path d="M38.1836 8.48242H36.0625V10.6035H38.1836V8.48242Z" fill="black" />
      <path d="M40.2968 8.48242H38.1758V10.6035H40.2968V8.48242Z" fill="black" />
      <path d="M53.0254 8.48242H50.9043V10.6035H53.0254V8.48242Z" fill="black" />
      <path d="M25.455 10.6055H23.334V12.7265H25.455V10.6055Z" fill="black" />
      <path d="M27.5781 10.6055H25.457V12.7265H27.5781V10.6055Z" fill="black" />
      <path d="M31.8144 10.6055H29.6934V12.7265H31.8144V10.6055Z" fill="black" />
      <path d="M33.9375 10.6055H31.8164V12.7265H33.9375V10.6055Z" fill="black" />
      <path d="M38.1836 10.6055H36.0625V12.7265H38.1836V10.6055Z" fill="black" />
      <path d="M40.2968 10.6055H38.1758V12.7265H40.2968V10.6055Z" fill="black" />
      <path d="M42.4199 10.6055H40.2988V12.7265H42.4199V10.6055Z" fill="black" />
      <path d="M46.666 10.6055H44.5449V12.7265H46.666V10.6055Z" fill="black" />
      <path d="M48.789 10.6055H46.668V12.7265H48.789V10.6055Z" fill="black" />
      <path d="M50.9021 10.6055H48.781V12.7265H50.9021V10.6055Z" fill="black" />
      <path d="M53.0254 10.6055H50.9043V12.7265H53.0254V10.6055Z" fill="black" />
      <path d="M19.0857 12.7285H16.9646V14.8496H19.0857V12.7285Z" fill="black" />
      <path d="M23.332 12.7285H21.2109V14.8496H23.332V12.7285Z" fill="black" />
      <path d="M27.5781 12.7285H25.457V14.8496H27.5781V12.7285Z" fill="black" />
      <path d="M31.8144 12.7285H29.6934V14.8496H31.8144V12.7285Z" fill="black" />
      <path d="M36.0605 12.7285H33.9395V14.8496H36.0605V12.7285Z" fill="black" />
      <path d="M40.2968 12.7285H38.1758V14.8496H40.2968V12.7285Z" fill="black" />
      <path d="M44.5429 12.7285H42.4219V14.8496H44.5429V12.7285Z" fill="black" />
      <path d="M48.789 12.7285H46.668V14.8496H48.789V12.7285Z" fill="black" />
      <path d="M53.0254 12.7285H50.9043V14.8496H53.0254V12.7285Z" fill="black" />
      <path d="M21.209 14.8516H19.0879V16.9726H21.209V14.8516Z" fill="black" />
      <path d="M25.455 14.8516H23.334V16.9726H25.455V14.8516Z" fill="black" />
      <path d="M29.6914 14.8516H27.5703V16.9726H29.6914V14.8516Z" fill="black" />
      <path d="M33.9375 14.8516H31.8164V16.9726H33.9375V14.8516Z" fill="black" />
      <path d="M38.1836 14.8516H36.0625V16.9726H38.1836V14.8516Z" fill="black" />
      <path d="M40.2968 14.8516H38.1758V16.9726H40.2968V14.8516Z" fill="black" />
      <path d="M42.4199 14.8516H40.2988V16.9726H42.4199V14.8516Z" fill="black" />
      <path d="M46.666 14.8516H44.5449V16.9726H46.666V14.8516Z" fill="black" />
      <path d="M50.9021 14.8516H48.781V16.9726H50.9021V14.8516Z" fill="black" />
      <path d="M6.36716 16.9646H4.24609V19.0857H6.36716V16.9646Z" fill="black" />
      <path d="M10.6035 16.9646H8.48242V19.0857H10.6035V16.9646Z" fill="black" />
      <path d="M12.7265 16.9646H10.6055V19.0857H12.7265V16.9646Z" fill="black" />
      <path d="M14.8496 16.9646H12.7285V19.0857H14.8496V16.9646Z" fill="black" />
      <path d="M19.0857 16.9646H16.9646V19.0857H19.0857V16.9646Z" fill="black" />
      <path d="M21.209 16.9646H19.0879V19.0857H21.209V16.9646Z" fill="black" />
      <path d="M23.332 16.9646H21.2109V19.0857H23.332V16.9646Z" fill="black" />
      <path d="M27.5781 16.9646H25.457V19.0857H27.5781V16.9646Z" fill="black" />
      <path d="M29.6914 16.9646H27.5703V19.0857H29.6914V16.9646Z" fill="black" />
      <path d="M33.9375 16.9646H31.8164V19.0857H33.9375V16.9646Z" fill="black" />
      <path d="M40.2968 16.9646H38.1758V19.0857H40.2968V16.9646Z" fill="black" />
      <path d="M42.4199 16.9646H40.2988V19.0857H42.4199V16.9646Z" fill="black" />
      <path d="M44.5429 16.9646H42.4219V19.0857H44.5429V16.9646Z" fill="black" />
      <path d="M46.666 16.9646H44.5449V19.0857H46.666V16.9646Z" fill="black" />
      <path d="M53.0254 16.9646H50.9043V19.0857H53.0254V16.9646Z" fill="black" />
      <path d="M55.1484 16.9646H53.0273V19.0857H55.1484V16.9646Z" fill="black" />
      <path d="M63.6308 16.9646H61.5098V19.0857H63.6308V16.9646Z" fill="black" />
      <path d="M70 16.9646H67.8789V19.0857H70V16.9646Z" fill="black" />
      <path d="M2.12106 19.0879H0V21.209H2.12106V19.0879Z" fill="black" />
      <path d="M8.48019 19.0879H6.35913V21.209H8.48019V19.0879Z" fill="black" />
      <path d="M16.9726 19.0879H14.8516V21.209H16.9726V19.0879Z" fill="black" />
      <path d="M21.209 19.0879H19.0879V21.209H21.209V19.0879Z" fill="black" />
      <path d="M25.455 19.0879H23.334V21.209H25.455V19.0879Z" fill="black" />
      <path d="M27.5781 19.0879H25.457V21.209H27.5781V19.0879Z" fill="black" />
      <path d="M53.0254 19.0879H50.9043V21.209H53.0254V19.0879Z" fill="black" />
      <path d="M57.2714 19.0879H55.1504V21.209H57.2714V19.0879Z" fill="black" />
      <path d="M70 19.0879H67.8789V21.209H70V19.0879Z" fill="black" />
      <path d="M4.24411 21.2109H2.12305V23.332H4.24411V21.2109Z" fill="black" />
      <path d="M6.36716 21.2109H4.24609V23.332H6.36716V21.2109Z" fill="black" />
      <path d="M8.48019 21.2109H6.35913V23.332H8.48019V21.2109Z" fill="black" />
      <path d="M10.6035 21.2109H8.48242V23.332H10.6035V21.2109Z" fill="black" />
      <path d="M14.8496 21.2109H12.7285V23.332H14.8496V21.2109Z" fill="black" />
      <path d="M16.9726 21.2109H14.8516V23.332H16.9726V21.2109Z" fill="black" />
      <path d="M21.209 21.2109H19.0879V23.332H21.209V21.2109Z" fill="black" />
      <path d="M23.332 21.2109H21.2109V23.332H23.332V21.2109Z" fill="black" />
      <path d="M55.1484 21.2109H53.0273V23.332H55.1484V21.2109Z" fill="black" />
      <path d="M61.5078 21.2109H59.3867V23.332H61.5078V21.2109Z" fill="black" />
      <path d="M65.7539 21.2109H63.6328V23.332H65.7539V21.2109Z" fill="black" />
      <path d="M67.8769 21.2109H65.7559V23.332H67.8769V21.2109Z" fill="black" />
      <path d="M70 21.2109H67.8789V23.332H70V21.2109Z" fill="black" />
      <path d="M2.12106 23.334H0V25.455H2.12106V23.334Z" fill="black" />
      <path d="M8.48019 23.334H6.35913V25.455H8.48019V23.334Z" fill="black" />
      <path d="M16.9726 23.334H14.8516V25.455H16.9726V23.334Z" fill="black" />
      <path d="M19.0857 23.334H16.9646V25.455H19.0857V23.334Z" fill="black" />
      <path d="M53.0254 23.334H50.9043V25.455H53.0254V23.334Z" fill="black" />
      <path d="M55.1484 23.334H53.0273V25.455H55.1484V23.334Z" fill="black" />
      <path d="M57.2714 23.334H55.1504V25.455H57.2714V23.334Z" fill="black" />
      <path d="M63.6308 23.334H61.5098V25.455H63.6308V23.334Z" fill="black" />
      <path d="M2.12106 25.457H0V27.5781H2.12106V25.457Z" fill="black" />
      <path d="M4.24411 25.457H2.12305V27.5781H4.24411V25.457Z" fill="black" />
      <path d="M14.8496 25.457H12.7285V27.5781H14.8496V25.457Z" fill="black" />
      <path d="M19.0857 25.457H16.9646V27.5781H19.0857V25.457Z" fill="black" />
      <path d="M21.209 25.457H19.0879V27.5781H21.209V25.457Z" fill="black" />
      <path d="M50.9021 25.457H48.781V27.5781H50.9021V25.457Z" fill="black" />
      <path d="M53.0254 25.457H50.9043V27.5781H53.0254V25.457Z" fill="black" />
      <path d="M55.1484 25.457H53.0273V27.5781H55.1484V25.457Z" fill="black" />
      <path d="M57.2714 25.457H55.1504V27.5781H57.2714V25.457Z" fill="black" />
      <path d="M59.3945 25.457H57.2734V27.5781H59.3945V25.457Z" fill="black" />
      <path d="M70 25.457H67.8789V27.5781H70V25.457Z" fill="black" />
      <path d="M2.12106 27.5703H0V29.6914H2.12106V27.5703Z" fill="black" />
      <path d="M6.36716 27.5703H4.24609V29.6914H6.36716V27.5703Z" fill="black" />
      <path d="M10.6035 27.5703H8.48242V29.6914H10.6035V27.5703Z" fill="black" />
      <path d="M16.9726 27.5703H14.8516V29.6914H16.9726V27.5703Z" fill="black" />
      <path d="M53.0254 27.5703H50.9043V29.6914H53.0254V27.5703Z" fill="black" />
      <path d="M55.1484 27.5703H53.0273V29.6914H55.1484V27.5703Z" fill="black" />
      <path d="M57.2714 27.5703H55.1504V29.6914H57.2714V27.5703Z" fill="black" />
      <path d="M67.8769 27.5703H65.7559V29.6914H67.8769V27.5703Z" fill="black" />
      <path d="M70 27.5703H67.8789V29.6914H70V27.5703Z" fill="black" />
      <path d="M2.12106 29.6934H0V31.8144H2.12106V29.6934Z" fill="black" />
      <path d="M4.24411 29.6934H2.12305V31.8144H4.24411V29.6934Z" fill="black" />
      <path d="M14.8496 29.6934H12.7285V31.8144H14.8496V29.6934Z" fill="black" />
      <path d="M16.9726 29.6934H14.8516V31.8144H16.9726V29.6934Z" fill="black" />
      <path d="M19.0857 29.6934H16.9646V31.8144H19.0857V29.6934Z" fill="black" />
      <path d="M53.0254 29.6934H50.9043V31.8144H53.0254V29.6934Z" fill="black" />
      <path d="M55.1484 29.6934H53.0273V31.8144H55.1484V29.6934Z" fill="black" />
      <path d="M59.3945 29.6934H57.2734V31.8144H59.3945V29.6934Z" fill="black" />
      <path d="M61.5078 29.6934H59.3867V31.8144H61.5078V29.6934Z" fill="black" />
      <path d="M65.7539 29.6934H63.6328V31.8144H65.7539V29.6934Z" fill="black" />
      <path d="M67.8769 29.6934H65.7559V31.8144H67.8769V29.6934Z" fill="black" />
      <path d="M70 29.6934H67.8789V31.8144H70V29.6934Z" fill="black" />
      <path d="M6.36716 31.8164H4.24609V33.9375H6.36716V31.8164Z" fill="black" />
      <path d="M12.7265 31.8164H10.6055V33.9375H12.7265V31.8164Z" fill="black" />
      <path d="M53.0254 31.8164H50.9043V33.9375H53.0254V31.8164Z" fill="black" />
      <path d="M57.2714 31.8164H55.1504V33.9375H57.2714V31.8164Z" fill="black" />
      <path d="M67.8769 31.8164H65.7559V33.9375H67.8769V31.8164Z" fill="black" />
      <path d="M70 31.8164H67.8789V33.9375H70V31.8164Z" fill="black" />
      <path d="M2.12106 33.9395H0V36.0605H2.12106V33.9395Z" fill="black" />
      <path d="M14.8496 33.9395H12.7285V36.0605H14.8496V33.9395Z" fill="black" />
      <path d="M19.0857 33.9395H16.9646V36.0605H19.0857V33.9395Z" fill="black" />
      <path d="M55.1484 33.9395H53.0273V36.0605H55.1484V33.9395Z" fill="black" />
      <path d="M57.2714 33.9395H55.1504V36.0605H57.2714V33.9395Z" fill="black" />
      <path d="M63.6308 33.9395H61.5098V36.0605H63.6308V33.9395Z" fill="black" />
      <path d="M67.8769 33.9395H65.7559V36.0605H67.8769V33.9395Z" fill="black" />
      <path d="M2.12106 36.0625H0V38.1836H2.12106V36.0625Z" fill="black" />
      <path d="M4.24411 36.0625H2.12305V38.1836H4.24411V36.0625Z" fill="black" />
      <path d="M8.48019 36.0625H6.35913V38.1836H8.48019V36.0625Z" fill="black" />
      <path d="M10.6035 36.0625H8.48242V38.1836H10.6035V36.0625Z" fill="black" />
      <path d="M55.1484 36.0625H53.0273V38.1836H55.1484V36.0625Z" fill="black" />
      <path d="M57.2714 36.0625H55.1504V38.1836H57.2714V36.0625Z" fill="black" />
      <path d="M63.6308 36.0625H61.5098V38.1836H63.6308V36.0625Z" fill="black" />
      <path d="M65.7539 36.0625H63.6328V38.1836H65.7539V36.0625Z" fill="black" />
      <path d="M70 36.0625H67.8789V38.1836H70V36.0625Z" fill="black" />
      <path d="M2.12106 38.1758H0V40.2968H2.12106V38.1758Z" fill="black" />
      <path d="M6.36716 38.1758H4.24609V40.2968H6.36716V38.1758Z" fill="black" />
      <path d="M10.6035 38.1758H8.48242V40.2968H10.6035V38.1758Z" fill="black" />
      <path d="M12.7265 38.1758H10.6055V40.2968H12.7265V38.1758Z" fill="black" />
      <path d="M14.8496 38.1758H12.7285V40.2968H14.8496V38.1758Z" fill="black" />
      <path d="M57.2714 38.1758H55.1504V40.2968H57.2714V38.1758Z" fill="black" />
      <path d="M59.3945 38.1758H57.2734V40.2968H59.3945V38.1758Z" fill="black" />
      <path d="M65.7539 38.1758H63.6328V40.2968H65.7539V38.1758Z" fill="black" />
      <path d="M67.8769 38.1758H65.7559V40.2968H67.8769V38.1758Z" fill="black" />
      <path d="M70 38.1758H67.8789V40.2968H70V38.1758Z" fill="black" />
      <path d="M4.24411 40.2988H2.12305V42.4199H4.24411V40.2988Z" fill="black" />
      <path d="M16.9726 40.2988H14.8516V42.4199H16.9726V40.2988Z" fill="black" />
      <path d="M55.1484 40.2988H53.0273V42.4199H55.1484V40.2988Z" fill="black" />
      <path d="M59.3945 40.2988H57.2734V42.4199H59.3945V40.2988Z" fill="black" />
      <path d="M67.8769 40.2988H65.7559V42.4199H67.8769V40.2988Z" fill="black" />
      <path d="M70 40.2988H67.8789V42.4199H70V40.2988Z" fill="black" />
      <path d="M2.12106 42.4219H0V44.5429H2.12106V42.4219Z" fill="black" />
      <path d="M6.36716 42.4219H4.24609V44.5429H6.36716V42.4219Z" fill="black" />
      <path d="M10.6035 42.4219H8.48242V44.5429H10.6035V42.4219Z" fill="black" />
      <path d="M14.8496 42.4219H12.7285V44.5429H14.8496V42.4219Z" fill="black" />
      <path d="M16.9726 42.4219H14.8516V44.5429H16.9726V42.4219Z" fill="black" />
      <path d="M53.0254 42.4219H50.9043V44.5429H53.0254V42.4219Z" fill="black" />
      <path d="M57.2714 42.4219H55.1504V44.5429H57.2714V42.4219Z" fill="black" />
      <path d="M63.6308 42.4219H61.5098V44.5429H63.6308V42.4219Z" fill="black" />
      <path d="M67.8769 42.4219H65.7559V44.5429H67.8769V42.4219Z" fill="black" />
      <path d="M70 42.4219H67.8789V44.5429H70V42.4219Z" fill="black" />
      <path d="M4.24411 44.5449H2.12305V46.666H4.24411V44.5449Z" fill="black" />
      <path d="M6.36716 44.5449H4.24609V46.666H6.36716V44.5449Z" fill="black" />
      <path d="M8.48019 44.5449H6.35913V46.666H8.48019V44.5449Z" fill="black" />
      <path d="M10.6035 44.5449H8.48242V46.666H10.6035V44.5449Z" fill="black" />
      <path d="M21.209 44.5449H19.0879V46.666H21.209V44.5449Z" fill="black" />
      <path d="M50.9021 44.5449H48.781V46.666H50.9021V44.5449Z" fill="black" />
      <path d="M53.0254 44.5449H50.9043V46.666H53.0254V44.5449Z" fill="black" />
      <path d="M55.1484 44.5449H53.0273V46.666H55.1484V44.5449Z" fill="black" />
      <path d="M59.3945 44.5449H57.2734V46.666H59.3945V44.5449Z" fill="black" />
      <path d="M63.6308 44.5449H61.5098V46.666H63.6308V44.5449Z" fill="black" />
      <path d="M70 44.5449H67.8789V46.666H70V44.5449Z" fill="black" />
      <path d="M2.12106 46.668H0V48.789H2.12106V46.668Z" fill="black" />
      <path d="M6.36716 46.668H4.24609V48.789H6.36716V46.668Z" fill="black" />
      <path d="M12.7265 46.668H10.6055V48.789H12.7265V46.668Z" fill="black" />
      <path d="M14.8496 46.668H12.7285V48.789H14.8496V46.668Z" fill="black" />
      <path d="M21.209 46.668H19.0879V48.789H21.209V46.668Z" fill="black" />
      <path d="M50.9021 46.668H48.781V48.789H50.9021V46.668Z" fill="black" />
      <path d="M53.0254 46.668H50.9043V48.789H53.0254V46.668Z" fill="black" />
      <path d="M57.2714 46.668H55.1504V48.789H57.2714V46.668Z" fill="black" />
      <path d="M61.5078 46.668H59.3867V48.789H61.5078V46.668Z" fill="black" />
      <path d="M65.7539 46.668H63.6328V48.789H65.7539V46.668Z" fill="black" />
      <path d="M67.8769 46.668H65.7559V48.789H67.8769V46.668Z" fill="black" />
      <path d="M70 46.668H67.8789V48.789H70V46.668Z" fill="black" />
      <path d="M4.24411 48.781H2.12305V50.9021H4.24411V48.781Z" fill="black" />
      <path d="M8.48019 48.781H6.35913V50.9021H8.48019V48.781Z" fill="black" />
      <path d="M12.7265 48.781H10.6055V50.9021H12.7265V48.781Z" fill="black" />
      <path d="M19.0857 48.781H16.9646V50.9021H19.0857V48.781Z" fill="black" />
      <path d="M46.666 48.781H44.5449V50.9021H46.666V48.781Z" fill="black" />
      <path d="M48.789 48.781H46.668V50.9021H48.789V48.781Z" fill="black" />
      <path d="M53.0254 48.781H50.9043V50.9021H53.0254V48.781Z" fill="black" />
      <path d="M55.1484 48.781H53.0273V50.9021H55.1484V48.781Z" fill="black" />
      <path d="M59.3945 48.781H57.2734V50.9021H59.3945V48.781Z" fill="black" />
      <path d="M70 48.781H67.8789V50.9021H70V48.781Z" fill="black" />
      <path d="M2.12106 50.9043H0V53.0254H2.12106V50.9043Z" fill="black" />
      <path d="M8.48019 50.9043H6.35913V53.0254H8.48019V50.9043Z" fill="black" />
      <path d="M10.6035 50.9043H8.48242V53.0254H10.6035V50.9043Z" fill="black" />
      <path d="M12.7265 50.9043H10.6055V53.0254H12.7265V50.9043Z" fill="black" />
      <path d="M14.8496 50.9043H12.7285V53.0254H14.8496V50.9043Z" fill="black" />
      <path d="M23.332 50.9043H21.2109V53.0254H23.332V50.9043Z" fill="black" />
      <path d="M27.5781 50.9043H25.457V53.0254H27.5781V50.9043Z" fill="black" />
      <path d="M29.6914 50.9043H27.5703V53.0254H29.6914V50.9043Z" fill="black" />
      <path d="M36.0605 50.9043H33.9395V53.0254H36.0605V50.9043Z" fill="black" />
      <path d="M44.5429 50.9043H42.4219V53.0254H44.5429V50.9043Z" fill="black" />
      <path d="M46.666 50.9043H44.5449V53.0254H46.666V50.9043Z" fill="black" />
      <path d="M48.789 50.9043H46.668V53.0254H48.789V50.9043Z" fill="black" />
      <path d="M50.9021 50.9043H48.781V53.0254H50.9021V50.9043Z" fill="black" />
      <path d="M53.0254 50.9043H50.9043V53.0254H53.0254V50.9043Z" fill="black" />
      <path d="M55.1484 50.9043H53.0273V53.0254H55.1484V50.9043Z" fill="black" />
      <path d="M57.2714 50.9043H55.1504V53.0254H57.2714V50.9043Z" fill="black" />
      <path d="M59.3945 50.9043H57.2734V53.0254H59.3945V50.9043Z" fill="black" />
      <path d="M61.5078 50.9043H59.3867V53.0254H61.5078V50.9043Z" fill="black" />
      <path d="M63.6308 50.9043H61.5098V53.0254H63.6308V50.9043Z" fill="black" />
      <path d="M70 50.9043H67.8789V53.0254H70V50.9043Z" fill="black" />
      <path d="M19.0857 53.0273H16.9646V55.1484H19.0857V53.0273Z" fill="black" />
      <path d="M25.455 53.0273H23.334V55.1484H25.455V53.0273Z" fill="black" />
      <path d="M27.5781 53.0273H25.457V55.1484H27.5781V53.0273Z" fill="black" />
      <path d="M29.6914 53.0273H27.5703V55.1484H29.6914V53.0273Z" fill="black" />
      <path d="M38.1836 53.0273H36.0625V55.1484H38.1836V53.0273Z" fill="black" />
      <path d="M40.2968 53.0273H38.1758V55.1484H40.2968V53.0273Z" fill="black" />
      <path d="M44.5429 53.0273H42.4219V55.1484H44.5429V53.0273Z" fill="black" />
      <path d="M46.666 53.0273H44.5449V55.1484H46.666V53.0273Z" fill="black" />
      <path d="M48.789 53.0273H46.668V55.1484H48.789V53.0273Z" fill="black" />
      <path d="M50.9021 53.0273H48.781V55.1484H50.9021V53.0273Z" fill="black" />
      <path d="M53.0254 53.0273H50.9043V55.1484H53.0254V53.0273Z" fill="black" />
      <path d="M61.5078 53.0273H59.3867V55.1484H61.5078V53.0273Z" fill="black" />
      <path d="M65.7539 53.0273H63.6328V55.1484H65.7539V53.0273Z" fill="black" />
      <path d="M67.8769 53.0273H65.7559V55.1484H67.8769V53.0273Z" fill="black" />
      <path d="M70 53.0273H67.8789V55.1484H70V53.0273Z" fill="black" />
      <path d="M23.332 55.1504H21.2109V57.2714H23.332V55.1504Z" fill="black" />
      <path d="M25.455 55.1504H23.334V57.2714H25.455V55.1504Z" fill="black" />
      <path d="M33.9375 55.1504H31.8164V57.2714H33.9375V55.1504Z" fill="black" />
      <path d="M38.1836 55.1504H36.0625V57.2714H38.1836V55.1504Z" fill="black" />
      <path d="M44.5429 55.1504H42.4219V57.2714H44.5429V55.1504Z" fill="black" />
      <path d="M48.789 55.1504H46.668V57.2714H48.789V55.1504Z" fill="black" />
      <path d="M50.9021 55.1504H48.781V57.2714H50.9021V55.1504Z" fill="black" />
      <path d="M53.0254 55.1504H50.9043V57.2714H53.0254V55.1504Z" fill="black" />
      <path d="M57.2714 55.1504H55.1504V57.2714H57.2714V55.1504Z" fill="black" />
      <path d="M61.5078 55.1504H59.3867V57.2714H61.5078V55.1504Z" fill="black" />
      <path d="M67.8769 55.1504H65.7559V57.2714H67.8769V55.1504Z" fill="black" />
      <path d="M70 55.1504H67.8789V57.2714H70V55.1504Z" fill="black" />
      <path d="M19.0857 57.2734H16.9646V59.3945H19.0857V57.2734Z" fill="black" />
      <path d="M25.455 57.2734H23.334V59.3945H25.455V57.2734Z" fill="black" />
      <path d="M27.5781 57.2734H25.457V59.3945H27.5781V57.2734Z" fill="black" />
      <path d="M36.0605 57.2734H33.9395V59.3945H36.0605V57.2734Z" fill="black" />
      <path d="M40.2968 57.2734H38.1758V59.3945H40.2968V57.2734Z" fill="black" />
      <path d="M42.4199 57.2734H40.2988V59.3945H42.4199V57.2734Z" fill="black" />
      <path d="M44.5429 57.2734H42.4219V59.3945H44.5429V57.2734Z" fill="black" />
      <path d="M46.666 57.2734H44.5449V59.3945H46.666V57.2734Z" fill="black" />
      <path d="M48.789 57.2734H46.668V59.3945H48.789V57.2734Z" fill="black" />
      <path d="M50.9021 57.2734H48.781V59.3945H50.9021V57.2734Z" fill="black" />
      <path d="M53.0254 57.2734H50.9043V59.3945H53.0254V57.2734Z" fill="black" />
      <path d="M61.5078 57.2734H59.3867V59.3945H61.5078V57.2734Z" fill="black" />
      <path d="M70 57.2734H67.8789V59.3945H70V57.2734Z" fill="black" />
      <path d="M19.0857 59.3867H16.9646V61.5078H19.0857V59.3867Z" fill="black" />
      <path d="M29.6914 59.3867H27.5703V61.5078H29.6914V59.3867Z" fill="black" />
      <path d="M31.8144 59.3867H29.6934V61.5078H31.8144V59.3867Z" fill="black" />
      <path d="M33.9375 59.3867H31.8164V61.5078H33.9375V59.3867Z" fill="black" />
      <path d="M36.0605 59.3867H33.9395V61.5078H36.0605V59.3867Z" fill="black" />
      <path d="M42.4199 59.3867H40.2988V61.5078H42.4199V59.3867Z" fill="black" />
      <path d="M44.5429 59.3867H42.4219V61.5078H44.5429V59.3867Z" fill="black" />
      <path d="M48.789 59.3867H46.668V61.5078H48.789V59.3867Z" fill="black" />
      <path d="M53.0254 59.3867H50.9043V61.5078H53.0254V59.3867Z" fill="black" />
      <path d="M55.1484 59.3867H53.0273V61.5078H55.1484V59.3867Z" fill="black" />
      <path d="M57.2714 59.3867H55.1504V61.5078H57.2714V59.3867Z" fill="black" />
      <path d="M59.3945 59.3867H57.2734V61.5078H59.3945V59.3867Z" fill="black" />
      <path d="M61.5078 59.3867H59.3867V61.5078H61.5078V59.3867Z" fill="black" />
      <path d="M67.8769 59.3867H65.7559V61.5078H67.8769V59.3867Z" fill="black" />
      <path d="M21.209 61.5098H19.0879V63.6308H21.209V61.5098Z" fill="black" />
      <path d="M23.332 61.5098H21.2109V63.6308H23.332V61.5098Z" fill="black" />
      <path d="M25.455 61.5098H23.334V63.6308H25.455V61.5098Z" fill="black" />
      <path d="M27.5781 61.5098H25.457V63.6308H27.5781V61.5098Z" fill="black" />
      <path d="M33.9375 61.5098H31.8164V63.6308H33.9375V61.5098Z" fill="black" />
      <path d="M42.4199 61.5098H40.2988V63.6308H42.4199V61.5098Z" fill="black" />
      <path d="M44.5429 61.5098H42.4219V63.6308H44.5429V61.5098Z" fill="black" />
      <path d="M48.789 61.5098H46.668V63.6308H48.789V61.5098Z" fill="black" />
      <path d="M61.5078 61.5098H59.3867V63.6308H61.5078V61.5098Z" fill="black" />
      <path d="M63.6308 61.5098H61.5098V63.6308H63.6308V61.5098Z" fill="black" />
      <path d="M65.7539 61.5098H63.6328V63.6308H65.7539V61.5098Z" fill="black" />
      <path d="M19.0857 63.6328H16.9646V65.7539H19.0857V63.6328Z" fill="black" />
      <path d="M21.209 63.6328H19.0879V65.7539H21.209V63.6328Z" fill="black" />
      <path d="M25.455 63.6328H23.334V65.7539H25.455V63.6328Z" fill="black" />
      <path d="M27.5781 63.6328H25.457V65.7539H27.5781V63.6328Z" fill="black" />
      <path d="M29.6914 63.6328H27.5703V65.7539H29.6914V63.6328Z" fill="black" />
      <path d="M33.9375 63.6328H31.8164V65.7539H33.9375V63.6328Z" fill="black" />
      <path d="M36.0605 63.6328H33.9395V65.7539H36.0605V63.6328Z" fill="black" />
      <path d="M38.1836 63.6328H36.0625V65.7539H38.1836V63.6328Z" fill="black" />
      <path d="M48.789 63.6328H46.668V65.7539H48.789V63.6328Z" fill="black" />
      <path d="M50.9021 63.6328H48.781V65.7539H50.9021V63.6328Z" fill="black" />
      <path d="M55.1484 63.6328H53.0273V65.7539H55.1484V63.6328Z" fill="black" />
      <path d="M59.3945 63.6328H57.2734V65.7539H59.3945V63.6328Z" fill="black" />
      <path d="M61.5078 63.6328H59.3867V65.7539H61.5078V63.6328Z" fill="black" />
      <path d="M70 63.6328H67.8789V65.7539H70V63.6328Z" fill="black" />
      <path d="M23.332 65.7559H21.2109V67.8769H23.332V65.7559Z" fill="black" />
      <path d="M25.455 65.7559H23.334V67.8769H25.455V65.7559Z" fill="black" />
      <path d="M29.6914 65.7559H27.5703V67.8769H29.6914V65.7559Z" fill="black" />
      <path d="M40.2968 65.7559H38.1758V67.8769H40.2968V65.7559Z" fill="black" />
      <path d="M42.4199 65.7559H40.2988V67.8769H42.4199V65.7559Z" fill="black" />
      <path d="M46.666 65.7559H44.5449V67.8769H46.666V65.7559Z" fill="black" />
      <path d="M50.9021 65.7559H48.781V67.8769H50.9021V65.7559Z" fill="black" />
      <path d="M53.0254 65.7559H50.9043V67.8769H53.0254V65.7559Z" fill="black" />
      <path d="M55.1484 65.7559H53.0273V67.8769H55.1484V65.7559Z" fill="black" />
      <path d="M59.3945 65.7559H57.2734V67.8769H59.3945V65.7559Z" fill="black" />
      <path d="M67.8769 65.7559H65.7559V67.8769H67.8769V65.7559Z" fill="black" />
      <path d="M21.209 67.8789H19.0879V70H21.209V67.8789Z" fill="black" />
      <path d="M23.332 67.8789H21.2109V70H23.332V67.8789Z" fill="black" />
      <path d="M27.5781 67.8789H25.457V70H27.5781V67.8789Z" fill="black" />
      <path d="M29.6914 67.8789H27.5703V70H29.6914V67.8789Z" fill="black" />
      <path d="M31.8144 67.8789H29.6934V70H31.8144V67.8789Z" fill="black" />
      <path d="M38.1836 67.8789H36.0625V70H38.1836V67.8789Z" fill="black" />
      <path d="M42.4199 67.8789H40.2988V70H42.4199V67.8789Z" fill="black" />
      <path d="M44.5429 67.8789H42.4219V70H44.5429V67.8789Z" fill="black" />
      <path d="M48.789 67.8789H46.668V70H48.789V67.8789Z" fill="black" />
      <path d="M50.9021 67.8789H48.781V70H50.9021V67.8789Z" fill="black" />
      <path d="M53.0254 67.8789H50.9043V70H53.0254V67.8789Z" fill="black" />
      <path d="M55.1484 67.8789H53.0273V70H55.1484V67.8789Z" fill="black" />
      <path d="M57.2714 67.8789H55.1504V70H57.2714V67.8789Z" fill="black" />
      <path d="M59.3945 67.8789H57.2734V70H59.3945V67.8789Z" fill="black" />
      <path d="M61.5078 67.8789H59.3867V70H61.5078V67.8789Z" fill="black" />
      <path d="M63.6308 67.8789H61.5098V70H63.6308V67.8789Z" fill="black" />
      <path d="M67.8769 67.8789H65.7559V70H67.8769V67.8789Z" fill="black" />
      <path d="M70 67.8789H67.8789V70H70V67.8789Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 2.1175H3.625C2.86147 2.1175 2.2425 2.73647 2.2425 3.5V11.375C2.2425 12.1385 2.86147 12.7575 3.625 12.7575H11.5C12.2635 12.7575 12.8825 12.1385 12.8825 11.375V3.5C12.8825 2.73647 12.2635 2.1175 11.5 2.1175ZM3.625 0C1.692 0 0.125 1.567 0.125 3.5V11.375C0.125 13.308 1.692 14.875 3.625 14.875H11.5C13.433 14.875 15 13.308 15 11.375V3.5C15 1.567 13.433 0 11.5 0H3.625Z"
        fill="black"
      />
      <path
        d="M4.0625 5.6875C4.0625 4.721 4.846 3.9375 5.8125 3.9375H9.3125C10.279 3.9375 11.0625 4.721 11.0625 5.6875V9.1875C11.0625 10.154 10.279 10.9375 9.3125 10.9375H5.8125C4.846 10.9375 4.0625 10.154 4.0625 9.1875V5.6875Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 57.1175H3.625C2.86147 57.1175 2.2425 57.7365 2.2425 58.5V66.375C2.2425 67.1385 2.86147 67.7575 3.625 67.7575H11.5C12.2635 67.7575 12.8825 67.1385 12.8825 66.375V58.5C12.8825 57.7365 12.2635 57.1175 11.5 57.1175ZM3.625 55C1.692 55 0.125 56.567 0.125 58.5V66.375C0.125 68.308 1.692 69.875 3.625 69.875H11.5C13.433 69.875 15 68.308 15 66.375V58.5C15 56.567 13.433 55 11.5 55H3.625Z"
        fill="black"
      />
      <path
        d="M4.0625 60.6875C4.0625 59.721 4.846 58.9375 5.8125 58.9375H9.3125C10.279 58.9375 11.0625 59.721 11.0625 60.6875V64.1875C11.0625 65.154 10.279 65.9375 9.3125 65.9375H5.8125C4.846 65.9375 4.0625 65.154 4.0625 64.1875V60.6875Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5 2.1175H58.625C57.8615 2.1175 57.2425 2.73647 57.2425 3.5V11.375C57.2425 12.1385 57.8615 12.7575 58.625 12.7575H66.5C67.2635 12.7575 67.8825 12.1385 67.8825 11.375V3.5C67.8825 2.73647 67.2635 2.1175 66.5 2.1175ZM58.625 0C56.692 0 55.125 1.567 55.125 3.5V11.375C55.125 13.308 56.692 14.875 58.625 14.875H66.5C68.433 14.875 70 13.308 70 11.375V3.5C70 1.567 68.433 0 66.5 0H58.625Z"
        fill="black"
      />
      <path
        d="M59.0625 5.6875C59.0625 4.721 59.846 3.9375 60.8125 3.9375H64.3125C65.279 3.9375 66.0625 4.721 66.0625 5.6875V9.1875C66.0625 10.154 65.279 10.9375 64.3125 10.9375H60.8125C59.846 10.9375 59.0625 10.154 59.0625 9.1875V5.6875Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 35C48.9553 39.4336 47.9451 43.1859 45.5402 45.5398C43.1801 47.9469 39.4342 48.9558 35 49C30.5657 48.9558 26.8199 47.9469 24.4598 45.5398C22.0549 43.1858 21.0447 39.4336 21 35C21.0447 30.5664 22.0549 26.8142 24.4598 24.4602C26.8199 22.0531 30.5658 21.0442 35 21C39.4342 21.0442 43.18 22.0531 45.5402 24.4602C47.9361 26.8142 48.9553 30.5664 49 35ZM36.198 35C33.9809 32.6283 31.8174 30.3274 29.645 28.0177C28.8493 28.6372 28.6616 28.7876 27.9106 29.3628C27.9106 29.3628 29.8327 32.5487 30.8072 34.0708C31.0396 34.4425 31.1827 34.7257 31.2095 35C31.1916 35.2743 31.0486 35.5575 30.8072 35.9292C29.8417 37.4513 27.9106 40.6372 27.9106 40.6372C28.6526 41.2124 28.8404 41.3629 29.645 41.9823C31.8174 39.6726 33.9809 37.3717 36.198 35ZM43.8953 35C41.5173 32.4602 39.7025 30.531 37.3423 28.0177C36.5467 28.6372 36.3589 28.7876 35.6079 29.3628C35.6079 29.3628 37.53 32.5487 38.5045 34.0708C38.7369 34.4425 38.88 34.7257 38.9068 35C38.8889 35.2743 38.7459 35.5575 38.5045 35.9292C37.539 37.4513 35.6079 40.6372 35.6079 40.6372C36.35 41.2124 36.5377 41.3629 37.3423 41.9823C39.5058 39.6726 41.6693 37.3717 43.8953 35Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.0857 0H16.9646V2.12106H19.0857V0Z" fill="black" />
      <path d="M21.209 0H19.0879V2.12106H21.209V0Z" fill="black" />
      <path d="M23.3321 0H21.2111V2.12106H23.3321V0Z" fill="black" />
      <path d="M27.5781 0H25.457V2.12106H27.5781V0Z" fill="black" />
      <path d="M29.6913 0H27.5702V2.12106H29.6913V0Z" fill="black" />
      <path d="M31.8144 0H29.6934V2.12106H31.8144V0Z" fill="black" />
      <path d="M33.9375 0H31.8164V2.12106H33.9375V0Z" fill="black" />
      <path d="M36.0606 0H33.9396V2.12106H36.0606V0Z" fill="black" />
      <path d="M40.2968 0H38.1758V2.12106H40.2968V0Z" fill="black" />
      <path d="M50.9021 0H48.781V2.12106H50.9021V0Z" fill="black" />
      <path d="M53.0254 0H50.9043V2.12106H53.0254V0Z" fill="black" />
      <path d="M19.0857 2.12329H16.9646V4.24435H19.0857V2.12329Z" fill="black" />
      <path d="M23.3321 2.12329H21.2111V4.24435H23.3321V2.12329Z" fill="black" />
      <path d="M29.6913 2.12329H27.5702V4.24435H29.6913V2.12329Z" fill="black" />
      <path d="M31.8144 2.12329H29.6934V4.24435H31.8144V2.12329Z" fill="black" />
      <path d="M33.9375 2.12329H31.8164V4.24435H33.9375V2.12329Z" fill="black" />
      <path d="M38.1836 2.12329H36.0625V4.24435H38.1836V2.12329Z" fill="black" />
      <path d="M40.2968 2.12329H38.1758V4.24435H40.2968V2.12329Z" fill="black" />
      <path d="M53.0254 2.12329H50.9043V4.24435H53.0254V2.12329Z" fill="black" />
      <path d="M19.0857 4.24609H16.9646V6.36716H19.0857V4.24609Z" fill="black" />
      <path d="M21.209 4.24609H19.0879V6.36716H21.209V4.24609Z" fill="black" />
      <path d="M23.3321 4.24609H21.2111V6.36716H23.3321V4.24609Z" fill="black" />
      <path d="M29.6913 4.24609H27.5702V6.36716H29.6913V4.24609Z" fill="black" />
      <path d="M31.8144 4.24609H29.6934V6.36716H31.8144V4.24609Z" fill="black" />
      <path d="M33.9375 4.24609H31.8164V6.36716H33.9375V4.24609Z" fill="black" />
      <path d="M40.2968 4.24609H38.1758V6.36716H40.2968V4.24609Z" fill="black" />
      <path d="M44.5429 4.24609H42.4219V6.36716H44.5429V4.24609Z" fill="black" />
      <path d="M46.666 4.24609H44.5449V6.36716H46.666V4.24609Z" fill="black" />
      <path d="M50.9021 4.24609H48.781V6.36716H50.9021V4.24609Z" fill="black" />
      <path d="M53.0254 4.24609H50.9043V6.36716H53.0254V4.24609Z" fill="black" />
      <path d="M21.209 6.35913H19.0879V8.48019H21.209V6.35913Z" fill="black" />
      <path d="M27.5781 6.35913H25.457V8.48019H27.5781V6.35913Z" fill="black" />
      <path d="M31.8144 6.35913H29.6934V8.48019H31.8144V6.35913Z" fill="black" />
      <path d="M33.9375 6.35913H31.8164V8.48019H33.9375V6.35913Z" fill="black" />
      <path d="M38.1836 6.35913H36.0625V8.48019H38.1836V6.35913Z" fill="black" />
      <path d="M40.2968 6.35913H38.1758V8.48019H40.2968V6.35913Z" fill="black" />
      <path d="M44.5429 6.35913H42.4219V8.48019H44.5429V6.35913Z" fill="black" />
      <path d="M48.7889 6.35913H46.6678V8.48019H48.7889V6.35913Z" fill="black" />
      <path d="M50.9021 6.35913H48.781V8.48019H50.9021V6.35913Z" fill="black" />
      <path d="M23.3321 8.48242H21.2111V10.6035H23.3321V8.48242Z" fill="black" />
      <path d="M25.455 8.48242H23.334V10.6035H25.455V8.48242Z" fill="black" />
      <path d="M27.5781 8.48242H25.457V10.6035H27.5781V8.48242Z" fill="black" />
      <path d="M31.8144 8.48242H29.6934V10.6035H31.8144V8.48242Z" fill="black" />
      <path d="M33.9375 8.48242H31.8164V10.6035H33.9375V8.48242Z" fill="black" />
      <path d="M38.1836 8.48242H36.0625V10.6035H38.1836V8.48242Z" fill="black" />
      <path d="M40.2968 8.48242H38.1758V10.6035H40.2968V8.48242Z" fill="black" />
      <path d="M46.666 8.48242H44.5449V10.6035H46.666V8.48242Z" fill="black" />
      <path d="M48.7889 8.48242H46.6678V10.6035H48.7889V8.48242Z" fill="black" />
      <path d="M53.0254 8.48242H50.9043V10.6035H53.0254V8.48242Z" fill="black" />
      <path d="M19.0857 10.6055H16.9646V12.7265H19.0857V10.6055Z" fill="black" />
      <path d="M25.455 10.6055H23.334V12.7265H25.455V10.6055Z" fill="black" />
      <path d="M36.0606 10.6055H33.9396V12.7265H36.0606V10.6055Z" fill="black" />
      <path d="M38.1836 10.6055H36.0625V12.7265H38.1836V10.6055Z" fill="black" />
      <path d="M40.2968 10.6055H38.1758V12.7265H40.2968V10.6055Z" fill="black" />
      <path d="M44.5429 10.6055H42.4219V12.7265H44.5429V10.6055Z" fill="black" />
      <path d="M48.7889 10.6055H46.6678V12.7265H48.7889V10.6055Z" fill="black" />
      <path d="M19.0857 12.7285H16.9646V14.8496H19.0857V12.7285Z" fill="black" />
      <path d="M23.3321 12.7285H21.2111V14.8496H23.3321V12.7285Z" fill="black" />
      <path d="M27.5781 12.7285H25.457V14.8496H27.5781V12.7285Z" fill="black" />
      <path d="M31.8144 12.7285H29.6934V14.8496H31.8144V12.7285Z" fill="black" />
      <path d="M36.0606 12.7285H33.9396V14.8496H36.0606V12.7285Z" fill="black" />
      <path d="M40.2968 12.7285H38.1758V14.8496H40.2968V12.7285Z" fill="black" />
      <path d="M44.5429 12.7285H42.4219V14.8496H44.5429V12.7285Z" fill="black" />
      <path d="M48.7889 12.7285H46.6678V14.8496H48.7889V12.7285Z" fill="black" />
      <path d="M53.0254 12.7285H50.9043V14.8496H53.0254V12.7285Z" fill="black" />
      <path d="M19.0857 14.8518H16.9646V16.9729H19.0857V14.8518Z" fill="black" />
      <path d="M21.209 14.8518H19.0879V16.9729H21.209V14.8518Z" fill="black" />
      <path d="M23.3321 14.8518H21.2111V16.9729H23.3321V14.8518Z" fill="black" />
      <path d="M25.455 14.8518H23.334V16.9729H25.455V14.8518Z" fill="black" />
      <path d="M29.6913 14.8518H27.5702V16.9729H29.6913V14.8518Z" fill="black" />
      <path d="M33.9375 14.8518H31.8164V16.9729H33.9375V14.8518Z" fill="black" />
      <path d="M38.1836 14.8518H36.0625V16.9729H38.1836V14.8518Z" fill="black" />
      <path d="M40.2968 14.8518H38.1758V16.9729H40.2968V14.8518Z" fill="black" />
      <path d="M42.4198 14.8518H40.2987V16.9729H42.4198V14.8518Z" fill="black" />
      <path d="M46.666 14.8518H44.5449V16.9729H46.666V14.8518Z" fill="black" />
      <path d="M48.7889 14.8518H46.6678V16.9729H48.7889V14.8518Z" fill="black" />
      <path d="M53.0254 14.8518H50.9043V16.9729H53.0254V14.8518Z" fill="black" />
      <path d="M6.36728 16.9646H4.24622V19.0857H6.36728V16.9646Z" fill="black" />
      <path d="M8.48019 16.9646H6.35913V19.0857H8.48019V16.9646Z" fill="black" />
      <path d="M10.6034 16.9646H8.4823V19.0857H10.6034V16.9646Z" fill="black" />
      <path d="M14.8496 16.9646H12.7285V19.0857H14.8496V16.9646Z" fill="black" />
      <path d="M19.0857 16.9646H16.9646V19.0857H19.0857V16.9646Z" fill="black" />
      <path d="M23.3321 16.9646H21.2111V19.0857H23.3321V16.9646Z" fill="black" />
      <path d="M25.455 16.9646H23.334V19.0857H25.455V16.9646Z" fill="black" />
      <path d="M27.5781 16.9646H25.457V19.0857H27.5781V16.9646Z" fill="black" />
      <path d="M36.0606 16.9646H33.9396V19.0857H36.0606V16.9646Z" fill="black" />
      <path d="M38.1836 16.9646H36.0625V19.0857H38.1836V16.9646Z" fill="black" />
      <path d="M40.2968 16.9646H38.1758V19.0857H40.2968V16.9646Z" fill="black" />
      <path d="M44.5429 16.9646H42.4219V19.0857H44.5429V16.9646Z" fill="black" />
      <path d="M48.7889 16.9646H46.6678V19.0857H48.7889V16.9646Z" fill="black" />
      <path d="M55.1485 16.9646H53.0275V19.0857H55.1485V16.9646Z" fill="black" />
      <path d="M57.2714 16.9646H55.1504V19.0857H57.2714V16.9646Z" fill="black" />
      <path d="M59.3945 16.9646H57.2734V19.0857H59.3945V16.9646Z" fill="black" />
      <path d="M65.7539 16.9646H63.6328V19.0857H65.7539V16.9646Z" fill="black" />
      <path d="M67.8768 16.9646H65.7557V19.0857H67.8768V16.9646Z" fill="black" />
      <path d="M70 16.9646H67.8789V19.0857H70V16.9646Z" fill="black" />
      <path d="M4.24423 19.0879H2.12317V21.209H4.24423V19.0879Z" fill="black" />
      <path d="M8.48019 19.0879H6.35913V21.209H8.48019V19.0879Z" fill="black" />
      <path d="M12.7265 19.0879H10.6055V21.209H12.7265V19.0879Z" fill="black" />
      <path d="M50.9021 19.0879H48.781V21.209H50.9021V19.0879Z" fill="black" />
      <path d="M53.0254 19.0879H50.9043V21.209H53.0254V19.0879Z" fill="black" />
      <path d="M55.1485 19.0879H53.0275V21.209H55.1485V19.0879Z" fill="black" />
      <path d="M57.2714 19.0879H55.1504V21.209H57.2714V19.0879Z" fill="black" />
      <path d="M63.6308 19.0879H61.5098V21.209H63.6308V19.0879Z" fill="black" />
      <path d="M65.7539 19.0879H63.6328V21.209H65.7539V19.0879Z" fill="black" />
      <path d="M67.8768 19.0879H65.7557V21.209H67.8768V19.0879Z" fill="black" />
      <path d="M70 19.0879H67.8789V21.209H70V19.0879Z" fill="black" />
      <path d="M4.24423 21.2109H2.12317V23.332H4.24423V21.2109Z" fill="black" />
      <path d="M14.8496 21.2109H12.7285V23.332H14.8496V21.2109Z" fill="black" />
      <path d="M16.9727 21.2109H14.8517V23.332H16.9727V21.2109Z" fill="black" />
      <path d="M19.0857 21.2109H16.9646V23.332H19.0857V21.2109Z" fill="black" />
      <path d="M21.209 21.2109H19.0879V23.332H21.209V21.2109Z" fill="black" />
      <path d="M23.3321 21.2109H21.2111V23.332H23.3321V21.2109Z" fill="black" />
      <path d="M48.7889 21.2109H46.6678V23.332H48.7889V21.2109Z" fill="black" />
      <path d="M55.1485 21.2109H53.0275V23.332H55.1485V21.2109Z" fill="black" />
      <path d="M57.2714 21.2109H55.1504V23.332H57.2714V21.2109Z" fill="black" />
      <path d="M59.3945 21.2109H57.2734V23.332H59.3945V21.2109Z" fill="black" />
      <path d="M65.7539 21.2109H63.6328V23.332H65.7539V21.2109Z" fill="black" />
      <path d="M67.8768 21.2109H65.7557V23.332H67.8768V21.2109Z" fill="black" />
      <path d="M4.24423 23.334H2.12317V25.455H4.24423V23.334Z" fill="black" />
      <path d="M8.48019 23.334H6.35913V25.455H8.48019V23.334Z" fill="black" />
      <path d="M12.7265 23.334H10.6055V25.455H12.7265V23.334Z" fill="black" />
      <path d="M19.0857 23.334H16.9646V25.455H19.0857V23.334Z" fill="black" />
      <path d="M21.209 23.334H19.0879V25.455H21.209V23.334Z" fill="black" />
      <path d="M50.9021 23.334H48.781V25.455H50.9021V23.334Z" fill="black" />
      <path d="M57.2714 23.334H55.1504V25.455H57.2714V23.334Z" fill="black" />
      <path d="M65.7539 23.334H63.6328V25.455H65.7539V23.334Z" fill="black" />
      <path d="M67.8768 23.334H65.7557V25.455H67.8768V23.334Z" fill="black" />
      <path d="M2.12106 25.457H0V27.5781H2.12106V25.457Z" fill="black" />
      <path d="M4.24423 25.457H2.12317V27.5781H4.24423V25.457Z" fill="black" />
      <path d="M6.36728 25.457H4.24622V27.5781H6.36728V25.457Z" fill="black" />
      <path d="M8.48019 25.457H6.35913V27.5781H8.48019V25.457Z" fill="black" />
      <path d="M10.6034 25.457H8.4823V27.5781H10.6034V25.457Z" fill="black" />
      <path d="M14.8496 25.457H12.7285V27.5781H14.8496V25.457Z" fill="black" />
      <path d="M19.0857 25.457H16.9646V27.5781H19.0857V25.457Z" fill="black" />
      <path d="M50.9021 25.457H48.781V27.5781H50.9021V25.457Z" fill="black" />
      <path d="M53.0254 25.457H50.9043V27.5781H53.0254V25.457Z" fill="black" />
      <path d="M55.1485 25.457H53.0275V27.5781H55.1485V25.457Z" fill="black" />
      <path d="M61.5077 25.457H59.3866V27.5781H61.5077V25.457Z" fill="black" />
      <path d="M4.24423 27.5703H2.12317V29.6914H4.24423V27.5703Z" fill="black" />
      <path d="M8.48019 27.5703H6.35913V29.6914H8.48019V27.5703Z" fill="black" />
      <path d="M10.6034 27.5703H8.4823V29.6914H10.6034V27.5703Z" fill="black" />
      <path d="M12.7265 27.5703H10.6055V29.6914H12.7265V27.5703Z" fill="black" />
      <path d="M16.9727 27.5703H14.8517V29.6914H16.9727V27.5703Z" fill="black" />
      <path d="M57.2714 27.5703H55.1504V29.6914H57.2714V27.5703Z" fill="black" />
      <path d="M63.6308 27.5703H61.5098V29.6914H63.6308V27.5703Z" fill="black" />
      <path d="M65.7539 27.5703H63.6328V29.6914H65.7539V27.5703Z" fill="black" />
      <path d="M70 27.5703H67.8789V29.6914H70V27.5703Z" fill="black" />
      <path d="M2.12106 29.6934H0V31.8144H2.12106V29.6934Z" fill="black" />
      <path d="M6.36728 29.6934H4.24622V31.8144H6.36728V29.6934Z" fill="black" />
      <path d="M8.48019 29.6934H6.35913V31.8144H8.48019V29.6934Z" fill="black" />
      <path d="M14.8496 29.6934H12.7285V31.8144H14.8496V29.6934Z" fill="black" />
      <path d="M16.9727 29.6934H14.8517V31.8144H16.9727V29.6934Z" fill="black" />
      <path d="M53.0254 29.6934H50.9043V31.8144H53.0254V29.6934Z" fill="black" />
      <path d="M55.1485 29.6934H53.0275V31.8144H55.1485V29.6934Z" fill="black" />
      <path d="M57.2714 29.6934H55.1504V31.8144H57.2714V29.6934Z" fill="black" />
      <path d="M63.6308 29.6934H61.5098V31.8144H63.6308V29.6934Z" fill="black" />
      <path d="M65.7539 29.6934H63.6328V31.8144H65.7539V29.6934Z" fill="black" />
      <path d="M67.8768 29.6934H65.7557V31.8144H67.8768V29.6934Z" fill="black" />
      <path d="M2.12106 31.8164H0V33.9375H2.12106V31.8164Z" fill="black" />
      <path d="M4.24423 31.8164H2.12317V33.9375H4.24423V31.8164Z" fill="black" />
      <path d="M6.36728 31.8164H4.24622V33.9375H6.36728V31.8164Z" fill="black" />
      <path d="M12.7265 31.8164H10.6055V33.9375H12.7265V31.8164Z" fill="black" />
      <path d="M16.9727 31.8164H14.8517V33.9375H16.9727V31.8164Z" fill="black" />
      <path d="M19.0857 31.8164H16.9646V33.9375H19.0857V31.8164Z" fill="black" />
      <path d="M55.1485 31.8164H53.0275V33.9375H55.1485V31.8164Z" fill="black" />
      <path d="M57.2714 31.8164H55.1504V33.9375H57.2714V31.8164Z" fill="black" />
      <path d="M59.3945 31.8164H57.2734V33.9375H59.3945V31.8164Z" fill="black" />
      <path d="M65.7539 31.8164H63.6328V33.9375H65.7539V31.8164Z" fill="black" />
      <path d="M70 31.8164H67.8789V33.9375H70V31.8164Z" fill="black" />
      <path d="M4.24423 33.9395H2.12317V36.0605H4.24423V33.9395Z" fill="black" />
      <path d="M6.36728 33.9395H4.24622V36.0605H6.36728V33.9395Z" fill="black" />
      <path d="M8.48019 33.9395H6.35913V36.0605H8.48019V33.9395Z" fill="black" />
      <path d="M10.6034 33.9395H8.4823V36.0605H10.6034V33.9395Z" fill="black" />
      <path d="M14.8496 33.9395H12.7285V36.0605H14.8496V33.9395Z" fill="black" />
      <path d="M16.9727 33.9395H14.8517V36.0605H16.9727V33.9395Z" fill="black" />
      <path d="M19.0857 33.9395H16.9646V36.0605H19.0857V33.9395Z" fill="black" />
      <path d="M55.1485 33.9395H53.0275V36.0605H55.1485V33.9395Z" fill="black" />
      <path d="M59.3945 33.9395H57.2734V36.0605H59.3945V33.9395Z" fill="black" />
      <path d="M61.5077 33.9395H59.3866V36.0605H61.5077V33.9395Z" fill="black" />
      <path d="M63.6308 33.9395H61.5098V36.0605H63.6308V33.9395Z" fill="black" />
      <path d="M67.8768 33.9395H65.7557V36.0605H67.8768V33.9395Z" fill="black" />
      <path d="M70 33.9395H67.8789V36.0605H70V33.9395Z" fill="black" />
      <path d="M2.12106 36.0625H0V38.1836H2.12106V36.0625Z" fill="black" />
      <path d="M6.36728 36.0625H4.24622V38.1836H6.36728V36.0625Z" fill="black" />
      <path d="M8.48019 36.0625H6.35913V38.1836H8.48019V36.0625Z" fill="black" />
      <path d="M19.0857 36.0625H16.9646V38.1836H19.0857V36.0625Z" fill="black" />
      <path d="M53.0254 36.0625H50.9043V38.1836H53.0254V36.0625Z" fill="black" />
      <path d="M57.2714 36.0625H55.1504V38.1836H57.2714V36.0625Z" fill="black" />
      <path d="M67.8768 36.0625H65.7557V38.1836H67.8768V36.0625Z" fill="black" />
      <path d="M70 36.0625H67.8789V38.1836H70V36.0625Z" fill="black" />
      <path d="M2.12106 38.1758H0V40.2968H2.12106V38.1758Z" fill="black" />
      <path d="M4.24423 38.1758H2.12317V40.2968H4.24423V38.1758Z" fill="black" />
      <path d="M6.36728 38.1758H4.24622V40.2968H6.36728V38.1758Z" fill="black" />
      <path d="M8.48019 38.1758H6.35913V40.2968H8.48019V38.1758Z" fill="black" />
      <path d="M10.6034 38.1758H8.4823V40.2968H10.6034V38.1758Z" fill="black" />
      <path d="M12.7265 38.1758H10.6055V40.2968H12.7265V38.1758Z" fill="black" />
      <path d="M14.8496 38.1758H12.7285V40.2968H14.8496V38.1758Z" fill="black" />
      <path d="M19.0857 38.1758H16.9646V40.2968H19.0857V38.1758Z" fill="black" />
      <path d="M55.1485 38.1758H53.0275V40.2968H55.1485V38.1758Z" fill="black" />
      <path d="M61.5077 38.1758H59.3866V40.2968H61.5077V38.1758Z" fill="black" />
      <path d="M65.7539 38.1758H63.6328V40.2968H65.7539V38.1758Z" fill="black" />
      <path d="M67.8768 38.1758H65.7557V40.2968H67.8768V38.1758Z" fill="black" />
      <path d="M2.12106 40.2988H0V42.4199H2.12106V40.2988Z" fill="black" />
      <path d="M6.36728 40.2988H4.24622V42.4199H6.36728V40.2988Z" fill="black" />
      <path d="M16.9727 40.2988H14.8517V42.4199H16.9727V40.2988Z" fill="black" />
      <path d="M19.0857 40.2988H16.9646V42.4199H19.0857V40.2988Z" fill="black" />
      <path d="M53.0254 40.2988H50.9043V42.4199H53.0254V40.2988Z" fill="black" />
      <path d="M57.2714 40.2988H55.1504V42.4199H57.2714V40.2988Z" fill="black" />
      <path d="M59.3945 40.2988H57.2734V42.4199H59.3945V40.2988Z" fill="black" />
      <path d="M63.6308 40.2988H61.5098V42.4199H63.6308V40.2988Z" fill="black" />
      <path d="M65.7539 40.2988H63.6328V42.4199H65.7539V40.2988Z" fill="black" />
      <path d="M67.8768 40.2988H65.7557V42.4199H67.8768V40.2988Z" fill="black" />
      <path d="M4.24423 42.4219H2.12317V44.5429H4.24423V42.4219Z" fill="black" />
      <path d="M6.36728 42.4219H4.24622V44.5429H6.36728V42.4219Z" fill="black" />
      <path d="M8.48019 42.4219H6.35913V44.5429H8.48019V42.4219Z" fill="black" />
      <path d="M10.6034 42.4219H8.4823V44.5429H10.6034V42.4219Z" fill="black" />
      <path d="M14.8496 42.4219H12.7285V44.5429H14.8496V42.4219Z" fill="black" />
      <path d="M16.9727 42.4219H14.8517V44.5429H16.9727V42.4219Z" fill="black" />
      <path d="M19.0857 42.4219H16.9646V44.5429H19.0857V42.4219Z" fill="black" />
      <path d="M53.0254 42.4219H50.9043V44.5429H53.0254V42.4219Z" fill="black" />
      <path d="M59.3945 42.4219H57.2734V44.5429H59.3945V42.4219Z" fill="black" />
      <path d="M61.5077 42.4219H59.3866V44.5429H61.5077V42.4219Z" fill="black" />
      <path d="M63.6308 42.4219H61.5098V44.5429H63.6308V42.4219Z" fill="black" />
      <path d="M67.8768 42.4219H65.7557V44.5429H67.8768V42.4219Z" fill="black" />
      <path d="M2.12106 44.5449H0V46.666H2.12106V44.5449Z" fill="black" />
      <path d="M6.36728 44.5449H4.24622V46.666H6.36728V44.5449Z" fill="black" />
      <path d="M8.48019 44.5449H6.35913V46.666H8.48019V44.5449Z" fill="black" />
      <path d="M10.6034 44.5449H8.4823V46.666H10.6034V44.5449Z" fill="black" />
      <path d="M16.9727 44.5449H14.8517V46.666H16.9727V44.5449Z" fill="black" />
      <path d="M21.209 44.5449H19.0879V46.666H21.209V44.5449Z" fill="black" />
      <path d="M59.3945 44.5449H57.2734V46.666H59.3945V44.5449Z" fill="black" />
      <path d="M65.7539 44.5449H63.6328V46.666H65.7539V44.5449Z" fill="black" />
      <path d="M67.8768 44.5449H65.7557V46.666H67.8768V44.5449Z" fill="black" />
      <path d="M70 44.5449H67.8789V46.666H70V44.5449Z" fill="black" />
      <path d="M2.12106 46.668H0V48.789H2.12106V46.668Z" fill="black" />
      <path d="M8.48019 46.668H6.35913V48.789H8.48019V46.668Z" fill="black" />
      <path d="M10.6034 46.668H8.4823V48.789H10.6034V46.668Z" fill="black" />
      <path d="M12.7265 46.668H10.6055V48.789H12.7265V46.668Z" fill="black" />
      <path d="M14.8496 46.668H12.7285V48.789H14.8496V46.668Z" fill="black" />
      <path d="M19.0857 46.668H16.9646V48.789H19.0857V46.668Z" fill="black" />
      <path d="M50.9021 46.668H48.781V48.789H50.9021V46.668Z" fill="black" />
      <path d="M53.0254 46.668H50.9043V48.789H53.0254V46.668Z" fill="black" />
      <path d="M59.3945 46.668H57.2734V48.789H59.3945V46.668Z" fill="black" />
      <path d="M65.7539 46.668H63.6328V48.789H65.7539V46.668Z" fill="black" />
      <path d="M67.8768 46.668H65.7557V48.789H67.8768V46.668Z" fill="black" />
      <path d="M2.12106 48.781H0V50.9021H2.12106V48.781Z" fill="black" />
      <path d="M8.48019 48.781H6.35913V50.9021H8.48019V48.781Z" fill="black" />
      <path d="M16.9727 48.781H14.8517V50.9021H16.9727V48.781Z" fill="black" />
      <path d="M19.0857 48.781H16.9646V50.9021H19.0857V48.781Z" fill="black" />
      <path d="M21.209 48.781H19.0879V50.9021H21.209V48.781Z" fill="black" />
      <path d="M25.455 48.781H23.334V50.9021H25.455V48.781Z" fill="black" />
      <path d="M46.666 48.781H44.5449V50.9021H46.666V48.781Z" fill="black" />
      <path d="M48.7889 48.781H46.6678V50.9021H48.7889V48.781Z" fill="black" />
      <path d="M50.9021 48.781H48.781V50.9021H50.9021V48.781Z" fill="black" />
      <path d="M59.3945 48.781H57.2734V50.9021H59.3945V48.781Z" fill="black" />
      <path d="M63.6308 48.781H61.5098V50.9021H63.6308V48.781Z" fill="black" />
      <path d="M65.7539 48.781H63.6328V50.9021H65.7539V48.781Z" fill="black" />
      <path d="M67.8768 48.781H65.7557V50.9021H67.8768V48.781Z" fill="black" />
      <path d="M70 48.781H67.8789V50.9021H70V48.781Z" fill="black" />
      <path d="M2.12106 50.9043H0V53.0254H2.12106V50.9043Z" fill="black" />
      <path d="M6.36728 50.9043H4.24622V53.0254H6.36728V50.9043Z" fill="black" />
      <path d="M12.7265 50.9043H10.6055V53.0254H12.7265V50.9043Z" fill="black" />
      <path d="M14.8496 50.9043H12.7285V53.0254H14.8496V50.9043Z" fill="black" />
      <path d="M19.0857 50.9043H16.9646V53.0254H19.0857V50.9043Z" fill="black" />
      <path d="M21.209 50.9043H19.0879V53.0254H21.209V50.9043Z" fill="black" />
      <path d="M25.455 50.9043H23.334V53.0254H25.455V50.9043Z" fill="black" />
      <path d="M29.6913 50.9043H27.5702V53.0254H29.6913V50.9043Z" fill="black" />
      <path d="M33.9375 50.9043H31.8164V53.0254H33.9375V50.9043Z" fill="black" />
      <path d="M40.2968 50.9043H38.1758V53.0254H40.2968V50.9043Z" fill="black" />
      <path d="M42.4198 50.9043H40.2987V53.0254H42.4198V50.9043Z" fill="black" />
      <path d="M48.7889 50.9043H46.6678V53.0254H48.7889V50.9043Z" fill="black" />
      <path d="M50.9021 50.9043H48.781V53.0254H50.9021V50.9043Z" fill="black" />
      <path d="M53.0254 50.9043H50.9043V53.0254H53.0254V50.9043Z" fill="black" />
      <path d="M55.1485 50.9043H53.0275V53.0254H55.1485V50.9043Z" fill="black" />
      <path d="M57.2714 50.9043H55.1504V53.0254H57.2714V50.9043Z" fill="black" />
      <path d="M59.3945 50.9043H57.2734V53.0254H59.3945V50.9043Z" fill="black" />
      <path d="M61.5077 50.9043H59.3866V53.0254H61.5077V50.9043Z" fill="black" />
      <path d="M63.6308 50.9043H61.5098V53.0254H63.6308V50.9043Z" fill="black" />
      <path d="M19.0857 53.0273H16.9646V55.1484H19.0857V53.0273Z" fill="black" />
      <path d="M21.209 53.0273H19.0879V55.1484H21.209V53.0273Z" fill="black" />
      <path d="M25.455 53.0273H23.334V55.1484H25.455V53.0273Z" fill="black" />
      <path d="M27.5781 53.0273H25.457V55.1484H27.5781V53.0273Z" fill="black" />
      <path d="M31.8144 53.0273H29.6934V55.1484H31.8144V53.0273Z" fill="black" />
      <path d="M33.9375 53.0273H31.8164V55.1484H33.9375V53.0273Z" fill="black" />
      <path d="M36.0606 53.0273H33.9396V55.1484H36.0606V53.0273Z" fill="black" />
      <path d="M42.4198 53.0273H40.2987V55.1484H42.4198V53.0273Z" fill="black" />
      <path d="M46.666 53.0273H44.5449V55.1484H46.666V53.0273Z" fill="black" />
      <path d="M53.0254 53.0273H50.9043V55.1484H53.0254V53.0273Z" fill="black" />
      <path d="M61.5077 53.0273H59.3866V55.1484H61.5077V53.0273Z" fill="black" />
      <path d="M63.6308 53.0273H61.5098V55.1484H63.6308V53.0273Z" fill="black" />
      <path d="M70 53.0273H67.8789V55.1484H70V53.0273Z" fill="black" />
      <path d="M21.209 55.1504H19.0879V57.2714H21.209V55.1504Z" fill="black" />
      <path d="M29.6913 55.1504H27.5702V57.2714H29.6913V55.1504Z" fill="black" />
      <path d="M46.666 55.1504H44.5449V57.2714H46.666V55.1504Z" fill="black" />
      <path d="M50.9021 55.1504H48.781V57.2714H50.9021V55.1504Z" fill="black" />
      <path d="M53.0254 55.1504H50.9043V57.2714H53.0254V55.1504Z" fill="black" />
      <path d="M57.2714 55.1504H55.1504V57.2714H57.2714V55.1504Z" fill="black" />
      <path d="M61.5077 55.1504H59.3866V57.2714H61.5077V55.1504Z" fill="black" />
      <path d="M67.8768 55.1504H65.7557V57.2714H67.8768V55.1504Z" fill="black" />
      <path d="M27.5781 57.2734H25.457V59.3945H27.5781V57.2734Z" fill="black" />
      <path d="M31.8144 57.2734H29.6934V59.3945H31.8144V57.2734Z" fill="black" />
      <path d="M33.9375 57.2734H31.8164V59.3945H33.9375V57.2734Z" fill="black" />
      <path d="M36.0606 57.2734H33.9396V59.3945H36.0606V57.2734Z" fill="black" />
      <path d="M38.1836 57.2734H36.0625V59.3945H38.1836V57.2734Z" fill="black" />
      <path d="M42.4198 57.2734H40.2987V59.3945H42.4198V57.2734Z" fill="black" />
      <path d="M44.5429 57.2734H42.4219V59.3945H44.5429V57.2734Z" fill="black" />
      <path d="M46.666 57.2734H44.5449V59.3945H46.666V57.2734Z" fill="black" />
      <path d="M48.7889 57.2734H46.6678V59.3945H48.7889V57.2734Z" fill="black" />
      <path d="M53.0254 57.2734H50.9043V59.3945H53.0254V57.2734Z" fill="black" />
      <path d="M61.5077 57.2734H59.3866V59.3945H61.5077V57.2734Z" fill="black" />
      <path d="M63.6308 57.2734H61.5098V59.3945H63.6308V57.2734Z" fill="black" />
      <path d="M65.7539 57.2734H63.6328V59.3945H65.7539V57.2734Z" fill="black" />
      <path d="M67.8768 57.2734H65.7557V59.3945H67.8768V57.2734Z" fill="black" />
      <path d="M70 57.2734H67.8789V59.3945H70V57.2734Z" fill="black" />
      <path d="M19.0857 59.3865H16.9646V61.5075H19.0857V59.3865Z" fill="black" />
      <path d="M33.9375 59.3865H31.8164V61.5075H33.9375V59.3865Z" fill="black" />
      <path d="M38.1836 59.3865H36.0625V61.5075H38.1836V59.3865Z" fill="black" />
      <path d="M40.2968 59.3865H38.1758V61.5075H40.2968V59.3865Z" fill="black" />
      <path d="M46.666 59.3865H44.5449V61.5075H46.666V59.3865Z" fill="black" />
      <path d="M53.0254 59.3865H50.9043V61.5075H53.0254V59.3865Z" fill="black" />
      <path d="M55.1485 59.3865H53.0275V61.5075H55.1485V59.3865Z" fill="black" />
      <path d="M57.2714 59.3865H55.1504V61.5075H57.2714V59.3865Z" fill="black" />
      <path d="M59.3945 59.3865H57.2734V61.5075H59.3945V59.3865Z" fill="black" />
      <path d="M61.5077 59.3865H59.3866V61.5075H61.5077V59.3865Z" fill="black" />
      <path d="M67.8768 59.3865H65.7557V61.5075H67.8768V59.3865Z" fill="black" />
      <path d="M70 59.3865H67.8789V61.5075H70V59.3865Z" fill="black" />
      <path d="M19.0857 61.5098H16.9646V63.6308H19.0857V61.5098Z" fill="black" />
      <path d="M21.209 61.5098H19.0879V63.6308H21.209V61.5098Z" fill="black" />
      <path d="M23.3321 61.5098H21.2111V63.6308H23.3321V61.5098Z" fill="black" />
      <path d="M29.6913 61.5098H27.5702V63.6308H29.6913V61.5098Z" fill="black" />
      <path d="M31.8144 61.5098H29.6934V63.6308H31.8144V61.5098Z" fill="black" />
      <path d="M33.9375 61.5098H31.8164V63.6308H33.9375V61.5098Z" fill="black" />
      <path d="M38.1836 61.5098H36.0625V63.6308H38.1836V61.5098Z" fill="black" />
      <path d="M40.2968 61.5098H38.1758V63.6308H40.2968V61.5098Z" fill="black" />
      <path d="M44.5429 61.5098H42.4219V63.6308H44.5429V61.5098Z" fill="black" />
      <path d="M48.7889 61.5098H46.6678V63.6308H48.7889V61.5098Z" fill="black" />
      <path d="M50.9021 61.5098H48.781V63.6308H50.9021V61.5098Z" fill="black" />
      <path d="M53.0254 61.5098H50.9043V63.6308H53.0254V61.5098Z" fill="black" />
      <path d="M55.1485 61.5098H53.0275V63.6308H55.1485V61.5098Z" fill="black" />
      <path d="M61.5077 61.5098H59.3866V63.6308H61.5077V61.5098Z" fill="black" />
      <path d="M65.7539 61.5098H63.6328V63.6308H65.7539V61.5098Z" fill="black" />
      <path d="M67.8768 61.5098H65.7557V63.6308H67.8768V61.5098Z" fill="black" />
      <path d="M19.0857 63.6328H16.9646V65.7539H19.0857V63.6328Z" fill="black" />
      <path d="M23.3321 63.6328H21.2111V65.7539H23.3321V63.6328Z" fill="black" />
      <path d="M25.455 63.6328H23.334V65.7539H25.455V63.6328Z" fill="black" />
      <path d="M27.5781 63.6328H25.457V65.7539H27.5781V63.6328Z" fill="black" />
      <path d="M29.6913 63.6328H27.5702V65.7539H29.6913V63.6328Z" fill="black" />
      <path d="M31.8144 63.6328H29.6934V65.7539H31.8144V63.6328Z" fill="black" />
      <path d="M38.1836 63.6328H36.0625V65.7539H38.1836V63.6328Z" fill="black" />
      <path d="M44.5429 63.6328H42.4219V65.7539H44.5429V63.6328Z" fill="black" />
      <path d="M46.666 63.6328H44.5449V65.7539H46.666V63.6328Z" fill="black" />
      <path d="M50.9021 63.6328H48.781V65.7539H50.9021V63.6328Z" fill="black" />
      <path d="M55.1485 63.6328H53.0275V65.7539H55.1485V63.6328Z" fill="black" />
      <path d="M57.2714 63.6328H55.1504V65.7539H57.2714V63.6328Z" fill="black" />
      <path d="M63.6308 63.6328H61.5098V65.7539H63.6308V63.6328Z" fill="black" />
      <path d="M23.3321 65.7559H21.2111V67.8769H23.3321V65.7559Z" fill="black" />
      <path d="M27.5781 65.7559H25.457V67.8769H27.5781V65.7559Z" fill="black" />
      <path d="M38.1836 65.7559H36.0625V67.8769H38.1836V65.7559Z" fill="black" />
      <path d="M46.666 65.7559H44.5449V67.8769H46.666V65.7559Z" fill="black" />
      <path d="M59.3945 65.7559H57.2734V67.8769H59.3945V65.7559Z" fill="black" />
      <path d="M65.7539 65.7559H63.6328V67.8769H65.7539V65.7559Z" fill="black" />
      <path d="M27.5781 67.8789H25.457V70H27.5781V67.8789Z" fill="black" />
      <path d="M29.6913 67.8789H27.5702V70H29.6913V67.8789Z" fill="black" />
      <path d="M33.9375 67.8789H31.8164V70H33.9375V67.8789Z" fill="black" />
      <path d="M36.0606 67.8789H33.9396V70H36.0606V67.8789Z" fill="black" />
      <path d="M38.1836 67.8789H36.0625V70H38.1836V67.8789Z" fill="black" />
      <path d="M42.4198 67.8789H40.2987V70H42.4198V67.8789Z" fill="black" />
      <path d="M46.666 67.8789H44.5449V70H46.666V67.8789Z" fill="black" />
      <path d="M50.9021 67.8789H48.781V70H50.9021V67.8789Z" fill="black" />
      <path d="M53.0254 67.8789H50.9043V70H53.0254V67.8789Z" fill="black" />
      <path d="M55.1485 67.8789H53.0275V70H55.1485V67.8789Z" fill="black" />
      <path d="M63.6308 67.8789H61.5098V70H63.6308V67.8789Z" fill="black" />
      <path d="M67.8768 67.8789H65.7557V70H67.8768V67.8789Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 35C48.9553 39.4336 47.9451 43.1859 45.5402 45.5398C43.1801 47.9469 39.4342 48.9558 35 49C30.5657 48.9558 26.8199 47.9469 24.4598 45.5398C22.0549 43.1858 21.0447 39.4336 21 35C21.0447 30.5664 22.0549 26.8142 24.4598 24.4602C26.8199 22.0531 30.5658 21.0442 35 21C39.4342 21.0442 43.18 22.0531 45.5402 24.4602C47.9361 26.8142 48.9553 30.5664 49 35ZM36.198 35C33.9809 32.6283 31.8174 30.3274 29.645 28.0177C28.8493 28.6372 28.6616 28.7876 27.9106 29.3628C27.9106 29.3628 29.8327 32.5487 30.8072 34.0708C31.0396 34.4425 31.1827 34.7257 31.2095 35C31.1916 35.2743 31.0486 35.5575 30.8072 35.9292C29.8417 37.4513 27.9106 40.6372 27.9106 40.6372C28.6526 41.2124 28.8404 41.3628 29.645 41.9823C31.8174 39.6726 33.9809 37.3717 36.198 35ZM43.8953 35C41.5173 32.4602 39.7025 30.531 37.3423 28.0177C36.5467 28.6372 36.3589 28.7876 35.6079 29.3628C35.6079 29.3628 37.53 32.5487 38.5045 34.0708C38.7369 34.4425 38.88 34.7257 38.9068 35C38.8889 35.2743 38.7459 35.5575 38.5045 35.9292C37.539 37.4513 35.6079 40.6372 35.6079 40.6372C36.35 41.2124 36.5377 41.3628 37.3423 41.9823C39.5058 39.6726 41.6693 37.3717 43.8953 35Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.375 2.1175H3.5C2.73647 2.1175 2.1175 2.73647 2.1175 3.5V11.375C2.1175 12.1385 2.73647 12.7575 3.5 12.7575H11.375C12.1385 12.7575 12.7575 12.1385 12.7575 11.375V3.5C12.7575 2.73647 12.1385 2.1175 11.375 2.1175ZM3.5 0C1.567 0 0 1.567 0 3.5V11.375C0 13.308 1.567 14.875 3.5 14.875H11.375C13.308 14.875 14.875 13.308 14.875 11.375V3.5C14.875 1.567 13.308 0 11.375 0H3.5Z"
        fill="black"
      />
      <path
        d="M3.9375 5.6875C3.9375 4.721 4.721 3.9375 5.6875 3.9375H9.1875C10.154 3.9375 10.9375 4.721 10.9375 5.6875V9.1875C10.9375 10.154 10.154 10.9375 9.1875 10.9375H5.6875C4.721 10.9375 3.9375 10.154 3.9375 9.1875V5.6875Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.375 57.2425H3.5C2.73647 57.2425 2.1175 57.8615 2.1175 58.625V66.5C2.1175 67.2635 2.73647 67.8825 3.5 67.8825H11.375C12.1385 67.8825 12.7575 67.2635 12.7575 66.5V58.625C12.7575 57.8615 12.1385 57.2425 11.375 57.2425ZM3.5 55.125C1.567 55.125 0 56.692 0 58.625V66.5C0 68.433 1.567 70 3.5 70H11.375C13.308 70 14.875 68.433 14.875 66.5V58.625C14.875 56.692 13.308 55.125 11.375 55.125H3.5Z"
        fill="black"
      />
      <path
        d="M3.9375 60.8125C3.9375 59.846 4.721 59.0625 5.6875 59.0625H9.1875C10.154 59.0625 10.9375 59.846 10.9375 60.8125V64.3125C10.9375 65.279 10.154 66.0625 9.1875 66.0625H5.6875C4.721 66.0625 3.9375 65.279 3.9375 64.3125V60.8125Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.5 2.1175H58.625C57.8615 2.1175 57.2425 2.73647 57.2425 3.5V11.375C57.2425 12.1385 57.8615 12.7575 58.625 12.7575H66.5C67.2635 12.7575 67.8825 12.1385 67.8825 11.375V3.5C67.8825 2.73647 67.2635 2.1175 66.5 2.1175ZM58.625 0C56.692 0 55.125 1.567 55.125 3.5V11.375C55.125 13.308 56.692 14.875 58.625 14.875H66.5C68.433 14.875 70 13.308 70 11.375V3.5C70 1.567 68.433 0 66.5 0H58.625Z"
        fill="black"
      />
      <path
        d="M59.0625 5.6875C59.0625 4.721 59.846 3.9375 60.8125 3.9375H64.3125C65.279 3.9375 66.0625 4.721 66.0625 5.6875V9.1875C66.0625 10.154 65.279 10.9375 64.3125 10.9375H60.8125C59.846 10.9375 59.0625 10.154 59.0625 9.1875V5.6875Z"
        fill="black"
      />
    </svg>
  );

QRCode.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW,
  ]),
};

QRCode.defaultProps = {
  version: SUCCESS_DIALOG_VERSION.DEFAULT,
};

export default QRCode;
