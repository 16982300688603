import React, { PureComponent } from 'react';
import AltContainer from 'alt-container';
import { STATUS_INITIAL } from 'app-constants';
import AuthStore from 'stores/AuthStore';
import ChannelsStore from 'stores/ChannelsStore';
import ChannelActions from 'actions/ChannelActions';
import TimelineStore from 'stores/TimelineStore';
import NewsStandStore from 'stores/NewsStandStore';
import { get } from 'lodash';
import TimelineNavigation from './components/TimelineNavigation';

function fetchChannels() {
  if (ChannelsStore.getState().channels.status === STATUS_INITIAL) {
    ChannelActions.fetchChannels(AuthStore.getState().user.id);
  }
}

export default class TimelineNavigationContainer extends PureComponent {
  componentDidMount() {
    fetchChannels();
  }

  componentDidUpdate() {
    fetchChannels();
  }

  renderNavigation = ({ timelineState, channelsSate, newsStandState }) => {
    const { activeTimelineKey, timelines } = timelineState;
    const timeline = timelines.get(activeTimelineKey);

    if (!timeline) {
      return null;
    }

    return (
      <TimelineNavigation
        timeline={timeline}
        channels={channelsSate.channels.data}
        kioskCategories={get(newsStandState, 'newsStand.categories', [])}
      />
    );
  };

  render() {
    return (
      <AltContainer
        stores={{
          channelsSate: ChannelsStore,
          timelineState: TimelineStore,
          newsStandState: NewsStandStore,
        }}
        render={this.renderNavigation}
      />
    );
  }
}
