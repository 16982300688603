import R from 'ramda';
import Environment from 'environment';
import {
  PREMIUM_YEARLY_PRODUCT,
  PREMIUM_MONTHLY_PRODUCT,
  PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL,
  PREMIUM_MIGRATION_YEARLY_PAID_PER_MONTH,
} from 'app-constants';
import { getUtmString, getRefreshTokenIfDevelopmentEnv, buildQueryString } from './helpers';

export const supportedSubscriptionUids = [
  PREMIUM_YEARLY_PRODUCT,
  PREMIUM_MONTHLY_PRODUCT,
  PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL,
  PREMIUM_MIGRATION_YEARLY_PAID_PER_MONTH,
];

/**
 * @param {Object} config
 * @param {string} [config.successPath]
 * @param {string} [config.searchParams]
 * @param {string} [config.couponCode]
 * @param {string} [config.upgradeCouponCode]
 * @param {boolean} [config.autoApply]
 */
function getSearchParams({
  successPath = '/home/success',
  searchParams = '',
  couponCode,
  upgradeCouponCode,
  autoApply,
}) {
  const utmString = getUtmString();
  const refreshToken = getRefreshTokenIfDevelopmentEnv();

  const searchParamsFragments = [
    searchParams,
    `successPath=${encodeURIComponent(successPath)}`,
    refreshToken && `refreshToken=${refreshToken}`,
    couponCode && `couponCode=${couponCode}`,
    upgradeCouponCode && `upgradeCouponCode=${upgradeCouponCode}`,
    autoApply && `autoApply=${autoApply}`,
    utmString,
  ];

  return buildQueryString(searchParamsFragments.filter(Boolean));
}

export const validatePaymentPath = path =>
  R.includes(path, ['/start-with', '/try-now', '/switch-to-premium']) ? path : '/start-with';

export const getValidProductId = subscriptionType =>
  R.includes(subscriptionType, supportedSubscriptionUids)
    ? subscriptionType
    : PREMIUM_MONTHLY_PRODUCT;

const isPremiumMonthlyReactivation = R.equals(PREMIUM_THIRTY_DAYS_REACTIVATION_AUTO_RENEWAL);

export function getProductPath(productId) {
  if (!productId) {
    return '';
  }

  const validProductId = getValidProductId(productId);

  // for the Premium Monthly Reaction product we need to map the product ID to
  // a more user friendly display_id that is used by web-payment
  return isPremiumMonthlyReactivation(validProductId)
    ? '/blendlepremium_monthly_reactivation'
    : `/${validProductId}`;
}

export const AllowedPaymentMethods = {
  Card: 'card',
  Ideal: 'ideal',
  SepaDebit: 'sepa_debit',
};

const isAllowedPaymentMethod = R.anyPass([
  R.equals(AllowedPaymentMethods.Card),
  R.equals(AllowedPaymentMethods.Ideal),
  R.equals(AllowedPaymentMethods.SepaDebit),
]);

export function getPaymentMethodPath({ productPath, paymentMethod }) {
  if (!productPath || R.isEmpty(productPath) || !paymentMethod) {
    return '';
  }

  if (!isAllowedPaymentMethod(paymentMethod)) {
    throw new Error(`Payment method '${paymentMethod}' is not allowed`);
  }

  return `/${paymentMethod}`;
}

/**
 * @param {Object} config
 * @param {string} [config.paymentPath]
 * @param {string} [config.successPath]
 * @param {string} [config.productId]
 * @param {string} [config.paymentMethod]
 * @param {string} [config.couponCode]
 * @param {string} [config.upgradeCouponCode]
 * @param {boolean} [config.autoApply]
 */
export function getSubscriptionPaymentPageUri({
  paymentPath,
  successPath,
  productId,
  paymentMethod,
  upgradeCouponCode,
  couponCode,
  autoApply,
} = {}) {
  const validPaymentPath = validatePaymentPath(paymentPath);

  const searchParams = getSearchParams({
    successPath,
    couponCode,
    upgradeCouponCode,
    autoApply,
  });

  const productPath = getProductPath(productId);

  return [
    Environment.paymentUri,
    validPaymentPath,
    productPath,
    getPaymentMethodPath({ productPath, paymentMethod }),
    searchParams,
  ].join('');
}

/**
 * To create the uri for the payment project after a magic login.
 * Since you can be redirected to any page inside the project the path is not validated here.
 * @param {string} pathname
 * @param {object} query
 */
export function getPaymentPageUriForMagicLogin(pathname, query) {
  return `${Environment.paymentUri}${pathname}${getSearchParams({
    ...query,
  })}`;
}
