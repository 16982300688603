import React from 'react';
import classNames from 'classnames';
import { translate } from 'instances/i18n';
import CSS from './styles.scss';
import ConsentNeededHint from './ConsentNeededHint';

const CookieConsentNeeded = () => {
  return (
    <>
      <div className={classNames(['btn', 'btn-text', 'btn-facebook', CSS.disabled])}>
        {translate('app.error.facebook_unavailable')}
      </div>
      <ConsentNeededHint />
    </>
  );
};

export default CookieConsentNeeded;
