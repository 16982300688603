import React from 'react';
import { node, string, oneOf } from 'prop-types';
import Headroom from 'react-headroom';
import { NAVIGATION_BAR_TYPE } from 'app-constants';
import classNames from 'classnames';
import CSS from './style.scss';

const getClassName = (navigationBarType) => {
  switch (navigationBarType) {
    case NAVIGATION_BAR_TYPE.PREMIUM_ONLY:
      return CSS.premiumOnly;
    case NAVIGATION_BAR_TYPE.PREMIUM_AND_MICROPAYMENTS:
      return CSS.premiumAndMicropayments;
    default:
      return CSS.micropayments;
  }
};

const HideOnScroll = ({ children, className, navigationBarType }) => (
  <Headroom
    upTolerance={10}
    downTolerance={10}
    className={classNames(getClassName(navigationBarType), className)}
  >
    {children}
  </Headroom>
);

HideOnScroll.propTypes = {
  children: node,
  className: string,
  navigationBarType: oneOf([
    NAVIGATION_BAR_TYPE.MICROPAYMENTS,
    NAVIGATION_BAR_TYPE.PREMIUM_AND_MICROPAYMENTS,
    NAVIGATION_BAR_TYPE.PREMIUM_ONLY,
  ]).isRequired,
};

HideOnScroll.defaultProps = {
  children: null,
  className: undefined,
};

export default HideOnScroll;
