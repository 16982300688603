import { Observable } from 'apollo-boost';

export const promiseToObservable = promise =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) {
          return;
        }

        subscriber.next(value);
        subscriber.complete();
      },
      err => {
        subscriber.error(err);
      },
    );
  });
