import { getItem, removeItem } from 'helpers/sessionStorage';

const LOCATION_IN_LAYOUT_KEY = 'LOCATION_IN_LAYOUT_KEY';

export function getLocationInLayout() {
  return getItem(LOCATION_IN_LAYOUT_KEY);
}

export function resetLocationInLayout() {
  return removeItem(LOCATION_IN_LAYOUT_KEY);
}
