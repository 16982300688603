import { history } from 'byebye';
import { asyncRoute } from 'helpers/routerHelpers';

function onEnter() {
  document.body.classList.add('m-dialogue');
}

function onLeave() {
  document.body.classList.remove('m-dialogue');
}

export default [
  {
    module: 'dialogue',
    path: 'issue-acquired/:issueId',
    onEnter,
    onLeave,
    getComponent: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          nextState.returnUrl = nextState.returnUrl || history.getPrevious(); // eslint-disable-line no-param-reassign
          const mod = require('./module');
          cb(null, {
            dialogue: () => mod.openIssueAcquired(nextState.params.issueId, nextState),
          });
        },
        'dialogue',
      );
    }),
  },
  {
    module: 'dialogue',
    path: 'unsubscribe-newsletter/:optOutType',
    onEnter,
    onLeave,
    requireAuth: false,
    getComponent: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          nextState.returnUrl = nextState.returnUrl || history.getPrevious(); // eslint-disable-line no-param-reassign
          const mod = require('./module');
          cb(null, {
            dialogue: () => mod.openUnsubscribeNewsletter(nextState.params.optOutType, nextState),
          });
        },
        'dialogue',
      );
    }),
  },
];
