// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".section-1MYsg{margin-bottom:72px}.title-2aIoc{color:#333;font-family:\"GT-Walsheim\",sans-serif;font-size:30px;letter-spacing:-0.02em;text-align:center;width:100%;margin-bottom:24px}\n", ""]);
// Exports
exports.locals = {
	"section": "section-1MYsg",
	"title": "title-2aIoc"
};
module.exports = exports;
