export const VIDEO_ACTION = {
  CONFETTI_REQUESTED: 'CONFETTI_REQUESTED',
  AUDIO_TOGGLED: 'AUDIO_TOGGLED',
  VIDEO_PAUSED: 'VIDEO_PAUSED',
  AUTO_PLAY_FAILED: 'AUTO_PLAY_FAILED',
  USER_STARTED_VIDEO: 'USER_STARTED_VIDEO',
};

export const initialState = {
  showPlayButton: false,
  showMoreConfettiButton: false,
  isAudioMuted: false,
  currentSectionIndex: 0,
};

export function reducer(state, action) {
  switch (action.type) {
    case VIDEO_ACTION.AUTO_PLAY_FAILED:
      return {
        ...state,
        showPlayButton: true,
      };
    case VIDEO_ACTION.USER_STARTED_VIDEO:
      return {
        ...state,
        showPlayButton: false,
      };
    case VIDEO_ACTION.VIDEO_PAUSED:
      return {
        ...state,
        showMoreConfettiButton: true,
      };
    case VIDEO_ACTION.CONFETTI_REQUESTED:
      return {
        ...state,
        showMoreConfettiButton: false,
        currentSectionIndex: state.currentSectionIndex + 1,
      };
    case VIDEO_ACTION.AUDIO_TOGGLED:
      return {
        ...state,
        isAudioMuted: !state.isAudioMuted,
      };
    default:
      throw new Error(`Unknown VIDEO_ACITON: ${action.type}`);
  }
}
