import alt from 'instances/altInstance';
import SyncActions from '../actions/SyncActions';

const initialState = {
  actions: [],
};

class SyncStore {
  constructor() {
    this.bindActions(SyncActions);
    this.state = initialState;
  }

  onAddAction(action) {
    this.setState((currentState) => ({
      actions: [...currentState.actions, action],
    }));
  }
}

export default alt.createStore(SyncStore, 'SyncStore');
