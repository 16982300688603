import environment from 'environment';
import doubleclick from './doubleclick';

function trackExistingUsers(user) {
  if (user.get('ad_retargeting_opt_out') || !user.hasActivePremiumSubscription()) {
    return;
  }

  doubleclick.track('conversion', {
    allow_custom_scripts: true,
    send_to: environment.doubleclick_existing_user_id,
  });
}

export default trackExistingUsers;
