/* eslint-disable global-require */
import { asyncRoute } from 'helpers/routerHelpers';
import { FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS } from 'app-constants';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { capabilityIsLockedAsync } from 'selectors/capabilities';

const onEnter = (_, replace, callback) => {
  capabilityIsLockedAsync(FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS).then((kioskIsLocked) => {
    if (kioskIsLocked) {
      replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_HAS_BRAND_AND_TOPICS));
    }

    callback();
  });
};

function routeRecentIssues(path) {
  return {
    module: 'discoverIssue',
    path,
    onEnter,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/RecentIssuesRootContainer'),
          });
        },
        'discoverIssue',
      );
    }),
  };
}

function routeProviderIssues(path) {
  return {
    module: 'discoverIssue',
    path,
    onEnter,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/ProviderIssuesRootContainer'),
          });
        },
        'discoverIssue',
      );
    }),
  };
}

function onEnterIssue(_, replace, callback) {
  const discoverNavigationActions = require('actions/DiscoverNavigationActions');
  discoverNavigationActions.setIssuePageActive();

  onEnter(_, replace, callback);
}

function onLeaveIssue() {
  const discoverNavigationActions = require('actions/DiscoverNavigationActions');
  discoverNavigationActions.setIssuePageInactive();
}

function routeIssue(path) {
  return {
    module: 'discoverIssue',
    path,
    onEnter: onEnterIssue,
    onLeave: onLeaveIssue,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/IssueRootContainer'),
          });
        },
        'discoverIssue',
      );
    }),
  };
}

export default [
  routeRecentIssues('discover/issue'),
  routeProviderIssues('discover/issue/:graphQLProviderId'),
  routeIssue('discover/issue/:graphQLProviderId/:graphQLIssueId'),
];
