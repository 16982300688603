import alt from 'instances/altInstance';
import PaymentManager from 'managers/payment';
import Auth from 'controllers/auth';
import { history } from 'byebye';

export default alt.createActions({
  resetState: () => true,

  setActiveStep: x => x,

  setAmount(amount) {
    return parseInt(amount);
  },

  /**
   * Set the value for the payment dialogue (don't send request)
   */
  setRecurringRequestContract(enabled) {
    return { recurringRequest: enabled };
  },

  topUp(amount, recurringContract, isRecurringRequest, user) {
    PaymentManager.directTopUp(
      {
        amount,
        recurringContractId: recurringContract.id,
        recurring: isRecurringRequest,
      },
      user.id,
    )
      .then(this.topUpSuccess)
      .catch(errorMessage => {
        this.topUpError(errorMessage);

        throw errorMessage;
      });

    return null;
  },

  topUpSuccess(result) {
    // Refresh for new embedded user with updated balance
    Auth.renewJWT().then(() =>
      history.navigate('/payment/success', { trigger: true, replace: true }),
    );

    return result;
  },

  topUpError(errorMessage) {
    history.navigate('/payment/cancelled', { trigger: true });

    return errorMessage;
  },
});
