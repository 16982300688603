import { get, unescape } from 'lodash';
import moment from 'moment';
import alt from 'instances/altInstance';
import windowOpen from 'helpers/windowOpen';
import { translate } from 'instances/i18n';
import Facebook from 'instances/facebook';
import Twitter from 'instances/twitter';
import Analytics from 'instances/analytics';
import SharingManager from 'managers/sharing';
import TileActions from 'actions/TileActions';
import { getContentAsText, getTitle, getManifestBody, getAnIntro } from 'helpers/manifest';
import { providerById, prefillSelector } from 'selectors/providers';
import { createItemUriFromManifest } from 'helpers/prettyUrl';
import { LINKEDIN_LINK, XHR_STATUS, SHARING_PLATFORM, SYNC_ACTION_TYPE } from 'app-constants';
import { sprintf } from 'sprintf-js';
import { getFeaturedImage } from 'selectors/manifest';
import { getShareItemUrl } from 'helpers/shareItemUrl';
import { getManifest } from 'selectors/tiles';
import TilesStore from 'stores/TilesStore';
import SyncActions from './SyncActions';

function trackEvent(eventName, itemId) {
  const { tiles } = TilesStore.getState();
  const manifest = getManifest(tiles, itemId);

  Analytics.track(eventName, {
    platform: 'blendle',
    provider: manifest.provider.id,
    item_id: itemId,
    item_title: getTitle(getManifestBody(manifest)),
  });
}

class ShareActions {
  constructor() {
    this.generateActions(
      'shareItemToEmailSuccess',
      'shareItemToEmailError',
      'loadPlatform',
      'loadPlatformSuccess',
      'resetStatus',
    );
  }

  loadPlatforms() {
    this.loadPlatform(SHARING_PLATFORM.TWITTER);
    this.loadPlatform(SHARING_PLATFORM.FACEBOOK);

    Twitter.load()
      .then(() => this.loadPlatformSuccess(SHARING_PLATFORM.TWITTER))
      .catch(() => null);

    Facebook.load()
      .then(() => this.loadPlatformSuccess(SHARING_PLATFORM.FACEBOOK))
      .catch(() => null);

    return null;
  }

  shareItemToPlatform(platform, manifest, incomingAnalytics, user, extraOptions) {
    const providerName = prefillSelector(providerById)(manifest.provider.id).name;
    const body = getManifestBody(manifest);

    const url = getShareItemUrl({
      userId: user.id,
      path: createItemUriFromManifest(manifest),
      bnlId: manifest.id,
      utmData: {
        utm_medium: platform,
        utm_campaign: 'social-share',
        utm_source: 'blendle',
      },
    });

    const { date, id } = manifest;

    const analytics = {
      item_id: id,
      ...incomingAnalytics,
    };

    // Medium size should always be available if there are images
    const featuredImage = getFeaturedImage(manifest);
    const image = featuredImage ? get(featuredImage, '_links.medium.href') : undefined;

    switch (platform) {
      case SHARING_PLATFORM.TWITTER:
        return this.shareItemToTwitter({
          providerName,
          body,
          url,
          analytics,
          shareMessage: extraOptions ? extraOptions.shareMessage : undefined,
        });
      case SHARING_PLATFORM.FACEBOOK:
        return this.shareItemToFacebook({
          providerName,
          body,
          url,
          analytics,
          image,
          date,
        });
      case SHARING_PLATFORM.WHATSAPP:
        return this.shareItemToWhatsApp({ providerName, body, url, analytics });
      case SHARING_PLATFORM.LINKEDIN:
        return this.shareItemToLinkedIn({ providerName, body, url, analytics });
      default:
        return null;
    }
  }

  shareItemToFacebook({ providerName, body, url, analytics = {}, image, date }) {
    Facebook.lib.ui({
      method: 'feed',
      display: 'popup',
      name: getContentAsText(getTitle(body)),
      caption: sprintf('Blendle: %s %s', providerName, moment(date).format('L')),
      description: getContentAsText(getAnIntro(body)),
      link: url,
      picture: image,
    });

    Analytics.track('Start Social Share Item', {
      ...analytics,
      platform: SHARING_PLATFORM.FACEBOOK,
    });

    return true;
  }

  shareItemToEmail(userId, itemId, to, message, analytics = {}) {
    SharingManager.shareToEmail(userId, itemId, to, message)
      .then(() => {
        Analytics.track('Share Email: Sent', {
          item_id: itemId,
          recipients_count: to.length,
          ...analytics,
        });

        this.shareItemToEmailSuccess(to);
      })
      .catch((error) => {
        this.shareItemToEmailError({ error });

        if (error.type !== XHR_STATUS) {
          throw error;
        }
      });

    return true;
  }

  shareItemToTwitter({ providerName, body, url, analytics = {}, shareMessage }) {
    const text =
      shareMessage ||
      translate('item.text.sharing.twitter', [providerName, getContentAsText(getTitle(body))]);

    Twitter.openTweet(unescape(text), url);

    Analytics.track('Start Social Share Item', {
      ...analytics,
      platform: SHARING_PLATFORM.TWITTER,
    });

    return true;
  }

  shareToPocket(itemId) {
    return (dispatch) => {
      SharingManager.shareToPocket(itemId).then(() => dispatch({ itemId }));
    };
  }

  shareItemToLinkedIn({ providerName, body, url, analytics = {} }) {
    const linkedInUrl = sprintf(
      LINKEDIN_LINK,
      encodeURIComponent(url),
      encodeURIComponent(`${getContentAsText(getTitle(body))} (${providerName})`),
      encodeURIComponent(getContentAsText(getAnIntro(body))),
      'Blendle',
    );

    windowOpen({
      src: linkedInUrl,
      name: 'intent',
      width: 550,
      height: 542,
    });

    Analytics.track('Start Social Share Item', {
      ...analytics,
      platform: SHARING_PLATFORM.LINKEDIN,
    });

    return true;
  }

  shareItemToWhatsApp({ providerName, body, url, analytics = {} }) {
    const text = translate('item.text.sharing.general', [
      providerName,
      getContentAsText(getTitle(body)),
      url,
    ]);
    const location = `whatsapp://send?text=${encodeURIComponent(unescape(text))}`;

    if (window.BrowserDetect.device === 'Android') {
      window.open(location, '_blank');
    } else {
      window.location = location;
    }

    Analytics.track('Start Social Share Item', {
      ...analytics,
      platform: SHARING_PLATFORM.WHATSAPP,
    });

    return true;
  }

  shareToFollowing(itemId, userId, text) {
    SharingManager.shareToFollowing(userId, itemId, text).then(() => {
      SyncActions.addAction(SYNC_ACTION_TYPE.ITEM_LIKED, {
        itemId,
      });
      TileActions.fetchTile(userId, itemId);
      this.shareToFollowingSuccess({ itemId, userId, text });
    });

    trackEvent(text ? 'Add Text to Post' : 'Post Item', itemId);

    return null;
  }

  removeShareToFollowing(itemId, userId) {
    SharingManager.removeShareToFollowing(userId, itemId).then(() => {
      SyncActions.addAction(SYNC_ACTION_TYPE.ITEM_UN_LIKED, {
        itemId,
      });
      this.removeShareToFollowingSuccess({ itemId, userId });
      TileActions.fetchTile(userId, itemId);
    });

    trackEvent('Delete Post', itemId);

    return null;
  }

  shareToFollowingSuccess = (x) => x;

  removeShareToFollowingSuccess = (x) => x;

  setMessage = (message) => message;
}

export default alt.createActions(ShareActions);
