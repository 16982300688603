import React from 'react';
import styled from 'helpers/styledModule';
import { node } from 'prop-types';
import CSS from './styles.scss';

const Section = styled.section(CSS.section);
const Title = styled.h1(CSS.title);

export default function Faq({ children }) {
  return (
    <Section>
      <Title>Veelgestelde vragen</Title>
      {children}
    </Section>
  );
}

Faq.propTypes = {
  children: node.isRequired,
};
