// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".copy-X0UuO{margin-top:3px;margin-bottom:8px}.copy-X0UuO span{text-decoration:underline;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"copy": "copy-X0UuO"
};
module.exports = exports;
