import R from 'ramda';

const { BrowserDetect } = window;
const { DocumentTouch } = window;

const winWidth = window.innerWidth;
const winHeight = window.innerHeight;

const hasTouch =
  'ontouchstart' in window || (DocumentTouch && window.document instanceof DocumentTouch);

const isMobileUA = /android|blackberry|ipod|mobile|phone|tizen|webos/i.test(navigator.userAgent);
const isPhoneSized = Math.min(winWidth, winHeight) < 550;
const isPhone = Boolean(isMobileUA && isPhoneSized);

const isAndroidDevice = () =>
  R.compose(
    R.equals('android'),
    R.toLower,
  )(BrowserDetect.device);

export default {
  hasTouch: () => hasTouch,
  isMobile: () => isPhone,
  isDesktop: () => !isPhone,
  isIPad: () => BrowserDetect.device === 'iPad',
  isAndroidBrowser: () => BrowserDetect.browser === 'Android Browser',
  getOrientation: () => (winWidth > winHeight ? 'landscape' : 'portrait'),
  isAndroidDevice,
  platformHasApp: () => isAndroidDevice() || BrowserDetect.iOS,
  isIOS: () => BrowserDetect.iOS,
};
