// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".deviceContainerMobile-YKU3D{min-height:540px;flex-basis:343px;position:relative;display:none}@media (max-width: 850px){.deviceContainerMobile-YKU3D{display:block}}.deviceContainerDesktop-23t08{min-height:540px;flex-basis:343px;position:relative}.backdrop-2ul1u{border-top-right-radius:10px;border-bottom-right-radius:10px}@media (max-width: 850px){.backdrop-2ul1u{display:none}}.device-2ezH4{position:absolute;bottom:0}@media (max-width: 850px){.device-2ezH4{position:relative;margin:7px auto 0}}.background-X7YpM{height:110%;width:100%;position:absolute;top:100px;display:none}@media (max-width: 850px){.background-X7YpM{display:block}}.razzmatazzBackground-aEdMT{background:linear-gradient(326.79deg, #ff6255 0%, #ff0e61 100%)}.purpleBackground-3qxzH{background:linear-gradient(163.84deg, #ab32e5 -53.86%, #4900fe 100%)}\n", ""]);
// Exports
exports.locals = {
	"deviceContainerMobile": "deviceContainerMobile-YKU3D",
	"deviceContainerDesktop": "deviceContainerDesktop-23t08",
	"backdrop": "backdrop-2ul1u",
	"device": "device-2ezH4",
	"background": "background-X7YpM",
	"razzmatazzBackground": "razzmatazzBackground-aEdMT",
	"purpleBackground": "purpleBackground-3qxzH"
};
module.exports = exports;
