import React from 'react';
import styledModule from 'helpers/styledModule';
import CheckmarkIconThin from 'components/icons/CheckmarkIconThin';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
const Intro = styledModule.p(CSS.intro);
const BenefitWrapper = styledModule.div(CSS.benefitWrapper);
const BenefitContainer = styledModule.div(CSS.benefit);
const StyledCheckmark = styledModule(CheckmarkIconThin)(CSS.checkmark);

// eslint-disable-next-line react/prop-types
const Benefit = ({ children }) => (
  <BenefitContainer>
    <StyledCheckmark fill="#4900FE" />
    <div>{children}</div>
  </BenefitContainer>
);

const Benefits = () => (
  <Container>
    <Intro>Met Blendle Premium krijg je iedere dag:</Intro>
    <BenefitWrapper>
      <Benefit>Een voorselectie uit alle kranten</Benefit>
      <Benefit>De beste nieuwsbrief van Nederland</Benefit>
      <Benefit>De opvallendste stukken uit Amerika</Benefit>
      <Benefit>Onbeperkt toegang tot tijdschriften</Benefit>
      <Benefit>Artikelen die zijn voorgelezen</Benefit>
      <Benefit>Toegang tot ruim 3 miljoen artikelen</Benefit>
    </BenefitWrapper>
  </Container>
);

export default Benefits;
