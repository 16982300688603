import withRouter from 'react-router/lib/withRouter';
import { compose, branch } from '@blendle/recompose';
import redirect from 'higher-order-components/redirect';
import altConnect from 'higher-order-components/altConnect';
import pathIsLocked from 'helpers/pathIsLocked';
import { path, __ } from 'ramda';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import {
  FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
  FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER,
} from 'app-constants';
import { capabilityIsLocked, hasCapability } from 'selectors/capabilities';

const blendleIsLocked = capabilityIsLocked(__, FEATURE_CAPABILITY_ACCESS_TO_BLENDLE);

const query = path(['location', 'query']);
const pathname = path(['location', 'pathname']);

function mapStateToProps({ featureCapabilitiesState }, props) {
  const pathName = pathname(props);
  const queryParams = query(props);

  if (blendleIsLocked(featureCapabilitiesState) && pathIsLocked(pathName)) {
    const upsellPage = hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER,
    )
      ? '/discover-premium'
      : '/getpremium/why-premium';

    return {
      redirectTo: { pathname: upsellPage, query: queryParams },
    };
  }

  return {
    redirectTo: undefined,
  };
}
mapStateToProps.stores = { FeatureCapabilitiesStore };

export default compose(
  withRouter,
  altConnect(mapStateToProps),
  branch(({ redirectTo }) => redirectTo, redirect({ replace: true, renderNothing: true })),
);
