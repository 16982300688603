import CookieConsentStore from 'stores/CookieConsentStore';
import useAlt from 'hooks/useAlt';

/**
 * Get the cookie bar store content.
 *
 * @return {{hasGivenConsent: boolean, showCookieBar: boolean}}
 */
const useCookieConsent = () =>
  useAlt([CookieConsentStore], ([cookieConsentState]) => cookieConsentState);

export default useCookieConsent;
