import environment from 'environment';

const onlyDev = environment.name === 'development' || environment.name === 'local';
const onlyProd = environment.name === 'production';
const onlyApproval = environment.name === 'approval';
const excludeProd = environment.name !== 'production';
const excludeTest = environment.name !== 'test';

export default {
  // Only use adwords for production
  adwords: onlyProd,

  showBetaAsReleased: onlyApproval,

  // Smart cropped images in tiles and reader
  smartCropImages: excludeTest,

  // Exclude images with "original" size because they can be HUGE
  excludeOriginalImages: true,

  // feature gate subscriptions by ID
  subscriptions: {
    nationalgeographictraveler: excludeProd,
    glamour: excludeProd,
    vogue: excludeProd,
    pmmagazin: excludeProd,
  },

  // If we have to enable this again for whatever reason
  // We should make sure that we don't show the images when the reader
  // is rendering with only the manifest
  hideImagesAfterXDays: false,

  landingPageMicroFrontend: ['local', 'staging', 'production'].includes(environment.name),

  deeplinkLandingPageMicroFrontend: ['local', 'staging', 'production'].includes(environment.name),

  overrideCapabilities: onlyDev,

  showCategoriesInKiosk: () => ['local'].includes(environment.name),

  enableSegment: () =>
    ['local', 'development', 'approval', 'test', 'staging'].includes(environment.name),
};
