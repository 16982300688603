// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-1Att8{from{opacity:0}to{opacity:1}}.title-ZwDPt{color:#fff;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;padding-bottom:9px;line-height:36px;font-size:32px;letter-spacing:-0.05em;margin:24px auto 0;max-width:320px;opacity:0;animation:fadeIn-1Att8 0.3s 0.3s ease-out forwards}@media (min-width: 600px){.title-ZwDPt{max-width:620px;font-size:44px;line-height:48px;margin:31px auto 0;text-align:center}}.introCopy-3wd6B{font-family:\"GT-Walsheim\",sans-serif;color:#f7f0ff;letter-spacing:-0.02em;font-size:17px;line-height:24px;padding-bottom:16px;max-width:600px;margin:0 auto 7px;opacity:0;animation:fadeIn-1Att8 0.3s 0.6s ease-out forwards}@media (min-width: 600px){.introCopy-3wd6B{font-size:22px;line-height:31px;margin:0 auto 41px;text-align:center}}\n", ""]);
// Exports
exports.locals = {
	"title": "title-ZwDPt",
	"fadeIn": "fadeIn-1Att8",
	"introCopy": "introCopy-3wd6B"
};
module.exports = exports;
