// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-33sIi{from{opacity:0}to{opacity:1}}.article-ooggl{border-bottom:1px solid rgba(0,0,0,0.08)}.header-kX4kJ{cursor:pointer;width:100%;display:flex;align-items:center;justify-content:space-between;min-height:70px;padding:0}.title-1gsfV{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-size:20px;line-height:1.3;letter-spacing:-0.03em;text-align:left;margin-right:16px;padding:20px 0}@media (min-width: 600px){.title-1gsfV{font-size:22px}}.arrow-FEO8F{flex-shrink:0;margin-right:8px;transition:transform 0.15s ease-in}.arrowDown-38DnU{transform:rotate(90deg)}.answer-75t3V{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:24px;letter-spacing:-0.02em;color:#474747;margin:-8px 0 32px;opacity:0;animation:fadeIn-33sIi 0.2s ease-in forwards}.answer-75t3V p{margin-bottom:16px;line-height:1.5}.answer-75t3V p:last-child{margin-bottom:8px}.answer-75t3V ul{margin-top:32px}.answer-75t3V li{margin-left:32px;list-style-type:disc}\n", ""]);
// Exports
exports.locals = {
	"article": "article-ooggl",
	"header": "header-kX4kJ",
	"title": "title-1gsfV",
	"arrow": "arrow-FEO8F",
	"arrowDown": "arrowDown-38DnU",
	"answer": "answer-75t3V",
	"fadeIn": "fadeIn-33sIi"
};
module.exports = exports;
