import { lensPath, lensProp, view, compose } from 'ramda';
import AuthStore from 'stores/AuthStore';

const userLens = lensProp('user');
export const user = view(userLens);
const firstNameLens = lensPath(['attributes', 'first_name']);
const userFirstNameLens = compose(
  userLens,
  firstNameLens,
);
const didPremiumOnboardingLens = lensPath(['attributes', 'preferences', 'did_premium_onboarding']);
const userDidPremiumOnboardingLens = compose(
  userLens,
  didPremiumOnboardingLens,
);

/**
 * Get users firstName from the authState
 * @param  {Object} authState
 * @return {String}
 */
export const getFirstName = view(userFirstNameLens);

/**
 * Did user finish premium onboarding
 * @param  {Object} authState
 * @return {Boolean}
 */
export const didPremiumOnboarding = compose(
  Boolean,
  view(userDidPremiumOnboardingLens),
);

export const isLoggedIn = () => {
  const { user } = AuthStore.getState();

  return !!user;
};

const userCountryLens = lensPath(['attributes', 'country']);
export const country = view(userCountryLens);
