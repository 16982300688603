import React from 'react';
import { number, func, node } from 'prop-types';
import { STATUS_PENDING, STATUS_OK, STATUS_ERROR, INTERNAL_LOCATION } from 'app-constants';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import User from 'components/User';
import useSendDownloadAppLink from 'hooks/useSendDownloadAppLink';
import { email } from 'selectors/user';
import { equals } from 'ramda';
import CSS from './style.scss';

const Wrapper = styledModule.div(CSS.wrapper);
const Body = styledModule.p(CSS.body);
const Container = styledModule.div(CSS.container);
const ErrorMessage = styledModule(Body)(CSS.error);
const StyledButton = styledModule(Button)(CSS.button);

const Box = ({ children }) => (
  <Wrapper>
    <Container>{children}</Container>
  </Wrapper>
);

Box.propTypes = {
  children: node.isRequired,
};

const SendEmailButton = ({ status, requestEmail }) => (
  <StyledButton color="transparent" onClick={requestEmail} isLoading={status === STATUS_PENDING}>
    Stuur download-linkje
  </StyledButton>
);

SendEmailButton.propTypes = {
  status: number.isRequired,
  requestEmail: func.isRequired,
};

const ErrorState = ({ status, requestEmail }) => (
  <Box>
    <ErrorMessage>Oef! Er ging iets mis. Probeer het anders nog&nbsp;eens?</ErrorMessage>
    <SendEmailButton status={status} requestEmail={requestEmail} />
  </Box>
);

ErrorState.propTypes = {
  status: number.isRequired,
  requestEmail: func.isRequired,
};

const SuccessState = () => (
  <Box>
    <Body>Gelukt! De email is via de information-cyber-highway naar je&nbsp;onderweg.</Body>
  </Box>
);

const isOk = equals(STATUS_OK);
const isError = equals(STATUS_ERROR);

const SendDownloadLink = () => {
  const { downloadAppLinkStatus, sendDownloadAppLink } = useSendDownloadAppLink(
    INTERNAL_LOCATION.EXPLAIN_PREMIUM_PAGE,
  );

  if (isOk(downloadAppLinkStatus)) {
    return <SuccessState />;
  }

  if (isError(downloadAppLinkStatus)) {
    return <ErrorState status={downloadAppLinkStatus} requestEmail={sendDownloadAppLink} />;
  }

  return (
    <Box>
      <User>
        {user => (
          <Body>
            We kunnen je ook een linkje naar de app sturen op <strong>{email(user)}</strong>
          </Body>
        )}
      </User>
      <SendEmailButton status={downloadAppLinkStatus} requestEmail={sendDownloadAppLink} />
    </Box>
  );
};

export default SendDownloadLink;
