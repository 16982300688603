// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".video-2_tvs{height:540px;width:340px;border-top-right-radius:10px;border-bottom-right-radius:10px}\n", ""]);
// Exports
exports.locals = {
	"video": "video-2_tvs"
};
module.exports = exports;
