import { contains, endsWith, startsWith, any, flip } from 'ramda';

const { origin } = window.location;
const containsOrigin = contains(origin);
const isNetlify = endsWith('netlify.com', origin);
/**
 * @param {string[]} list a list of origins which a orgin can start with
 * @returns {boolean}
 */
const originStartsWithAny = any(flip(startsWith)(origin));

export const isTest = () => process.env.NODE_ENV === 'test';

export const isDevelopment = () =>
  containsOrigin(['https://development.blendle.com', 'https://blendle-test.com']) || isNetlify;

export const isLocal = () =>
  containsOrigin([
    'http://localhost:9000',
    'http://localhost:4545',
    'http://127.0.0.1:9000',
    'http://127.0.0.1:4545',
  ]) || originStartsWithAny(['http://192.168.', 'http://10.']);

export const isApproval = () => containsOrigin(['https://approval.blendle.com']);

export const isStaging = () => containsOrigin(['https://staging.blendle.com']);

export const isProduction = () => containsOrigin(['https://blendle.com']);

export const getEnvironmentName = () => {
  if (isTest()) {
    return 'test';
  }

  if (isLocal()) {
    return 'local';
  }

  if (isDevelopment()) {
    return 'development';
  }

  if (isApproval()) {
    return 'approval';
  }

  if (isStaging()) {
    return 'staging';
  }

  return 'production';
};

/**
 * returns true if it is any of the development environments
 */
export const isDev = () => isLocal() || isDevelopment();
