export default {
  name: 'staging',
  api: 'https://static.blendle.com/api.json',
  tokens: 'https://ws.blendle.com/tokens',
  i18n: 'https://ws.blendle.com/i18n',
  providerConfigurations: 'https://static.blendle.nl/provider_configurations.json',
  analytics: 'https://events-staging.blendle.io/v1/events',
  gaTrackingId: 'UA-90032766-5', // google analytics tracking id
  maintenanceFile: 'https://static.blendle.com/maintenance_staging.json',
  ssl: true,
  paymentUri: 'https://payment.blendle.com',
  doubleclick_existing_user_id: 'DC-8327133/test/test_000+standard',
  doubleclick_pageview_id: 'DC-8327133/test/test_00+standard',
  doubleclick_success_id: 'DC-8327133/test/test_0+standard',
  landing_project_protocol: 'https',
  landing_project_host: 'staging.blendle.com',
  landing_experiments_endpoint: 'https://landing-staging.blendle.io/api/active-experiments',
  facebookAppId: '157559707786240',
  facebookVersion: 'v3.1',
  sentryApiKey: 'https://7b78e985a16a453db2434ccdff3b81b7@app.getsentry.com/19602',
  facebookPixelId: '742491399133724',
  twitterPixelId: 'nw3xj',
  doubleClickId: 'DC-8327133',
  graphQLHost: 'https://graph.blendle.com/graphql',
  segmentEnvironmentName: 'development',
};
