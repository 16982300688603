// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-dXjSt{left:0;top:0;transition-property:transform, opacity;transition-duration:0.2s;transition-timing-function:ease-out;transform:translateY(-100%);z-index:1010;max-height:54px;opacity:0}@media (min-width: 600px){.form-dXjSt{max-height:66px}}.overlay-3FL7Y{position:absolute;background:#4a5170;z-index:1000;opacity:0.8;top:0;left:0;width:100vw;height:100vh}.isOpen-1ipz_{transform:none;opacity:1}.closeButton-3BZU6{cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"form": "form-dXjSt",
	"overlay": "overlay-3FL7Y",
	"isOpen": "isOpen-1ipz_",
	"closeButton": "closeButton-3BZU6"
};
module.exports = exports;
