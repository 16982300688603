// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-13iwo{background-image:url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8A9ff4/wmtA+QQodHfAAAAAElFTkSuQmCC\");display:flex;flex-direction:column;margin-top:40px;margin-left:-16px;margin-right:-16px;padding:16px}@media (min-width: 700px){.container-13iwo{flex-direction:row;background-size:100% 87%;background-repeat:no-repeat;background-position-y:36px;margin-top:0;margin-left:0;margin-right:0;padding:0}}.device-Y99nf{width:339px;margin:0 auto}@media (min-width: 850px){.device-Y99nf{margin:0;margin-left:64px}}.content-2sDPK{display:flex;flex-direction:column;justify-content:center;padding:16px 0;max-width:415px}@media (min-width: 700px){.content-2sDPK{padding:16px;padding-right:15px}}@media (min-width: 850px){.content-2sDPK{margin-left:72px}}.labelWrapper-1r6vX{display:flex;margin-bottom:10px}.kicker-2TcsU{font-family:\"GT-Walsheim\",sans-serif;text-transform:uppercase;font-weight:600;font-size:11px;line-height:14px;letter-spacing:0.2em;padding-left:2px;align-self:center}.uspWrapper-3XzY7{margin-top:16px;margin-bottom:15px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-13iwo",
	"device": "device-Y99nf",
	"content": "content-2sDPK",
	"labelWrapper": "labelWrapper-1r6vX",
	"kicker": "kicker-2TcsU",
	"uspWrapper": "uspWrapper-3XzY7"
};
module.exports = exports;
