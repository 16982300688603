import alt from 'instances/altInstance';

class FeatureCapabilitiesActions {
  setCapabilities(capabilities) {
    return capabilities;
  }

  setForceCapability({ capability, value }) {
    return { capability, value };
  }
}

export default alt.createActions(FeatureCapabilitiesActions);
