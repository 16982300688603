// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".balance-St-f7{margin-left:8px;padding:4px 6px 3px;font-size:13px}\n", ""]);
// Exports
exports.locals = {
	"balance": "balance-St-f7"
};
module.exports = exports;
