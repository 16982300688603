import React from 'react';
import { node } from 'prop-types';
import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
const Gradient = styledModule.div(CSS.gradient);

const Background = ({ children }) => (
  <Container>
    <Gradient />
    {children}
  </Container>
);

Background.propTypes = {
  children: node.isRequired,
};

export default Background;
