import { getEnvironmentName } from 'helpers/environment';
import local from 'config/env/local';
import development from 'config/env/development';
import test from 'config/env/test';
import approval from 'config/env/approval';
import staging from 'config/env/staging';
import production from 'config/env/production';

const environment = {
  local,
  development,
  test,
  approval,
  staging,
  production,
}[getEnvironmentName()];

export default environment;

// eslint-disable-next-line no-underscore-dangle
window._sentryApiKey = environment.sentryApiKey;
