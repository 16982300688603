export default {
  name: 'approval',
  api: 'https://static-approval.blendle.nl/api_approval.json',
  tokens: 'https://ws-approval.blendle.com/tokens',
  providerConfigurations: 'https://static-approval.blendle.nl/provider_configurations.json',
  analytics: 'https://events-staging.blendle.io/v1/events',
  maintenanceFile: 'https://static-approval.blendle.nl/maintenance.json',
  i18n: 'https://ws-approval.blendle.com/i18n',
  ssl: true,
  doubleclick_existing_user_id: 'DC-8327133/test/test_000+standard',
  doubleclick_pageview_id: 'DC-8327133/test/test_00+standard',
  doubleclick_success_id: 'DC-8327133/test/test_0+standard',
  facebookAppId: '157559707786240',
  facebookVersion: 'v3.1',
  sentryApiKey: 'https://3d825c64681a4032b8931c8407503690@app.getsentry.com/73683',
  facebookPixelId: '742491399133724',
  twitterPixelId: 'nw3xj',
  doubleClickId: 'DC-8327133',
  graphQLHost: 'https://graph.blendle.com/graphql',
  segmentEnvironmentName: 'development',
};
