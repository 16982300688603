import { STATUS_INITIAL, STATUS_OK, STATUS_ERROR, STATUS_PENDING } from 'app-constants';
import alt from 'instances/altInstance';
import AuthActions from 'actions/AuthActions';

class AuthStore {
  constructor() {
    this.bindActions(AuthActions);

    this.state = {
      status: STATUS_INITIAL,
      user: null,
      loadingProperties: new Set(),
    };
  }

  onAuthenticateUser(user) {
    this.setState({
      status: STATUS_OK,
      user,
    });
  }

  onLogout() {
    this.setState({
      status: STATUS_INITIAL,
      user: null,
    });
  }

  onUpdate(user) {
    this.setState({
      status: STATUS_OK,
      user,
    });
  }

  onUserInputChange(userInput) {
    this.setState({ userInput });
  }

  onFetchUserSuccess(user) {
    this.setState({ user });
  }

  onUpdateUserProperty(property) {
    this.setState({
      status: STATUS_PENDING,
      loadingProperties: this.state.loadingProperties.add(property),
    });
  }

  onUpdateUserPropertySuccess({ user, property }) {
    const loadingProperties = new Set(this.state.loadingProperties);
    loadingProperties.delete(property);

    this.setState({
      status: STATUS_OK,
      user,
      loadingProperties,
    });
  }

  onUpdateUserPropertyError(error) {
    this.setState({
      status: STATUS_ERROR,
      error,
    });
  }
}

export default alt.createStore(AuthStore, 'AuthStore');
