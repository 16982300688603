import alt from 'instances/altInstance';
import DiscoverNavigationActions from '../actions/DiscoverNavigationActions';
import SharedNavigationStore from './SharedNavigationStore';

class DiscoverNavigationStore extends SharedNavigationStore {
  constructor() {
    super();

    this.bindActions(DiscoverNavigationActions);
  }
}

export default alt.createStore(DiscoverNavigationStore, 'DiscoverNavigationStore');
