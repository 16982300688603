import React from 'react';
import styledModule from 'helpers/styledModule';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import { string, oneOf, bool } from 'prop-types';
import QRCode from '../QRCode';
import AppButtons from '../AppButtons';
import CSS from './styles.scss';
import { getColor } from '../helper';

const Arrow = ({ color, className }) => (
  <svg
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4501 29.9634C22.0517 28.7793 25.752 27.8419 29.0576 25.8684C29.4029 25.6711 29.5016 25.0297 29.0576 24.8817C25.6533 23.451 22.2984 21.9709 18.7462 21.0335C18.1541 20.8855 17.7101 21.6255 18.1541 22.0695C18.9929 22.9083 19.7822 23.7964 20.473 24.7338C13.1711 24.6351 4.68517 24.8818 2.41568 16.1985C1.42894 12.3503 1.97156 8.05795 4.43848 4.85105C7.05326 1.49599 11.3456 0.755933 15.3418 1.00262C15.7365 1.00262 15.8352 0.410575 15.4405 0.311902C6.51055 -1.61224 -0.445945 5.64028 0.787476 14.5209C1.37952 18.9119 3.45167 22.6122 7.6453 24.487C11.3949 26.1645 15.8846 26.0657 19.9795 25.9671C19.1408 26.9045 18.4007 27.9405 17.8087 29.0259C17.5127 29.5193 17.9074 30.1607 18.4501 29.9634ZM21.7063 25.5231C21.805 25.2271 21.657 25.0297 21.4103 24.931C21.4103 24.931 21.4103 24.931 21.3609 24.8817C21.1143 24.0923 20.7196 23.4016 20.2755 22.7602C22.693 23.5003 25.0612 24.487 27.38 25.4244C24.9625 26.7072 22.3477 27.4473 19.7822 28.286C20.5222 27.3978 21.2623 26.5591 21.7063 25.5231Z"
      fill={color}
    />
  </svg>
);

Arrow.propTypes = {
  color: string.isRequired,
  className: string.isRequired,
};

const Outro = styledModule.p(CSS.outro);
const AppStoreSection = styledModule.div(CSS.appStoreSection);
const StyledArrow = styledModule(Arrow)(CSS.arrow);
const AppStoreButtonMobile = styledModule(AppButtons)(CSS.appStoreMobile);

const DownloadAppSection = ({ version, renderAndroidVariant }) => {
  const color = getColor(version);
  const showAppStore = !renderAndroidVariant;
  const showPlayStore = renderAndroidVariant;

  return (
    <React.Fragment>
      <Outro>
        Er zit al een link naar de app in je mailbox óf{' '}
        <span style={{ color }}>scan deze code</span> om ‘m meteen te installeren!
      </Outro>
      <AppStoreSection>
        <StyledArrow color={color} />
        <QRCode version={version} />
        <AppButtons version={version} />
      </AppStoreSection>
      <AppStoreButtonMobile
        version={version}
        showAppStore={showAppStore}
        showPlayStore={showPlayStore}
      />
    </React.Fragment>
  );
};

DownloadAppSection.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW,
    SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK,
  ]).isRequired,
  renderAndroidVariant: bool,
};

DownloadAppSection.defaultProps = {
  renderAndroidVariant: false,
};

export default DownloadAppSection;
