import { getItem, setItem } from 'helpers/localStorage';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'cookies-js';

function getCookieUuid() {
  return Cookies.get('uuid');
}

function saveUuid(value) {
  setItem('uuid', value);

  Cookies.set('uuid', value, {
    domain: '.blendle.com',
    path: '/',
  });
  return value;
}

function getUuid() {
  const curLocal = getItem('uuid');
  const curCookie = getCookieUuid();

  if (curLocal && curCookie) {
    return saveUuid(curLocal);
  }

  if (curLocal || curCookie) {
    return saveUuid(curLocal || curCookie);
  }

  return saveUuid(uuidv4());
}

export default getUuid;
