import React from 'react';
import { Backdrop } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import { oneOf, bool } from 'prop-types';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import staticImageUrl from 'helpers/staticImageUrl';
import R from 'ramda';
import CSS from './styles.scss';

const useMigrationColors = R.anyPass([
  R.equals(SUCCESS_DIALOG_VERSION.WALLET_CONVERSION),
  R.equals(SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW),
  R.equals(SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW),
]);

const ContainerMobile = styledModule.div(CSS.deviceContainerMobile);
const ContainerDesktop = styledModule.div(CSS.deviceContainerDesktop);
const MobileBackground = styledModule.div(CSS.background);
const Device = styledModule.img(CSS.device);

const innerBackdropStyled = {
  width: '430px',
  height: '430px',
  right: '-35%',
  bottom: '-47%',
};

const DeviceSection = ({ version, renderAndroidVariant, showOnDesktop }) => {
  const backdropColor = useMigrationColors(version)
    ? 'linear-gradient(137.28deg, #AB32E5 -53.86%, #4900FE 100%)'
    : 'linear-gradient(301.97deg, #FF6255 0%, #FF0E61 100%)';

  const imagePath = renderAndroidVariant
    ? staticImageUrl('/img/backgrounds/Pixel_3_timeline_success_dialog')
    : staticImageUrl('/img/backgrounds/iPhone_X_timeline_success_dialog');

  const content = (
    <React.Fragment>
      <Backdrop.SmallBottomRight
        className={CSS.backdrop}
        color={backdropColor}
        innerColor="linear-gradient(133.02deg, #FCA400 3.23%, #FECD43 100%)"
        rotate={45}
        innerStyle={innerBackdropStyled}
      />
      <MobileBackground
        className={useMigrationColors(version) ? CSS.purpleBackground : CSS.razzmatazzBackground}
      />
      <Device
        src={`${imagePath}@2x.png?auto=format,compress`}
        srcSet={`${imagePath}.png?auto=format,compress 1x, ${imagePath}@2x.png?auto=format,compress 2x`}
        alt="Smartphone"
      />
    </React.Fragment>
  );

  return showOnDesktop ? (
    <ContainerDesktop>{content}</ContainerDesktop>
  ) : (
    <ContainerMobile>{content}</ContainerMobile>
  );
};

DeviceSection.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW,
    SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW,
  ]),
  renderAndroidVariant: bool,
  showOnDesktop: bool,
};

DeviceSection.defaultProps = {
  version: SUCCESS_DIALOG_VERSION.DEFAULT,
  renderAndroidVariant: false,
  showOnDesktop: false,
};

export default DeviceSection;
