// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".outro-149n_{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:21px;letter-spacing:-0.02em;color:#333;font-weight:600;margin:19px 0 12px !important}@media (max-width: 850px){.outro-149n_{display:none}}.appStoreSection-1V8ER{display:flex;justify-content:space-between;position:relative}@media (max-width: 850px){.appStoreSection-1V8ER{display:none}}.arrow-2_ovA{position:absolute;left:-36px;top:-19px}.appStoreMobile-lRNIl{display:none !important}@media (max-width: 850px){.appStoreMobile-lRNIl{display:flex !important;margin:15px auto 0 !important;justify-content:center !important}}\n", ""]);
// Exports
exports.locals = {
	"outro": "outro-149n_",
	"appStoreSection": "appStoreSection-1V8ER",
	"arrow": "arrow-2_ovA",
	"appStoreMobile": "appStoreMobile-lRNIl"
};
module.exports = exports;
