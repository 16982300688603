import AuthController from 'controllers/auth';
import { propOr, equals, pipe, join } from 'ramda';
import { promiseToObservable } from './helpers';

export const requestHandler = operation => {
  const token = AuthController.getToken();

  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token.get('jwt')}`,
      },
    });
  }
};

const joinPath = join('.');
const hasAuthenticationError = pipe(propOr(undefined, 'statusCode'), equals(401));

/* eslint-disable consistent-return */
export const errorHandler = ({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    // Renew JWT and retry request
    // Based on this example: https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
    // Good to know is that if this next request also fails Apollo won't run this 'onError' function again,
    // this is to prevent a possible endless request loop.
    if (hasAuthenticationError(networkError)) {
      return promiseToObservable(AuthController.renewJWT()).flatMap(() => forward(operation));
    }

    const { message, result } = networkError;

    window.ErrorLogger.captureException(`GraphQL Network Error: ${message}`, {
      error: JSON.stringify(result),
    });

    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      const { message, path } = graphQLError;

      window.ErrorLogger.captureException(`GraphQL Error: ${message}`, {
        path: joinPath(path || []),
      });
    });

    return;
  }

  window.ErrorLogger.captureMessage('GraphQL: Unexpected and unknown Apollo GQL client error');
};
