import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import R from 'ramda';

export const isAppFlowMigration = R.equals(SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW);

export function getColor(version) {
  switch (version) {
    case SUCCESS_DIALOG_VERSION.WALLET_CONVERSION:
    case SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW:
    case SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW:
      return '#4900fe';
    default:
      return '#ff1060';
  }
}
