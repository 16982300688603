import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { ARTICLE_QUERY } from 'queries';
import { GRAPHQL_TYPE_NAME } from 'app-constants';
import { useEffect } from 'react';

const useRefreshGraphQLArticle = () => {
  const client = useApolloClient();
  const [runArticleQuery, { error }] = useLazyQuery(ARTICLE_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      window.ErrorLogger.captureException(error);
    }
  }, [error]);

  const refresh = (bnlId) => {
    const result = client.readFragment({
      id: `${GRAPHQL_TYPE_NAME.ARTICLE}:${bnlId}`,
      fragment: gql`
        fragment articleIdFragment on Article {
          id
        }
      `,
    });

    if (result && result.id) {
      runArticleQuery({
        variables: {
          id: result.id,
        },
      });
    }
  };

  return {
    refresh,
  };
};

export default useRefreshGraphQLArticle;
