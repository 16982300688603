import { get } from 'lodash';
import { XHR_STATUS } from 'app-constants';
import { fetchTiles } from '../managers/sectionsPageManager';

export default class SectionsPageActions {
  setHidden = scrollPosition => scrollPosition;

  resetScrollPosition = () => null;

  openItemFeedback = itemId => itemId;

  closeItemFeedback = () => null;

  removeItem = itemId => itemId;

  fetchSections = () => null;

  fetchSectionsSuccess = ({ sections, info, hasEarlier }) => ({
    sections,
    info,
    hasEarlier,
  });

  fetchSectionsError = error => error;

  fetchSectionFeed(sectionId, url) {
    fetchTiles(url).then(
      response => {
        const tiles = get(response, '_embedded[b:tiles]');
        if (!tiles) {
          this.fetchSectionFeedError({
            error: new Error('response did contain tiles'),
            sectionId,
          });
        }

        this.fetchSectionFeedSuccess({
          sectionId,
          tiles,
          nextUrl: get(response, '_links.next.href'),
        });
      },
      error => {
        this.fetchSectionFeedError({ error, sectionId });

        if (error.type !== XHR_STATUS) {
          throw error;
        }
      },
    );

    return { sectionId };
  }

  fetchSectionFeedSuccess = ({ sectionId, tiles }) => ({ sectionId, tiles });

  fetchSectionFeedError = error => error;
}
