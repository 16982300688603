// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-2jRRI{display:flex;align-items:center;background:white;height:100%;padding:15px;position:absolute;width:100%}.input-2YU53{width:100%;height:21px;line-height:21px;margin-left:10px;margin-right:10px;font-family:\"GT-Walsheim\",sans-serif;font-size:16px}.input-2YU53:focus{box-shadow:none}.input-2YU53::placeholder{color:#b2b2b2}.searchIcon-HivPq{position:relative;width:19px;height:19px;color:#999}.active-23U4R{color:#ff1060}.cursorPointer-16zFR{cursor:pointer}.container-1TDIr{position:relative;width:54px;height:66px}.form-2jRRI{transition:transform 0.2s ease-out, width 0.2s ease-out;transition-delay:0.1s;justify-content:center}.contentWrapper-1mTo3{display:flex;width:100%}@media (min-width: calc(1080px + 2 * 145px + 2 * 15px)){.contentWrapper-1mTo3{max-width:1080px}}.input-2YU53{opacity:0;transition:opacity 0.2s ease-out;transition-delay:0s}.searchIcon-HivPq{top:3px}.closeButton-3w6Eh{padding:0;opacity:0;transition:opacity 0.2s ease-out;transition-delay:0s;cursor:pointer}.isOpen-1140Q.form-2jRRI{width:calc(100vw - 145px - 54px);transform:translateX(calc(-100% + 54px));transition-delay:0s}@media (min-width: calc(1080px + 2 * (145px + 15px))){.isOpen-1140Q .contentWrapper-1mTo3{margin-right:calc(145px - 54px)}}.isOpen-1140Q .input-2YU53{opacity:1;transition-delay:0.1s}.isOpen-1140Q .closeButton-3w6Eh{opacity:1;transition-delay:0.1s}@media (min-width: calc(1080px + 2 * 145px + 2 * 15px)){.isOpenOutsideSearchPage-1yIhO.form-2jRRI{width:calc(40vw - 54px)}.isOpenOutsideSearchPage-1yIhO .contentWrapper-1mTo3{margin-right:0}}\n", ""]);
// Exports
exports.locals = {
	"form": "form-2jRRI",
	"input": "input-2YU53",
	"searchIcon": "searchIcon-HivPq",
	"active": "active-23U4R",
	"cursorPointer": "cursorPointer-16zFR",
	"container": "container-1TDIr",
	"contentWrapper": "contentWrapper-1mTo3",
	"closeButton": "closeButton-3w6Eh",
	"isOpen": "isOpen-1140Q",
	"isOpenOutsideSearchPage": "isOpenOutsideSearchPage-1yIhO"
};
module.exports = exports;
