import alt from 'instances/altInstance';
import { STATUS_PENDING, STATUS_OK, STATUS_ERROR } from 'app-constants';
import PremiumIssueActions from '../actions/PremiumIssueActions';

class PremiumIssueStore {
  constructor() {
    this.bindActions(PremiumIssueActions);
    this.state = {
      issues: new Map(),
      tilesStatus: new Map(),
    };
  }

  onFetchIssue({ bnlId }) {
    this.setState(({ issues, ...rest }) => ({
      ...rest,
      issues: issues.set(bnlId, { status: STATUS_PENDING }),
    }));
  }

  onFetchIssueSuccess({ bnlId, issue }) {
    this.setState(({ issues, ...rest }) => ({
      ...rest,
      issues: issues.set(bnlId, { status: STATUS_OK, issue }),
    }));
  }

  onFetchIssueError({ bnlId }) {
    this.setState(({ issues, ...rest }) => ({
      ...rest,
      issues: issues.set(bnlId, { status: STATUS_ERROR }),
    }));
  }

  onFetchTilesStatusStart({ bnlId }) {
    this.setState(({ tilesStatus, ...rest }) => ({
      ...rest,
      tilesStatus: tilesStatus.set(bnlId, { status: STATUS_PENDING }),
    }));
  }

  onFetchTilesStatusSuccess({ bnlId }) {
    this.setState(({ tilesStatus, ...rest }) => ({
      ...rest,
      tilesStatus: tilesStatus.set(bnlId, { status: STATUS_OK }),
    }));
  }

  onFetchTilesStatusError({ bnlId }) {
    this.setState(({ tilesStatus, ...rest }) => ({
      ...rest,
      tilesStatus: tilesStatus.set(bnlId, { status: STATUS_ERROR }),
    }));
  }
}

export default alt.createStore(PremiumIssueStore, 'PremiumIssueStore');
