import React from 'react';
import styledModule from 'helpers/styledModule';
import { string, node } from 'prop-types';
import CSS from './styles.scss';

const CheckMark = ({ color, className }) => (
  <svg
    className={className}
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2944 0.78168C17.064 0.729116 16.6474 0.67761 16.0187 0.623987C15.3742 0.570012 14.9759 0.564721 14.7646 0.60529C14.5508 0.646565 14.1645 0.842358 13.5835 1.20466C13.019 1.55815 12.1547 2.25453 11.0145 3.27406C9.87436 4.29571 8.76134 5.41261 7.70654 6.59336C6.72369 7.69333 5.76025 8.86596 4.8388 10.0823C4.49342 9.05329 4.13888 8.32903 3.75576 7.87642C3.32467 7.36524 2.93344 7.07561 2.5609 6.99059C2.2628 6.9218 1.87863 6.98741 1.38438 7.19062C0.877087 7.39876 0.582164 7.66793 0.508786 7.99072C0.4534 8.23414 0.568406 8.58586 0.872149 9.09844C1.40767 10.0026 1.8557 10.9456 2.20459 11.9027C2.42826 12.4996 2.58101 12.8629 2.67167 13.0125C2.74787 13.1381 2.93908 13.306 3.748 13.4902C4.16075 13.5847 4.50048 13.632 4.75836 13.632C5.03459 13.632 5.23073 13.5784 5.35456 13.4708C5.55741 13.3018 5.96204 12.7843 6.5914 11.8886C8.10271 9.71722 9.84826 7.68168 11.779 5.83736C13.7098 3.99338 15.4208 2.63483 16.8626 1.80086C17.3039 1.55744 17.3878 1.49077 17.4175 1.46748C17.5117 1.39093 17.572 1.2985 17.596 1.19408C17.632 1.03639 17.5713 0.845533 17.2944 0.78168Z"
      fill={color}
    />
  </svg>
);

CheckMark.propTypes = {
  color: string.isRequired,
  className: string.isRequired,
};

const Container = styledModule.div(CSS.container);
const StyledCheckmark = styledModule(CheckMark)(CSS.checkmark);
const Copy = styledModule.p(CSS.copy);

const USP = ({ checkMarkColor, children }) => (
  <Container>
    <StyledCheckmark color={checkMarkColor} />
    <Copy>{children}</Copy>
  </Container>
);

USP.propTypes = {
  checkMarkColor: string,
  children: node.isRequired,
};

USP.defaultProps = {
  checkMarkColor: '#ff1060',
};

export default USP;
