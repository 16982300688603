import { STATUS_INITIAL, STATUS_PENDING, STATUS_ERROR, STATUS_OK } from 'app-constants';
import SubscriptionOrderActions from 'actions/SubscriptionOrderActions';
import alt from 'instances/altInstance';

class SubscriptionOrderStore {
  constructor() {
    this.bindActions(SubscriptionOrderActions);

    this.state = {
      status: STATUS_INITIAL,
      jwt: null,
      affiliateStatus: STATUS_INITIAL,
    };
  }

  onCreateOrder() {
    this.setState({ status: STATUS_PENDING });
  }

  onCreateOrderSuccess(data) {
    const { redirect_url } = data;
    const orderSuccessData = data.redirect_url ? { redirect_url } : { jwt: data };

    this.setState({
      ...orderSuccessData,
      status: STATUS_OK,
    });
  }

  onStartAffiliateSubscription() {
    this.setState({
      affiliateStatus: STATUS_PENDING,
    });
  }

  onStartAffiliateSubscriptionSuccess() {
    this.setState({ affiliateStatus: STATUS_OK });
  }

  onStartAffiliateSubscriptionFailure(error) {
    this.setState({
      affiliateStatus: STATUS_ERROR,
      error,
    });
  }
}

export default alt.createStore(SubscriptionOrderStore, 'SubscriptionOrderStore');
