import React from 'react';
import { string, func, number } from 'prop-types';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import Link from 'components/Link';
import UpsellCopy from 'components/WalletConversion/UpsellCopy';
import { LOCATION_IN_LAYOUT } from 'app-constants';
import CSS from './styles.scss';
import SharedCSS from '../shared.scss';

const Container = styledModule.div(CSS.container);
const OfferContainer = styledModule.div(CSS.offerContainer);
const Cta = styledModule(Button)(CSS.cta);
const WalletOffer = styledModule.div(CSS.walletOffer);
const StyledUpsellCopy = styledModule(UpsellCopy)(CSS.upsell);
const Title = styledModule.h1(SharedCSS.title);
const IntroCopy = styledModule.p(SharedCSS.introCopy);

const IntroPlusMonthlyMigrationOffer = ({
  paymentPageUri,
  onWalletOfferClick,
  balance,
  offeredNumberOfMonths,
  equivalentMoneyOfferedMonths,
}) => {
  const onCtaClick = () => onWalletOfferClick(LOCATION_IN_LAYOUT.UPSELL_BANNER);

  return paymentPageUri ? (
    <Container>
      <Title>Gratis Blendle Premium</Title>
      <OfferContainer>
        <WalletOffer>
          <StyledUpsellCopy
            balance={balance}
            offeredNumberOfMonths={offeredNumberOfMonths}
            equivalentMoneyOfferedMonths={equivalentMoneyOfferedMonths}
            labelClassName={CSS.upsellLabel}
          />
        </WalletOffer>
        <Cta onClick={onCtaClick} component={Link} href={paymentPageUri}>
          Ja, dat wil ik!
        </Cta>
      </OfferContainer>
      <IntroCopy>
        Probeer het vernieuwde Blendle nu gratis. Je leest, luistert, zoekt en bladert nu voor een
        vast bedrag per maand. Lees onbeperkt tijdschriften én krijg een dagelijks krantenoverzicht
        op basis van je&nbsp;leesvoorkeuren.
      </IntroCopy>
    </Container>
  ) : null;
};

IntroPlusMonthlyMigrationOffer.propTypes = {
  onWalletOfferClick: func.isRequired,
  paymentPageUri: string.isRequired,
  balance: number.isRequired,
  offeredNumberOfMonths: number.isRequired,
  equivalentMoneyOfferedMonths: number.isRequired,
};

export default IntroPlusMonthlyMigrationOffer;
