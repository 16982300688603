// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h3-31ISO{font-family:\"GT-Walsheim\",sans-serif;color:#333;margin-bottom:10px;font-weight:600;font-size:34px;line-height:35px;letter-spacing:-0.05em}.h4-7Q5EE{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-weight:600;font-size:30px;line-height:32px;letter-spacing:-0.02em}.h5-2FANH{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-weight:600;font-size:22px;line-height:23px;letter-spacing:-0.05em;margin-top:8px}.body-3cezA{font-family:\"GT-Walsheim\",sans-serif;font-size:22px;line-height:33px;color:#474747;letter-spacing:-0.02em}\n", ""]);
// Exports
exports.locals = {
	"h3": "h3-31ISO",
	"h4": "h4-7Q5EE",
	"h5": "h5-2FANH",
	"body": "body-3cezA"
};
module.exports = exports;
