import React, { useState } from 'react';
import { translate } from 'instances/i18n';
import Analytics from 'instances/analytics';
import { string, func, object, shape } from 'prop-types';
import useCookieConsent from 'hooks/useCookieConsent';
import FacebookConnect from './FacebookConnect';
import CookieConsentNeeded from './CookieConsentNeeded';

const FacebookConnectContainer = ({
  onError: incomingOnError,
  onLogin: incomingOnLogin,
  onSignUp,
  onClick,
  analyticsName,
  analyticsPayload,
  signUpType,
  buttonText,
  signUpContext,
}) => {
  const [error, setError] = useState(null);
  const { hasGivenConsent } = useCookieConsent();

  const onError = err => {
    Analytics.track(analyticsName, {
      event: 'signup_error_facebook',
      error: err.message,
    });

    setError(translate('app.error.error_default'));

    incomingOnError(err);
  };

  const onLogin = (...args) => {
    Analytics.track('Login Successful', {
      ...analyticsPayload,
      platform: 'facebook',
    });

    incomingOnLogin(...args);
  };

  return hasGivenConsent ? (
    <FacebookConnect
      signUpType={signUpType}
      error={error}
      buttonText={buttonText}
      onSignUp={onSignUp}
      onLogin={onLogin}
      onError={onError}
      onClick={onClick}
      signUpContext={signUpContext}
    />
  ) : (
    <CookieConsentNeeded />
  );
};

FacebookConnectContainer.propTypes = {
  onLogin: func.isRequired,
  onSignUp: func.isRequired,
  onError: func.isRequired,
  onClick: func.isRequired,
  buttonText: string,
  analyticsName: string,
  analyticsPayload: shape({
    login_type: string,
    location_in_layout: string,
  }),
  signUpType: string,
  // provide extra data to send to the signup and confirmation webservice
  // this could contain keys like `referrer` or `signup_deeplink_item`
  // eslint-disable-next-line react/forbid-prop-types
  signUpContext: object,
};

FacebookConnectContainer.defaultProps = {
  analyticsName: 'Login Form',
  analyticsPayload: {},
  signUpContext: {},
  buttonText: undefined,
  signUpType: undefined,
};

export default FacebookConnectContainer;
