import alt from 'instances/altInstance';
import { get } from 'lodash';
import { XHR_STATUS } from 'app-constants';
import { fetchPersonalPage } from '../managers/sectionsPageManager';
import { filterSupportedSections } from '../selectors/sections';
import SectionsPageActions from './SectionsPageActions';

class PersonalPageActions extends SectionsPageActions {
  fetchPersonalPage(userId) {
    // Dispatch for loading state
    this.fetchSections();

    fetchPersonalPage(userId).then(
      response =>
        this.fetchSectionsSuccess({
          sections: filterSupportedSections(response._embedded['b:sections']),
          info: response.info,
          hasEarlier: !!get(response, '_links.earlier.href'),
        }),
      error => {
        this.fetchSectionsError(error);

        if (error.type !== XHR_STATUS) {
          throw error;
        }
      },
    );

    return null;
  }
}

export default alt.createActions(PersonalPageActions);
