import alt from 'instances/altInstance';
import Auth from 'controllers/auth';
import Environment from 'environment';
import AuthActions from 'actions/AuthActions';
import { INVALID_TOKEN, XHR_STATUS, MISSING_PASSWORD, HOME_ROUTE } from 'app-constants';
import parseJWT from 'helpers/parseJWT';
import { browserHistory } from 'react-router';
import Analytics from 'instances/analytics';
import axios from 'axios';
import Settings from 'controllers/settings';
import ApplicationState from 'instances/application_state';
import R from 'ramda';
import { history } from 'byebye';
import { getPaymentPageUriForMagicLogin } from 'helpers/paymentPageUrl/getSubscriptionPaymentPageUri';
import getRedirectFromToken from 'helpers/getRedirectFromToken';
import magicLoginRedirectUrl from 'helpers/magicLoginRedirectUrl';

const isRedirectToPaymentProject = R.equals(Environment.paymentUri);

function redirectAfterLogin({ pathname, query, origin }) {
  if (origin && isRedirectToPaymentProject(origin)) {
    window.location = getPaymentPageUriForMagicLogin(pathname, query);
  } else {
    history.navigate({ pathname, query }, { trigger: true, replace: true });
  }
}

export default alt.createActions({
  loginWithEmailTokenAndRedirect(token) {
    const data = parseJWT(token);
    const { pathname, query, origin } = getRedirectFromToken(data);

    Auth.loginWithCredentials({ email_token: token })
      .then(() => {
        Analytics.track('Login Successful', {
          platform: 'blendle',
          login_type: 'email',
        });

        redirectAfterLogin({ pathname, query, origin });
      })
      .catch(err => {
        if (err.type === INVALID_TOKEN) {
          const userId = Auth.getId();
          // User is authenticated with same ID as in token
          if (userId === data.user_id) {
            // Navigate to the URL in token
            redirectAfterLogin({ pathname, query, origin });
            return;
          }

          this.setEmail(data.user_email);
          ApplicationState.set('requireAuthUrl', pathname);

          // User is authenticated with another account, logout.
          if (!!userId && userId !== data.user_id) {
            AuthActions.logout();
          }

          browserHistory.replace('/login');
          return;
        }

        throw err;
      });

    return null;
  },

  /**
   * Log in using email/username and password
   * @param {Object} credentials { login, password } or { facebook_id, facebook_access_token }
   */
  loginWithCredentials(credentials, analyticsPayload) {
    Auth.loginWithCredentials(credentials)
      .then(data => this.loginSuccess(data, analyticsPayload))
      .catch(err => {
        if (err.type === INVALID_TOKEN || err.type === MISSING_PASSWORD) {
          return this.loginError(err);
        }
        throw err;
      });

    return null;
  },

  loginSuccess(data, analyticsPayload = {}) {
    Analytics.track('Login Successful', {
      platform: 'blendle',
      ...analyticsPayload,
    });

    return data;
  },

  loginError(err) {
    const fallbackMessage = err.eventType || err.message || err.type;
    const event = err.type === INVALID_TOKEN ? 'InvalidCredentials' : fallbackMessage;

    Analytics.track('Login Error', {
      event,
      platform: 'blendle',
    });

    return err;
  },

  /**
   * Request a Login email using email/username
   * @param {string} login  userId or email
   * @param {string} itemId the ID of the item to redirect to
   * @param {string} redirectUrl the URL to be redirected to
   * @param {Object} Analytics payload
   */
  sendLoginEmail(login, itemId, redirectUrl = HOME_ROUTE, analytics = {}) {
    // Use 'email' key for an email and 'user_id' for an userId
    const data = {
      ...(login.includes('@') ? { email: login } : { user_id: login }),
      ...(itemId ? { item_id: itemId } : null),
      redirect: magicLoginRedirectUrl(redirectUrl),
    };

    const additionalAnalytics = {
      internal_location: itemId ? 'deeplink' : 'login',
    };

    Analytics.track('Magic Login Email Start', {
      ...analytics,
      ...additionalAnalytics,
    });

    axios
      .post(Settings.getLink('email_tokens'), data)
      .then(res => {
        Analytics.track('Magic Login Email Success', {
          ...analytics,
          ...additionalAnalytics,
        });

        this.sendLoginEmailSuccess(res);
      })
      .catch(err => {
        Analytics.track('Magic Login Email Error', {
          ...analytics,
          ...additionalAnalytics,
          error: err.status,
        });

        if (err.type === XHR_STATUS && err.status === 404) {
          return this.sendLoginEmailError(err);
        }
        throw err;
      });

    return null;
  },

  sendLoginEmailSuccess: x => x,

  sendLoginEmailError: x => x,

  setEmail(email) {
    return email;
  },
});
