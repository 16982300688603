import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import {
  compose,
  getDisplayName,
  setDisplayName,
  setPropTypes,
  withProps,
} from '@blendle/recompose';

/**
 * @param {(string|React.ReactNode)} Component
 */
const styledModule = (Component) =>
  /**
   * @param {...string} staticClassNames the className you want to add
   * @returns {React.Component}
   */
  (...staticClassNames) => {
    const hasStaticClassNames = staticClassNames && staticClassNames.length > 0;

    let displayName = getDisplayName(Component);

    if (hasStaticClassNames) {
      displayName += `.${staticClassNames.join('.')}`;
    }

    return compose(
      setDisplayName(`styledModule(${displayName})`),
      setPropTypes({
        children: node,
        className: string,
      }),
      withProps((props) => ({
        className: hasStaticClassNames
          ? classNames(...staticClassNames, props.className)
          : props.className,
      })),
    )((props) => <Component {...props} />);
  };

styledModule.h1 = styledModule('h1');
styledModule.h2 = styledModule('h2');
styledModule.h3 = styledModule('h3');
styledModule.p = styledModule('p');
styledModule.div = styledModule('div');
styledModule.main = styledModule('main');
styledModule.section = styledModule('section');
styledModule.article = styledModule('article');
styledModule.img = styledModule('img');
styledModule.video = styledModule('video');
styledModule.strong = styledModule('strong');
styledModule.span = styledModule('span');
styledModule.button = styledModule('button');
styledModule.a = styledModule('a');

export default styledModule;
