import React from 'react';
import { string } from 'prop-types';

function CheckmarkIconThin({ fill, ...props }) {
  return (
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.535 0.725708L4.23987 7.82545L1.444 5.15505L0 6.62517L4.19681 10.7257H4.21484L13 2.16584L11.535 0.725708Z"
        fill={fill}
      />
    </svg>
  );
}

CheckmarkIconThin.propTypes = {
  fill: string,
};

CheckmarkIconThin.defaultProps = {
  fill: '#FF0E61',
};

export default CheckmarkIconThin;
