import React from 'react';
import { FooterType } from 'modules/discoverPremium/config';
import styledModule from 'helpers/styledModule';
import { ADJUST_ADGROUP } from 'app-constants';
import AppButtons from 'components/WalletConversion/LandingPage/AppButtons';
import { oneOf } from 'prop-types';
import SendDownloadLink from '../SendDownloadLink';
import CSS from './styles.scss';
import AppDownloadBanner from '../AppDownloadBanner';

const StyledAppButtons = styledModule(AppButtons)(CSS.appButtons);

const Footer = ({ footer }) => {
  switch (footer) {
    case FooterType.APP_BUTTONS:
      return <StyledAppButtons adjustAdGroup={ADJUST_ADGROUP.FOOTER} />;
    case FooterType.DOWNLOAD_APP_LINK:
      return (
        <React.Fragment>
          <AppDownloadBanner />
          <SendDownloadLink />
        </React.Fragment>
      );
    default:
      return null;
  }
};

Footer.propTypes = {
  footer: oneOf([FooterType.APP_BUTTONS, FooterType.DOWNLOAD_APP_LINK]),
};

Footer.defaultProps = {
  footer: FooterType.APP_BUTTONS,
};

export default Footer;
