import React, { useEffect, useState } from 'react';
import { oneOf, string } from 'prop-types';
import Analytics from 'instances/analytics';
import { SUCCESS_DIALOG_VERSION, HOME_ROUTE, INTERNAL_LOCATION } from 'app-constants';
import PremiumSuccessOverlay from 'components/PremiumSuccessOverlay';
import Auth from 'controllers/auth';
import { history } from 'byebye';
import BrowserEnv from 'instances/browser_environment';
import GoogleAdwords from 'instances/googleadwords';
import { resetPreferredProductInterval } from 'helpers/preferredProductInterval';
import R from 'ramda';
import DaisyconPixel from 'helpers/trackingPixels/daisycon';
import { getSignupEntryPoint } from 'helpers/signupEntryPoint';
import useCookieConsent from 'hooks/useCookieConsent';

const isRegularOnboarding = R.equals(SUCCESS_DIALOG_VERSION.DEFAULT);
const isItemDeeplinkOnboarding = R.equals(SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK);
const isPremiumOnboadingInternalLocation = R.equals(INTERNAL_LOCATION.PREMIUM_ONBOARDUNG);
const sendTrialStartedEvent = R.anyPass([isRegularOnboarding, isItemDeeplinkOnboarding]);

const didSignupOnPremiumLandingPage = () => {
  const signupEntryPoint = getSignupEntryPoint();

  return isPremiumOnboadingInternalLocation(signupEntryPoint);
};

const renderAndroidVariant = BrowserEnv.isAndroidDevice();

const PremiumOverlayContainer = ({ version, itemId, testId }) => {
  const { hasGivenConsent } = useCookieConsent();
  const [isVisible, setIsVisible] = useState(true);
  const shouldRenderDaisyconTrackingPixel = hasGivenConsent && didSignupOnPremiumLandingPage();

  useEffect(() => {
    if (sendTrialStartedEvent(version)) {
      GoogleAdwords.conversion('optOutTrialStarted');
    }
    // Reset the preferred product interval, which we have no need for anymore
    // since the user just finished the payment in the previous step
    resetPreferredProductInterval();
  }, [version]);

  const onClose = () => {
    setIsVisible(false);

    if (isItemDeeplinkOnboarding(version)) {
      Analytics.track('Confirmation Overlay Dismissed', {
        internal_location: INTERNAL_LOCATION.ITEM,
        item_id: itemId,
      });
    } else {
      Analytics.track('Confirmation Overlay Dismissed', {
        internal_location: INTERNAL_LOCATION.TIMELINE_PREMIUM,
      });

      history.navigate(HOME_ROUTE, { trigger: true });
    }
  };

  return (
    <React.Fragment>
      {shouldRenderDaisyconTrackingPixel && <DaisyconPixel />}
      {isVisible && (
        <PremiumSuccessOverlay
          version={version}
          firstName={Auth.getUser().getFirstName()}
          onClose={onClose}
          renderAndroidVariant={renderAndroidVariant}
          testId={testId}
        />
      )}
    </React.Fragment>
  );
};

PremiumOverlayContainer.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW,
    SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK,
  ]),
  itemId: string,
  testId: string,
};

PremiumOverlayContainer.defaultProps = {
  version: SUCCESS_DIALOG_VERSION.DEFAULT,
  itemId: undefined,
  testId: undefined,
};

export default PremiumOverlayContainer;
