// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3q5w_{background-color:#f5f7f8;border-radius:4px;text-align:center;padding:42px 16px 16px;margin-top:19px;margin-bottom:65px;margin-left:-16px;margin-right:-16px}@media (min-width: 600px){.container-3q5w_{padding:70px 16px 44px}}@media (min-width: 700px){.container-3q5w_{margin-left:0;margin-right:0}}.featureWrapper-2qDid{display:flex;max-width:796px;margin:53px auto 0;flex-wrap:wrap;justify-content:space-between}.feature-1fS5U{max-width:386px;width:100%;margin:0 auto 27px}.copy-1sr7A{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:27px;color:#474747;letter-spacing:-0.02em;max-width:332px;margin:6px auto 20px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3q5w_",
	"featureWrapper": "featureWrapper-2qDid",
	"feature": "feature-1fS5U",
	"copy": "copy-1sr7A"
};
module.exports = exports;
