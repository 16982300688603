import React from 'react';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import Analytics from 'instances/analytics';
import CookieConsentActions from 'actions/CookieConsentActions';
import { setCookieConsent } from 'helpers/cookieConsent';
import { getPrivacyLink } from 'helpers/privacyLink';
import { translate, translateElement } from 'instances/i18n';
import useCookieConsent from 'hooks/useCookieConsent';
import FacebookActions from 'actions/FacebookActions';
import CSS from './styles.scss';

const BarContainer = styledModule.div(CSS.barContainer);
const HorizontalAlignment = styledModule.div(CSS.horizontalAlignment);
const Bar = styledModule.div(CSS.bar);
const Copy = styledModule.p(CSS.copy);
const ButtonSection = styledModule.div(CSS.buttonSection);
const StyledButton = styledModule(Button)(CSS.styledButton);
const AcceptButton = styledModule(StyledButton)(CSS.acceptButton);
const DeclineButton = styledModule(StyledButton)(CSS.declineButton);

export const CookieBar = () => {
  const { showCookieBar } = useCookieConsent();

  const onAccept = () => {
    const consent = { hasGivenConsent: true };
    CookieConsentActions.setConsentAndHideBar(consent);
    FacebookActions.initializeFacebook();
    setCookieConsent(consent);
    Analytics.track('Cookie Consent: Accepted');
  };

  const onDecline = () => {
    const consent = { hasGivenConsent: false };
    CookieConsentActions.setConsentAndHideBar(consent);
    setCookieConsent(consent);
    Analytics.track('Cookie Consent: Dismissed');
  };

  return showCookieBar ? (
    <BarContainer>
      <HorizontalAlignment>
        <Bar>
          <Copy>{translateElement('cookie_bar.contents', [getPrivacyLink()])}</Copy>
          <ButtonSection>
            <AcceptButton onClick={onAccept} color="cash-green" data-testid="accept-cookies">
              {translate('cookie_bar.accept')}
            </AcceptButton>
            <DeclineButton onClick={onDecline} color="transparent" data-testid="decline-cookies">
              {translate('cookie_bar.decline')}
            </DeclineButton>
          </ButtonSection>
        </Bar>
      </HorizontalAlignment>
    </BarContainer>
  ) : null;
};
