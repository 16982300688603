import Environment from 'environment';
import { getUtmString, getRefreshTokenIfDevelopmentEnv, buildQueryString } from './helpers';

function getSearchParams() {
  const utmString = getUtmString();
  const refreshToken = getRefreshTokenIfDevelopmentEnv();

  const searchParamsFragments = [refreshToken && `refreshToken=${refreshToken}`, utmString];

  return buildQueryString(searchParamsFragments.filter(Boolean));
}

export default function getSubscriptionOverviewPageUri() {
  const searchParams = getSearchParams();

  return [Environment.paymentUri, '/subscription', searchParams].join('');
}
