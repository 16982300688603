import LabStore from 'stores/LabStore';
import { LAB_EXPERIMENTS } from 'app-constants';

export const labExperimentEnabled = (key, experiments) => {
  const foundExperiment = experiments.find(experiment => experiment.key === key);
  return (foundExperiment && foundExperiment.enabled) || false;
};

export const editorButtonEnabled = () =>
  labExperimentEnabled(LAB_EXPERIMENTS.EDITOR_BUTTON, LabStore.getState().experiments);

export const textToSpeechEnabled = () =>
  labExperimentEnabled(LAB_EXPERIMENTS.TEXT_TO_SPEECH, LabStore.getState().experiments);

export const searchEnabled = () =>
  labExperimentEnabled(LAB_EXPERIMENTS.SEARCH, LabStore.getState().experiments);
