export default {
  name: 'development',
  api: 'https://static.blendle.com/api_development.json',
  tokens: 'https://ws-development.blendle.com/tokens',
  i18n: 'https://ws-development.blendle.com/i18n',
  providerConfigurations: 'https://static.blendle.nl/provider_configurations.json',
  analytics: 'https://events-staging.blendle.io/v1/events',
  gaTrackingId: 'UA-90032766-4', // google analytics tracking id,
  maintenanceFile: 'https://static.blendle.com/maintenance_development.json',
  ssl: true,
  paymentUri: 'https://payment.blendle-test.com',
  doubleclick_existing_user_id: 'DC-8327133/test/test_000+standard',
  doubleclick_pageview_id: 'DC-8327133/test/test_00+standard',
  doubleclick_success_id: 'DC-8327133/test/test_0+standard',
  landing_project_protocol: 'https',
  landing_project_host: 'development.blendle.com',
  facebookAppId: '1578814928872262',
  facebookVersion: 'v3.1',
  adjust_url: 'http://localhost:9000',
  adjust_tracker: 'fake-adjust-tracker',
  sentryApiKey: 'https://06183bae92f649afae50959edc520bf7@app.getsentry.com/19495',
  facebookPixelId: '742491399133724',
  twitterPixelId: 'nw3xj',
  doubleClickId: 'DC-8327133',
  graphQLHost: 'https://graph.blendle-sandbox.com/graphql',
  segmentEnvironmentName: 'development',
};
