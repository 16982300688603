// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1S-Zi{display:flex;width:266px;height:40px;padding:0;margin:15px 0;justify-content:space-between}.container-1S-Zi img{width:100%;height:100%}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1S-Zi"
};
module.exports = exports;
