import React from 'react';
import styledModule from 'helpers/styledModule';
import HorizontalAlignment from 'components/WalletConversion/LandingPage/HorizontalAlignment';
import { Label } from '@blendle/lego';
import staticImageUrl from 'helpers/staticImageUrl';
import { translate, translateElement } from 'instances/i18n';
import CSS from './styles.scss';

const Container = styledModule.section(CSS.container);
const BackgroundGradient = styledModule.div(CSS.backgroundGradient);
const StyledLabel = styledModule(Label)(CSS.label);
const Title = styledModule.h2(CSS.title);
const Paragraph = styledModule.p(CSS.paragraph);

const imagePath = staticImageUrl('/img/backgrounds/credits_screens');

const Credits = () => (
  <Container>
    <BackgroundGradient />
    <HorizontalAlignment>
      <StyledLabel backgroundGradient="razzmatazzGradient" color="snowWhite">
        {translate('discover_premium.credits.new')}
      </StyledLabel>
      <Title>{translateElement('discover_premium.credits.title')}</Title>
      <Paragraph>{translateElement('discover_premium.credits.text')}</Paragraph>
      <img
        src={`${imagePath}@2x.png?auto=format,compress`}
        srcSet={`${imagePath}.png?auto=format,compress 1x, ${imagePath}@2x.png?auto=format,compress 2x`}
        alt="A laptop, tablet and smartphone showing the new Credits feature"
      />
    </HorizontalAlignment>
  </Container>
);

export default Credits;
