import React from 'react';
import styledModule from 'helpers/styledModule';
import { APP_DEEPLINK, INTERNAL_LOCATION } from 'app-constants';
import Analytics from 'instances/analytics';
import CSS from './styles.scss';

const StyledButton = styledModule.button(CSS.button);

const onClick = () => {
  Analytics.track('Open App Button Clicked', {
    internal_location: INTERNAL_LOCATION.TIMELINE_PREMIUM,
  });

  window.open(APP_DEEPLINK.WALLET_CONVERSION_SUCCCESS);
};

const OpenAppButton = () => (
  <StyledButton onClick={onClick}>Breng me terug naar de app</StyledButton>
);

export default OpenAppButton;
