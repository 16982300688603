import { useEffect } from 'react';
import Analytics from 'instances/analytics';

const useEventOnMount = (eventName, payload = {}) => {
  useEffect(() => {
    Analytics.track(eventName, payload);
  }, []);
};

export default useEventOnMount;
