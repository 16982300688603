import { kebabCase } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { status as statusPropType } from 'libs/propTypes';
import { translate, translateElement } from 'instances/i18n';
import NotificationsHubContainer from 'containers/NotificationsHubContainer';
import { STATUS_INITIAL, STATUS_ERROR, STATUS_OK, STATUS_PENDING } from 'app-constants';
import NavigationBarContainer from 'containers/navigation/NavigationBarContainer';
import CSS from './Application.scss';

const { body } = document;
const html = body.parentNode;
const bodyClassList = body.classList;

function updateClassNames(props) {
  const { isLoggedIn } = props;

  bodyClassList.toggle('s-loggedin', isLoggedIn);
  bodyClassList.toggle('s-not-loggedin', !isLoggedIn);
  bodyClassList.remove('s-loading');
  bodyClassList.add('s-success');
}

function updateMetaInformation(languageCode) {
  html.lang = languageCode;
  document.title = translate('app.text.page_title');
}

function renderArea(className, childNode) {
  return childNode ? <div className={className}>{childNode}</div> : null;
}

class Application extends Component {
  static propTypes = {
    languageCode: PropTypes.string.isRequired,
    userAgent: PropTypes.object.isRequired,
    status: statusPropType.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    content: PropTypes.any,
    navigation: PropTypes.any,
    primaryNavigation: PropTypes.node,
    overlay: PropTypes.any,
    item: PropTypes.any,
    page: PropTypes.any,
    dialogue: PropTypes.any,
    legoDialog: PropTypes.node,
  };

  componentDidMount() {
    const { userAgent, languageCode } = this.props;
    updateClassNames(this.props);
    updateMetaInformation(languageCode);

    html.classList.add(
      kebabCase(`device-${userAgent.device.toLowerCase()}`),
      kebabCase(`os-${userAgent.operatingSystem.toLowerCase()}`),
      kebabCase(`browser-${userAgent.browser.toLowerCase()}`),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    updateClassNames(nextProps);

    const { languageCode, content, overlay, item } = this.props;

    if (nextProps.languageCode !== languageCode) {
      updateMetaInformation(nextProps.languageCode);
    }

    if (!nextProps.content && content) {
      this.previousContent = content;
    }

    if (nextProps.overlay) {
      this.previousOverlay = null;
    }

    if (nextProps.item) {
      this.previousItem = null;
    }

    if (nextProps.dialogue || nextProps.overlay || nextProps.legoDialog) {
      if (!nextProps.overlay && !this.previousOverlay) {
        this.previousOverlay = overlay;
      }
      if (!nextProps.item && !this.previousItem) {
        this.previousItem = item;
      }
    }

    if (nextProps.content) {
      if (!nextProps.item && this.previousItem) {
        this.previousItem = null;
      }
      if (!nextProps.overlay && this.previousOverlay) {
        this.previousOverlay = null;
      }
    }
  }

  getStatusClassName() {
    const map = {
      [STATUS_INITIAL]: 'statusInit',
      [STATUS_OK]: 'statusOk',
      [STATUS_PENDING]: 'statusPending',
      [STATUS_ERROR]: 'statusError',
    };
    const { status } = this.props;

    return CSS[map[status]];
  }

  renderPrimaryNavigation() {
    const { primaryNavigation, content, navigation } = this.props;

    if (primaryNavigation) {
      return primaryNavigation;
    }

    if (content || this.previousContent) {
      return <NavigationBarContainer subNavigation={navigation} />;
    }

    return null;
  }

  render() {
    const { content, item, overlay, dialogue, legoDialog, page } = this.props;
    return (
      <div id="app" className={this.getStatusClassName()}>
        <div className="a-main">
          {this.renderPrimaryNavigation()}
          {renderArea('a-content', content || this.previousContent)}
        </div>
        {renderArea('a-item l-overlay', item || this.previousItem)}
        {renderArea('a-overlay l-overlay', overlay || this.previousOverlay)}
        <div className="a-dialogue l-overlay">{dialogue}</div>
        {legoDialog}
        {renderArea('a-page', page)}
        {translateElement(<div className="a-footer" />, 'footer.html')}
        <div className="a-tooltips" />
        <NotificationsHubContainer />
      </div>
    );
  }
}

export default Application;
