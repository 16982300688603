import environment from 'environment';

const whitelist = [
  'huibertscholtens',
  'mathieuh', // Mathieu Heruer
  'albert56', // Investor
  'janwillemmeer',
  'jasperstein',
  'arthurkosten', // Arthur Kosten, External
  'matthiasbraun', // Matthias Braun, Axel Springer
  'mattbraun', // Matthias Braun, Axel Springer, Second Account,
  // Following users are part of our User Testing Group
  'bouwhouse',
  'rikmoedt',
  'maikeveltman',
  'harryotse',
  'andreasvos',
  'cvanschie',
  'joshi',
  'itom',
  'slaarhuis',
  'govertwondergem',
  'jeanluc033',
  'frankbaars',
  'leonmelein',
  'lmaarschalkerweerd',
  'testuser1',
  'jimkrokke',
  'gcconvent',
  'jobbilsen',
  // end of the list for User Testing Group
  'boudewijnpols1', // Vodafone executive
  'laurabaak', // Vodafone executive
  'olbyova', // US test user
  'tmpce4ee884d35e378b7', // Noortje Habets
];

export default function(user) {
  const hasUserPref = user.get('preferences.private_lab_access') === 'true';
  const hasBlendleEmail = /@blendle\.com$/.test(user.get('email'));
  const hasWhitelistedUid = whitelist.includes(user.id.toLowerCase());
  const isAllowedEnviroment = environment.name === 'approval';

  return hasUserPref || hasBlendleEmail || hasWhitelistedUid || isAllowedEnviroment;
}
