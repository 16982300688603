import environment from 'environment';
import doubleclick from './doubleclick';
import twitter from './twitter';
import facebook from './facebook';

const ONBOARDING_URL_PATTERNS = /\/getpremium/i;
const SUCCESS_URL_PATTERNS = /\/home\/success|\/i\/.*verified=true/i;

function trackDoubleclickPageView() {
  doubleclick.track('conversion', {
    allow_custom_scripts: true,
    send_to: environment.doubleclick_pageview_id,
  });
}

function trackDoubleclickSuccess() {
  doubleclick.track('conversion', {
    allow_custom_scripts: true,
    send_to: environment.doubleclick_success_id,
  });
}

function trackTwitterPageView() {
  twitter.track('PageView');
}

function trackFacebookPageView() {
  facebook.track('PageView');
}

function trackPageView(location, isLoggedIn) {
  const pathWithSearch = `${location.pathname}${location.search}`;

  const isOnboardingUrl = ONBOARDING_URL_PATTERNS.test(pathWithSearch);
  const isSuccessUrl = SUCCESS_URL_PATTERNS.test(pathWithSearch);

  if (isSuccessUrl) {
    trackDoubleclickSuccess();
    trackTwitterPageView();
    trackFacebookPageView();
  } else if (!isLoggedIn) {
    trackDoubleclickPageView();
    trackTwitterPageView();
    trackFacebookPageView();
  } else if (isLoggedIn && isOnboardingUrl) {
    trackDoubleclickPageView();
    trackTwitterPageView();
    trackFacebookPageView();
  }
}

export default trackPageView;
