import { asyncRoute } from 'helpers/routerHelpers';
import React from 'react';
import Analytics from 'instances/analytics';
import { track } from 'helpers/premiumOnboardingEvents';
import { countryEligibleForPremium } from 'helpers/premiumEligibility';
import Country from 'instances/country';
import AuthStore from 'stores/AuthStore';
import { getTrackingURL } from 'helpers/url';
import { getLocationInLayout, resetLocationInLayout } from 'helpers/locationInLayout';
import {
  onEnterNoAuth,
  onEnterAffiliate,
  debouncedTrackEvent,
  debouncedTrack,
} from './helpers/routes';

function trackPageViewWithGoogleAnalytics(location = window.location) {
  debouncedTrack(getTrackingURL(location));
}

function onLeaveNoAuth() {
  document.body.classList.remove('m-premiumsignup');
}

const baseRoute = { module: 'premiumsignup' };

function route(path, getComponentsHandler, other) {
  return {
    ...baseRoute,
    path,
    requireAuth: false,
    getComponents: asyncRoute(getComponentsHandler),
    ...other,
  };
}

const signupChildRoutes = [
  route(
    'signup',
    (nextState, cb) => {
      track(Analytics, 'Signup/Start', {
        location_in_layout: getLocationInLayout(),
      });
      trackPageViewWithGoogleAnalytics();

      // Remove the stored location_in_layout to make sure we don't sent it along with other events
      resetLocationInLayout();

      require.ensure(
        [],
        () => {
          cb(null, {
            body: require('./components/Authentication'),
            footer: require('./components/SignupFooter'),
            overlay: require('./components/SignupDisclaimer'),
          });
        },
        'premiumSignup',
      );
    },
    {
      onEnter: onEnterNoAuth,
      activeStepIndex: 0,
      animationKey: 'auth',
    },
  ),
  route(
    'login',
    (nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            body: require('./components/Authentication'),
            footer: require('./components/LoginFooter'),
            overlay: require('./components/SignupDisclaimer'),
          });
        },
        'premiumSignup',
      );
    },
    {
      onEnter: onEnterNoAuth,
      activeStepIndex: 1,
      animationKey: 'auth',
    },
  ),
  route(
    'loginwarning',
    (nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            body: require('./containers/LoginWarning'),
          });
        },
        'premiumSignup',
      );
    },
    {
      onEnter: onEnterNoAuth,
    },
  ),
  route(
    'reset',
    (nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            body: require('./components/Authentication'),
          });
        },
        'premiumSignup',
      );
    },
    {
      onEnter: onEnterNoAuth,
      activeStepIndex: 2,
      animationKey: 'auth',
    },
  ),
  route(
    'channels',
    (nextState, cb) => {
      trackPageViewWithGoogleAnalytics();

      require.ensure(
        [],
        () => {
          cb(null, {
            header: require('./components/Progress'),
            body: require('./containers/Channels'),
            footer: require('./containers/ChannelsFooterContainer'),
          });
        },
        'premiumSignup',
      );
    },
    {
      requireAuth: true,
      isOnboarding: true,
      fullScreen: true,
      progress: 30,
    },
  ),
  route(
    'publications',
    (nextState, cb) => {
      trackPageViewWithGoogleAnalytics();

      require.ensure(
        [],
        () => {
          cb(null, {
            header: require('./components/Progress'),
            body: require('./containers/Publications'),
            footer: require('./containers/PublicationsFooterContainer'),
          });
        },
        'premiumSignup',
      );
    },
    {
      requireAuth: true,
      isOnboarding: true,
      fullScreen: true,
      progress: 60,
    },
  ),
  route(
    'confirm',
    (nextState, cb) => {
      trackPageViewWithGoogleAnalytics();
      require.ensure(
        [],
        () => {
          cb(null, {
            header: require('./components/Progress'),
            body: require('./containers/ConfirmEmail'),
          });
        },
        'premiumSignup',
      );
    },
    {
      isOnboarding: true,
      fullScreen: true,
      progress: 90,
    },
  ),
  route(
    'redeem',
    (nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            header: require('./components/Progress'),
            body: require('./containers/Redeem'),
          });
        },
        'premiumSignup',
      );
    },
    { fullScreen: true, progress: 90 },
  ),
  route(
    'change-email',
    (nextState, cb) => {
      track(Analytics, 'Signup Change Email');
      require.ensure(
        [],
        () => {
          cb(null, {
            body: require('./containers/ChangeEmail'),
          });
        },
        'premiumSignup',
      );
    },
    { fullScreen: true },
  ),
  route(
    'activate',
    (nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            header: require('./components/Progress'),
            body: require('./containers/Activate'),
          });
        },
        'premiumSignup',
      );
    },
    { fullScreen: true, progress: 90 },
  ),
  route(
    'vodafone-connect',
    (nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            header: require('./components/Progress'),
            body: require('./components/VodafoneConnect'),
          });
        },
        'premiumSignup',
      );
    },
    { fullScreen: true, progress: 0 },
  ),
];

function premiumAudioSignupRoute(path) {
  return {
    ...baseRoute,
    path,
    requireAuth: false,
    onEnter: onEnterNoAuth,
    onLeave: onLeaveNoAuth,
    getComponents: asyncRoute((nextState, cb) => {
      trackPageViewWithGoogleAnalytics();

      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/PremiumAudioLanding'),
            primaryNavigation: () => <span />,
          });
        },
        'premiumSignup',
      );
    }),
    childRoutes: signupChildRoutes,
  };
}

function premiumAffiliateRoute(path) {
  return {
    ...baseRoute,
    path,
    requireAuth: false,
    onEnter: onEnterAffiliate,
    onLeave: onLeaveNoAuth,
    getComponents: asyncRoute((nextState, cb) => {
      trackPageViewWithGoogleAnalytics();
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/AffiliatePremiumLanding'),
            primaryNavigation: require('./containers/PremiumNavigation'),
          });
        },
        'premiumSignup',
      );
    }),
    childRoutes: signupChildRoutes,
  };
}

function premiumDeeplinkRoute(path) {
  return {
    ...baseRoute,
    path,
    onEnter: (nextState, replace) => {
      const {
        location: { pathname },
      } = nextState;
      const { user } = AuthStore.getState();
      const isLoggedIn = !!user;

      if (isLoggedIn || !countryEligibleForPremium()) {
        if (isLoggedIn) {
          debouncedTrackEvent(pathname, 'redirect', 'user logged in');
        } else {
          debouncedTrackEvent(pathname, 'redirect', 'country redirect', Country.getCountryCode());
        }

        replace(`/item/${nextState.params.itemId}`);
      }
    },
  };
}

const signupRoutes = [
  premiumAudioSignupRoute('getpremium'),
  premiumAffiliateRoute('getpremium/actie/:affiliateId'),
  premiumDeeplinkRoute('getpremium/item/:itemId'),
  premiumDeeplinkRoute('getpremium/item/:itemId/referrer/:ref'),
  premiumDeeplinkRoute('getpremium/item/:itemId/ref/:ref'),
  premiumDeeplinkRoute('getpremium/item/:itemId/r/:ref'),
  // `/login` and `/signup` are here to make sure logged in users
  // don't see a 404 page but get redirected to the reader
  premiumDeeplinkRoute('getpremium/item/:itemId/login'),
  premiumDeeplinkRoute('getpremium/item/:itemId/signup'),
];
export default signupRoutes;
