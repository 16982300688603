// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".barContainer-hXICG{text-align:center;width:100%;position:fixed;left:0;bottom:0;box-shadow:0 -2px 10px rgba(0,0,0,0.02),0 -1px 0 #e5e5e5;background-color:#fff;z-index:1000000}.horizontalAlignment-3l4jD{max-width:1024px;margin:0 auto;padding:0 16px}@media (min-width: 1056px){.horizontalAlignment-3l4jD{padding:0}}.bar-3xc-_{font-family:\"GT-Walsheim\",sans-serif;width:100%;display:flex;padding-top:11px;padding-bottom:16px;flex-direction:column}@media (min-width: 600px){.bar-3xc-_{flex-direction:row;padding-top:20px;padding-bottom:20px}}.copy-2KCR8{font-size:12px;line-height:17px;color:#3d3d3d;text-align:left;margin:0}.copy-2KCR8 a{color:#ff1060}@media (min-width: 600px){.copy-2KCR8{font-size:14px;line-height:20px}}.buttonSection-2fKHS{display:flex;flex-direction:row;margin-top:12px}@media (min-width: 600px){.buttonSection-2fKHS{margin-top:0;margin-left:16px;flex-direction:column}}@media (min-width: 800px){.buttonSection-2fKHS{margin-left:32px;flex-direction:row}}.styledButton-1gAtp{font-weight:500;border-radius:4px;align-self:center;height:36px;padding:0 !important}@media (min-width: 600px){.styledButton-1gAtp{height:40px}}.acceptButton-1bu0_{box-shadow:0 1px 3px rgba(0,0,0,0.1);margin-right:8px;width:100%}@media (min-width: 600px){.acceptButton-1bu0_{width:144px;margin-bottom:8px;margin-right:0}}@media (min-width: 800px){.acceptButton-1bu0_{margin-bottom:0;margin-right:8px}}.declineButton-1216h{color:#5b5b5b;width:100%}@media (min-width: 600px){.declineButton-1216h{width:144px}}@media (min-width: 800px){.declineButton-1216h{width:104px}}\n", ""]);
// Exports
exports.locals = {
	"barContainer": "barContainer-hXICG",
	"horizontalAlignment": "horizontalAlignment-3l4jD",
	"bar": "bar-3xc-_",
	"copy": "copy-2KCR8",
	"buttonSection": "buttonSection-2fKHS",
	"styledButton": "styledButton-1gAtp",
	"acceptButton": "acceptButton-1bu0_",
	"declineButton": "declineButton-1216h"
};
module.exports = exports;
