import { asyncRoute } from 'helpers/routerHelpers';
import { capabilityIsLockedAsync } from 'selectors/capabilities';
import {
  FEATURE_CAPABILITY_MICROPAYMENTS,
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
} from 'app-constants';
import { getCapabilityLockedRoute } from 'helpers/capabilities';

function route(path, page) {
  return {
    module: 'settings',
    path,
    components: { page },
  };
}

const redirectSubscriptionPage = from => ({
  path: from,
  onEnter: (nextState, replace) => {
    const { location } = nextState;
    const { pathname, search } = location;

    replace(`${pathname.replace('settings/subscriptions', 'settings/connections')}${search}`);
  },
});

function micropaymentWalletRoute(path, page) {
  return {
    module: 'settings',
    path,
    onEnter: (_, replace, callback) => {
      capabilityIsLockedAsync(FEATURE_CAPABILITY_MICROPAYMENTS).then(capabilityIsLocked => {
        if (capabilityIsLocked) {
          replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_MICROPAYMENTS));
        }

        callback();
      });
    },
    components: { page },
  };
}

function creditsWalletRoute(path, page) {
  return {
    module: 'settings',
    path,
    onEnter: (_, replace, callback) => {
      capabilityIsLockedAsync(FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS).then(capabilityIsLocked => {
        if (capabilityIsLocked) {
          replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS));
        }

        callback();
      });
    },
    components: { page },
  };
}

export default [
  {
    module: 'settings',
    path: '/settings',
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            overlay: require('modules/settings/containers/SettingsModuleContainer'),
          });
        },
        'settings',
      );
    }),
    indexRoute: route('', 'Profile'),
    childRoutes: [
      route('profile', 'Profile'),
      route('profile/:action', 'Profile'),
      route('emails', 'Emails'),
      micropaymentWalletRoute('wallet', 'Wallet'),
      creditsWalletRoute('credits', 'Credits'),
      route('termsandconditions', 'TermsAndConditions'),
      route('resetpassword', 'ResetPassword'),
      route('recurring/activate', 'RecurringActivate'),
      route('lab', 'Lab'),
      route('social', 'Connections'),
      route('connections', 'Connections'),
      route('connections/disabled', 'SubscriptionsWithDisabled'),
      route('connections/callback/:provider', 'SubscriptionCallback'),
      route('connections/callback/:provider/:code', 'SubscriptionCallback'),
      route('connections/:provider', 'Connections'),
      route('connections/:provider/:status', 'SubscriptionsResult'),
    ],
  },
  redirectSubscriptionPage('settings/subscriptions'),
  redirectSubscriptionPage('settings/subscriptions/disabled'),
  redirectSubscriptionPage('settings/subscriptions/callback/:provider'),
  redirectSubscriptionPage('settings/subscriptions/callback/:provider/:code'),
  redirectSubscriptionPage('settings/subscriptions/:provider'),
  redirectSubscriptionPage('settings/subscriptions/:provider/:status'),
];
