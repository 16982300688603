// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".logo-1LrSr{height:54px;margin-left:15px;margin-right:15px}@media (min-width: 600px){.logo-1LrSr{height:66px;margin-left:20px;margin-right:30px}}\n", ""]);
// Exports
exports.locals = {
	"logo": "logo-1LrSr"
};
module.exports = exports;
