import React from 'react';
import styledModule from 'helpers/styledModule';
import staticImageUrl from 'helpers/staticImageUrl';
import { ADJUST_ADGROUP } from 'app-constants';
import AppButtons from 'components/WalletConversion/LandingPage/AppButtons';
import { translate } from 'instances/i18n';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
const Box = styledModule.div(CSS.box);
const Content = styledModule.div(CSS.content);
const Title = styledModule.h2(CSS.title);
const Copy = styledModule.p(CSS.copy);
const StyledAppButtons = styledModule(AppButtons)(CSS.appButtons);
const Device = styledModule.img(CSS.device);
const Mobile = styledModule.div(CSS.mobile);
const Desktop = styledModule.div(CSS.desktop);

const imagePath = staticImageUrl('/img/backgrounds/iPhone_X_timeline_partial');

const AppDownloadBanner = () => (
  <Container>
    <Box>
      <Content>
        <Desktop>
          <Title>{translate('discover_premium.download_app.desktop.title')}</Title>
          <Copy>{translate('discover_premium.download_app.desktop.text')}</Copy>
        </Desktop>
        <Mobile>
          <Title>{translate('discover_premium.download_app.mobile.title')}</Title>
          <Copy>{translate('discover_premium.download_app.mobile.text')}</Copy>
        </Mobile>
        <StyledAppButtons adjustAdGroup={ADJUST_ADGROUP.BANNER} />
      </Content>
    </Box>
    <Device
      src={`${imagePath}@2x.png?auto=format,compress`}
      srcSet={`${imagePath}.png?auto=format,compress 1x, ${imagePath}@2x.png?auto=format,compress 2x`}
      alt="iPhone X Covers"
    />
  </Container>
);

export default AppDownloadBanner;
