import alt from 'instances/altInstance';
import CreditsKioskNavigationActions from '../actions/CreditsKioskNavigationActions';
import SharedNavigationStore from './SharedNavigationStore';

class CreditsKioskNavigationStore extends SharedNavigationStore {
  constructor() {
    super();

    this.bindActions(CreditsKioskNavigationActions);
  }
}

export default alt.createStore(CreditsKioskNavigationStore, 'CreditsKioskNavigationStore');
