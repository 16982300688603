import { gql } from 'apollo-boost';
import { DEFAULT_ISSUES_PER_PAGE } from 'app-constants';
import { GlobalFragments, IssueFragments, ArticleFragments } from './fragments';

export const PROVIDER_ISSUES_QUERY = gql`
  query ProviderIssues($ids: [ID!]!, $first: Int = ${DEFAULT_ISSUES_PER_PAGE}, $after: String) {
    nodes(ids: $ids) {
      ... on Provider {
        providerId
        issues(first: $first, after: $after) {
          pageInfo {
            ...pageInfoFragment
          }
          nodes {
            ...issueFragment
          }
        }
      }
    }
  }
  ${GlobalFragments.pageInfo}
  ${IssueFragments.issue}
`;

export const ISSUE_QUERY = gql`
  query Issue($id: ID!) {
    node(id: $id) {
      ...issueFragment
    }
  }
  ${IssueFragments.issue}
`;

export const ARTICLE_QUERY = gql`
  query Article($id: ID!) {
    node(id: $id) {
      ...articleFragment
    }
  }
  ${ArticleFragments.article}
`;
