import React from 'react';
import { translate } from 'instances/i18n';
import styledModule from 'helpers/styledModule';
import { string } from 'prop-types';
import { NavigationBar, NavigationLeft, NavigationRight } from '@blendle/lego';
import LogoLink from 'components/navigation/LogoLink';
import UserDropdownContainer from 'containers/navigation/UserDropdownContainer';
import { convertToLandingProjectURL } from 'libs/middleware/routerRedirectToLanding';

import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);
const StyledNavigationBar = styledModule(NavigationBar)(CSS.navigationBar);
const StyledLink = styledModule.a(CSS.publishersOverviewLink);

const Navigation = ({ logoColor, textColor }) => {
  return (
    <Container>
      <StyledNavigationBar>
        <NavigationLeft>
          <LogoLink className={CSS.logo} width={97} height={26} color={logoColor} />
        </NavigationLeft>
        <NavigationRight>
          <StyledLink href={convertToLandingProjectURL('/aanbod')} style={{ color: textColor }}>
            {translate('navigation.links.publishersOverview.label')}
          </StyledLink>
          <UserDropdownContainer />
        </NavigationRight>
      </StyledNavigationBar>
    </Container>
  );
};
Navigation.propTypes = {
  logoColor: string,
  textColor: string,
};

Navigation.defaultProps = {
  logoColor: undefined,
  textColor: '#fff',
};

export default Navigation;
