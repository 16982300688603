import alt from 'instances/altInstance';

class SyncActions {
  addAction = (action, payload) => ({
    type: action,
    payload,
  });
}

export default alt.createActions(SyncActions);
