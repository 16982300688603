// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-YPeLQ{margin-top:35px}@media (min-width: 600px){.container-YPeLQ{margin-top:52px}}.intro-1xClI{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:25px;color:#333;text-align:center}.benefitWrapper-1z7CS{margin-top:23px;display:grid;grid-template-columns:1fr;grid-column-gap:15px;grid-row-gap:13px}@media (min-width: 600px){.benefitWrapper-1z7CS{grid-template-columns:1fr 1fr 1fr}}.benefit-1WBMn{font-family:\"GT-Walsheim\",sans-serif;font-size:16px;color:#5b5b5b;display:flex}.checkmark-eNeMZ{margin-top:5px;margin-right:11px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-YPeLQ",
	"intro": "intro-1xClI",
	"benefitWrapper": "benefitWrapper-1z7CS",
	"benefit": "benefit-1WBMn",
	"checkmark": "checkmark-eNeMZ"
};
module.exports = exports;
