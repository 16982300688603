/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';
import staticImageUrl from 'helpers/staticImageUrl';
import Link from 'components/Link';

const PlayStoreButton = props => (
  <Link target="_blank" {...props}>
    <img src={staticImageUrl('/img/buttons/google-play.svg')} alt="Google Play" />
  </Link>
);

PlayStoreButton.propTypes = {
  href: string,
};

PlayStoreButton.defaultProps = {
  href: 'https://play.google.com/store/apps/details?id=com.blendle.app',
};

export default PlayStoreButton;
