import React, { useEffect } from 'react';
import { oneOf } from 'prop-types';
import {
  DISCOVER_PREMIUM_PAGE_VERSION,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
  FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER,
  FEATURE_CAPABILITY_MICROPAYMENTS,
} from 'app-constants';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { branch } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import { capabilityIsLocked, hasCapability } from 'selectors/capabilities';
import { compose, __ } from 'ramda';
import redirect from 'higher-order-components/redirect';
import Analytics from 'instances/analytics';
import WalletMigrationActions from 'actions/WalletMigrationActions';
import { determineVersion } from '../helpers/version';
import DiscoverPremiumContext from '../context/DiscoverPremiumContext';
import { discoverPremiumConfig } from '../config';
import PremiumOnlyUserLanding from '../components/Landings/PremiumOnlyUserLanding';
import UpsellOnlyOfferLanding from '../components/Landings/UpsellOnlyOfferLanding';

const onCtaClick = ({ internalLocation, locationInLayout, offeredNumberOfMonths }) => {
  Analytics.track('Premium Migration Offer Clicked', {
    internal_location: internalLocation,
    location_in_layout: locationInLayout,
    migration_offer: `${offeredNumberOfMonths}_months`,
  });
};

export const Container = ({ version }) => {
  useEffect(() => {
    if (version === DISCOVER_PREMIUM_PAGE_VERSION.UPSELL_ONLY) {
      WalletMigrationActions.getMigrationOffer();
    }
  }, [version]);

  switch (version) {
    case DISCOVER_PREMIUM_PAGE_VERSION.PREMIUM_ONLY:
      return (
        <DiscoverPremiumContext.Provider
          value={{
            ...discoverPremiumConfig.get(version),
            onCtaClick,
          }}
        >
          <PremiumOnlyUserLanding data-testid="premium-user-landing" />
        </DiscoverPremiumContext.Provider>
      );
    case DISCOVER_PREMIUM_PAGE_VERSION.UPSELL_ONLY:
      return (
        <DiscoverPremiumContext.Provider
          value={{
            ...discoverPremiumConfig.get(version),
            onCtaClick,
          }}
        >
          <UpsellOnlyOfferLanding data-testid="upsell-only-offer-landing" />
        </DiscoverPremiumContext.Provider>
      );
    default:
      return null;
  }
};

Container.propTypes = {
  version: oneOf([
    DISCOVER_PREMIUM_PAGE_VERSION.PREMIUM_ONLY,
    DISCOVER_PREMIUM_PAGE_VERSION.UPSELL_ONLY,
  ]),
};

Container.defaultProps = {
  version: undefined,
};

const hasBlendlePremium = (state) => hasCapability(state, FEATURE_CAPABILITY_BLENDLE_PREMIUM);
const hasNoMicropayments = capabilityIsLocked(__, FEATURE_CAPABILITY_MICROPAYMENTS);
const blendleIsLocked = capabilityIsLocked(__, FEATURE_CAPABILITY_ACCESS_TO_BLENDLE);
const hasUpsellWalletOffer = (state) =>
  hasCapability(state, FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER);

function mapStateToProps({ featureCapabilitiesState }, props) {
  const isPremiumOnlyUser =
    hasNoMicropayments(featureCapabilitiesState) && hasBlendlePremium(featureCapabilitiesState);
  const isUpsellOnlyUserWithOffer =
    blendleIsLocked(featureCapabilitiesState) && hasUpsellWalletOffer(featureCapabilitiesState);

  const version = determineVersion({
    isPremiumOnlyUser,
    isUpsellOnlyUserWithOffer,
  });

  if (!version) {
    return {
      shouldRedirect: true,
    };
  }

  return {
    version,
    ...props,
  };
}

mapStateToProps.stores = {
  FeatureCapabilitiesStore,
};

const enhance = compose(
  altConnect(mapStateToProps),
  branch(({ shouldRedirect }) => shouldRedirect, redirect({ replace: true, to: '/' })),
);

export default enhance(Container);
