import { gql } from 'apollo-boost';

export const GlobalFragments = {
  pageInfo: gql`
    fragment pageInfoFragment on PageInfo {
      hasNextPage
      endCursor
    }
  `,
  coverImage: gql`
    fragment coverImageFragment on CoverImage {
      url
      size {
        width
        height
      }
    }
  `,
};

export const IssueFragments = {
  issue: gql`
    fragment issueFragment on Issue {
      id
      bnlId
      articleIds
      browsable
      publishedDate
      provider {
        id
        providerId
        name
      }
      coverImage {
        ...coverImageFragment
      }
    }
    ${GlobalFragments.coverImage}
  `,
};

export const ArticleFragments = {
  article: gql`
    fragment articleFragment on Article {
      audioTrack {
        uuid
      }
      bnlId
      cardDisplayType
      contentPreviewElements {
        content
        type
      }
      entities {
        nodes {
          entityId
          label
        }
      }
      featuredImage {
        url
      }
      id
      provider {
        name
        providerId
      }
      publishedAt
      readingTime
      viewerContext {
        formattedPrice
        liked
        pinned
        opened
        likeCount
        listened
      }
    }
  `,
};
