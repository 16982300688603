import environment from 'environment';

function wsjUri() {
  if (environment.name === 'approval') {
    return 'https://int.accounts.dowjones.com/authorize?scope=openid+given_name+family_name+email+roles+trackid+offline_access&client_id=epxrVNV3i0lc9FeriBh3DKCnuxax4l8r&response_type=code&redirect_uri=https://approval.blendle.com/settings/subscriptions/callback/wsj&connection=DJldap&ui_locales=en-us-x-wsj-3';
  }
  return 'https://accounts.dowjones.com/authorize?scope=openid+given_name+family_name+email+roles+trackid+offline_access&client_id=nrTcVuYue97b6hno2oSrZkfOWGS0ykRK&response_type=code&redirect_uri=https://blendle.com/settings/subscriptions/callback/wsj&connection=DJldap&ui_locales=en-us-x-wsj-3';
}

function telegraafUri() {
  if (environment.name === 'approval') {
    return 'https://triplea-acc.telegraaf.nl/api/o/authorize/?response_type=code&client_id=wOV7SdNG4wikSwQI4JwsZKsD43PHSspFJ4X8AzmQ&scope=subscription_read&redirect_uri=https%3A%2F%2Fapproval.blendle.com%2Fsettings%2Fsubscriptions%2Fcallback%2Ftelegraaf';
  }
  if (environment.name === 'staging') {
    return 'https://triplea.telegraaf.nl/api/o/authorize/?response_type=code&client_id=kCHMTmUlrqp1tVSkJMjrj8yWgS3MhpV52H5UBp3o&scope=subscription_read&redirect_uri=https%3A%2F%2Fstaging.blendle.com%2Fsettings%2Fsubscriptions%2Fcallback%2Ftelegraaf';
  }
  return 'https://triplea.telegraaf.nl/api/o/authorize/?response_type=code&client_id=kCHMTmUlrqp1tVSkJMjrj8yWgS3MhpV52H5UBp3o&scope=subscription_read&redirect_uri=https%3A%2F%2Fblendle.com%2Fsettings%2Fsubscriptions%2Fcallback%2Ftelegraaf';
}

function genjUri(url, service) {
  return `${url}?continueUrl=https%3A%2F%2Fblendle.com%2Fsettings%2Fsubscriptions%2Fcallback%2F${service}`;
}

function sueddeutschezeitungUri(service) {
  if (environment.name === 'approval') {
    return 'https://id-stage.sueddeutsche.de/service/ticket?redirect_uri=https://approval.blendle.com/settings/subscriptions/callback/sueddeutschezeitung&service_id=blendle';
  }
  return 'https://id.sueddeutsche.de/service/ticket?redirect_uri=https://blendle.com/settings/subscriptions/callback/sueddeutschezeitung&service_id=blendle';
}

const subscriptions = [
  {
    id: 'lc',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'dvhn',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'sueddeutschezeitung',
    name: 'Süddeutsche Zeitung (Digital)',
    url: sueddeutschezeitungUri(),
  },
  {
    id: 'vn',
    secondField: {
      secret: true,
    },
  },
  {
    id: '360',
    firstField: {
      label: 'E-mail',
    },
    secondField: {
      secret: true,
    },
  },
  {
    id: 'elsevier',
    intro:
      'Voer je abonneenummer en postcode in van je account bij <strong>Elsevier</strong> om je abonnement te koppelen.',
    firstField: {
      label: 'Abonneenummer',
    },
    secondField: {
      label: 'Postcode',
      filter: value => value.replace(' ', ''),
    },
  },
  {
    id: 'newscientist',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'tpomagazine',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'brightideas',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'yoga528',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'happinez',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'historischnieuwsblad',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'filosofiemagazine',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'maarten',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'nedag',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'kicker',
    secondField: {
      secret: true,
    },
  },
  {
    id: 'wallstreetjournal840',
    url: wsjUri(),
    redirect: true,
  },
  {
    id: 'wsj',
    url: wsjUri(),
    redirect: true,
  },
  {
    id: 'quest',
    url: genjUri('https://www.quest.nl/entitlement/login/blendle/quest', 'quest'),
    redirect: true,
  },
  {
    id: 'questhistorie',
    url: genjUri('https://www.quest.nl/entitlement/login/blendle/quest_historie', 'questhistorie'),
    redirect: true,
  },
  {
    id: 'questpsychologie',
    url: genjUri(
      'https://www.quest.nl/entitlement/login/blendle/quest_psychologie',
      'questpsychologie',
    ),
    redirect: true,
  },
  {
    id: 'nationalgeographic',
    url: genjUri(
      'https://www.natgeoshop.nl/entitlement/login/blendle/nationalgeographic',
      'nationalgeographic',
    ),
    redirect: true,
  },
  {
    id: 'jan',
    url: genjUri('https://www.jan-magazine.nl/entitlement/login/blendle/jan', 'jan'),
    redirect: true,
  },
  {
    id: 'glamour',
    url: genjUri('https://www.glamour.nl/entitlement/login/blendle/glamour', 'glamour'),
    redirect: true,
  },
  {
    id: 'vogue',
    url: genjUri('https://www.vogue.nl/entitlement/login/blendle/vogue', 'vogue'),
    redirect: true,
  },
  {
    id: 'telegraaf',
    name: 'Telegraaf (Digitaal)',
    url: telegraafUri(),
    redirect: true,
  },
  {
    id: 'zeit',
    secondField: {
      secret: true,
    },
  },
];

export default subscriptions;
