import React from 'react';
import { asyncRoute } from 'helpers/routerHelpers';

const HidePrimaryNavigation = () => <span />;

function route(path) {
  return {
    module: 'shareReader',
    path,
    requireAuth: false,
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            primaryNavigation: HidePrimaryNavigation,
            content: require('./containers/ShareReaderContainer'),
          });
        },
        'shareReader',
      );
    }),
  };
}

export default [route('shared/:token')];
