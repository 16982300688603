import Settings from 'controllers/settings';
import { isDev } from 'helpers/environment';
import axios from 'axios';
import { ENTITY_STATE } from 'app-constants';
import { path } from 'ramda';
import { getLink } from 'helpers/hal';
import { loadResourceOnce } from 'helpers/resources';
import { loadServicesOnce } from './services';

export const fetchUserEntities = userId => {
  const url = Settings.getLink('user_entity_follows', { user_id: userId });

  return axios.get(url).then(path(['data', '_embedded', 'b:entities']));
};

export const followEntity = (userId, entityId) => {
  const url = Settings.getLink('user_entity_follows', { user_id: userId });

  const data = {
    id: entityId,
    state: ENTITY_STATE.FOLLOW,
  };

  return axios.post(url, data).then(() => data);
};

export const unfollowEntity = (userId, entityId) => {
  const url = Settings.getLink('user_entity_follow', {
    user_id: userId,
    entity_id: entityId,
  });

  return axios.delete(url).then(() => ({
    id: entityId,
    state: undefined,
  }));
};

export const fetchAllEntities = () =>
  loadServicesOnce(isDev() ? 'development' : 'production')
    .then(services => loadResourceOnce(getLink(services, 's:entities')))
    .then(entityService => axios(getLink(entityService, 'reverse_index')))
    .then(entityIndexResponse => entityIndexResponse.data);
