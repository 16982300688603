// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".desktopContainer-C3Nbn{display:none}@media (min-width: 600px){.desktopContainer-C3Nbn{display:block}}.mobileContainer-aWURV{display:block}@media (min-width: 600px){.mobileContainer-aWURV{display:none}}\n", ""]);
// Exports
exports.locals = {
	"desktopContainer": "desktopContainer-C3Nbn",
	"mobileContainer": "mobileContainer-aWURV"
};
module.exports = exports;
