import { FEATURE_CAPABILITY_ACCESS_TO_BLENDLE } from 'app-constants';
import { compose, not, __, curry } from 'ramda';
import { capabilityIsLocked } from 'selectors/capabilities';

const userHasAccessToBlendle = compose(
  not,
  capabilityIsLocked(__, FEATURE_CAPABILITY_ACCESS_TO_BLENDLE),
);
const getPreference = curry((preference, user) => user.getPreference(preference));
const didPremiumOnboarding = getPreference('did_premium_onboarding');
const didOnboarding = getPreference('did_onboarding');

/**
 * Redirect a user with access to blendle to the onboarding if that is not yet completed
 * @param {Object} user - Usermodel
 * @param {Function} replace - Replace function from router
 */
export default function redirectIfOnboardingNotComplete(user, replace, capabilityState) {
  if (!user) {
    return;
  }

  const didNotCompleteOnboarding = !didPremiumOnboarding(user) || !didOnboarding(user);

  if (userHasAccessToBlendle(capabilityState) && didNotCompleteOnboarding) {
    replace('/preferences/channels');
  }
}
