import {
  HELP_DESK_URL,
  FEATURE_CAPABILITY_ALERTS,
  FEATURE_CAPABILITY_MICROPAYMENTS,
  FEATURE_CAPABILITY_CONTENT_PREFERENCE_SELECTOR,
  FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
  FEATURE_CAPABILITY_MANAGE_PREMIUM,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA,
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST,
} from 'app-constants';
import Analytics from 'instances/analytics';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { compose, withHandlers } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import { capabilityIsLocked, hasCapability } from 'selectors/capabilities';
import AuthStore from 'stores/AuthStore';
import Country from 'instances/country';
import DefaultNavigationUserDropdown from 'components/navigation/DefaultNavigationUserDropdown';
import getSubscriptionOverviewPageUri from 'helpers/paymentPageUrl/getSubscriptionOverviewPageUri';

const zendeskCountryCodes = new Map([
  ['nl_NL', 'nl'],
  ['de_DE', 'en-us'], // The Germans are redirected to the US FAQ as well
  ['en_US', 'en-us'],
]);

function mapStateToProps({ authState: { user }, featureCapabilitiesState }, ownProps) {
  const isLoggedIn = Boolean(user);
  const avatarUrl = isLoggedIn ? user.getAvatarHref() : '';
  const useNeutralLowBalanceColor = isLoggedIn ? user.hasActivePremiumSubscription() : false;

  const locale = Country.getLocale();
  const helpPageHref = `${HELP_DESK_URL}hc/${zendeskCountryCodes.get(locale)}`;
  const subscriptionPageHref = getSubscriptionOverviewPageUri();

  return {
    ...ownProps,
    showAlerts: hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_ALERTS),
    showAudio: hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST,
    ),
    showMicropaymentsWallet: hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_MICROPAYMENTS,
    ),
    showCreditsWallet: hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
    ),
    showContentPreferences: hasCapability(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_CONTENT_PREFERENCE_SELECTOR,
    ),
    blendleIsLocked: capabilityIsLocked(
      featureCapabilitiesState,
      FEATURE_CAPABILITY_ACCESS_TO_BLENDLE,
    ),
    showSubscription: hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_MANAGE_PREMIUM),
    avatarUrl,
    useNeutralLowBalanceColor,
    helpPageHref,
    subscriptionPageHref,
    showPublishersOverview:
      hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_BLENDLE_PREMIUM) ||
      hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA),
    showDiscoverPremium:
      hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_BLENDLE_PREMIUM) &&
      !hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_MICROPAYMENTS),
  };
}

mapStateToProps.stores = {
  AuthStore,
  FeatureCapabilitiesStore,
};

const enhance = compose(
  altConnect(mapStateToProps),
  withHandlers({
    onToggleDropdown: () => (isOpen) => {
      const eventName = isOpen ? 'User Dropdown Opened' : 'User Dropdown Closed';

      Analytics.track(eventName);
    },
  }),
);

export default enhance(DefaultNavigationUserDropdown);
