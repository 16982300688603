import React from 'react';
import useAlt from 'hooks/useAlt';
import AuthStore from 'stores/AuthStore';
import { NavigationItemBadge } from '@blendle/lego';

export const mapState = ([{ user }]) => {
  return user ? user.get('pins') : 0;
};

const PinsCountNavigationItemBadge = props => {
  const pins = useAlt([AuthStore], mapState);

  return <NavigationItemBadge {...props}>{pins}</NavigationItemBadge>;
};

export default PinsCountNavigationItemBadge;
