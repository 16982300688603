import { useState } from 'react';
import { STATUS_INITIAL, STATUS_PENDING, STATUS_OK, STATUS_ERROR, XHR_STATUS } from 'app-constants';
import Settings from 'controllers/settings';
import axios from 'axios';
import AuthStore from 'stores/AuthStore';
import Analytics from 'instances/analytics';

function requestEmail(userId) {
  const link = Settings.getLink('download_app_email', { user_uid: userId });

  return axios.post(link);
}

const useSendDownloadAppLink = internalLocation => {
  const [status, setStatus] = useState(STATUS_INITIAL);

  const sendDownloadAppLink = () => {
    setStatus(STATUS_PENDING);

    Analytics.track('Native App Download Link Requested', {
      internal_location: internalLocation,
    });

    requestEmail(AuthStore.getState().user.id)
      .then(() => {
        setStatus(STATUS_OK);
      })
      .catch(error => {
        setStatus(STATUS_ERROR);

        if (error.type !== XHR_STATUS) {
          throw error;
        }
      });
  };

  return {
    downloadAppLinkStatus: status,
    sendDownloadAppLink,
  };
};

export default useSendDownloadAppLink;
