import React from 'react';
import { string } from 'prop-types';
import AppStoreButton from 'components/buttons/AppStoreButton';
import PlayStoreButton from 'components/buttons/PlayStoreButton';
import styledModule from 'helpers/styledModule';
import Analytics from 'instances/analytics';
import { getAdjustUrl, getAdjustLoginDeeplink } from 'helpers/adjust';
import { ADJUST_CREATIVE, APP_STORE_URL, PLAY_STORE_URL } from 'app-constants';
import { useDiscoverPremiumPageContext } from 'modules/discoverPremium/context/DiscoverPremiumContext';
import AuthStore from 'stores/AuthStore';
import CSS from './styles.scss';

const Container = styledModule.div(CSS.container);

const AppButtons = ({ className, adjustAdGroup }) => {
  const { internalLocation, adjustCampaign } = useDiscoverPremiumPageContext();

  const onClickStoreButton = platform => {
    Analytics.track('Download Native App Button Clicked', {
      internal_location: internalLocation,
      platform,
    });
  };

  const { user } = AuthStore.getState();
  const deeplink = getAdjustLoginDeeplink(user);

  const appStoreAdjustUrl = getAdjustUrl({
    campaign: adjustCampaign,
    adgroup: adjustAdGroup,
    creative: ADJUST_CREATIVE.GENERIC,
    fallback: APP_STORE_URL,
    deeplink,
  });

  const playStoreAdjustUrl = getAdjustUrl({
    campaign: adjustCampaign,
    adgroup: adjustAdGroup,
    creative: ADJUST_CREATIVE.GENERIC,
    fallback: PLAY_STORE_URL,
    deeplink,
  });

  return (
    <Container className={className}>
      <AppStoreButton onClick={() => onClickStoreButton('ios')} href={appStoreAdjustUrl} />
      <PlayStoreButton onClick={() => onClickStoreButton('android')} href={playStoreAdjustUrl} />
    </Container>
  );
};

AppButtons.propTypes = {
  // eslint-disable-next-line react/require-default-props
  className: string,
  adjustAdGroup: string.isRequired,
};

export default AppButtons;
