// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loginDropdown-2sUEM{float:right;display:flex;margin-right:10px;height:100%;align-items:center;transition:opacity 0.5s ease}.l-mobile .loginButton-m9OEu{line-height:30px;height:30px}.open-1cXwJ:after{content:'';display:block;position:fixed;top:62px;left:0;right:0;bottom:0;z-index:9;transform:translateZ(0);box-shadow:none;background:rgba(0,0,0,0.7);pointer-events:none;animation:overlayIn-2YDMG 0.2s ease;animation-fill-mode:forwards}.dropdownContainer-1GDJ3{z-index:1000;position:absolute;background:#fff;left:auto;top:75px;right:20px;opacity:0;border-radius:3px;transition:opacity 0.2s;animation:show-dropdown-3mbkv 0.2s ease-out;animation-fill-mode:forwards}.dropdownContainer-1GDJ3:before{content:'';border:10px solid transparent;border-bottom-color:#fff;position:absolute;top:-20px;right:20px;margin-left:-10px}.hidden-1gKWM{display:none}@keyframes overlayIn-2YDMG{from{opacity:0}to{opacity:1}}@keyframes show-dropdown-3mbkv{from{transform:translateY(-10px);opacity:0}to{transform:translateY(0);opacity:1}}\n", ""]);
// Exports
exports.locals = {
	"loginDropdown": "loginDropdown-2sUEM",
	"loginButton": "loginButton-m9OEu",
	"open": "open-1cXwJ",
	"overlayIn": "overlayIn-2YDMG",
	"dropdownContainer": "dropdownContainer-1GDJ3",
	"show-dropdown": "show-dropdown-3mbkv",
	"hidden": "hidden-1gKWM"
};
module.exports = exports;
