import { curry, pipe, not } from 'ramda';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { isLoggedIn } from 'helpers/user';

function privateHasCapability(
  { forceAddedCapabilities = new Set(), forceRemovedCapabilities = new Set(), capabilities },
  capability,
) {
  const isForceAddedCapability = forceAddedCapabilities.has(capability);
  const isForceRemovedCapability = forceRemovedCapabilities.has(capability);

  return isForceAddedCapability || (!isForceRemovedCapability && capabilities.has(capability));
}

export const capabilityIsLocked = curry((capabilitiesState, capability) => {
  if (!capabilitiesState.capabilities) {
    return undefined;
  }

  return !privateHasCapability(capabilitiesState, capability);
});

export const getCapabilityStateAsync = () =>
  new Promise((resolve) => {
    if (!isLoggedIn()) {
      resolve(null);
      return;
    }

    const initialState = FeatureCapabilitiesStore.getState();

    if (initialState.capabilities !== undefined) {
      resolve(initialState);
      return;
    }

    const listener = (state) => {
      if (state.capabilities !== undefined) {
        FeatureCapabilitiesStore.unlisten(listener);

        resolve(state);
      }
    };

    FeatureCapabilitiesStore.listen(listener);
  });

export const capabilityIsLockedAsync = (capability) =>
  getCapabilityStateAsync().then((state) =>
    state ? capabilityIsLocked(state, capability) : false,
  );

export const hasCapability = pipe(capabilityIsLocked, not);
