// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1JGRf{position:relative;overflow:hidden}.section-35VKB{position:absolute;left:0;bottom:0;width:100%;transition:all 0.3s;background:#fff;border-radius:3px;opacity:0}.section-35VKB.login-2YZke{transform:translate3d(-100%, 0, 0)}.section-35VKB.password-3RJZg{transform:translate3d(100%, 0, 0)}.section-35VKB.active-1HEV4{opacity:1;position:relative;transform:translate3d(0, 0, 0)}.legacy-android-pre-4-4 .isOpen-akFIr{position:absolute;width:100%;right:0;top:0;height:100%}.legacy-android-pre-4-4 .isOpen-akFIr .active-1HEV4{height:100%;width:100%;position:fixed}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1JGRf",
	"section": "section-35VKB",
	"login": "login-2YZke",
	"password": "password-3RJZg",
	"active": "active-1HEV4",
	"isOpen": "isOpen-akFIr"
};
module.exports = exports;
