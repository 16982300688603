/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import styledModule from 'helpers/styledModule';
import { useDiscoverPremiumPageContext } from 'modules/discoverPremium/context/DiscoverPremiumContext';
import useEventOnMount from 'hooks/useEventOnMount';
import Audio from 'components/WalletConversion/LandingPage/Features/Audio';
import Discover from 'components/WalletConversion/LandingPage/Features/Discover';
import PersonalSelection from 'components/WalletConversion/LandingPage/Features/PersonalSelection';
import Covers from 'components/WalletConversion/LandingPage/Features/Covers';
import More from 'components/WalletConversion/LandingPage/More';
import Footer from 'components/WalletConversion/LandingPage/Footer';
import Navigation from 'components/WalletConversion/LandingPage/Navigation';
import HorizontalAlignment from 'components/WalletConversion/LandingPage/HorizontalAlignment';
import Credits from 'components/WalletConversion/LandingPage/Credits';
import MagazineCoversCurved from 'components/WalletConversion/LandingPage/MagazineCoversCurved';
import useDidSeeDiscoverPremiumPage from 'hooks/useDidSeeDiscoverPremiumPage';
import usePageEventOnMount from 'hooks/usePageEventOnMount';
import CSS from '../shared.scss';
import Intro from '../../Intro';

const Container = styledModule.div(CSS.container);

const PremiumOnlyUserLanding = () => {
  const { internalLocation, footer } = useDiscoverPremiumPageContext();
  const { userSawDiscoverPremiumPage } = useDidSeeDiscoverPremiumPage();

  useEffect(() => {
    userSawDiscoverPremiumPage();
  }, []);

  const properties = {
    internal_location: internalLocation,
  };

  useEventOnMount('Explain Premium Upsell Page Shown', properties);
  usePageEventOnMount({
    pageName: 'Explain Premium',
    properties,
  });

  return (
    <Container>
      <Navigation logoColor="razzmatazz" textColor="#333" />
      <HorizontalAlignment>
        <Intro />
      </HorizontalAlignment>
      <MagazineCoversCurved />
      <Credits />
      <HorizontalAlignment>
        <PersonalSelection />
        <Covers />
        <Audio />
        <Discover />
        <More />
        <Footer footer={footer} />
      </HorizontalAlignment>
    </Container>
  );
};

export default PremiumOnlyUserLanding;
