import React from 'react';
import { bool, string } from 'prop-types';
import { translate } from 'instances/i18n';
import { SearchIcon, CloseIcon } from '@blendle/lego';
import classNames from 'classnames';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';
import SharedCSS from '../shared.scss';
import useSearch from '../hooks/useSearch';

const Container = styledModule.div(CSS.container);
const Form = styledModule('form')(SharedCSS.form, CSS.form);
const ContentWrapper = styledModule.div(CSS.contentWrapper);
const StyledSearchIcon = styledModule(SearchIcon)(SharedCSS.searchIcon, CSS.searchIcon);
const CloseButton = styledModule.button(CSS.closeButton);

const DesktopSearchBar = ({ isOnSearchRoute, searchQueryFromUrl }) => {
  const {
    inputRef,
    onChangeQuery,
    onSubmit,
    query,
    openSearchBar,
    closeSearchBar,
    isOpen,
  } = useSearch({
    searchQueryFromUrl,
    isOnSearchRoute,
  });

  const isOpenOutsideSearchPage = isOpen && !isOnSearchRoute;

  const searchIconClassNames = classNames(
    isOnSearchRoute && !isOpen && SharedCSS.active,
    !isOpen && SharedCSS.cursorPointer,
  );

  return (
    <Container>
      <Form
        onSubmit={onSubmit}
        className={classNames(
          isOpen && CSS.isOpen,
          isOpenOutsideSearchPage && CSS.isOpenOutsideSearchPage,
        )}
      >
        <ContentWrapper>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <span
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (!isOpen) {
                openSearchBar();
              }
            }}
            data-testid="form-search-button"
          >
            <StyledSearchIcon className={searchIconClassNames} data-testid="search-icon" />
          </span>
          <input
            type="text"
            // not using styledModule because that doesn't pass the ref down
            className={classNames(SharedCSS.input, CSS.input)}
            ref={inputRef}
            value={query}
            name="search-query"
            onChange={onChangeQuery}
            placeholder={translate('search.text.placeholder_desktop')}
            disabled={!isOpen}
          />
          <CloseButton
            type="button"
            onClick={closeSearchBar}
            disabled={!isOpen}
            data-testid="form-close-icon"
          >
            <CloseIcon />
          </CloseButton>
        </ContentWrapper>
      </Form>
    </Container>
  );
};

DesktopSearchBar.propTypes = {
  isOnSearchRoute: bool.isRequired,
  searchQueryFromUrl: string,
};

DesktopSearchBar.defaultProps = {
  searchQueryFromUrl: '',
};

export default DesktopSearchBar;
