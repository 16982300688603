import alt from 'instances/altInstance';

class ModuleNavigationActions {
  setActiveUrl(url) {
    let activeUrl = url;
    if (!url.startsWith('/')) {
      activeUrl = `/${url}`;
    }

    return { activeUrl };
  }

  setActiveModule = activeModule => activeModule;

  setAnalytics(url, analytics) {
    return { url: url.startsWith('/') ? url : `/${url}`, analytics };
  }
}

export default alt.createActions(ModuleNavigationActions);
