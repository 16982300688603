/* eslint-disable class-methods-use-this */

import alt from 'instances/altInstance';
import { get } from 'lodash';
import * as ItemManager from 'managers/item';
import { fetchTiles } from 'modules/sectionsPage/managers/sectionsPageManager';

class ReadMoreActions {
  fetchReadMoreSuccess = sections => sections;

  fetchReadMoreError = error => error;

  fetchReadMore(userId, itemId) {
    ItemManager.fetchReadMore(userId, itemId)
      .then(res => {
        const sections = get(res, '_embedded[b:sections]', []);

        // Fetch the feed for each section
        sections.forEach(section => {
          this.fetchSectionFeed(section.id, get(section, '_links[b:feed].href'));
        });

        this.fetchReadMoreSuccess(sections);
      })
      .catch(error => this.fetchReadMoreError(error));

    return { userId, itemId };
  }

  fetchSectionFeedSuccess = ({ sectionId, tiles }) => ({ sectionId, tiles });

  fetchSectionFeedError = error => error;

  fetchSectionFeed(sectionId, feedUrl) {
    fetchTiles(feedUrl)
      .then(res =>
        this.fetchSectionFeedSuccess({
          sectionId,
          tiles: get(res, '_embedded[b:tiles]'),
        }),
      )
      .catch(error => this.fetchSectionFeedError(error));

    return sectionId;
  }
}

export default alt.createActions(ReadMoreActions);
