import { ABTest } from 'ab';
import Cookies from 'cookies-js';
import BrowserEnv from 'instances/browser_environment';
import { assignedExperimentVariant } from './experiments';

export function pickVariant(experiment) {
  return ABTest({
    // eslint-disable-line new-cap
    name: experiment.name,
    cookiePath: '/',
    variations: experiment.variants.reduce((map, variant) => {
      map[variant] = () => {};
      return map;
    }, {}),
  }).assignedVariation;
}

export function hasForcedVariant(search, experimentName) {
  return search.indexOf(`abjs-setvar-${experimentName}`) !== -1;
}

export function getVariantFromCookie(experimentName) {
  return Cookies.get(`abjs_${experimentName}`);
}
