import React from 'react';
import { DeviceFrame } from '@blendle/lego';

import styledModule from 'helpers/styledModule';
import staticImageUrl from 'helpers/staticImageUrl';
import { translate } from 'instances/i18n';
import { H3, Body } from '../../Typography';
import SharedCSS from '../shared.scss';
import CSS from './styles.scss';
import USP from '../USP';

const Container = styledModule.div(SharedCSS.container);
const StyledContainer = styledModule(Container)(CSS.container);
const Content = styledModule.div(SharedCSS.content);
const StyledContent = styledModule(Content)(CSS.content);
const UspWrapper = styledModule.div(SharedCSS.uspWrapper);
const StyledDeviceFrame = styledModule(DeviceFrame)(CSS.deviceWrapper);

const imagePath = staticImageUrl('/img/backgrounds/iPhone_X_personal_selection');

const PersonalSelection = () => (
  <StyledContainer>
    <StyledContent>
      <H3>{translate('discover_premium.features.personal_selection.title')}</H3>
      <Body>{translate('discover_premium.features.personal_selection.text')}</Body>
      <UspWrapper>
        <USP>{translate('discover_premium.features.personal_selection.usp_one')}</USP>
        <USP>{translate('discover_premium.features.personal_selection.usp_two')}</USP>
        <USP>{translate('discover_premium.features.personal_selection.usp_three')}</USP>
      </UspWrapper>
    </StyledContent>
    <StyledDeviceFrame device="iPhoneX">
      <img
        src={`${imagePath}@2x.png?auto=format,compress`}
        srcSet={`${imagePath}.png?auto=format,compress 1x, ${imagePath}@2x.png?auto=format,compress 2x`}
        alt="iPhone X with showing the Personal Page in the Blendle app"
      />
    </StyledDeviceFrame>
  </StyledContainer>
);

export default PersonalSelection;
