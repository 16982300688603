import { asyncRoute } from 'helpers/routerHelpers';

function route(path) {
  return {
    module: 'try-audio',
    path,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/AudioLandingContainer'),
          });
        },
        'try-audio',
      );
    }),
  };
}

export default [route('probeer-audio')];
