import alt from 'instances/altInstance';
import PaymentManager from 'managers/payment';
import Analytics from 'instances/analytics';
import Cookies from 'cookies-js';
import TopUpActions from 'actions/TopUpActions';
import { getSelectedRecurringContract } from 'selectors/payment';

function setPaymentRequestCookie(method, paymentState, topUpState, rest) {
  Cookies.set(
    'paymentRequest',
    JSON.stringify({
      method,
      amount: topUpState.selectedAmount,
      recurring: !!paymentState.recurring_enabled,
      returnUrl: paymentState.returnUrl,
      useHardRedirect: paymentState.useHardRedirect,
      ...rest,
    }),
  );
}

export default alt.createActions({
  fetchPaymentMethodsSuccess: x => x,
  fetchPaymentMethodsError: x => x,
  setReturnUrl: (returnUrl, useHardRedirect) => ({
    returnUrl,
    useHardRedirect,
  }),

  fetchPaymentMethods(user) {
    return dispatch => {
      dispatch();

      return Promise.all([
        PaymentManager.getPaymentMethodsProcessed(user),
        PaymentManager.getRecurringContract(user),
      ])
        .then(([paymentMethods, recurring]) => {
          // Check if last_payment_method and last_bank are still available
          const prefLastPaymentMethod = user.get('preferences').last_payment_method;
          const prefLastBank = user.get('preferences').last_bank;
          let lastPaymentMethod = paymentMethods.find(
            paymentMethod => paymentMethod.code === prefLastPaymentMethod,
          );

          // Remove PayPal recurring contracts when user has more than 1 because the user can't tell the difference.
          const payPalContract = recurring._embedded.recurring_contracts.filter(
            ({ variant }) => variant === 'paypal',
          );

          if (payPalContract.length > 1) {
            recurring._embedded.recurring_contracts = recurring._embedded.recurring_contracts.filter(
              ({ variant }) => variant !== 'paypal',
            );
          }

          // @FEATURE recurring_contract top up
          if (recurring._embedded.recurring_contracts.length > 0) {
            lastPaymentMethod = recurring._embedded.recurring_contracts[0].id;
          }

          let lastBank;
          if (lastPaymentMethod && lastPaymentMethod.banks) {
            lastBank = lastPaymentMethod.banks.find(bank => bank.code === prefLastBank);
          }

          if (lastPaymentMethod && lastPaymentMethod.code) {
            lastPaymentMethod = lastPaymentMethod.code;
          }

          this.setPaymentMethod(
            lastPaymentMethod,
            lastBank ? lastBank.code : undefined,
            paymentMethods,
          );

          // Preselect ideal for the first order if it's available
          if (user.get('orders') === 0 && paymentMethods.find(pm => pm.code === 'ideal')) {
            this.setPaymentMethod('ideal', undefined, paymentMethods);
          }

          TopUpActions.setRecurringRequestContract(recurring.recurring_enabled);

          return {
            paymentMethods,
            recurring: recurring.recurring_enabled,
            recurring_contracts: recurring._embedded.recurring_contracts,
          };
        })
        .then(payload => {
          this.fetchPaymentMethodsSuccess(payload);
          return payload;
        })
        .catch(error => {
          this.fetchPaymentMethodsError(error);

          throw error;
        });
    };
  },

  /**
   * Also sets selected bank when method is creditcard to first creditcard in banks array
   * @param {string} selectedPaymentMethod id
   * @param {string} selectedBank id
   * @param {array} paymentMethods
   */
  setPaymentMethod(selectedPaymentMethod = '0', selectedBank = '0', paymentMethods) {
    if (selectedPaymentMethod === 'creditcard' && selectedBank === '0') {
      const availableBanks = paymentMethods.find(method => method.code === selectedPaymentMethod)
        .banks;
      selectedBank = availableBanks[0].code;
    }

    return { selectedPaymentMethod, selectedBank };
  },

  /**
   * Enable or disable recurring (sends request to server)
   */
  setRecurringContract(user, enabled) {
    return dispatch => {
      PaymentManager.setRecurringContract(user, enabled)
        .then(data => PaymentManager.getRecurringState(data))
        .then(recurring => dispatch({ recurring }));
    };
  },

  /**
   * @param {object} paymentState current state
   * @param {object} topUpState current TopUp state
   * @param {object} user
   */
  fetchPaymentURL(paymentState, topUpState, user) {
    return dispatch => {
      dispatch();

      // User wants to pay with a recurring contract
      if (getSelectedRecurringContract(paymentState)) {
        this.fetchPaymentURLSuccess(paymentState, topUpState, '');

        return null;
      }

      const options = {
        success_url: 'payment/success',
        pending_url: 'payment/pending',
        cancel_url: 'payment/cancelled',
        method: paymentState.selectedPaymentMethod,
        recurring: topUpState.recurringRequest,
        amount: topUpState.selectedAmount,
      };

      if (paymentState.selectedBanks[paymentState.selectedPaymentMethod]) {
        options.bank = paymentState.selectedBanks[paymentState.selectedPaymentMethod];
      }

      user.savePreferences({
        last_payment_method: options.method,
        last_bank: options.bank,
      });

      PaymentManager.fetchPaymentURL(user, options).then(paymentURL => {
        this.fetchPaymentURLSuccess(paymentState, topUpState, paymentURL);
      });
    };
  },

  /**
   * @param {object} paymentState current state
   * @param {object} topUpState current TopUp state
   * @param {string} paymentURL adyen url
   */
  fetchPaymentURLSuccess(paymentState, topUpState, paymentURL) {
    const bank = {
      bank: paymentState.selectedBanks[paymentState.selectedPaymentMethod],
    };
    const recurringContract = getSelectedRecurringContract(paymentState);

    const method = recurringContract
      ? `${recurringContract.variant}-recurring`
      : paymentState.selectedPaymentMethod;

    Analytics.track('Payment Request', {
      method,
      amount: topUpState.selectedAmount,
      recurring: !!paymentState.recurring_enabled,
      ...(bank.bank !== '0' ? bank : undefined),
    });

    setPaymentRequestCookie(method, paymentState, topUpState, {
      ...(bank.bank !== '0' ? bank : undefined),
    });

    TopUpActions.setActiveStep('confirmation');

    return { paymentURL };
  },
});
