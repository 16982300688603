/* eslint-disable react/require-default-props */
import React from 'react';
import styledModule from 'helpers/styledModule';
import { useDiscoverPremiumPageContext } from 'modules/discoverPremium/context/DiscoverPremiumContext';
import useEventOnMount from 'hooks/useEventOnMount';
import Audio from 'components/WalletConversion/LandingPage/Features/Audio';
import Discover from 'components/WalletConversion/LandingPage/Features/Discover';
import PersonalSelection from 'components/WalletConversion/LandingPage/Features/PersonalSelection';
import Covers from 'components/WalletConversion/LandingPage/Features/Covers';
import More from 'components/WalletConversion/LandingPage/More';
import MagazineCovers from 'components/WalletConversion/LandingPage/MagazineCovers';
import Benefits from 'components/WalletConversion/LandingPage/Benefits';
import Footer from 'components/WalletConversion/LandingPage/Footer';
import Background from 'components/WalletConversion/LandingPage/Background';
import Navigation from 'components/WalletConversion/LandingPage/Navigation';
import HorizontalAlignment from 'components/WalletConversion/LandingPage/HorizontalAlignment';
import renderNothingIfLoadingWalletMigrationOffer from 'higher-order-components/renderNothingIfLoadingWalletMigrationOffer';
import useMigrationOffer from 'hooks/useMigrationOffer';
import Credits from 'components/WalletConversion/LandingPage/Credits';
import usePageEventOnMount from 'hooks/usePageEventOnMount';
import CSS from '../shared.scss';
import Outro from '../../Outro';
import FaqSection from '../../FaqSection';
import IntroPlusMonthlyMigrationOffer from '../../IntroPlusMonthlyMigrationOffer';

const Container = styledModule.div(CSS.container);

export const UpsellOnlyOfferLandingComponent = () => {
  const { internalLocation, onCtaClick, footer } = useDiscoverPremiumPageContext();
  const {
    paymentPageUri,
    balance,
    offeredNumberOfMonths,
    equivalentMoneyOfferedMonths,
  } = useMigrationOffer();

  const properties = {
    internal_location: internalLocation,
  };

  useEventOnMount('Explain Premium Upsell Page Shown', properties);
  usePageEventOnMount({ pageName: 'Explain Premium - Ex Micropayment', properties });

  const onWalletOfferClick = (locationInLayout) => {
    onCtaClick({
      locationInLayout,
      offeredNumberOfMonths,
      internalLocation,
    });
  };

  return (
    <Container>
      <Background>
        <Navigation logoColor="razzmatazz-white" />
        <HorizontalAlignment>
          <IntroPlusMonthlyMigrationOffer
            paymentPageUri={paymentPageUri}
            balance={balance}
            offeredNumberOfMonths={offeredNumberOfMonths}
            equivalentMoneyOfferedMonths={equivalentMoneyOfferedMonths}
            onWalletOfferClick={onWalletOfferClick}
          />
        </HorizontalAlignment>
      </Background>
      <HorizontalAlignment>
        <Benefits />
      </HorizontalAlignment>
      <MagazineCovers />
      <Credits />
      <HorizontalAlignment>
        <PersonalSelection />
        <Covers />
        <Audio />
        <Discover />
        <More />
        <Outro />
        <FaqSection balance={balance} />
        <Footer footer={footer} />
      </HorizontalAlignment>
    </Container>
  );
};

export default renderNothingIfLoadingWalletMigrationOffer(UpsellOnlyOfferLandingComponent);
