import URI from 'urijs';
import Environment from 'environment';

export function isGetPremiumLandingPageRoute(route) {
  const uri = new URI(route);

  return [
    '/getpremium',
    '/getpremium/login',
    '/getpremium/reset',
    '/getpremium/reset/success',
    '/getpremium/signup',
  ].includes(uri.pathname());
}

export function isGetPremiumDeeplinkRoute(route) {
  const uri = new URI(route);

  const regex = RegExp('^/getpremium/item/.*');

  return regex.test(uri.pathname());
}

export function isLandingProjectHost(url) {
  const uri = new URI(url);

  return uri.host() === Environment.landing_project_host;
}

export function isLandingProjectDomainOrSubDomain(url) {
  const uri = new URI(url);

  return uri.host() ? uri.host().includes(Environment.landing_project_host) : false;
}

export default undefined;
