import alt from 'instances/altInstance';
import affiliateCampaigns from 'config/affiliates';
import AffiliatesActions from 'actions/AffiliatesActions';

class AffiliatesStore {
  constructor() {
    this.bindActions(AffiliatesActions);

    this.state = {};
  }

  onSelectAffiliate(payload) {
    const affiliate = affiliateCampaigns.find(campaign => campaign.name === payload);

    if (affiliate) {
      this.setState({ affiliate });
    }
  }

  onSaveVodafoneMetaData({ vodafoneFullUrl, isUserEligibleForTrialMonth }) {
    const meta = {
      ...this.state.meta,
      ...(vodafoneFullUrl && { vodafone_full_url: vodafoneFullUrl }),
      isUserEligibleForTrialMonth,
    };

    this.setState({ meta });
  }
}

export default alt.createStore(AffiliatesStore, 'AffiliatesStore');
