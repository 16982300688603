import React, { useState } from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames';
import styled from 'helpers/styledModule';
import CSS from './styles.scss';

const Article = styled.article(CSS.article);
const Header = styled.button(CSS.header);
const Title = styled.h1(CSS.title);
const Answer = styled.div(CSS.answer);

function ArrowIcon(props) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.59 10.59L2 12L8 6L2 5.24537e-07L0.589999 1.41L5.17 6L0.59 10.59Z"
        fill="#333333"
      />
    </svg>
  );
}

function Question({ title, children, openByDefault }) {
  const [isOpen, setIsOpen] = useState(openByDefault);

  const onClick = () => setIsOpen(!isOpen);
  const iconClassName = classNames(CSS.arrow, { [CSS.arrowDown]: isOpen });

  return (
    <Article>
      <Header onClick={onClick} type="button">
        <Title>{title}</Title>
        <ArrowIcon className={iconClassName} />
      </Header>
      {isOpen && <Answer>{children}</Answer>}
    </Article>
  );
}

Question.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  openByDefault: bool,
};

Question.defaultProps = {
  openByDefault: false,
};

export default Question;
