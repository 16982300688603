import React from 'react';
import { Overlay, Dialog } from '@blendle/lego';
import Confetti from 'components/SquareConfetti';
import styledModule from 'helpers/styledModule';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import { string, oneOf, bool, func } from 'prop-types';
import CSS from './styles.scss';
import DeviceSection from './DeviceSection';
import VideoSection from './VideoSection';
import USP from './USP';
import { getColor, isAppFlowMigration } from './helper';
import DownloadAppSection from './DownloadAppSection';
import OpenAppButton from './OpenAppButton';
import { isMobileViewport } from './isMobileViewport';

const StyledDialog = styledModule(Dialog)(CSS.dialog);
const ContentWrapper = styledModule.div(CSS.contentWrapper);
const CopyContainer = styledModule.div(CSS.copyContainer);
const Title = styledModule.h1(CSS.title);
const PartyIcon = styledModule.img(CSS.partyIcon);
const Intro = styledModule.p(CSS.intro);
const Bold = styledModule.span(CSS.bold);

function getIntroCopy(version) {
  switch (version) {
    case SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW:
      return 'Ontdek nu de nieuwste functies in de app, zoals:';
    case SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW:
      return 'Blendle Premium is nu nog beter! Download onze gratis app voor de beste Premium ervaring en ontdek alle nieuwe functies zoals:';
    default:
      return 'Je hebt nu Blendle Premium! Download onze gratis app voor de beste Premium ervaring en ontdek alle nieuwe functies zoals:';
  }
}

const PremiumSuccessOverlay = ({ version, firstName, renderAndroidVariant, onClose, testId }) => {
  const color = getColor(version);

  return (
    <Overlay>
      <Confetti limit={200}>
        <StyledDialog
          overlayClassName={CSS.dialogOverlay}
          onClose={onClose}
          closeOnOverlayClick={false}
          data-testid={testId}
        >
          <ContentWrapper>
            <CopyContainer>
              <div>
                <Title>
                  Hoppa, gelukt!
                  <PartyIcon src="/img/illustrations/party.png" />
                </Title>
                <Intro>
                  Gefeliciteerd{firstName && ` ${firstName}`}. {getIntroCopy(version)}
                </Intro>
                <USP checkMarkColor={color}>
                  <Bold>Onbeperkt</Bold> lezen van de beste artikelen
                </USP>
                <USP checkMarkColor={color}>
                  <Bold>Blader</Bold> door de leukste tijdschriften
                </USP>
                <USP checkMarkColor={color}>
                  <Bold>Luister</Bold> dagelijks naar artikelen met Audio
                </USP>
                <USP checkMarkColor={color}>
                  <Bold>Zoek</Bold> in ons archief van 3 miljoen artikelen
                </USP>
                {isAppFlowMigration(version) ? (
                  <OpenAppButton />
                ) : (
                  <DownloadAppSection
                    version={version}
                    renderAndroidVariant={renderAndroidVariant}
                  />
                )}
              </div>
            </CopyContainer>
            {isAppFlowMigration(version) || isMobileViewport() ? (
              <DeviceSection
                version={version}
                renderAndroidVariant={renderAndroidVariant}
                showOnDesktop
              />
            ) : (
              <VideoSection version={version} />
            )}
          </ContentWrapper>
        </StyledDialog>
      </Confetti>
    </Overlay>
  );
};

PremiumSuccessOverlay.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION_APP_FLOW,
    SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW,
    SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK,
  ]),
  firstName: string,
  renderAndroidVariant: bool,
  onClose: func.isRequired,
  testId: string,
};

PremiumSuccessOverlay.defaultProps = {
  version: SUCCESS_DIALOG_VERSION.DEFAULT,
  firstName: undefined,
  renderAndroidVariant: false,
  testId: undefined,
};

export default PremiumSuccessOverlay;
