import { getIso6391 } from 'instances/i18n';
import {
  convertToLandingProjectURL,
  languageHasMovedAboutPage,
} from 'libs/middleware/routerRedirectToLanding';
import { ifElse } from 'ramda';

export const getPrivacyLink = () =>
  ifElse(
    languageHasMovedAboutPage,
    () => convertToLandingProjectURL('/legal/privacy'),
    () => '/about/privacy',
  )(getIso6391());
