import { STATUS_INITIAL, STATUS_PENDING } from 'app-constants';
import { compose } from '@blendle/recompose';
import WalletMigrationStore from 'stores/WalletMigrationStore';
import altConnect from './altConnect';
import renderNothingIfLoading from './renderNothingIfLoading';

const statusWhileNotReady = [STATUS_INITIAL, STATUS_PENDING];

function mapStateToProps({ walletMigrationState }, props) {
  const { offer } = walletMigrationState;
  const { status } = offer;

  const isLoading = statusWhileNotReady.includes(status);

  return {
    isLoading,
    ...props,
  };
}

mapStateToProps.stores = {
  WalletMigrationStore,
};

export default compose(altConnect(mapStateToProps), renderNothingIfLoading);
