import React from 'react';
import { asyncRoute } from 'helpers/routerHelpers';
import { getCapabilityLockedRoute } from 'helpers/capabilities';
import { isProviderHidden, prefillSelector } from 'selectors/providers';
import { FEATURE_CAPABILITY_NEWSSTAND } from 'app-constants';
import { capabilityIsLockedAsync } from 'selectors/capabilities';

function onEnter(nextState, replace, callback) {
  capabilityIsLockedAsync(FEATURE_CAPABILITY_NEWSSTAND).then(kioskIsLocked => {
    if (kioskIsLocked) {
      replace(getCapabilityLockedRoute(FEATURE_CAPABILITY_NEWSSTAND));
      callback();

      return;
    }

    document.body.classList.add('m-issue');

    require.ensure(
      [],
      () => {
        const IssueActions = require('actions/IssueActions');
        IssueActions.changeVisibility.defer(true);
      },
      'kiosk',
    );

    if (prefillSelector(isProviderHidden)(nextState.params.providerId)) {
      replace('/404');
    }

    callback();
  });
}

function onLeave() {
  document.body.classList.remove('m-issue');

  require.ensure(
    [],
    () => {
      const IssueActions = require('actions/IssueActions');
      IssueActions.changeVisibility.defer(false);
    },
    'kiosk',
  );
}

const modulePortalNavigationRoot = () => <div className="moduleNavigationPortalRoot" />;

function routeIssue(path) {
  return {
    module: 'issue',
    path,
    onEnter,
    onLeave,
    getComponents: asyncRoute((nextState, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('./containers/IssueRouterContainer'),
            navigation: modulePortalNavigationRoot,
          });
        },
        'kiosk',
      ); // named kiosk, the issue and kiosk modules are most likely to used together
    }),
  };
}

export default [
  routeIssue('issue/:providerId'),
  routeIssue('issue/:providerId/:issueId'),
  routeIssue('issue/:providerId/:issueId/:section'),
  routeIssue('issue/:providerId/:issueId/manifest/:itemId'),
];
