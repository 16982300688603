import { DISCOVER_PREMIUM_PAGE_VERSION, INTERNAL_LOCATION, ADJUST_CAMPAIGN } from 'app-constants';

export const FooterType = {
  APP_BUTTONS: 'app_buttons',
  DOWNLOAD_APP_LINK: 'download_app_link',
};

export const discoverPremiumConfig = new Map([
  [
    DISCOVER_PREMIUM_PAGE_VERSION.UPSELL_ONLY,
    {
      internalLocation: INTERNAL_LOCATION.EXPLAIN_PREMIUM_UPSELL_PAGE,
      adjustCampaign: ADJUST_CAMPAIGN.DISCOVER_PREMIUM_HARDPHASE,
      footer: FooterType.APP_BUTTONS,
      version: DISCOVER_PREMIUM_PAGE_VERSION.UPSELL_ONLY,
      primaryColor: '#4900fe',
    },
  ],
  [
    DISCOVER_PREMIUM_PAGE_VERSION.PREMIUM_ONLY,
    {
      internalLocation: INTERNAL_LOCATION.EXPLAIN_PREMIUM_PAGE,
      adjustCampaign: ADJUST_CAMPAIGN.DISCOVER_PREMIUM_HARDPHASE,
      footer: FooterType.DOWNLOAD_APP_LINK,
      version: DISCOVER_PREMIUM_PAGE_VERSION.PREMIUM_ONLY,
      primaryColor: '#ff1060',
    },
  ],
]);
