import alt from 'instances/altInstance';
import SubscriptionsManager from 'managers/subscriptions';

export default alt.createActions({
  name: 'SubscriptionOrderActions',

  createOrder({ subscriptionProductId, paymentType, ...rest }, userId) {
    return dispatch => {
      dispatch();

      return SubscriptionsManager.createOrder(
        { subscriptionProductId, paymentType, ...rest },
        userId,
      ).then(this.createOrderSuccess);
    };
  },

  createOrderSuccess: x => x,

  startAffiliateSubscription(options, userId) {
    const optionsWithAcceptHeader = {
      ...options,
      acceptHeader: 'application/json',
    };

    this.createOrder(optionsWithAcceptHeader, userId)
      .then(() => this.startAffiliateSubscriptionSuccess(options.subscriptionProductId))
      .catch(error => this.startAffiliateSubscriptionFailure(error));

    return null;
  },

  startAffiliateSubscriptionSuccess: x => x,
  startAffiliateSubscriptionFailure: x => x,
});
