import React, { useRef, useEffect, useReducer } from 'react';
import styledModule from 'helpers/styledModule';
import { oneOf } from 'prop-types';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import R from 'ramda';
import Analytics from 'instances/analytics';
import Video from './Video';
import CSS from './styles.scss';
import { PlayMoreButton, PlayButton } from './Buttons';
import { isMigratedUserVersion } from './helper';
import AudioToggle from './AudioToggle';
import { reducer, initialState, VIDEO_ACTION } from './reducer';

const isAutoPlayAttempt = R.equals(0);

const Container = styledModule.div(CSS.deviceContainer);

const newUserVideoPauseMoments = [25.8, 33.2, 44.2, 66.0, 98.0];
const migratedUserPauseMoments = [39.0, 50.0, 60.5, 74.2, 85.0];

const VideoSection = ({ version }) => {
  const videoRef = useRef();
  const [
    { currentSectionIndex, showPlayButton, showMoreConfettiButton, isAudioMuted },
    dispatch,
  ] = useReducer(reducer, initialState);

  const pauseMoments = isMigratedUserVersion(version)
    ? migratedUserPauseMoments
    : newUserVideoPauseMoments;

  const playTillNextPauseMoment = () => {
    const { current: video } = videoRef;

    if (video.currentTime >= pauseMoments[currentSectionIndex]) {
      video.pause();
      dispatch({ type: VIDEO_ACTION.VIDEO_PAUSED });
    }
  };

  useEffect(() => {
    const { current: video } = videoRef;
    video.addEventListener('timeupdate', playTillNextPauseMoment);

    const playPromise = video.play();

    // Some older browsers dont' return a promise from play call
    if (isAutoPlayAttempt(currentSectionIndex) && playPromise) {
      // Show a play button in case autoPlay is not allowed
      playPromise.catch(() => {
        dispatch({ type: VIDEO_ACTION.AUTO_PLAY_FAILED });
      });
    }

    return () => video.removeEventListener('timeupdate', playTillNextPauseMoment);
  }, [currentSectionIndex]);

  const onPlay = () => {
    const { current: video } = videoRef;

    dispatch({ type: VIDEO_ACTION.USER_STARTED_VIDEO });
    video.play();
  };

  const onMoreConfetti = () => {
    Analytics.track('More Confetti Button Clicked');
    dispatch({ type: VIDEO_ACTION.CONFETTI_REQUESTED });
  };

  const toggleAudio = () => {
    dispatch({ type: VIDEO_ACTION.AUDIO_TOGGLED });
  };

  return (
    <Container>
      <Video videoRef={videoRef} version={version} muteAudio={isAudioMuted} />
      <AudioToggle onClick={toggleAudio} isAudioMuted={isAudioMuted} />
      {showPlayButton && <PlayButton onClick={onPlay} version={version} />}
      {showMoreConfettiButton && (
        <PlayMoreButton
          onClick={onMoreConfetti}
          version={version}
          currentSectionIndex={currentSectionIndex}
        />
      )}
    </Container>
  );
};

VideoSection.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.MP_PLUS_PREMIUM_FLOW,
    SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK,
  ]),
};

VideoSection.defaultProps = {
  version: SUCCESS_DIALOG_VERSION.DEFAULT,
};

export default VideoSection;
