import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import BrowserEnv from 'instances/browser_environment';
import ModuleNavigationActions from 'actions/ModuleNavigationActions';
import { translate } from 'instances/i18n';
import TimelineManager from 'managers/timeline';
import { history } from 'byebye';
import ModuleNavigation from 'components/moduleNavigation/ModuleNavigation';
import { getComponents } from 'helpers/moduleNavigationHelpers';
import { get } from 'lodash';
import { FEATURE_CAPABILITY_NEWSSTAND, FEATURE_CAPABILITY_LEGACY_TIMELINES } from 'app-constants';
import { capabilityIsLocked } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { ThemeProvider } from '@blendle/lego';
import FollowChannels from './FollowChannels';

function unmountChannelsOverlay() {
  ReactDOM.unmountComponentAtNode(document.querySelector('.a-tooltips'));
}

const kioskIsLocked = capabilitiesState =>
  capabilityIsLocked(capabilitiesState, FEATURE_CAPABILITY_NEWSSTAND);

const legacyTimelinesAreLocked = capabilitiesState =>
  capabilityIsLocked(capabilitiesState, FEATURE_CAPABILITY_LEGACY_TIMELINES);

export default class TimelineNavigation extends PureComponent {
  static propTypes = {
    timeline: PropTypes.object,
    channels: PropTypes.any.isRequired,
    kioskCategories: PropTypes.array.isRequired,
  };

  constructor() {
    super();

    this.state = {
      channelsOverlayVisible: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this._onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this._onWindowClick);
    if (this.state.channelsOverlayVisible) {
      unmountChannelsOverlay();
    }
  }

  _onWindowClick = () => {
    if (this.state.channelsOverlayVisible) {
      ModuleNavigationActions.setActiveUrl(window.location.pathname);
      this.setState({ channelsOverlayVisible: false });
      unmountChannelsOverlay();
    }
  };

  _getNavigationItems() {
    return [
      this._getTrending(),
      this._getKiosk(),
      ...this._getFollowingChannels(),
      this._getFollowChannels(),
    ].filter(item => !!item);
  }

  _getKiosk() {
    if (kioskIsLocked(FeatureCapabilitiesStore.getState())) {
      return null;
    }

    const { kioskCategories, timeline } = this.props;

    if (BrowserEnv.isMobile() || get(this, 'props.timeline.name') !== 'kiosk') {
      return {
        key: 'kiosk',
        label: translate('navigation.links.collapsed.kiosk.label'),
        className: 'menu-kiosk',
        url: 'kiosk',
        aliases: ['kiosk'],
      };
    }

    const kiosk = {
      key: 'popular',
      label: translate('navigation.links.collapsed.kiosk.label'),
      url: 'kiosk',
      aliases: ['kiosk'],
    };

    const myIssues = {
      key: 'my-issues',
      label: translate('kiosk.navigation.my_issues'),
      url: 'kiosk/my-issues',
    };

    const categories = kioskCategories.map(category => ({
      key: category.id,
      url: `kiosk/${category.id}`,
      label: category.title,
    }));

    const children = [kiosk, myIssues, ...categories];
    const active = children.find(child => child.key === timeline.categoryId);

    return {
      ...active,
      children,
      className: 'menu-kiosk s-active',
    };
  }

  _getTrending() {
    if (legacyTimelinesAreLocked(FeatureCapabilitiesStore.getState())) {
      return null;
    }

    if (BrowserEnv.isMobile()) {
      return {
        key: 'trending',
        label: translate('timeline.trending.now', ['']),
        className: 'menu-trending',
        url: 'trending/now',
        aliases: ['trending'],
      };
    }

    const options = TimelineManager.getTrendingFilters();
    if (get(this, 'props.timeline.name') !== 'trending') {
      return {
        key: 'trending',
        label: options[0].label,
        className: 'menu-trending',
        url: 'trending/now',
      };
    }

    const children = options.map(option => ({
      ...option,
      key: option.trending,
      url: `trending/${option.trending}`,
    }));

    const active = options.find(option => option.trending === this.props.timeline.options.details);

    return {
      ...active,
      url: `trending/${active.trending}`,
      key: 'trending',
      className: 'menu-trending s-active',
      children,
    };
  }

  _getFollowingChannels() {
    if (legacyTimelinesAreLocked(FeatureCapabilitiesStore.getState())) {
      return null;
    }

    const { channels } = this.props;

    return channels
      .filter(channel => channel.get('following'))
      .map(channel => ({
        key: channel.id,
        label: channel.get('username'),
        url: `channel/${channel.id}`,
        className: `channel channel-${channel.id}`,
      }));
  }

  _getFollowChannels() {
    if (legacyTimelinesAreLocked(FeatureCapabilitiesStore.getState())) {
      return null;
    }

    const onClose = ev => {
      if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
      }

      ModuleNavigationActions.setActiveUrl(window.location.pathname);
      this.setState({ channelsOverlayVisible: false });
      unmountChannelsOverlay();
    };

    return {
      key: 'add-channel',
      className: 'v-add-channel-button',
      label: BrowserEnv.isMobile() ? '' : translate('timeline.channel.button'),
      url: 'channels',
      onClick: ev => {
        ev.preventDefault();
        ev.stopPropagation();

        if (this.state.channelsOverlayVisible) {
          onClose();
          return;
        }

        // manually set the active url since the route /channels isn't working well
        ModuleNavigationActions.setActiveUrl('channels');
        this.setState({ channelsOverlayVisible: true });
        ReactDOM.render(
          <ThemeProvider>
            <FollowChannels onClose={onClose} />
          </ThemeProvider>,
          document.querySelector('.a-tooltips'),
        );
      },
    };
  }

  render() {
    return (
      <ModuleNavigation>
        {getComponents(this._getNavigationItems(), history.fragment)}
      </ModuleNavigation>
    );
  }
}
