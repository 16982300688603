import { STATUS_OK } from 'app-constants';
import { getSubscriptionPaymentPageUri } from 'helpers/paymentPageUrl/getSubscriptionPaymentPageUri';
import useAlt from 'hooks/useAlt';
import WalletMigrationStore from 'stores/WalletMigrationStore';

const useMigrationOffer = () => {
  const {
    status,
    balance,
    couponCode,
    equivalentMoneyOfferedMonths,
    offeredNumberOfMonths,
  } = useAlt([WalletMigrationStore], ([{ offer }]) => offer);

  return status === STATUS_OK
    ? {
        paymentPageUri: getSubscriptionPaymentPageUri({
          paymentPath: '/switch-to-premium',
          couponCode,
          autoApply: true,
        }),
        balance,
        equivalentMoneyOfferedMonths,
        offeredNumberOfMonths,
      }
    : undefined;
};

export default useMigrationOffer;
