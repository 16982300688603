import R from 'ramda';

const id = R.prop('id');
const itemBundledIn = R.prop('item_bundled_in');

export function getItemId(item) {
  return id(item) || R.path(['_embedded', 'b:manifest', 'id'], item);
}

export const isBundleItem = item => {
  // Also support tile items
  const itemBundles = itemBundledIn(item) || [];

  return itemBundles.includes('premium');
};

export const getManifest = item => R.path(['_embedded', 'manifest'], item);

export function isItemAcquired(itemState) {
  return R.pathOr(false, ['acquisition', 'acquired'], itemState);
}
