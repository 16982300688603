import React from 'react';
import styledModule from 'helpers/styledModule';
import { NavigationItem } from '@blendle/lego';
import Link from 'components/Link';
import { translate } from 'instances/i18n';
import useDidSeeDiscoverPremiumPage from 'hooks/useDidSeeDiscoverPremiumPage';
import CSS from './styles.scss';

const StyledNavigationItem = styledModule(NavigationItem)(CSS.navigationItem);

const DiscoverPremiumLink = () => {
  const { useProminentColor } = useDidSeeDiscoverPremiumPage();

  return (
    <StyledNavigationItem>
      <Link
        href="/discover-premium"
        className={useProminentColor ? CSS.prominentLink : CSS.regularLink}
      >
        {translate('navigation.links.discoverPremium.label')}
      </Link>
    </StyledNavigationItem>
  );
};

export default DiscoverPremiumLink;
