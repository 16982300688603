import React from 'react';
import Logo from 'components/Logo';
import Link from 'components/Link';
import { translate } from 'instances/i18n';
import useAlt from 'hooks/useAlt';
import { path } from 'ramda';
import { isError } from 'helpers/status';
import LoginStore from 'stores/LoginStore';
import { bool } from 'prop-types';

const getStatus = path(['login', 'status']);

const mapState = ([loginState]) => getStatus(loginState);

const TokenErrorPage = ({ isTokenMissing }) => {
  const loginStatus = useAlt([LoginStore], mapState);

  return (
    <div className="v-email-login-page">
      <header className="header">
        <Link href="/">
          <Logo />
        </Link>
      </header>
      <div className="login-error">
        <div className="body">
          {isTokenMissing || isError(loginStatus) ? (
            <div>
              <img
                src="/img/illustrations/magiclink.svg"
                className="magic-link-illustration"
                alt="Magic link illustration"
              />
              <h1>{translate('app.email_login.token_error')}</h1>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

TokenErrorPage.propTypes = {
  isTokenMissing: bool,
};
TokenErrorPage.defaultProps = {
  isTokenMissing: false,
};

export default TokenErrorPage;
