import axios from 'axios';
import URI from 'urijs';
import { prop } from 'ramda';
import Settings from 'controllers/settings';
import sendPolledRequest from 'helpers/sendPolledRequest';

const data = prop('data');

const headers = {
  accept: 'application/hal+json',
};

function fetchSectionsPageByUrl(url, maxRetries = Infinity) {
  const getTimeoutTime = (err, retryCount) => 500 + retryCount * 250;
  const requestOptions = {
    url,
    method: 'get',
    headers,
  };

  return sendPolledRequest(requestOptions, getTimeoutTime, maxRetries).then(resp => resp.data);
}

export function fetchPersonalPage(userId) {
  const url = Settings.getLink('b:user_home', {
    user_id: userId,
  });

  return fetchSectionsPageByUrl(url);
}

export function fetchEarlierPage(userId) {
  const url = Settings.getLink('b:user_earlier_page', {
    user_id: userId,
  });

  return axios
    .get(url, {
      headers,
    })
    .then(data);
}

export function fetchTiles(url) {
  const requestUrl = URI(url)
    .addSearch('zoom', 'b:tiles')
    .toString();

  return axios
    .get(requestUrl, {
      headers: {
        ...headers,
        'X-Tile-Version': 3,
      },
    })
    .then(data);
}
