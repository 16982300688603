import { useEffect, useState } from 'react';
import useRefreshGraphQLArticle from 'hooks/useRefreshGraphQLArticle';
import useAlt from 'hooks/useAlt';
import SyncStore from 'stores/SyncStore';
import { SYNC_ACTION_TYPE } from 'app-constants';
import ItemActions from 'actions/ItemActions';
import useUser from 'hooks/useUser';

export const useOnSyncAction = () => {
  const [nextProcessingActionIndex, setNextProcessingActionIndex] = useState(0);
  const { refresh } = useRefreshGraphQLArticle();
  const user = useUser();

  const { actions } = useAlt([SyncStore], ([state]) => state);

  const action = actions[nextProcessingActionIndex];

  useEffect(() => {
    if (action) {
      const { type, payload } = action;

      switch (type) {
        case SYNC_ACTION_TYPE.PIN_STATE_CHANGED_VIA_MUTATION:
          ItemActions.pinItemSuccess({ itemId: payload.itemId, pinned: payload.pinned, user });
          ItemActions.updatePinInTilesStore({ itemId: payload.itemId, pinned: payload.pinned });

          break;
        // All the following actions are always happening outside the GraphQL context
        // and all require a refresh of the item in the GraphQL cache
        case SYNC_ACTION_TYPE.PIN_STATE_CHANGED_VIA_TILES:
        case SYNC_ACTION_TYPE.ITEM_OPENED:
        case SYNC_ACTION_TYPE.ITEM_REFUNDED:
        case SYNC_ACTION_TYPE.ITEM_LIKED:
        case SYNC_ACTION_TYPE.ITEM_UN_LIKED:
          refresh(payload.itemId);

          break;
        default:
          window.ErrorLogger.captureMessage(`Received unknown async action type: ${action.type}`);
      }

      setNextProcessingActionIndex((index) => index + 1);
    }
  }, [action]);
};
