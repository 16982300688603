import React from 'react';
import { translate } from 'instances/i18n';
import { SearchIcon, CloseIcon, NavigationItem } from '@blendle/lego';
import classNames from 'classnames';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';
import SharedCSS from '../shared.scss';
import useSearch from '../hooks/useSearch';
import DesktopSearchBar from '../DesktopSearchBar';

const Form = styledModule('form')(SharedCSS.form, CSS.form);
const StyledSearchIcon = styledModule(SearchIcon)(SharedCSS.searchIcon);
const NavBarSearchIcon = styledModule(StyledSearchIcon)(SharedCSS.cursorPointer);
const Overlay = styledModule.button(CSS.overlay);
const CloseButton = styledModule.button(CSS.closeButton);

const MobileSearchBar = ({ isOnSearchRoute, searchQueryFromUrl }) => {
  const {
    inputRef,
    onChangeQuery,
    onSubmit,
    query,
    openSearchBar,
    closeSearchBar,
    isOpen,
  } = useSearch({
    searchQueryFromUrl,
    isOnSearchRoute,
  });

  return (
    <>
      <Form onSubmit={onSubmit} className={classNames(isOpen && CSS.isOpen)}>
        <StyledSearchIcon />
        <input
          // not using styledModule because that doesn't pass the ref down
          className={SharedCSS.input}
          type="text"
          ref={inputRef}
          name="search-query"
          onChange={onChangeQuery}
          value={query}
          placeholder={translate('search.text.placeholder_mobile')}
          disabled={!isOpen}
        />
        <CloseButton
          type="button"
          onClick={closeSearchBar}
          disabled={!isOpen}
          data-testid="form-close-icon"
        >
          <CloseIcon />
        </CloseButton>
      </Form>
      <NavigationItem>
        <NavBarSearchIcon
          data-testid="nav-bar-search-icon"
          onClick={openSearchBar}
          className={classNames(isOnSearchRoute && SharedCSS.active)}
        />
      </NavigationItem>
      {isOpen && !isOnSearchRoute && <Overlay onClick={closeSearchBar} data-testid="overlay" />}
    </>
  );
};

MobileSearchBar.propTypes = DesktopSearchBar.propTypes;

MobileSearchBar.defaultProps = DesktopSearchBar.defaultProps;

export default MobileSearchBar;
