import i18n from 'instances/i18n';
import { CREDIT_CURRENCY_NAME } from 'app-constants';

const creditsLowBalanceThreshold = 10;
const micropaymentsLowBalanceThreshold = 1;

export function getBalanceLabelColor({ balance, useNeutralLowBalanceColor = false }) {
  const lowBalanceThreshold =
    i18n.currentCurrency === CREDIT_CURRENCY_NAME
      ? creditsLowBalanceThreshold
      : micropaymentsLowBalanceThreshold;

  const lowBalanceColor = useNeutralLowBalanceColor ? 'cappuccino' : 'hotCoral';

  return Number(balance) <= lowBalanceThreshold ? lowBalanceColor : 'cashGreen';
}
