import { get } from 'lodash';
import { STATUS_PENDING } from 'app-constants';
import { SUPPORTED_SECTION_TYPES } from '../constants';

export const getTilesUrl = section => get(section, '_links[b:feed].href');

export const getSectionItemIds = (sectionsPageState, sectionId) => {
  const sectionFeed = sectionsPageState.sectionFeeds.get(sectionId);
  return get(sectionFeed, 'data', []);
};

/**
 * This functions returns a truthy value if a section is a featured section. This is only here to
 * make testing the FeaturedSection easier. The API should tell the client if a section is featured
 * or not.
 * @param  {String}  sectionId The ID of the section.
 * @return {Boolean}
 */
export const isFeaturedSection = sectionId => {
  const featuredSections = ['entity_blendle_exclusive'];

  return featuredSections.includes(sectionId);
};

/**
 * Takes an array of sections and returns all supported sections
 * @param  {Array} sections Array of section objects
 * @return {Array}          Array of section objects that are supported in the webclient
 */
export const filterSupportedSections = sections =>
  sections.filter(section => SUPPORTED_SECTION_TYPES.includes(section.type));

/**
 * Check if any if the sections on a page is being loaded
 * @param  {Array}  sectionIds   The sectionIds of all sections that should be checked
 * @param  {Map}    sectionFeeds Map of section feeds
 * @return {Boolean}             True when any of the sections is in the pending state
 */
export const isLoadingSectionFeed = (sectionIds, sectionFeeds) =>
  sectionIds
    .map(sectionId => sectionFeeds.get(sectionId))
    .some(sectionFeed => sectionFeed && sectionFeed.status === STATUS_PENDING);

/**
 * Check if the tiles of a section should be fetched. It could be possible that the tiles in the section are already loaded,
 * or that this is a section that does not have any tiles at all. In that case, we should not attempt to load the tiles.
 * @param {Object} section      Section object
 * @param {Map}    sectionFeeds Map of section feeds
 */
export const shouldFetchTiles = (section, sectionFeeds) => {
  const hasFeedInStore = sectionFeeds.get(section.id);
  const canFetchTiles = Boolean(getTilesUrl(section));

  return !hasFeedInStore && canFetchTiles;
};
