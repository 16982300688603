import React from 'react';
import { asyncRoute } from 'helpers/routerHelpers';
import { pathOr } from 'ramda';
import DiscoverPremiumContainer from './containers/DiscoverPremiumContainer';

const successPath = pathOr(undefined, ['query', 'successPath']);

const HidePrimaryNavigation = () => <span />;

function route(path) {
  return {
    module: 'discoverPremium',
    path,
    getComponents: asyncRoute(({ location }, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            primaryNavigation: HidePrimaryNavigation,
            content: () => <DiscoverPremiumContainer appUserSuccessPath={successPath(location)} />,
          });
        },
        'discoverPremium',
      );
    }),
  };
}

export default [route('discover-premium')];
