import React from 'react';
import { oneOf, object, bool } from 'prop-types';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import staticImageUrl from 'helpers/staticImageUrl';
import { getHdImage } from 'helpers/images';
import CSS from './styles.scss';
import { isMigratedUserVersion } from '../helper';

const assetBase = '/img/video/successDialog/';
const migratedUserSrc = getHdImage(
  `${assetBase}migrated-users.mp4`,
  `${assetBase}migrated-users@2x.mp4`,
);
const newUserSrc = getHdImage(`${assetBase}new-users.mp4`, `${assetBase}new-users@2x.mp4`);
const migratedUserPoster = `${assetBase}migrated-users.jpg?auto=compress,format&w=340`;
const newUserPoster = `${assetBase}new-users.jpg?auto=compress,format&w=340`;

const Video = ({ videoRef, version, muteAudio }) => {
  const poster = isMigratedUserVersion(version) ? migratedUserPoster : newUserPoster;
  const source = isMigratedUserVersion(version) ? migratedUserSrc : newUserSrc;

  return (
    <video
      poster={staticImageUrl(poster)}
      src={staticImageUrl(source)}
      ref={videoRef}
      className={CSS.video}
      muted={muteAudio}
    />
  );
};

Video.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  videoRef: object.isRequired,
  muteAudio: bool.isRequired,
};

export default Video;
