import { getItem, removeItem } from 'helpers/sessionStorage';
import { PREMIUM_YEARLY_PRODUCT, PREMIUM_MONTHLY_PRODUCT } from 'app-constants';

const PREFERRED_PRODUCT_INTERVAL_KEY = 'PREFERRED_PRODUCT_INTERVAL_KEY';

export const productIntervals = new Map([
  [
    'blendlepremium_monthly_with_trial',
    {
      productId: PREMIUM_MONTHLY_PRODUCT,
    },
  ],
  [
    'blendlepremium_monthly',
    {
      productId: PREMIUM_MONTHLY_PRODUCT,
    },
  ],
  [
    'blendlepremium_yearly',
    {
      productId: PREMIUM_YEARLY_PRODUCT,
    },
  ],
]);

export function getPreferredProductInterval() {
  const item = getItem(PREFERRED_PRODUCT_INTERVAL_KEY);

  return productIntervals.has(item) ? item : null;
}

export function resetPreferredProductInterval() {
  return removeItem(PREFERRED_PRODUCT_INTERVAL_KEY);
}
