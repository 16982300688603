import alt from 'instances/altInstance';
import { XHR_STATUS } from 'app-constants';
import { fetchEarlierPage } from '../managers/sectionsPageManager';
import { filterSupportedSections } from '../selectors/sections';
import SectionsPageActions from './SectionsPageActions';

class EarlierPageActions extends SectionsPageActions {
  fetchEarlierPage(userId) {
    // Dispatch for loading state
    this.fetchSections();

    fetchEarlierPage(userId).then(
      response => {
        this.fetchSectionsSuccess({
          sections: filterSupportedSections(response._embedded['b:sections']),
        });
      },
      error => {
        this.fetchSectionsError(error);

        if (error.type !== XHR_STATUS) {
          throw error;
        }
      },
    );

    return null;
  }
}

export default alt.createActions(EarlierPageActions);
