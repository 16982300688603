import React from 'react';
import { wrapDisplayName } from '@blendle/recompose';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import environment from 'environment';
import { cache } from './cache';
import { requestHandler, errorHandler } from './handlers';

export default (ComposedComponent) =>
  class WithGraphQL extends React.PureComponent {
    // eslint-disable-next-line react/static-property-placement
    static displayName = wrapDisplayName(ComposedComponent, 'withGraphQL');

    constructor() {
      super();

      this.client = new ApolloClient({
        uri: environment.graphQLHost,
        cache,
        request: requestHandler,
        onError: errorHandler,
      });
    }

    render() {
      return (
        <ApolloProvider client={this.client}>
          <ComposedComponent {...this.props} />
        </ApolloProvider>
      );
    }
  };
