// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-QFba-{max-width:600px;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;margin:0 auto;text-align:center;padding:10px;font-size:1.28571em}@media screen and (min-width: 1024px){.l-desktop-33tFq .container-QFba-{padding:40px 10px}}.ninja-3Uh8Y{width:100%;max-width:250px;display:inline-block;margin:30px}.title-306Gi{font-size:1.71429em;margin-bottom:10px}.subTitle-ZI2VS{color:#948784;margin-bottom:10px;font-size:20px}.body-3aC2u{line-height:1.28571em}.logo-27TrL{margin:30px auto}\n", ""]);
// Exports
exports.locals = {
	"container": "container-QFba-",
	"l-desktop": "l-desktop-33tFq",
	"ninja": "ninja-3Uh8Y",
	"title": "title-306Gi",
	"subTitle": "subTitle-ZI2VS",
	"body": "body-3aC2u",
	"logo": "logo-27TrL"
};
module.exports = exports;
