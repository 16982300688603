import React from 'react';
import { wrapDisplayName } from '@blendle/recompose';
import { getCookieConsent } from 'helpers/cookieConsent';
import CookieConsentActions from 'actions/CookieConsentActions';
import { CookieBar } from 'components/CookieBar';
import FacebookActions from 'actions/FacebookActions';
import environment from 'environment';
import { prop, pipe, equals } from 'ramda';

const isTestEnvironment = pipe(prop('name'), equals('test'));

export default (ComposedComponent) =>
  class WithCookieBar extends React.Component {
    static displayName = wrapDisplayName(ComposedComponent, 'withCookieBar');

    constructor(props) {
      super(props);

      if (isTestEnvironment(environment)) {
        CookieConsentActions.setConsentAndHideBar({ hasGivenConsent: true });
        FacebookActions.initializeFacebook();

        return;
      }

      const consent = getCookieConsent();

      if (consent) {
        CookieConsentActions.setConsentAndHideBar(consent);

        const { hasGivenConsent } = consent;

        if (hasGivenConsent) {
          FacebookActions.initializeFacebook();
        }
      } else {
        CookieConsentActions.showCookieBar(true);
      }
    }

    render() {
      return (
        <React.Fragment>
          <ComposedComponent {...this.props} />
          <CookieBar />
        </React.Fragment>
      );
    }
  };
