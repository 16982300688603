import React from 'react';
import CookieConsentActions from 'actions/CookieConsentActions';
import { translateElement } from 'instances/i18n';
import styledModule from 'helpers/styledModule';
import { string } from 'prop-types';
import CSS from './styles.scss';

const Copy = styledModule.div(CSS.copy);

const ConsentNeededHint = ({ className }) => {
  return (
    <Copy onClick={CookieConsentActions.showCookieBar} className={className}>
      {translateElement(<p />, 'app.error.facebook_requires_cookies_hint')}
    </Copy>
  );
};

ConsentNeededHint.propTypes = {
  className: string,
};

ConsentNeededHint.defaultProps = {
  className: '',
};

export default ConsentNeededHint;
