// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".premiumOnly-303z6 .headroom{z-index:1000 !important;box-shadow:0 1px rgba(0,0,0,0.1)}@media (min-width: 820px){.premiumOnly-303z6 .headroom{box-shadow:none}.premiumOnly-303z6 .headroom--pinned{box-shadow:0 1px rgba(0,0,0,0.1)}}.premiumAndMicropayments-3LLU3 .headroom{z-index:1000 !important;box-shadow:0 1px rgba(0,0,0,0.1)}@media (min-width: 750px){.premiumAndMicropayments-3LLU3 .headroom{box-shadow:none}.premiumAndMicropayments-3LLU3 .headroom--pinned{box-shadow:0 1px rgba(0,0,0,0.1)}}.micropayments-2E5zW .headroom{z-index:1000 !important;box-shadow:0 1px rgba(0,0,0,0.1)}@media (min-width: 620px){.micropayments-2E5zW .headroom{box-shadow:none}.micropayments-2E5zW .headroom--pinned{box-shadow:0 1px rgba(0,0,0,0.1)}}\n", ""]);
// Exports
exports.locals = {
	"premiumOnly": "premiumOnly-303z6",
	"premiumAndMicropayments": "premiumAndMicropayments-3LLU3",
	"micropayments": "micropayments-2E5zW"
};
module.exports = exports;
