import { STATUS_INITIAL, STATUS_PENDING, STATUS_OK, STATUS_ERROR } from 'app-constants';
import alt from 'instances/altInstance';
import WalletMigrationActions from '../actions/WalletMigrationActions';

const initialOfferState = {
  status: STATUS_INITIAL,
  balance: null,
  couponCode: null,
  equivalentMoneyOfferedMonths: null,
  offeredNumberOfMonths: null,
};

const initialState = {
  offer: initialOfferState,
};

class WalletMigrationStore {
  constructor() {
    this.bindActions(WalletMigrationActions);
    this.state = initialState;
  }

  onGetMigrationOffer() {
    this.setState({
      offer: {
        ...initialOfferState,
        status: STATUS_PENDING,
      },
    });
  }

  onGetMigrationOfferSuccess({
    balance,
    couponCode,
    equivalentMoneyOfferedMonths,
    offeredNumberOfMonths,
  }) {
    this.setState({
      offer: {
        status: STATUS_OK,
        balance,
        couponCode,
        equivalentMoneyOfferedMonths,
        offeredNumberOfMonths,
      },
    });
  }

  onMigrationOfferError() {
    this.setState({
      offer: {
        ...initialOfferState,
        status: STATUS_ERROR,
      },
    });
  }
}

export default alt.createStore(WalletMigrationStore, 'WalletMigrationStore');
