
      export default {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Node",
        "possibleTypes": [
          {
            "name": "Article"
          },
          {
            "name": "AudioTrack"
          },
          {
            "name": "Entity"
          },
          {
            "name": "Provider"
          },
          {
            "name": "Issue"
          },
          {
            "name": "CuratedCollection"
          },
          {
            "name": "Curator"
          },
          {
            "name": "Topic"
          },
          {
            "name": "TopicCollection"
          },
          {
            "name": "User"
          },
          {
            "name": "DailySelection"
          },
          {
            "name": "ArticleSelection"
          },
          {
            "name": "Brand"
          },
          {
            "name": "BrandEdition"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Edition",
        "possibleTypes": [
          {
            "name": "CuratedCollection"
          },
          {
            "name": "TopicCollection"
          },
          {
            "name": "BrandEdition"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchResultItem",
        "possibleTypes": [
          {
            "name": "Article"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchPageResultItem",
        "possibleTypes": [
          {
            "name": "SearchArticleCard"
          },
          {
            "name": "SearchHeader"
          },
          {
            "name": "SearchIssueResult"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DailySelectionElement",
        "possibleTypes": [
          {
            "name": "DailySelectionHeader"
          },
          {
            "name": "EditorialSection"
          },
          {
            "name": "EntitySection"
          },
          {
            "name": "GenericSection"
          },
          {
            "name": "ProviderSection"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "TodayPageElement",
        "possibleTypes": [
          {
            "name": "DailySelectionHeader"
          },
          {
            "name": "DailySelections"
          },
          {
            "name": "Editions"
          },
          {
            "name": "EditorialSection"
          },
          {
            "name": "EntitySection"
          },
          {
            "name": "GenericSection"
          },
          {
            "name": "ProviderSection"
          }
        ]
      }
    ]
  }
}
    