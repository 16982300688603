// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-343TN{font-family:\"GT-Walsheim\",sans-serif;color:#faf2fd;font-size:16px}.label-Czb6d.label-Czb6d{height:20px;font-size:16px}.bold-2cfXD{font-weight:600}\n", ""]);
// Exports
exports.locals = {
	"container": "container-343TN",
	"label": "label-Czb6d",
	"bold": "bold-2cfXD"
};
module.exports = exports;
