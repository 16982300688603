import altConnect from 'higher-order-components/altConnect';
import AuthStore from 'stores/AuthStore';

function mapStateToProps({ authState }, props) {
  return {
    ...props,
    user: authState.user,
  };
}

mapStateToProps.stores = {
  AuthStore,
};

function UserComponent({ children, user }) {
  return children(user);
}

export default altConnect(mapStateToProps)(UserComponent);
