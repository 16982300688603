import React from 'react';
import styledModule from 'helpers/styledModule';
import { Label } from '@blendle/lego';
import formatCurrency from 'helpers/formatCurrency';
import { number, string } from 'prop-types';
import CSS from './styles.scss';

const StyledLabel = styledModule(Label)(CSS.label);
const Bold = styledModule.span(CSS.bold);

const UpsellCopy = ({
  className,
  labelClassName,
  balance,
  equivalentMoneyOfferedMonths,
  offeredNumberOfMonths,
}) => {
  return balance > 0 ? (
    <span className={className}>
      Jouw kortingsbon van{' '}
      <StyledLabel inline color="snowWhite" className={labelClassName}>
        {formatCurrency(balance / 100)}
      </StyledLabel>{' '}
      geeft recht op <Bold>{offeredNumberOfMonths}&nbsp;maanden Premium</Bold> t.w.v.&nbsp;€
      {equivalentMoneyOfferedMonths / 100}!
    </span>
  ) : (
    <span className={className}>
      Neem nu Premium en krijg <Bold>{offeredNumberOfMonths}&nbsp;maanden&nbsp;gratis</Bold>.
      Scheelt je €{equivalentMoneyOfferedMonths / 100}!
    </span>
  );
};

UpsellCopy.propTypes = {
  className: string,
  labelClassName: string,
  balance: number.isRequired,
  equivalentMoneyOfferedMonths: number.isRequired,
  offeredNumberOfMonths: number.isRequired,
};

UpsellCopy.defaultProps = {
  className: '',
  labelClassName: '',
};

export default UpsellCopy;
