// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button-J-hsP{color:#fff;background:linear-gradient(171.38deg, #ab32e5 -53.86%, #4900fe 100%);box-shadow:0 2px 2px rgba(0,0,0,0.15),0 0 2px rgba(0,0,0,0.05);border-radius:4px;text-align:center;cursor:pointer;font-family:\"GT-Walsheim\",sans-serif;width:100%;line-height:44px;font-size:16px;font-weight:500;letter-spacing:-0.02em;padding:0;margin-top:24px}.button-J-hsP:hover{color:#fff;background:linear-gradient(174.55deg, #8f16c9 -53.86%, #3800c1 100%)}.button-J-hsP:active{background:linear-gradient(174.55deg, #740da5 -53.86%, #2f0695 100%)}\n", ""]);
// Exports
exports.locals = {
	"button": "button-J-hsP"
};
module.exports = exports;
