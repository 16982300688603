import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { wrapDisplayName } from '@blendle/recompose';

export default (ComposedComponent) =>
  class WithVisibilitySensor extends React.Component {
    static displayName = wrapDisplayName(ComposedComponent, 'withVisibilitySensor');

    state = {
      hasBeenInViewPort: false,
      isInViewPort: false,
    };

    onInViewport = (isInViewPort) => {
      if (isInViewPort) {
        this.setState({ hasBeenInViewPort: true, isInViewPort });
      } else {
        this.setState({ isInViewPort });
      }
    };

    render() {
      return (
        <VisibilitySensor onChange={this.onInViewport} delayedCall partialVisibility>
          <ComposedComponent {...this.state} {...this.props} />
        </VisibilitySensor>
      );
    }
  };
