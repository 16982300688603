import React from 'react';
import { node } from 'prop-types';
import CheckmarkIconThin from 'components/icons/CheckmarkIconThin';
import styledModule from 'helpers/styledModule';
import { useDiscoverPremiumPageContext } from 'modules/discoverPremium/context/DiscoverPremiumContext';
import CSS from './USP.scss';

const Container = styledModule.div(CSS.container);
const StyledCheckmark = styledModule(CheckmarkIconThin)(CSS.checkmark);
const Copy = styledModule.p(CSS.copy);

const USP = ({ children }) => {
  const { primaryColor } = useDiscoverPremiumPageContext();

  return (
    <Container>
      <StyledCheckmark fill={primaryColor} />
      <Copy>{children}</Copy>
    </Container>
  );
};

USP.propTypes = {
  children: node.isRequired,
};

USP.defaultProps = {};

export default USP;
