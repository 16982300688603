import React from 'react';
import { Helmet } from 'react-helmet';
import SegmentAnalytics from 'instances/segmentAnalytics';

SegmentAnalytics.init();

const SegmentSnippet = () => (
  <Helmet>
    <script>{SegmentAnalytics.snippet}</script>
  </Helmet>
);

export default SegmentSnippet;
