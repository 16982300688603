import { STATUS_INITIAL, STATUS_OK, STATUS_ERROR, STATUS_PENDING } from 'app-constants';
import alt from 'instances/altInstance';
import ShareActions from 'actions/ShareActions';

class ShareStore {
  constructor() {
    this.bindActions(ShareActions);

    this.state = {
      status: STATUS_INITIAL,
      error: null,
      sentTo: null,
      loadingPlatforms: {},
      posts: [],
      message: '',
    };
  }

  onLoadPlatform(platform) {
    const { loadingPlatforms } = this.state;
    loadingPlatforms[platform] = STATUS_PENDING;
    this.setState({ loadingPlatforms });
  }

  onLoadPlatformSuccess(platform) {
    const { loadingPlatforms } = this.state;
    delete loadingPlatforms[platform];
    this.setState({ loadingPlatforms });
  }

  onShareItemToEmailSuccess(sentTo) {
    this.setState({
      status: STATUS_OK,
      sentTo,
    });
  }

  onShareItemToEmail() {
    this.setState({
      status: STATUS_PENDING,
    });
  }

  onShareItemToEmailError({ error }) {
    this.setState({
      status: STATUS_ERROR,
      error: error.message,
    });
  }

  onResetStatus() {
    this.setState({
      status: STATUS_INITIAL,
    });
  }

  onShareToFollowing() {
    this.setState({
      status: STATUS_PENDING,
    });
  }

  onShareToFollowingSuccess() {
    this.setState({
      status: STATUS_OK,
      message: '',
    });
  }

  onRemoveShareToFollowingSuccess() {
    this.setState({
      status: STATUS_OK,
    });
  }

  onSetMessage(message) {
    this.setState({ message });
  }
}

export default alt.createStore(ShareStore, 'ShareStore');
