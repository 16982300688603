import React from 'react';
import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';

const TopSection = styledModule.div(CSS.topSection);
const TopTitle = styledModule.h2(CSS.topTitle);
const TopBody = styledModule.p(CSS.topBody);

const BoxWrapper = styledModule.div(CSS.boxWrapper);
const BaseBox = styledModule.div(CSS.basebox);
const BoxLeft = styledModule(BaseBox)(CSS.boxLeft);
const BoxRight = styledModule(BaseBox)(CSS.boxRight);
const BoxTitle = styledModule.h2(CSS.boxTitle);
const BoxCopy = styledModule.p(CSS.boxCopy);

const Outro = () => (
  <React.Fragment>
    <TopSection>
      <TopTitle>Je bent in goed gezelschap</TopTitle>
      <TopBody>
        Meer dan 60.000 mensen lezen, luisteren, zoeken en bladeren al met Blendle Premium. Sta
        iedere dag op met een persoonlijke selectie op basis van je leesvoorkeuren, uit de grootste
        kranten. Volg nieuwe onderwerpen, blader door meer dan 50 tijdschriften en luister onderweg
        naar ingesproken&nbsp;verhalen.
      </TopBody>
    </TopSection>
    <BoxWrapper>
      <BoxLeft>
        <BoxTitle>Bespaar tijd en geld</BoxTitle>
        <BoxCopy>
          Stel je leesvoorkeuren in om alle verhalen over je favoriete onderwerpen te ontvangen, zo
          lees je meer in minder tijd. Ook heb je onbeperkt toegang tot meer dan 50 tijdschriften
          (die bij de AKO los ruim 250 euro per keer&nbsp;kosten)!
        </BoxCopy>
      </BoxLeft>
      <BoxRight>
        <BoxTitle>Hoe werkt deze actie?</BoxTitle>
        <BoxCopy>
          Bovenaan deze pagina kun je Blendle Premium activeren. Zonder bijbetaling. Je hebt dan
          direct weer toegang tot Blendle. Wel vragen we je om alvast jouw betaalgegevens te
          controleren voor ná de actieperiode. Stopzetten kan op ieder&nbsp;moment.
        </BoxCopy>
      </BoxRight>
    </BoxWrapper>
  </React.Fragment>
);

export default Outro;
