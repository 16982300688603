import Features from 'config/features';
import Environment from 'environment';
import {
  isGetPremiumDeeplinkRoute,
  isGetPremiumLandingPageRoute,
  isLandingProjectHost,
} from 'helpers/landingProject';
import { countryEligibleForPremium } from 'helpers/premiumEligibility';
import { getUTMParameters, makeUrlParamsFromObject } from 'helpers/url';
import Analytics from 'instances/analytics';
import { getIso6391 } from 'instances/i18n';
import { once } from 'lodash';
import R from 'ramda';
import React from 'react';
import URI from 'urijs';

// This function is called in a render function, so we have to wrap it in once()
// to make sure the event is only sent once
const trackUrlChange = once(() => {
  Analytics.urlChange();
});

const replacedRoutes = Object.freeze({
  '/about/privacy': '/legal/privacy',
  '/settings/privacy': '/legal/privacy',
});
const isReplacedRoute = route => Object.keys(replacedRoutes).includes(route);
const getReplacedRoute = route => replacedRoutes[route] || route;

const flatJoin = (arr, separator) => arr.filter(Boolean).join(separator);

export function convertToLandingProjectURL(route, query = {}) {
  const uri = new URI(getReplacedRoute(route));

  uri.protocol(Environment.landing_project_protocol);
  uri.host(Environment.landing_project_host);
  uri.query(query);

  const utmData = Analytics._utm || getUTMParameters();
  const utmQueryString = makeUrlParamsFromObject(utmData, 'utm_');

  if (utmQueryString) {
    uri.query(flatJoin([uri.query(), utmQueryString], '&'));
  }

  if (document.referrer && !isLandingProjectHost(document.referrer)) {
    uri.query(
      flatJoin(
        [uri.query(), makeUrlParamsFromObject({ originalReferrer: document.referrer })],
        '&',
      ),
    );
  }

  return uri.toString();
}

function isLiveLandingProjectRoute(pathname) {
  return (
    (Features.landingPageMicroFrontend && isGetPremiumLandingPageRoute(pathname)) ||
    (Features.deeplinkLandingPageMicroFrontend && isGetPremiumDeeplinkRoute(pathname))
  );
}

export const languageHasMovedAboutPage = R.equals('nl');

/**
 * @param {string} pathname
 */
function isMovedAboutPage(pathname) {
  return languageHasMovedAboutPage(getIso6391()) && isReplacedRoute(pathname);
}

const isCouponPageInLandingsProject = R.startsWith('/actie');
const isPostcodeloterijPath = R.equals('/postcodeloterij');
const isRouteWithSlashGPrefix = R.startsWith('/g/');

const addSendSessionEventParam = url => new URI(url).addQuery('sendSessionEvent', true).toString();

export default function(authController) {
  return {
    renderRouteComponent(child, details) {
      const user = authController.getUser();
      const { pathname, query } = details.location;
      const isLoggedIn = !!user;

      const isRequestForGetPremiumPage =
        !isLoggedIn && countryEligibleForPremium() && isLiveLandingProjectRoute(pathname);

      const shouldRedirectToLandingProject =
        isRequestForGetPremiumPage ||
        isMovedAboutPage(pathname) ||
        isPostcodeloterijPath(pathname) ||
        isCouponPageInLandingsProject(pathname) ||
        isRouteWithSlashGPrefix(pathname);

      if (shouldRedirectToLandingProject) {
        const landingProjectUrl = convertToLandingProjectURL(pathname, query);

        // When we are doing a redirect because a user opens one of these page, we haven't send
        // a session event yet. We need to tell web-landings to still send the session event.
        //
        // Unless it is any of the /getpremium pages we have in web-landings, for new sessions
        // the redirect would have already happenend in Fastly. So for the edge cases where we send
        // you from within web-client to these pages, the session event is already send.
        // This edge case happens for instance when you click logout and are then send to web-landings
        if (isRequestForGetPremiumPage) {
          window.location = landingProjectUrl;
        } else {
          window.location = addSendSessionEventParam(landingProjectUrl);
        }

        trackUrlChange();

        return <span />;
      }

      return child;
    },
  };
}
