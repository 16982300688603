import 'navigator.sendbeacon';
import 'whatwg-fetch';
import './polyfills/requestAnimationFrame';
import './polyfills/requestIdleCallback';
import './polyfills/classList';
import './polyfills/closest';

if (!window.FontFaceObserver) {
  window.FontFaceObserver = require('./polyfills/fontfaceobserver');
}
