/* eslint-disable max-len */

export default [
  /** Vodafone optional params */
  // managedExternally: true, // hides 'stopt vanzelf', cause we don't know, also doens't auto activate the subscription on signup
  // subscriptionProduct: 'blendlepremium_trial', // defaults to trial
  // returnUrl: 'https://offers.vodafone.com/nl/blendle/response', // gives returnUrl for button in last confirmation step
  /** /Vodafone optional params */
  {
    name: 'vodafone',
    managedExternally: true,
    useDefaultHeader: true,
    subscriptionProduct: 'blendlepremium_vodafone_1_month_trial',
    returnUrl: 'https://offers.vodafone.com/nl/blendle/response',
    copy: {
      redeem: {
        buttonText: 'Bevestig en ga terug naar Vodafone',
        bodyCopy: 'Alleen nog even bevestigen en je leest Blendle Premium de komende maand gratis.',
      },
      confirmEmail: {
        bodyCopy: 'Activeer je cadeautje via de link die we hebben gemaild naar ${email}.',
      },
    },
  },
];
