import alt from 'instances/altInstance';
import * as entitiesMananger from 'managers/entities';
import Analytics from 'instances/analytics';

class EntityActions {
  fetchUserEntities(userId) {
    entitiesMananger
      .fetchUserEntities(userId)
      .then(entities => this.fetchUserEntitiesSuccess(entities));

    return userId;
  }

  fetchUserEntitiesSuccess = entities => entities;

  followEntity(userId, entityId, analyticsPayload = {}) {
    entitiesMananger.followEntity(userId, entityId).then(entity => {
      this.setEntityState(entityId, entity);
      Analytics.track('Entity Followed', {
        entity_id: entityId,
        ...analyticsPayload,
      });
    });

    return entityId;
  }

  unfollowEntity(userId, entityId, analyticsPayload = {}) {
    entitiesMananger.unfollowEntity(userId, entityId).then(entity => {
      this.setEntityState(entityId, entity);
      Analytics.track('Entity Unfollowed', {
        entity_id: entityId,
        ...analyticsPayload,
      });
    });

    return entityId;
  }

  setEntityState = (entityId, entity) => ({ entityId, entity });

  fetchAllEntities() {
    entitiesMananger
      .fetchAllEntities()
      // Convert object to array
      .then(entities =>
        Object.keys(entities).map(entityId => ({
          ...entities[entityId],
          id: entityId,
        })),
      )
      .then(entities => this.fetchAllEntitiesSuccess(entities));

    return null;
  }

  fetchAllEntitiesSuccess = entities => entities;
}

export default alt.createActions(EntityActions);
