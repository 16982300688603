// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-1-AXm{max-width:825px !important;height:540px;width:100% !important;border-radius:10px !important}@media (max-width: 850px){.dialog-1-AXm{border-radius:0 !important;top:0 !important;width:100% !important;height:100% !important;max-height:100% !important;max-width:100% !important}}.dialogOverlay-2efsa{background-color:transparent !important}.contentWrapper-77-vk{display:flex;border-radius:10px}@media (max-width: 850px){.contentWrapper-77-vk{border-radius:0;flex-direction:column;justify-content:space-between;height:100%}}.copyContainer-1h7NI{text-align:left;padding:46px 58px;max-width:488px;flex-grow:1;flex-shrink:0}@media (max-width: 850px){.copyContainer-1h7NI{max-width:400px;padding:29px 20px 0;margin:0 auto;display:flex;flex-direction:column;justify-content:space-around}}.title-1oyh0{font-family:\"GT-Walsheim\",sans-serif;font-size:30px;line-height:26px;letter-spacing:-0.03em;color:#111;margin-bottom:23px}@media (max-width: 850px){.title-1oyh0{text-align:center;font-size:28px;margin-bottom:17px}}.partyIcon-77lxE{width:30px;height:30px;margin-left:7px;display:inline-block}.intro-1AzHi{font-family:\"GT-Walsheim\",sans-serif;font-size:20px;line-height:28px;letter-spacing:-0.02em;color:#333;margin-bottom:17px}@media (max-width: 850px){.intro-1AzHi{text-align:center;font-size:18px;line-height:23px}}.bold-1wjC6{font-weight:600}\n", ""]);
// Exports
exports.locals = {
	"dialog": "dialog-1-AXm",
	"dialogOverlay": "dialogOverlay-2efsa",
	"contentWrapper": "contentWrapper-77-vk",
	"copyContainer": "copyContainer-1h7NI",
	"title": "title-1oyh0",
	"partyIcon": "partyIcon-77lxE",
	"intro": "intro-1AzHi",
	"bold": "bold-1wjC6"
};
module.exports = exports;
