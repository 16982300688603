import { getHash } from 'helpers/sharerHash';

export function getShareItemUrl({ userId, path, bnlId, utmData }) {
  const utm = Object.keys(utmData)
    .map((utmKey) => `${encodeURIComponent(utmKey)}=${encodeURIComponent(utmData[utmKey])}`)
    .join('&');

  return [
    window.location.protocol,
    '//',
    window.location.host,
    '/',
    path,
    '?',
    utm,
    `&sharer=${encodeURIComponent(getHash(userId, bnlId))}`,
  ].join('');
}
