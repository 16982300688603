// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".logo-I1jMc{height:54px;margin-left:15px;margin-right:15px}@media (min-width: 600px){.logo-I1jMc{height:66px;margin-left:20px;margin-right:30px}}.link-1Blcd{transition:color 0.1s ease-out}.link-1Blcd:hover,.link-1Blcd.active{color:#ff1060}.iconWrapper-2POD9{display:flex;cursor:pointer}.iconWrapper-2POD9.active{color:#ff1060}.iconBrowse-3MlZj{width:18px;height:19px}.iconAudio-2UpvL{width:22px;height:20px}.iconBookmark-2vf2k{width:14px;height:20px}.iconBookmark-2vf2k path{fill:transparent;stroke:#999;stroke-width:1.5px}.active .iconBookmark-2vf2k path{stroke:#ff1060}\n", ""]);
// Exports
exports.locals = {
	"logo": "logo-I1jMc",
	"link": "link-1Blcd",
	"iconWrapper": "iconWrapper-2POD9",
	"iconBrowse": "iconBrowse-3MlZj",
	"iconAudio": "iconAudio-2UpvL",
	"iconBookmark": "iconBookmark-2vf2k"
};
module.exports = exports;
