// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-34KcZ{from{opacity:0}to{opacity:1}}.overlay-3Bd1h{position:absolute;top:0;bottom:0;right:0;left:0;background:rgba(0,0,0,0.4);display:flex;justify-content:center;border-top-right-radius:10px;border-bottom-right-radius:10px;opacity:0;animation:fadeIn-34KcZ 0.3s 0s ease-in forwards}.button-TQY8X{align-self:center;min-width:200px;height:55px;border:3px solid #fff;border-radius:100px;padding:0 16px;font-family:\"GT-Walsheim\",sans-serif;font-weight:600;font-size:20px;letter-spacing:-0.02em;color:#fff;opacity:0;animation:fadeIn-34KcZ 0.3s 0.3s ease-in forwards}.button-TQY8X:hover{cursor:pointer;transform:translateY(-2px);box-shadow:0 5px 20px rgba(0,0,0,0.1)}.partyIcon-1C41q{width:20px;height:20px;margin-left:7px;display:inline-block;position:relative;top:2px}\n", ""]);
// Exports
exports.locals = {
	"overlay": "overlay-3Bd1h",
	"fadeIn": "fadeIn-34KcZ",
	"button": "button-TQY8X",
	"partyIcon": "partyIcon-1C41q"
};
module.exports = exports;
