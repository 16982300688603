import { useEffect, useState } from 'react';

/**
 * Listen to state changes in Alt Stores and optionally map their value
 * @param {AltStore[]} stores Array of Alt Store instances. Changing the store after the hook has been called is **not supported**
 * @param {function} mapState Function to map the state of the Alt Stores
 */
const useAlt = (stores, mapState = x => x) => {
  const [state, setState] = useState(stores.map(store => store.getState()));

  useEffect(() => {
    const listeners = stores.map((store, index) => {
      // Add listener for each store
      return store.listen(storeState => {
        const nextState = state.slice(); // This is faster than [...state] https://measurethat.net/Benchmarks/Show/2667/0/arrayprototypeslice-vs-spread-operator
        nextState[index] = storeState;
        setState(nextState);
      });
    });

    return () => {
      // Remove all listeners
      listeners.forEach(unlisten => unlisten());
    };
  }, []);

  return mapState(state);
};

export default useAlt;
