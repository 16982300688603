import axios from 'axios';
import { XHR_STATUS, EMAIL_TOGGLES } from 'app-constants';
import alt from 'instances/altInstance';
import Analytics from 'instances/analytics';
import UserManager from 'managers/users';
import authController from 'controllers/auth';
import Settings from 'controllers/settings';
import User from 'models/user';
import EntityActions from './EntityActions';

export function getMe() {
  return axios
    .get(Settings.getLink('me'))
    .then(response => new User(response.data, { parse: true }));
}

function ensureActiveSubscription(user) {
  if (user.get('active_subscriptions')) {
    return Promise.resolve(user);
  }

  if (Settings.settingsFetched()) {
    return getMe();
  }

  return new Promise(resolve => {
    function callback() {
      getMe().then(resolve);
      Settings.off('sync', callback);
    }

    Settings.on('sync', callback);
  });
}

function trackEmailOptOutAnalytics(property, didOptOut) {
  const optInOut = didOptOut ? 'Opt Out' : 'Opt In';
  const label = property.replace(/_opt_out$/i, '');

  Analytics.track(`${optInOut}: ${label}`);
}

// These actions are triggered by the Auth controller's listen event.
export default alt.createActions({
  authenticateUser(user) {
    return dispatch => {
      // Populate the entities store with data for this user
      EntityActions.fetchUserEntities(user.get('id'));
      ensureActiveSubscription(user).then(newuser => dispatch(newuser));
    };
  },

  logout() {
    if (authController.getToken()) {
      authController.logout();
    }

    return null;
  },

  update(user) {
    return dispatch => {
      ensureActiveSubscription(user).then(newuser => dispatch(newuser));
    };
  },

  fetchUser() {
    getMe().then(user => this.fetchUserSuccess(user));
    return null;
  },
  fetchUserSuccess: x => x,

  userInputChange: user => user,

  updateUserProperty(user, key, value) {
    UserManager.updateProperty(user.id, key, value)
      .then(data => {
        if (EMAIL_TOGGLES.includes(key)) {
          trackEmailOptOutAnalytics(key, value);
        }

        this.updateUserPropertySuccess({
          user: new User(data, { parse: true }),
          property: key,
        });
      })
      .catch(error => {
        this.updateUserPropertyError({ error });

        if (error.type !== XHR_STATUS) {
          throw error;
        }
      });

    return key;
  },
  updateUserPropertySuccess: x => x,
  updateUserPropertyError: x => x,
});
