import { DISCOVER_PREMIUM_PAGE_VERSION } from 'app-constants';

export function determineVersion({ isPremiumOnlyUser, isUpsellOnlyUserWithOffer }) {
  if (isPremiumOnlyUser) {
    return DISCOVER_PREMIUM_PAGE_VERSION.PREMIUM_ONLY;
  }

  if (isUpsellOnlyUserWithOffer) {
    return DISCOVER_PREMIUM_PAGE_VERSION.UPSELL_ONLY;
  }

  return null;
}
