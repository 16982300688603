// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toggle-md_kI{position:absolute;bottom:9px;right:10px;width:30px;height:30px;padding-top:6px;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"toggle": "toggle-md_kI"
};
module.exports = exports;
