import React from 'react';
import { oneOf, func, number } from 'prop-types';
import styledModule from 'helpers/styledModule';
import { SUCCESS_DIALOG_VERSION } from 'app-constants';
import CSS from './styles.scss';
import { isMigratedUserVersion } from '../helper';

const PlayButtonOverlay = styledModule.div(CSS.overlay);
const StyledButton = styledModule.button(CSS.button);
const PartyIcon = styledModule.img(CSS.partyIcon);

const migrationGradient = 'linear-gradient(170.99deg, #ab32e5 -53.86%, #4900fe 100%)';
const defaultGradient = 'linear-gradient(344.62deg, #FF6255 0%, #FF0E61 100%)';

const getButtonStyle = version => ({
  background: isMigratedUserVersion(version) ? migrationGradient : defaultGradient,
});

export const PlayButton = ({ version, onClick }) => (
  <PlayButtonOverlay>
    <StyledButton type="button" onClick={onClick} style={getButtonStyle(version)}>
      Ik wil confetti
      <PartyIcon src="/img/illustrations/party.png" />
    </StyledButton>
  </PlayButtonOverlay>
);

PlayButton.propTypes = {
  version: oneOf([SUCCESS_DIALOG_VERSION.DEFAULT, SUCCESS_DIALOG_VERSION.WALLET_CONVERSION])
    .isRequired,
  onClick: func.isRequired,
};

const moreConfettiCopy = [
  'Geef me confetti!',
  'Nog meer confetti!',
  'Jaaa meer!',
  'Nog een beetje!',
  'Om het af te leren...',
];

export const PlayMoreButton = ({ version, onClick, currentSectionIndex }) => (
  <PlayButtonOverlay>
    <StyledButton type="button" onClick={onClick} style={getButtonStyle(version)}>
      {moreConfettiCopy[currentSectionIndex] || moreConfettiCopy[0]}
      <PartyIcon src="/img/illustrations/party.png" />
    </StyledButton>
  </PlayButtonOverlay>
);

PlayMoreButton.propTypes = {
  version: oneOf([
    SUCCESS_DIALOG_VERSION.DEFAULT,
    SUCCESS_DIALOG_VERSION.WALLET_CONVERSION,
    SUCCESS_DIALOG_VERSION.ITEM_DEEPLINK,
  ]).isRequired,
  onClick: func.isRequired,
  currentSectionIndex: number.isRequired,
};
