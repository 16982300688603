import React from 'react';
import { DeviceFrame } from '@blendle/lego';

import { Video } from 'components/Video';
import styledModule from 'helpers/styledModule';
import { translate } from 'instances/i18n';
import { H3, Body } from '../../Typography';
import SharedCSS from '../shared.scss';
import CSS from './styles.scss';
import USP from '../USP';

const Container = styledModule.div(SharedCSS.container);
const StyledContainer = styledModule(Container)(CSS.container);
const Content = styledModule.div(SharedCSS.content);
const UspWrapper = styledModule.div(SharedCSS.uspWrapper);
const StyledDeviceFrame = styledModule(DeviceFrame)(CSS.deviceWrapper);

const Covers = () => (
  <StyledContainer>
    <StyledDeviceFrame device="iPhoneX">
      <Video
        poster="https://publication.blendleimg.com/videos/features/discover-issues.png?auto=compress,format&fit=clip&w=236"
        src="https://static.blendle.nl/videos/features/discover-issues.mp4"
      />
    </StyledDeviceFrame>
    <Content>
      <H3>{translate('discover_premium.features.covers.title')}</H3>
      <Body>{translate('discover_premium.features.covers.text')}</Body>
      <UspWrapper>
        <USP>{translate('discover_premium.features.covers.usp_one')}</USP>
        <USP>{translate('discover_premium.features.covers.usp_two')}</USP>
        <USP>{translate('discover_premium.features.covers.usp_three')}</USP>
      </UspWrapper>
    </Content>
  </StyledContainer>
);

export default Covers;
