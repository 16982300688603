export default {
  name: 'test',
  api: 'http://localhost:3000/api_mock.json',
  tokens: 'http://localhost:3000/tokens',
  providerConfigurations: 'http://localhost:3000/provider_configurations.json',
  maintenanceFile: 'http://localhost:3000/maintenance.json',
  i18n: 'http://localhost:3000/i18n',
  ssl: false,
  analytics: 'http://localhost:3000/events',
  doubleclick_existing_user_id: 'DC-8327133/test/test_000+standard',
  doubleclick_pageview_id: 'DC-8327133/test/test_00+standard',
  doubleclick_success_id: 'DC-8327133/test/test_0+standard',
  facebookAppId: '1578814928872262',
  facebookVersion: 'v3.1',
  graphQLHost: 'https://graph.blendle-sandbox.com/graphql',
  segmentEnvironmentName: 'test',
};
