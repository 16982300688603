import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@blendle/lego';
import View from './view';

export default class ReactView extends View {
  render(props = {}) {
    const component = this.options.renderComponent(props);
    if (component) {
      // eslint-disable-next-line react/no-render-return-value, no-underscore-dangle
      this._instance = ReactDOM.render(<ThemeProvider>{component}</ThemeProvider>, this.el);
    } else {
      ReactDOM.unmountComponentAtNode(this.el);
    }
    return this;
  }

  getComponentInstance() {
    // eslint-disable-next-line no-underscore-dangle
    return this._instance;
  }

  unload() {
    ReactDOM.unmountComponentAtNode(this.el);
    View.prototype.unload.apply(this, arguments);
  }
}
