import Cookies from 'cookies-js';
import environment from 'environment';

const CONSENT_COOKIE_NAME = 'cookieConsent';

export const setCookieConsent = consent => {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 10); // ten years from now

  Cookies.set(CONSENT_COOKIE_NAME, JSON.stringify(consent), {
    expires: expirationDate,
    domain: environment.name === 'production' ? '.blendle.com' : '',
    secure: environment.ssl,
    path: '/',
  });
};

/**
 * @param {Object} parsedCookies
 * @returns {{hasGivenConsent: boolean}|undefined}
 */
export const getCookieConsent = () => {
  const consentCookie = Cookies.get(CONSENT_COOKIE_NAME);

  if (!consentCookie) {
    return undefined;
  }

  try {
    return JSON.parse(decodeURIComponent(consentCookie));
  } catch (error) {
    window.ErrorLogger.captureMessage(`Could not parse consentCookie, ${JSON.stringify(error)}`, {
      cookie: consentCookie,
    });

    return undefined;
  }
};
