import axios from 'axios';
import Settings from 'controllers/settings';
import Issue from 'models/issue';

const IssueManager = {
  fetchFavoriteIssues(userId) {
    const link = Settings.getLink('user_favourites', { user_id: userId });

    return axios.get(link).then(response => response.data._embedded.favourites);
  },
  fetchIssue(userId, bnlId) {
    const url = Settings.getLink('issue', {
      issue_id: bnlId,
      user_context: userId,
    });

    const issue = new Issue(null, { url });

    return issue.fetch().then(() => issue);
  },
  fetchTiles(userId, itemIds) {
    const link = Settings.getLink('user_item_tiles', {
      user_id: userId,
      item_ids: itemIds.join(','),
    });

    return axios
      .get(link, {
        headers: {
          accept: 'application/hal+json',
        },
      })
      .then(response => response.data);
  },
};

export default IssueManager;
