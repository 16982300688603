class SharedNavigationActions {
  constructor() {
    this.generateActions('setIssuePageActive', 'setIssuePageInactive');
  }

  saveCurrentPage = (page) => page;

  saveRecentIssuesPage = (scrollPosition) => scrollPosition;

  updateCurrentPage = (page) => page;

  removeLastPage = () => true;
}

export default SharedNavigationActions;
