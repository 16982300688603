import { getUTMParameters, makeUrlParamsFromObject } from 'helpers/url';
import Analytics from 'instances/analytics';

const buildQueryString = fragments => `${fragments.length > 0 ? '?' : ''}${fragments.join('&')}`;

export default function magicLoginRedirectUrl(redirectUrl) {
  const { protocol, hostname, port } = window.location;
  const urlPort = port ? `:${port}` : '';
  const urlBase = `${protocol}//${hostname}${urlPort}/login-email`;

  const utmData = Analytics._utm || getUTMParameters();
  const utmString = makeUrlParamsFromObject(utmData, 'utm_');

  const searchParamsFragments = [utmString, `redirect=${encodeURIComponent(redirectUrl)}`];
  const queryString = buildQueryString(searchParamsFragments.filter(Boolean));

  return `${urlBase}/{email_token}${queryString}`;
}
