// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2vQa4{background-image:url(\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8A9ff4/wmtA+QQodHfAAAAAElFTkSuQmCC\");display:flex;flex-direction:column;margin-top:40px;margin-left:-16px;margin-right:-16px;padding:16px}@media (min-width: 700px){.container-2vQa4{flex-direction:row;background-size:100% 87%;background-repeat:no-repeat;background-position-y:36px;margin-top:0;margin-left:0;margin-right:0;padding:0}}.device-1jjZ9{width:339px;margin:0 auto}@media (min-width: 850px){.device-1jjZ9{margin:0;margin-left:64px}}.content-372Ga{display:flex;flex-direction:column;justify-content:center;padding:16px 0;max-width:415px}@media (min-width: 700px){.content-372Ga{padding:16px;padding-right:15px}}@media (min-width: 850px){.content-372Ga{margin-left:72px}}.labelWrapper-2WSJP{display:flex;margin-bottom:10px}.kicker-16jhA{font-family:\"GT-Walsheim\",sans-serif;text-transform:uppercase;font-weight:600;font-size:11px;line-height:14px;letter-spacing:0.2em;padding-left:2px;align-self:center}.uspWrapper-ygKM6{margin-top:16px;margin-bottom:15px}.container-2vQa4{flex-direction:column-reverse}@media (min-width: 700px){.container-2vQa4{flex-direction:row;background-size:100% 91%;background-position-y:23px}}@media (min-width: 850px){.container-2vQa4{background-size:100% 79%;background-position-y:60px}}.content-372Ga{padding-right:0}@media (min-width: 850px){.content-372Ga{margin-left:66px}}@media (min-width: 850px){.device-1jjZ9{margin-left:70px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2vQa4",
	"device": "device-1jjZ9",
	"content": "content-372Ga",
	"labelWrapper": "labelWrapper-2WSJP",
	"kicker": "kicker-16jhA",
	"uspWrapper": "uspWrapper-ygKM6"
};
module.exports = exports;
