import { startsWith, __, any } from 'ramda';

const pathsAlwaysAllowed = pathToCheck =>
  any(startsWith(__, pathToCheck), [
    '/getpremium/why-premium',
    '/getpremium/channels',
    '/getpremium/publications',
    '/getpremium/confirm',
    '/getpremium/activate',
    '/getpremium/vodafone-connect',
    '/getpremium/welcome',
    '/getpremium/actie',
    '/payment/subscription',
    '/coupon',
    '/404',
    '/about',
    '/logout',
    '/settings',
    '/unsubscribe-newsletter',
    '/goodbye',
    '/login/reset',
    '/discover-premium',
  ]);

const pathIsLocked = pathToCheck => !pathsAlwaysAllowed(pathToCheck);

export default pathIsLocked;
