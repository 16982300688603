import React from 'react';
import styledModule from 'helpers/styledModule';
import { bool } from 'prop-types';
import { Label } from '@blendle/lego';
import { getBalanceLabelColor } from 'helpers/balanceLabelColor';
import useUser from 'hooks/useUser';
import formatCurrency from 'helpers/formatCurrency';
import CSS from './styles.scss';

const StyledLabel = styledModule(Label)(CSS.label);

const BalanceLabel = ({ useNeutralLowBalanceColor, ...rest }) => {
  const user = useUser();

  if (!user) {
    return null;
  }

  const balance = user.get('balance');
  const color = getBalanceLabelColor({ balance, useNeutralLowBalanceColor });

  return (
    <StyledLabel color={color} {...rest}>
      {formatCurrency(balance)}
    </StyledLabel>
  );
};

BalanceLabel.propTypes = {
  useNeutralLowBalanceColor: bool,
};

BalanceLabel.defaultProps = {
  useNeutralLowBalanceColor: false,
};

export default BalanceLabel;
