import { dropLast, last } from 'ramda';
import { DISCOVER_PAGE_TYPE } from 'app-constants';
import SharedNavigationActions from 'actions/SharedNavigationActions';

const removeLastPage = dropLast(1);

const initialPage = {
  provider: undefined,
  issue: undefined,
  scrollPosition: undefined,
  type: DISCOVER_PAGE_TYPE.RECENT_ISSUES,
  isIssuePageActive: false,
};

class SharedNavigationStore {
  constructor() {
    this.bindActions(SharedNavigationActions);

    this.state = {
      pages: [initialPage],
    };
  }

  onSaveCurrentPage(page) {
    const currentLastPage = last(this.state.pages);

    // Only save the current page if its not already saved.
    // This is the case when we navigate back to a page that we have already visited earlier
    if (currentLastPage.type === page.type) {
      return;
    }

    this.setState(({ pages }) => ({
      pages: [...pages, page],
    }));
  }

  // The recent issues page is always the starting point when navigating through the discover/kiosk pages
  // Therefor always overwriting any existing pages when we save the recent issues page
  onSaveRecentIssuesPage(scrollPosition) {
    this.setState(() => ({
      pages: [{ ...initialPage, scrollPosition }],
    }));
  }

  onUpdateCurrentPage(pageData) {
    this.setState(({ pages }) => {
      const currentPage = last(pages);

      const updatedCurrentPage = {
        ...currentPage,
        ...pageData,
      };

      return {
        pages: [...removeLastPage(pages), updatedCurrentPage],
      };
    });
  }

  onRemoveLastPage() {
    this.setState(({ pages }) => ({
      pages: removeLastPage(pages),
    }));
  }

  onSetIssuePageActive() {
    this.setState({ isIssuePageActive: true });
  }

  onSetIssuePageInactive() {
    this.setState({ isIssuePageActive: false });
  }
}

export default SharedNavigationStore;
