/**
 * Check if the passed standalone url should be opened. Due to a bug, the url was sometimes stored
 * as "null" (String). Therefor we visited the /null route, which stored "/null" for the next
 * time. We've fixed the bug where "null" is stored, but we still need the fix for user who already
 * have "null" or "/null" in their storage.
 * @param {String|undefined} standaloneUrl Standalone url from localStorage
 * @returns {Boolean}
 */
export function shouldOpenStandaloneUrl(standaloneUrl) {
  return !!standaloneUrl && !['null', '/null'].includes(standaloneUrl);
}

export default undefined;
