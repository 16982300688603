import alt from 'instances/altInstance';
import { STATUS_PENDING, STATUS_OK } from 'app-constants';
import EntityActions from 'actions/EntityActions';

class EntityStore {
  state = {
    status: null,
    userEntities: new Map(),
    entities: [],
  };

  constructor() {
    this.bindActions(EntityActions);
  }

  onFetchUserEntities() {
    this.setState({
      status: STATUS_PENDING,
    });
  }

  onFetchUserEntitiesSuccess(fetchedEntities) {
    const userEntities = new Map(this.state.userEntities);

    fetchedEntities.forEach(entity => {
      userEntities.set(entity.id, entity);
    });

    this.setState({
      status: STATUS_OK,
      userEntities,
    });
  }

  onSetEntityState({ entityId, entity }) {
    const userEntities = new Map(this.state.userEntities);

    userEntities.set(entityId, entity);

    this.setState({ userEntities });
  }

  onFetchAllEntitiesSuccess(entities) {
    this.setState({ entities });
  }
}

export default alt.createStore(EntityStore, 'EntityStore');
