import { asyncRoute } from 'helpers/routerHelpers';
import {
  FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA,
  HOME_ROUTE,
  FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER,
} from 'app-constants';
import { capabilityIsLockedAsync, capabilityIsLocked } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { pipe, not, __ } from 'ramda';

const hasUpsellWalletOffer = pipe(
  capabilityIsLocked(__, FEATURE_CAPABILITY_HAS_UPSELL_WALLET_OFFER),
  not,
);

function route(path) {
  return {
    module: 'recapture',
    path,
    onEnter: (_, replace, callback) => {
      capabilityIsLockedAsync(FEATURE_CAPABILITY_UPSELL_TO_PREMIUM_CTA).then(
        upsellToPremiumCtaIsLocker => {
          if (upsellToPremiumCtaIsLocker) {
            replace(HOME_ROUTE);
            return callback();
          }

          if (hasUpsellWalletOffer(FeatureCapabilitiesStore.getState())) {
            replace('/discover-premium');
          }

          return callback();
        },
      );
    },
    getComponents: asyncRoute((_, cb) => {
      require.ensure(
        [],
        () => {
          cb(null, {
            content: require('modules/recapture/containers/RecapturePageContainer'),
          });
        },
        'recapture',
      );
    }),
  };
}

export default [route('getpremium/why-premium')];
